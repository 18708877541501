import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  idrataFatture,
  queryIncassaFattura,
  scaricaFatturaDiCortesia,
  scaricaPDFEle,
  scaricaXMLFattura,
  queryInviaEmail,
  queryModificaStatoSTS,
  scaricaPDF,
  modificaDaIncassare,
  eliminaFattura,
  scaricaAttestazione,
} from '../../utility/QueryRiepilogoFatture'

export const idrata = createAsyncThunk(
  'riepilogoFatture/idrata',
  async (_, { getState, rejectWithValue }) => {
    const state = getState()
    const responseBody = await idrataFatture(
      state.user.user,
      state.riepilogoFatture.annoDiRiferimento
    )
    if (responseBody.error) {
      return rejectWithValue(responseBody.error)
    } else {
      return responseBody
    }
  }
)

export const impostaAnnoEIdrata = createAsyncThunk(
  'riepilogoFatture/impostaAnnoEIdrata',
  async (anno, { getState, rejectWithValue }) => {
    const state = getState()
    const responseBody = await idrataFatture(state.user.user, anno)
    if (responseBody.error) {
      return rejectWithValue(responseBody.error)
    } else {
      return { responseBody, anno }
    }
  }
)

export const incassaFattura = createAsyncThunk(
  'riepilogoFatture/incassaFattura',
  async (_, { getState, dispatch }) => {
    const state = getState()
    const responseBody = await queryIncassaFattura(
      state.user.user,
      state.riepilogoFatture.fatturaSelezionata.fattura.id,
      state.riepilogoFatture.dataIncasso
    )
    dispatch(idrata())
    return responseBody
  }
)

export const scaricaFatturaCortesia = createAsyncThunk(
  'riepilogoFatture/scaricaFatturaCortesia',
  async (idFattura, { getState }) => {
    const state = getState()
    const fatturaSelezionata = state.riepilogoFatture.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    const responseBody = await scaricaFatturaDiCortesia(
      state.user.user,
      fatturaSelezionata.fattura
    )
    return responseBody
  }
)

export const scaricaXML = createAsyncThunk(
  'riepilogoFatture/scaricaXML',
  async (idFattura, { getState }) => {
    const state = getState()
    const responseBody = await scaricaXMLFattura(state.user.user, idFattura)
    return responseBody
  }
)

export const scaricaPDFElettronica = createAsyncThunk(
  'riepilogoFatture/scaricaPDFElettronica',
  async (idFattura, { getState }) => {
    const state = getState()
    const fatturaSelezionata = state.riepilogoFatture.fatture.find(
      (fattura) => fattura.fattura.id === idFattura
    )
    const responseBody = await scaricaPDFEle(
      state.user.user,
      fatturaSelezionata.fattura
    )
    return responseBody
  }
)

export const inviaEmailFattura = createAsyncThunk(
  'riepilogoFatture/inviaEmailFattura',
  async (_, { getState }) => {
    const state = getState()
    const responseBody = await queryInviaEmail(
      state.user.user,
      state.riepilogoFatture.fatturaSelezionata.fattura.id,
      state.riepilogoFatture.fatturaSelezionata.email_cliente
    )
    return responseBody
  }
)

export const modificaStatoSTS = createAsyncThunk(
  'riepilogoFatture/modificaStatoSTS',
  async (idFattura, { getState }) => {
    const state = getState()
    const responseBody = await queryModificaStatoSTS(state.user.user, idFattura)
    console.log('responseBody', responseBody)
    return responseBody
  }
)

export const scaricaFatturaCartacea = createAsyncThunk(
  'riepilogoFatture/scaricaFatturaCartacea',
  async (idFattura, { getState }) => {
    const state = getState()
    const responseBody = await scaricaPDF(state.user.user, idFattura)
    return responseBody
  }
)

export const modificaInDaIncassare = createAsyncThunk(
  'riepilogoFatture/modificaInDaIncassare',
  async (idFattura, { getState, dispatch }) => {
    const state = getState()
    const responseBody = await modificaDaIncassare(state.user.user, idFattura)
    dispatch(idrata())
    return responseBody
  }
)

export const eliminazioneFattura = createAsyncThunk(
  'riepilogoFatture/eliminazioneFattura',
  async (idFattura, { getState, dispatch }) => {
    const state = getState()
    const responseBody = await eliminaFattura(state.user.user, idFattura)
    dispatch(idrata())
    return responseBody
  }
)

export const downloadAttestazione = createAsyncThunk(
  'riepilogoFatture/downloadAttestazione',
  async (_, { getState }) => {
    const state = getState()
    const responseBody = await scaricaAttestazione(
      state.user.user,
      state.riepilogoFatture.fatturaSelezionata.fattura
    )
    return responseBody
  }
)

import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  FormGroup,
  Spinner,
} from 'reactstrap'
import swal from 'sweetalert'

import { useSelector, useDispatch } from 'react-redux'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'
import {
  chiudiModalEmail,
  setEmailCliente,
} from '../../../app/store/riepilogoFatture/slice'
import { inviaEmailFattura } from '../../../app/store/riepilogoFatture/actions'

const ModalEmail = () => {
  const dati = useSelector(selectRiepilogoFattureData)
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const inviaEmail = async () => {
    if (
      dati.fatturaSelezionata?.email_cliente === null ||
      dati.fatturaSelezionata?.email_cliente === undefined ||
      dati.fatturaSelezionata?.email_cliente === ''
    ) {
      swal({
        title: 'Attenzione',
        text: "Non hai inserito l'email del cliente. Compila il campo e riprova",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setLoading(true)
    dispatch(inviaEmailFattura(dati.fatturaSelezionata?.email_cliente))
    setLoading(false)
  }

  return (
    <Modal isOpen={dati.modalEmailOpen} toggle={() => dispatch(chiudiModalEmail())}>
      <ModalHeader
        toggle={() => dispatch(chiudiModalEmail())}
        className="h4 font-regular font-grigio-scuro"
      >
        Invia la fattura per email
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Scrivi l&apos;indirizzo email del cliente e clicca il tasto &quot;Invia
          email&quot; per inviare la fattura 😊
        </h6>
        <br />
        <FormGroup>
          <Input
            type="text"
            placeholder="Inserisci l'email del cliente"
            value={
              dati.fatturaSelezionata?.email_cliente === null ||
              dati.fatturaSelezionata?.email_cliente === undefined
                ? ''
                : dati.fatturaSelezionata?.email_cliente
            }
            onChange={(e) => dispatch(setEmailCliente(e.target.value))}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => inviaEmail()} className="bottone-blu">
          {loading ? <Spinner size="sm" /> : 'Invia email'}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ModalEmail

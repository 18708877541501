import request from 'superagent'
import { saveAs } from 'file-saver'

export const idrataDichiarazione = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/idrata`)
    .send({ token: user.auth_token })
    .then((response) => response.body)

export const queryIdrataArchivio = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/idrata-archivio`)
    .send({ token: user.auth_token })
    .then((response) => response.body.archivio)
    .catch((e) => console.error(e.message))

export const chiudiStepFatture = (user, caricatiEIncassati) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/completa-step-fatture`)
    .send({ token: user.auth_token, caricatiEIncassati })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const chiudiStepContributi = (user, contributiVersati) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/completa-step-contributi`)
    .send({ token: user.auth_token, contributiVersati })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const chiudiStepQuiz = (user, risposte) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/completa-step-quiz`)
    .send({ token: user.auth_token, ...risposte })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const scaricaF24DaServer = (user, idF24, nomeFile) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/scarica-f24`)
    .send({ token: user.auth_token, idF24 })
    .then((response) =>
      fetch(response.body.url)
        .then((r) => r.blob())
        .then((blobFile) => {
          const file = new File([blobFile], nomeFile)
          saveAs(file)
        })
    )
    .catch((e) => console.error(e.message))

export const scaricaModelloDaServer = (user, idModello) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/scarica-modello`)
    .send({ token: user.auth_token, idModello })
    .then((response) =>
      fetch(response.body.url)
        .then((r) => r.blob())
        .then((blobFile) => {
          const file = new File([blobFile], 'Modello_Redditi.pdf')
          saveAs(file)
        })
    )
    .catch((e) => console.error(e.message))

export const inviaFile = (user, dati) => {
  if (dati.file === '') {
    alert('Non è selezionato nessun file')
    return
  }
  if (dati.file.size / 1073741824 > 0.02) {
    alert('La dimensione del file non può superare i 20MB')
    return
  }
  const formData = new FormData()
  formData.append('file', dati.file)
  formData.append('tappa', dati.tappa)
  formData.append('domanda', dati.domanda)
  formData.append('token', user.auth_token)
  return request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/upload`)
    .send(formData)
    .then((response) => response.body)
}

export const scaricaFileAllegatoUtente = (user, uuid) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/dichiarazione/scarica-file-allegato-utente`
    )
    .send({ token: user.auth_token, uuid })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const eliminaFile = (user, tappa, domanda, uuid) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/cancella-file`)
    .send({ token: user.auth_token, uuid, tappa, domanda })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const salvaRisp = (user, tappa, domanda, risposta) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/dichiarazione/salva-risposta`)
    .send({ token: user.auth_token, tappa, domanda, risposta })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

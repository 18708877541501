import React from 'react'
import { DropdownItem } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { scaricaFatturaCortesia } from '../../../app/store/riepilogoFatture/actions'

const ItemFatturaDiCortesia = (props) => {
  const dispatch = useDispatch()

  return (
    <DropdownItem
      onClick={() => dispatch(scaricaFatturaCortesia(props.dati.fattura.id))}
    >
      Scarica fattura di cortesia
    </DropdownItem>
  )
}

export default ItemFatturaDiCortesia

import React from 'react'
import PannelloCartacea from './PannelloCartacea'
import PannelloElettronica from './PannelloElettronica'
import PannelloPA from './PannelloPA'
import PannelloProforma from './PannelloProforma'
import PannelloNDCpa from './PannelloNDCpa'
import PannelloNDCpr from './PannelloNDCpr'
import PannelloNDDpr from './PannelloNDDpr'
import PannelloRettificaPr from './PannelloRettificaPr'
import PannelloAutofattura from './PannelloAutofattura'
import { useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const PannelloContainer = () => {
  const fiscomedData = useSelector(selectFiscomedData)

  return (
    <div>
      {fiscomedData.tipologiaFattura === 'Fattura cartacea' && <PannelloCartacea />}
      {fiscomedData.tipologiaFattura === 'Fattura elettronica' && (
        <PannelloElettronica />
      )}
      {fiscomedData.tipologiaFattura === 'Proforma' && <PannelloProforma />}
      {fiscomedData.tipologiaFattura === 'Nota di credito pa' && <PannelloNDCpa />}
      {fiscomedData.tipologiaFattura === 'Nota di credito pr' && <PannelloNDCpr />}
      {fiscomedData.tipologiaFattura === 'Nota di debito pr' && <PannelloNDDpr />}
      {fiscomedData.tipologiaFattura === 'Rettifica pr' && <PannelloRettificaPr />}
      {fiscomedData.tipologiaFattura === 'Fattura PA' && <PannelloPA />}
      {fiscomedData.tipologiaFattura === 'Autofattura' && <PannelloAutofattura />}
    </div>
  )
}

export default PannelloContainer

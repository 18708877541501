import React, { useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from 'chart.js'
import { Line } from 'react-chartjs-2'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
)

ChartJS.register(ArcElement, Tooltip, Legend)

const GraficoFatturatoMensile = (props) => {
  const [mesiCorrente, setMesiCorrente] = useState(props.fatturatoAnnoCorrente)
  const [mesiPrecedente, setMesiPrecedente] = useState(props.fatturatoAnnoPrecedente)

  useEffect(() => {
    setMesiCorrente(props.fatturatoAnnoCorrente)
    setMesiPrecedente(props.fatturatoAnnoPrecedente)
  }, [props])

  const [data, setData] = useState({
    labels: [
      'Gennaio',
      'Febbraio',
      'Marzo',
      'Aprile',
      'Maggio',
      'Giugno',
      'Luglio',
      'Agosto',
      'Settembre',
      'Ottobre',
      'Novembre',
      'Dicembre',
    ],
    datasets: [
      {
        label: new Date().getFullYear() - 1,
        data: [
          mesiPrecedente[0],
          mesiPrecedente[1],
          mesiPrecedente[2],
          mesiPrecedente[3],
          mesiPrecedente[4],
          mesiPrecedente[5],
          mesiPrecedente[6],
          mesiPrecedente[7],
          mesiPrecedente[8],
          mesiPrecedente[9],
          mesiPrecedente[10],
          mesiPrecedente[11],
        ],
        backgroundColor: '#f2b705',
        borderColor: '#f2b705',
      },
      {
        label: new Date().getFullYear(),
        data: [
          mesiCorrente[0],
          mesiCorrente[1],
          mesiCorrente[2],
          mesiCorrente[3],
          mesiCorrente[4],
          mesiCorrente[5],
          mesiCorrente[6],
          mesiCorrente[7],
          mesiCorrente[8],
          mesiCorrente[9],
          mesiCorrente[10],
          mesiCorrente[11],
        ],
        backgroundColor: '#66d98e',
        borderColor: '#66d98e',
      },
    ],
  })

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  }

  useEffect(() => {
    setData({
      labels: [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
      ],
      datasets: [
        {
          label: new Date().getFullYear() - 1,
          data: [
            mesiPrecedente[0],
            mesiPrecedente[1],
            mesiPrecedente[2],
            mesiPrecedente[3],
            mesiPrecedente[4],
            mesiPrecedente[5],
            mesiPrecedente[6],
            mesiPrecedente[7],
            mesiPrecedente[8],
            mesiPrecedente[9],
            mesiPrecedente[10],
            mesiPrecedente[11],
          ],
          backgroundColor: '#f2b705',
          borderColor: '#f2b705',
          lineTension: 0.05,
        },
        {
          label: new Date().getFullYear(),
          data: [
            mesiCorrente[0],
            mesiCorrente[1],
            mesiCorrente[2],
            mesiCorrente[3],
            mesiCorrente[4],
            mesiCorrente[5],
            mesiCorrente[6],
            mesiCorrente[7],
            mesiCorrente[8],
            mesiCorrente[9],
            mesiCorrente[10],
            mesiCorrente[11],
          ],
          backgroundColor: '#66d98e',
          borderColor: '#66d98e',
          lineTension: 0.05,
        },
      ],
    })
  }, [mesiCorrente, mesiPrecedente])

  return <Line options={options} data={data} />
}

export default GraficoFatturatoMensile

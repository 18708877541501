import React from 'react'
import { Row, Col } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import { selectRiepilogoFattureData } from '../../app/store/riepilogoFatture/selectors'
import { impostaAnnoEIdrata } from '../../app/store/riepilogoFatture/actions'

const SelettoreAnno = () => {
  const dati = useSelector(selectRiepilogoFattureData)
  const dispatch = useDispatch()

  const cambiaAnno = (anno) => dispatch(impostaAnnoEIdrata(anno))

  return (
    <Row>
      <Col>
        {((Math.min(...dati.anniConFattureEmesse) < dati.annoDiRiferimento &&
          dati.anniConFattureEmesse.length > 0) ||
          dati.annoDiRiferimento > dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() => cambiaAnno(dati.annoDiRiferimento - 1)}
          >
            <i className="bi bi-arrow-left-square float-end"></i>
          </h3>
        )}
      </Col>
      <Col>
        <center>
          <h3 className="font-regular">
            Anno di emissione {dati.annoDiRiferimento}
          </h3>
        </center>
      </Col>
      <Col>
        {(Math.max(...dati.anniConFattureEmesse) > dati.annoDiRiferimento ||
          dati.annoDiRiferimento < dati.annoCorrente) && (
          <h3
            className="font-regular"
            style={{ cursor: 'pointer' }}
            onClick={() => cambiaAnno(dati.annoDiRiferimento + 1)}
          >
            <i className="bi bi-arrow-right-square"></i>
          </h3>
        )}
      </Col>
    </Row>
  )
}

export default SelettoreAnno

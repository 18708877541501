import moment from 'moment'
import 'moment/locale/it'

export const currentYear = moment().year()
const nextYear = currentYear + 1
const lastYear = currentYear - 1

export const arrayScadenze = [
  [
    moment('15/01/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a dicembre ' +
      lastYear,
  ],
  [
    moment('31/01/' + currentYear, 'DD/MM/YYYY'),
    'Inserimento dati tessera sanitaria',
  ],
  [
    moment('15/02/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a gennaio ' +
      currentYear,
  ],
  [
    moment('28/02/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento imposta di bollo delle fatture elettroniche',
  ],
  [
    moment('15/03/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a febbraio ' +
      currentYear,
  ],
  [
    moment('15/04/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a marzo ' +
      currentYear,
  ],

  [moment('30/04/' + currentYear, 'DD/MM/YYYY'), 'Quota A ' + currentYear],
  [
    moment('15/05/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a aprile ' +
      currentYear,
  ],
  [
    moment('15/06/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a maggio ' +
      currentYear,
  ],
  [
    moment('31/07/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento saldo imposta sostitutiva relativa al ' +
      lastYear +
      ' e primo acconto imposta relativa al ' +
      currentYear +
      ' per tutte le persone che nel ' +
      lastYear +
      ' avevano già la Partita IVA',
  ],
  [
    moment('15/07/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a giugno ' +
      currentYear,
  ],
  [
    moment('15/08/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a luglio ' +
      currentYear,
  ],
  [
    moment('06/09/' + currentYear, 'DD/MM/YYYY'),
    'Invio Modello D relativo al ' + lastYear,
  ],
  [
    moment('15/09/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a agosto ' +
      currentYear,
  ],
  [
    moment('30/09/' + currentYear, 'DD/MM/YYYY'),
    'Inserimento dati tessera sanitaria',
  ],
  [
    moment('15/10/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a settembre ' +
      currentYear,
  ],

  [moment('31/10/' + currentYear, 'DD/MM/YYYY'), 'Quota B relativa al ' + lastYear],
  [
    moment('15/11/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a ottobre ' +
      currentYear,
  ],

  [
    moment('30/11/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento imposta di bollo delle fatture elettroniche',
  ],
  [
    moment('30/11/' + currentYear, 'DD/MM/YYYY'),
    'Pagamento secondo acconto imposta sostitutiva relativa al ' + currentYear,
  ],
  [
    moment('15/12/' + currentYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a novembre ' +
      currentYear,
  ],
  [
    moment('15/01/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a dicembre ' +
      currentYear,
  ],
  [
    moment('15/02/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a gennaio ' +
      nextYear,
  ],
  [
    moment('28/02/' + nextYear, 'DD/MM/YYYY'),
    'Pagamento imposta di bollo delle fatture elettroniche',
  ],
  [
    moment('15/03/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a febbraio ' +
      nextYear,
  ],
  [
    moment('15/04/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a marzo ' +
      nextYear,
  ],
  [moment('30/04/' + nextYear, 'DD/MM/YYYY'), 'Quota A ' + nextYear],
  [
    moment('15/05/' + nextYear, 'DD/MM/YYYY'),
    'Invio Autofatture (entro il 15) e pagamento IVA (entro il 16) delle fatture estere ricevute a aprile ' +
      nextYear,
  ],
  [
    moment('31/05/' + nextYear, 'DD/MM/YYYY'),
    'Pagamento imposta di bollo delle fatture elettroniche',
  ],
]

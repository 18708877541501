import React, { useEffect, useState } from 'react'
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, Tooltip, Legend)

const options = {
  plugins: {
    legend: {
      position: 'right',
    },
  },
}

const GraficoIncassatoIncassabile = (props) => {
  let incassato = props.incassato
  const incassabile = props.incassabile
  if (incassato === 0 && incassabile === 0) {
    incassato = 1
  }
  const [data, setData] = useState({
    labels: ['Incassato', 'Incassabile'],
    datasets: [
      {
        label: '# of Votes',
        data: [incassato, incassabile],
        backgroundColor: ['#66d98e', '#f2b705'],
        borderWidth: 0,
      },
    ],
  })

  useEffect(() => {
    let incassato = props.incassato
    const incassabile = props.incassabile
    if (incassato === 0 && incassabile === 0) {
      incassato = 1
    }
    setData({
      labels: ['Incassato', 'Incassabile'],
      datasets: [
        {
          label: '# of Votes',
          data: [incassato, incassabile],
          backgroundColor: ['#66d98e', '#f2b705'],
          borderWidth: 0,
        },
      ],
    })
  }, [props.incassato, props.incassabile])

  return (
    <Doughnut
      data={data}
      options={options}
      style={{ marginTop: '-30px', marginBottom: '-30px' }}
    />
  )
}

export default GraficoIncassatoIncassabile

import React from 'react'
import { Container } from 'reactstrap'
import { useParams } from 'react-router-dom'
import ErroreTrasmissione from './component/ErroreTrasmissione'
import InviaEmail from './component/InviaEmail'
import Fine from './component/Fine'

const EsitoFatturaElettronica = () => {
  const { esito, idFattura } = useParams()
  const [schermata, setSchermata] = React.useState('email')

  const emailInviata = () => {
    setSchermata('fine')
  }

  return (
    <Container className="mt-5">
      {esito === 'trasmessa' ? (
        schermata === 'email' ? (
          <InviaEmail idFattura={idFattura} emailInviata={emailInviata} />
        ) : (
          <Fine idFattura={idFattura} />
        )
      ) : (
        <ErroreTrasmissione />
      )}
    </Container>
  )
}

export default EsitoFatturaElettronica

import React, { useContext } from 'react'
import { Alert, Table } from 'reactstrap'
import { ImportaBorsaContext } from '../../app/context/ImportaBorsa'
import moment from 'moment'

const Tabella = () => {
  const importaBorseContext = useContext(ImportaBorsaContext)

  const cancellaFile = (uuidFile, nomeFile) => {
    if (window.confirm('Cancellare definitavemente il file ' + nomeFile + '?')) {
      importaBorseContext.cancellaFile(uuidFile)
    }
  }

  const tabella = importaBorseContext.dati.borse.map((element, i) => (
    <tr key={i}>
      <td>
        <span className="testo-verde">
          {moment(element.data_incasso).format('MM/YYYY')}
        </span>
      </td>
      <td>
        {element.tipologia === 'borsa_mmg'
          ? 'Borsa MMG'
          : 'Borsa di specializzazione'}
      </td>
      <td>
        {element.tipologia === 'borsa_specializzazione' ? (
          '-'
        ) : (
          <>{element.importo / 100}€</>
        )}
      </td>
      <td
        className="text-primary"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          importaBorseContext.scaricaFile(
            element.uuid_file,
            element.nome_originale_file
          )
        }
      >
        {element.nome_originale_file.substring(0, 20)}{' '}
        <i className="bi bi-cloud-arrow-down"></i>
      </td>
      <td className="font-regular">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() => importaBorseContext.modificaBorsa(element.uuid_file)}
        >
          <i className="bi bi-pencil"></i>
        </center>
      </td>
      <td className="text-danger">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() =>
            cancellaFile(element.uuid_file, element.nome_originale_file)
          }
        >
          <i className="bi bi-trash"></i>
        </center>
      </td>
    </tr>
  ))

  return (
    <>
      {importaBorseContext.dati.borse.length === 0 ? (
        <Alert color="info">
          <i className="bi bi-info-circle"></i> Nessuna borsa importata, controlla
          negli anni precedenti
        </Alert>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca">
          <thead>
            <tr>
              <th className="h6 font-regular">Mese</th>
              <th className="h6 font-regular">Tipologia</th>
              <th className="h6 font-regular">Importo</th>
              <th className="h6 font-regular">Scarica</th>
              <th className="h6 font-regular">Modifica</th>
              <th className="h6 font-regular">Cancella</th>
            </tr>
          </thead>
          <tbody>{tabella}</tbody>
        </Table>
      )}
    </>
  )
}

export default Tabella

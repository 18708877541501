import React from 'react'
import DecorrenzaTerminiPA from './DecorrenzaTerminiPA'
import EsitoPositivoPA from './EsitoPositivoPA'
import ImpossibilitaDiRecapito from './ImpossibilitaDiRecapito'
import MancataConsegnaPA from './MancataConsegnaPa'
import RicevutaDiConsegna from './RicevutaDiConsegna'
import RicevutaDiConsegnaPA from './RicevutaDiConsegnaPA'
import ScartoFattura from './ScartoFattura'
import ScartoVariazione from './ScartoVariazione'
import EsitoNegativoPA from './EsitoNegativoPA'
import Attestazione from './Attestazione'
import ScartoFatturaPA from './ScartoFatturaPA'
import MancataConsegnaPR from './MancataConsegnaPR'
import MancataConsegnaAutofattura from './MancataConsegnaAutofattura'
import VerificaSdI from './VerificaSdI'
import { getLast } from '../../../app/utility/array'

const ContainerEsito = (props) => {
  const esitoPA = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'decorrenza_termini':
        return <DecorrenzaTerminiPA />
      case 'notifica_esito_positivo':
        return <EsitoPositivoPA />
      case 'notifica_esito_negativo':
        return <EsitoNegativoPA dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegnaPA />
      case 'attestazione_trasmissione':
        return <Attestazione dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaPA />
      case 'scarto':
        return <ScartoFatturaPA dati={props.dati} />
      default:
        return null
    }
  }

  const esitoPrivato = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegna />
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'scarto':
        return <ScartoFattura dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaPR />
      default:
        return null
    }
  }

  const esitoAutofattura = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegna />
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'scarto':
        return <ScartoFatturaPA dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaAutofattura />
      default:
        return null
    }
  }

  const esitoDebito = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegna />
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'scarto':
        return <ScartoVariazione dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaPR />
      default:
        return null
    }
  }

  const esitoCredito = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegna />
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'scarto':
        return <ScartoVariazione dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaPR />
      default:
        return null
    }
  }

  const esitoDebitoPA = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'decorrenza_termini':
        return <DecorrenzaTerminiPA />
      case 'notifica_esito_positivo':
        return <EsitoPositivoPA />
      case 'notifica_esito_negativo':
        return <EsitoNegativoPA dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegnaPA />
      case 'attestazione_trasmissione':
        return <Attestazione dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaPA />
      case 'scarto':
        return <ScartoFatturaPA dati={props.dati} />
      default:
        return null
    }
  }

  const esitoCreditoPA = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'impossibilita_recapito':
        return <ImpossibilitaDiRecapito />
      case 'decorrenza_termini':
        return <DecorrenzaTerminiPA />
      case 'notifica_esito_positivo':
        return <EsitoPositivoPA />
      case 'notifica_esito_negativo':
        return <EsitoNegativoPA dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <RicevutaDiConsegnaPA />
      case 'attestazione_trasmissione':
        return <Attestazione dati={props.dati} />
      case 'mancata_consegna':
        return <MancataConsegnaPA />
      case 'scarto':
        return <ScartoFatturaPA dati={props.dati} />
      default:
        return null
    }
  }

  return (
    <>
      {props.dati.esito.length === 0 &&
        (props.dati.fattura.tipologia === 'Fattura elettronica' ||
          props.dati.fattura.tipologia === 'Nota di credito' ||
          props.dati.fattura.tipologia === 'Nota di debito' ||
          props.dati.fattura.tipologia === 'Nota di credito PA' ||
          props.dati.fattura.tipologia === 'Nota di debito PA' ||
          props.dati.fattura.tipologia === 'Fattura PA' ||
          props.dati.fattura.tipologia === 'Autofattura') && <VerificaSdI />}
      {props.dati.fattura.tipologia === 'Fattura PA' && esitoPA()}
      {props.dati.fattura.tipologia === 'Fattura elettronica' && esitoPrivato()}
      {props.dati.fattura.tipologia === 'Nota di credito' && esitoCredito()}
      {props.dati.fattura.tipologia === 'Nota di debito' && esitoDebito()}
      {props.dati.fattura.tipologia === 'Nota di credito PA' && esitoCreditoPA()}
      {props.dati.fattura.tipologia === 'Nota di debito PA' && esitoDebitoPA()}
      {props.dati.fattura.tipologia === 'Autofattura' && esitoAutofattura()}
    </>
  )
}

export default ContainerEsito

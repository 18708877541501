import React, { useEffect, useState } from 'react'
import { Card, FormGroup, Input } from 'reactstrap'
import ModaleNumeroFattura from '../Componenti/ModaleNumeroFattura'
import ModaleNumeroAutofattura from '../Componenti/ModaleNumeroAutofattura'
import 'moment/locale/it'
import classNames from 'classnames'
import { aggiornaTipologiaFattura } from '../../../app/store/fiscomed/slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const HeaderFattura = () => {
  const dispatch = useDispatch()
  const [colore, setColore] = useState('#0583f2')
  const fiscomedData = useSelector(selectFiscomedData)

  useEffect(() => {
    if (fiscomedData.tipologiaFattura === 'Fattura cartacea') {
      setColore('#66d98e')
    }
    if (fiscomedData.tipologiaFattura === 'Fattura elettronica') {
      setColore('#0583f2')
    }
    if (fiscomedData.tipologiaFattura === 'Fattura PA') {
      setColore('#f2b705')
    }
    if (fiscomedData.tipologiaFattura === 'Proforma') {
      setColore('white')
    }
    if (fiscomedData.tipologiaFattura === 'Autofattura') {
      setColore('#023e73')
    }
  }, [fiscomedData.tipologiaFattura])

  const cambiaTipologiaFattura = (e) =>
    dispatch(aggiornaTipologiaFattura(e.target.value))

  return (
    <Card
      className="mt-4"
      body
      style={{
        borderRadius: '10px',
        border: 'none',
        backgroundColor: colore,
      }}
    >
      <div className="d-flex justify-content-between">
        <FormGroup>
          <Input
            type="select"
            name="select"
            id="exampleSelect"
            value={fiscomedData.tipologiaFattura}
            onChange={(e) => cambiaTipologiaFattura(e)}
            style={{ maxWidth: '220px' }}
            className="font-regular mt-3"
          >
            <option>Fattura elettronica</option>
            <option>Fattura cartacea</option>
            <option>Fattura PA</option>
            <option>Proforma</option>
            <option>Autofattura</option>
          </Input>
        </FormGroup>
        {fiscomedData.tipologiaFattura !== 'Proforma' &&
          fiscomedData.tipologiaFattura !== 'Autofattura' && (
            <span
              className={classNames('h5 font-medium interlinea-30 text-inline', {
                'text-white': colore !== 'white',
              })}
            >
              <ModaleNumeroFattura />
            </span>
          )}
        {fiscomedData.tipologiaFattura === 'Autofattura' && (
          <span
            className={classNames('h5 font-medium interlinea-30 text-inline', {
              'text-white': colore !== 'white',
            })}
          >
            <ModaleNumeroAutofattura />
          </span>
        )}
      </div>
    </Card>
  )
}

export default HeaderFattura

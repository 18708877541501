import React, { useState } from 'react'
import { Row, Col } from 'reactstrap'

const DomandaRisposta = ({ children }) => {
  const [aperto, setAperto] = useState(false)

  return (
    <div className="mb-4">
      <Row>
        <Col xs="11">
          <h6 className="h6-landing" style={{ fontWeight: 600 }}>
            {children[0]}
          </h6>
          {aperto && <h6 className="h6-landing">{children[1]}</h6>}
        </Col>
        <Col xs="1">
          {aperto ? (
            <i
              className="float-end bi bi-dash-circle text-muted"
              style={{ cursor: 'pointer' }}
              onClick={() => setAperto(!aperto)}
            ></i>
          ) : (
            <i
              className="float-end bi bi-plus-circle text-muted"
              style={{ cursor: 'pointer' }}
              onClick={() => setAperto(!aperto)}
            ></i>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default DomandaRisposta

import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import ItemFatturaDiCortesia from './ItemFatturaDiCortesia'
import { useDispatch } from 'react-redux'
import {
  apriModalEmail,
  apriModalIncasso,
} from '../../../app/store/riepilogoFatture/slice'
import {
  scaricaXML,
  scaricaPDFElettronica,
  modificaInDaIncassare,
} from '../../../app/store/riepilogoFatture/actions'

const OpzioniConsegnataVariazione = (props) => {
  const dispatch = useDispatch()

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => dispatch(apriModalEmail(props.dati?.fattura?.id))}
          >
            Invia per email
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => dispatch(scaricaXML(props.dati.fattura.id))}>
            Scarica xml
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(scaricaPDFElettronica(props.dati.fattura.id))}
          >
            Scarica formato ministeriale
          </DropdownItem>
          <ItemFatturaDiCortesia dati={props.dati} />
          <DropdownItem divider />
          {props.dati.fattura.incassata === 'Si' ? (
            <DropdownItem
              onClick={() => dispatch(modificaInDaIncassare(props.dati.fattura.id))}
            >
              Modifica in &quot;da incassare&quot;
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={() => dispatch(apriModalIncasso(props.dati.fattura.id))}
            >
              Incassa
            </DropdownItem>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniConsegnataVariazione

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { Link } from 'react-router-dom'

export const BloccoF24Modal = ({ isOpen, onClose }) => (
  <Modal isOpen={isOpen} toggle={onClose}>
    <ModalHeader>Il tuo F24 è scaduto 😥</ModalHeader>
    <ModalBody>
      <h6 className="font-regular interlinea-30">
        Sembra che tu stia cercando di scaricare questo F24 con qualche giorno di
        ritardo. Se non lo hai ancora pagato o trasmesso, sappi che{' '}
        <b>non puoi più utilizzarlo</b>.
        <br />
        <br /> <b>Contatta il tuo o la tua commercialista</b> che preparerà per te un
        altro F24 con le sanzioni e gli interessi calcolati in base a quando vorrai
        pagare. Trovi la sua email <Link to="/chat-commercialista">qui</Link>.
        <br />
        <br />
        Questa è una prestazione extra e costa 12 euro. Sarà il commercialista che ti
        segue a inviarti il link per il pagamento, dopo che lo avrai contattato.
      </h6>
    </ModalBody>
  </Modal>
)

import React, { useRef, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'reactstrap'
import DomandaRisposta from '../landing/DomandaRisposta'

const CodiceCollega = () => {
  const fineDivColorato = useRef(null)
  const [altezzaDiv, setAltezzaDiv] = useState(0)

  useEffect(() => {
    setAltezzaDiv(fineDivColorato.current.offsetTop)
  }, [])

  return (
    <div>
      <Helmet>
        <title>Azzera il tuo abbonamento</title>
        <meta property="title" content="Azzera il tuo abbonamento" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/codice-collega" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Azzera il tuo abbonamento" />
        <meta
          property="og:description"
          content="Pagina che descrive i termini dell'iniziativa dal nome azzera il tuo abbonamento"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container className="container-landing">
        <div
          className="header-codice-collega"
          style={{ height: altezzaDiv + 'px' }}
        />
        <br />
        <br />
        <br />
        <br />
        <br />
        <center>
          <h1 className="titolo-landing-fiscomed font-medium">
            Lo sai che puoi
            <br /> <span className="testo-blu">azzerare il tuo abbonamento?</span>
          </h1>
        </center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <span ref={fineDivColorato} />
      </Container>
      <Container>
        <br />
        <br />
        <center>
          <span className="etichetta">Ecco come fare</span>
        </center>
        <Row>
          <Col xs={1} className="mt-5">
            <div style={{ marginTop: '-10px' }}>
              <i
                className="bi bi-check-circle testo-verde"
                style={{ fontSize: '70px' }}
              ></i>
            </div>
          </Col>
          <Col xs={11} className="mt-5">
            <h5 className="font-regular interlinea-30 mt-3">
              Nella tua home di Fiscomed, proprio nella prima pagina, puoi leggere il
              tuo <b>&quot;codice collega&quot;</b>. Si tratta di un vero e proprio
              codice sconto personale.
            </h5>
          </Col>
          <Col xs={1} className="mt-5">
            <div style={{ marginTop: '-10px' }}>
              <i
                className="bi bi-check-circle testo-verde"
                style={{ fontSize: '70px' }}
              ></i>
            </div>
          </Col>
          <Col xs={11} className="mt-5">
            <h5 className="font-regular interlinea-30 mt-3">
              Se ti trovi bene con noi e ti va di suggerire Fiscomed ad amici e
              colleghi, <b>diffondi</b> il <b>tuo codice</b>. Se una persona lo
              utilizza per affidarsi a Fiscomed, entrambe avrete uno sconto
              sull&apos;abbonamento.
            </h5>
          </Col>
          <Col xs={1} className="mt-5">
            <div style={{ marginTop: '-10px' }}>
              <i
                className="bi bi-check-circle testo-verde"
                style={{ fontSize: '70px' }}
              ></i>
            </div>
          </Col>
          <Col xs={11} className="mt-5">
            <h5 className="font-regular interlinea-30 mt-3">
              Lo sconto può essere applicato sia sull&apos;abbonamento <b>mensile</b>{' '}
              che sull&apos;<b>annuale</b> ed è cumulabile. Più persone usano il tuo
              codice collega più lo sconto per te aumenta
            </h5>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <center>
          <span className="titolo-3 mt-5">
            Come sempre, con un <span className="testo-verde">esempio</span> (anzi,
            due)
            <br />
            possiamo spiegarci meglio 😊
          </span>
        </center>
        <br />
        <br />
        <br />
        <Row>
          <Col xs={6} className="pr-5">
            <div className="div-giallo-guida p-4">
              <h4 className="testo-arancione">ESEMPIO #1</h4>
              <br />
              <h5 className="font-regular interlinea-30">
                Francesca è una cliente Fiscomed e ha un abbonamento mensile.
                <br />
                <br />È molto contenta di aver scelto questo servizio e, sapendo che
                un suo amico vuole aprire la Partita IVA, decide di dargli il suo
                codice collega.
                <br />
                <br />
                <b>Che cosa succede?</b>
              </h5>
            </div>
          </Col>
          <Col xs={6} className="pl-5">
            <br />
            <h5 className="font-regular interlinea-30">
              Se il suo amico sottoscrive un abbonamento di qualsiasi tipo e viene
              attivato il suo profilo sulla piattaforma, succederanno due cose:
              <br />
              <br />
              1. <b>Lui</b> avrà uno sconto immediato di <b>27 €</b>
              <br />
              2. <b>Francesca</b> accumulerà un credito di <b>37 €</b>
              <br />
              <br />
              Questo significa che, nel momento in cui dovrà pagare il suo
              abbonamento mensile, a Francesca verranno scalati 0 €.
              <br />
              Sì, proprio così: avrà praticamente un <b>mese gratis</b>
            </h5>
            <br />
          </Col>
          <Col xs={6} className="pr-5 mt-5">
            <div className="div-verde-guida p-4">
              <h4 className="testo-verde">ESEMPIO #2</h4>
              <br />
              <h5 className="font-regular interlinea-30">
                Carlo è un cliente Fiscomed che ha sottoscritto un abbonamento
                annuale.
                <br />
                <br />
                Una sua amica si è affidata a un altro servizio, del quale però non è
                per nulla soddisfatta. Carlo allora le consiglia di passare a
                Fiscomed e le da il suo codice collega.
                <br />
                <br />
                <b>Che cosa succede?</b>
              </h5>
            </div>
          </Col>
          <Col xs={6} className="pl-5 mt-5">
            <br />
            <h5 className="font-regular interlinea-30">
              Se la sua amica decide di passare a Fiscomed avrà subito uno sconto di{' '}
              <b>27 €</b>, mentre Carlo guadagnerà un credito di <b>37 €</b> nel
              momento in cui il profilo della sua amica verrà attivato.
              <br />
              <br />
              Quando arriverà il momento di rinnovare il suo abbonamento annuale,
              Carlo pagherà <b>363 € invece di 400 €</b> proprio perché ha portato
              un&apos;amica.
              <br />
              <br />E se in un anno convince due amici a passare a Fiscomed? Beh,
              allora per lui l&apos;abbonamento annuale sarà di 326 €. E cosi via
            </h5>
            <br />
          </Col>
        </Row>
        <br />
        <br />
        <center>
          <span className="titolo-3 mt-5">Le domande più frequenti</span>
          <h5 className="font-regular interlinea-30">
            Se hai altri dubbi scrivici su WhatsApp
          </h5>
        </center>
        <br />
        <br />
        <DomandaRisposta>
          <>Cos&apos;è &quot;Azzera il tuo abbonamento&quot;?</>
          <>
            È un programma che incentiva il passaparola. Quando qualcuno attiva un
            abbonamento con il tuo codice collega, riceverai uno sconto di 37 €.
            Nello specifico, riceverai il credito nel momento esatto in cui viene
            attivato il profilo della persona invitata sulla piattaforma. Inoltre, la
            persona che attiva l&apos;abbonamento otterrà uno sconto di 27 € che
            verrà applicato subito, al momento dell&apos;acquisto.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Come funziona l&apos;iniziativa &quot;Azzera il tuo abbonamento&quot;?</>
          <>
            Per ogni profilo attivato conseguentemente alla sottoscrizione di un
            abbonamento accumulerai un credito di 37 €. Questo sconto verrà applicato
            sulla prossima fattura che dovrai pagare per il tuo abbonamento a
            Fiscomed. Facciamo un esempio: hai attivato l&apos;abbonamento a Fiscomed
            il 5 gennaio 2024 e quindi ogni 5 del mese ti verranno addebitati i 37 €
            dell&apos;abbonamento. Il 15 marzo un tuo collega sottoscrive un
            abbonamento a Fiscomed con il tuo codice e tu accumuli 37 € di credito.
            Bene: il 5 aprile, al momento del saldo della mensilità, verrà applicato
            lo sconto di 37 € e quindi non pagherai nulla.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Dove trovo le condizioni dell&apos;iniziativa?</>
          <>
            Le puoi trovare{' '}
            <a href="/documenti/condizioni_azzera_abbonamento.pdf" target="_blank">
              qui
            </a>
            . Sappi però che, come tutti i documenti legali, sono scritte un po’ in
            burocratese. Se qualcosa non ti fosse chiaro, scrivici su WhatsApp.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Quante persone posso invitare?</>
          <>
            Non c’è un limite. Considerando che ogni anno si laureano circa 10.000
            persone in Medicina e Chirurgia hai un sacco di colleghe e colleghi da
            invitare. Diciamo che per riuscire ad avere un anno gratis…
            <ul>
              <li>
                se hai l’abbonamento mensile, dovresti far in modo che 12 persone
                sottoscrivano un abbonamento a Fiscomed
              </li>
              <li>
                se hai l’abbonamento annuale, hai bisogno che lo facciano 11 persone.
              </li>
            </ul>
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            Ho il mensile e ho invitato più di una persona prima che mi sia stato
            addebitato l’importo del mese. Cosa succede?
          </>
          <>
            Facciamo finta che hai invitato tre persone prima di dover saldare la
            fattura del mese con Fiscomed. Se tre persone sottoscrivono un
            abbonamento con il tuo codice, tu accumuli un credito di 111 €, che
            equivalgono a 3 mensilità. Quindi per 3 mesi ti verranno addebitati 0 €.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Quanto dura il credito accumulato?</>
          <>
            Non ha una scadenza. Naturalmente, però, viene utilizzato sulla prima
            fattura disponibile nei confronti di Fiscomed.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Come faccio a invitare i miei colleghi?</>
          <>
            Ti basta inviare il codice collega che trovi nella tua area personale di
            Fiscomed, proprio in prima pagina. Lo riconosci facilmente perché è un
            termine che hai incontrato sicuramente durante i tuoi studi (così è
            facile da ricordare per voi medici, no? 😀) Puoi copiarlo e incollarlo e
            condividerlo dove vuoi: su WhatsApp nel gruppo dell’università, nel
            gruppo di lavoro di Telegram, in DM alla tua migliore amica… (cerchiamo
            però di evitare lo spam, che non è mai piacevole).
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            Ho azzerato la mia fattura. Posso comunque continuare a invitare i miei
            amici?
          </>
          <>
            Certo. Il credito accumulato verrà scalato dalla fattura successiva e
            così via.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Se disdico l’abbonamento con Fiscomed perdo gli sconti?</>
          <>
            Sì, perderai il credito accumulato che non è stato ancora utilizzato. Non
            ti chiederemo però indietro il credito di cui hai già usufruito.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            E se la persona che ho invitato smette di essere una cliente di Fiscomed?
          </>
          <>
            Perderete gli sconti previsti per i mesi successivi alla disdetta.
            Naturalmente, però, non dovrete restituire gli sconti già percepiti.
          </>
        </DomandaRisposta>
        <br />
        <br />
      </Container>
    </div>
  )
}

export default CodiceCollega

import React, { useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'

const ModalViewFile = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <Modal
      isOpen={context.modalViewFile.aperto}
      toggle={() => context.chiudiModalViewFile()}
      size="lg"
    >
      <ModalHeader
        toggle={() => context.chiudiModalViewFile()}
        className="font-regular"
      >
        Carica un file
      </ModalHeader>
      <ModalBody>
        <object
          data={context.modalViewFile.url}
          width="100%"
          height="700px"
        ></object>
      </ModalBody>
    </Modal>
  )
}

export default ModalViewFile

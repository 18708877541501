import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label } from 'reactstrap'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaDatiAggiuntivi } from '../../../app/store/fiscomed/slice'

const RiferimentoAmministrazione = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)
  const setRifAmministrazione = (rifAmministrazione) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        rifAmministrazione,
      })
    )
  }

  return (
    <FormGroup>
      <Label>Riferimento Amministrazione 1.2.6</Label>
      <br />
      <span className="text-muted">Visibile solo nel formato ministeriale</span>
      <Input
        type="text"
        value={fiscomedData.datiAggiuntivi.rifAmministrazione}
        onChange={(e) => setRifAmministrazione(e.target.value)}
      />
    </FormGroup>
  )
}

export default RiferimentoAmministrazione

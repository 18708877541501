import React, { useEffect, useState } from 'react'
import {
  Card,
  Button,
  FormGroup,
  Input,
  Spinner,
  Row,
  Col,
  Progress,
  Form,
} from 'reactstrap'
import {
  emailClienteProforma,
  inviaEmailProforma,
} from '../../../../app/utility/QueryFattura'
import swal from 'sweetalert'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'

const InviaEmailProforma = (props) => {
  const emailInviata = props.emailInviata
  const idProforma = props.idProforma
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const user = useSelector(selectUser)

  useEffect(() => {
    emailClienteProforma(user, idProforma).then((res) => {
      setEmail(res.email_cliente === null ? '' : res.email_cliente)
    })
  }, [])

  const inviaFattura = async () => {
    if (email === null || email === '') {
      swal({
        title: 'Attenzione',
        text: "Inserisci l'email del cliente",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setLoading(true)
    const esito = await inviaEmailProforma(user, idProforma, email)
    if (esito.email_inviata) {
      swal({
        title: 'Fattura proforma inviata',
        text: 'La fattura proforma è stata inviata correttamente',
        icon: 'success',
        button: 'Ok',
      })
      emailInviata()
    } else {
      swal({
        title: 'Errore',
        text: "Si è verificato un errore durante l'invio della fattura proforma",
        icon: 'error',
        button: 'Ok',
      })
      setLoading(false)
    }
  }

  return (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={40} className="progress-gialla" />
          <h3 className="font-medium mt-4">
            La tua fattura proforma è stata creata 🎉
          </h3>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <Row>
            <Col xs="4">
              <h6 className="font-regular interlinea-25">
                Se ti va, <span className="font-bold">invia</span> la fattura
                proforma al tuo cliente. In questo modo potrà verificare se i dati
                che hai inserito sono corretti.
                <br />
                <br />
                <span className="font-bold">Scrivi la sua email</span> qui accanto e
                clicca sul tasto &quot;Invia fattura per email&quot;
              </h6>
            </Col>
            <Col xs="8">
              <Form inline className="float-end mt-5">
                <FormGroup>
                  <Input
                    type="text"
                    placeholder="Inserisci l'email del cliente"
                    value={email === null ? '' : email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="float-end"
                    style={{ width: '250px' }}
                  />
                </FormGroup>
                <Button onClick={() => inviaFattura()} className="bottone-blu ms-3">
                  {loading ? (
                    <Spinner size="sm" />
                  ) : (
                    <>
                      <i className="bi bi-envelope"></i> INVIA FATTURA PER EMAIL
                    </>
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
        </div>
      </Card>
      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            <span className="font-bold">Non vuoi farlo ora?</span> Nessun problema.
            Puoi sempre inviare la fattura proforma in un secondo momento, dalla
            sezione <Link to="/fiscomed/proforma">Proforma</Link> 🤗
          </h6>
          <div className="float-end">
            <Button
              className="bottone-blu-outline mt-3 mb-3"
              onClick={() => emailInviata()}
            >
              SALTA E VAI AVANTI
            </Button>
          </div>
        </div>
      </Card>
    </>
  )
}

export default InviaEmailProforma

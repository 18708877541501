import React from 'react'
import { Container, Card, CardBody, CardTitle } from 'reactstrap'

const VideoIntroduzione = () => (
  <Container style={{ marginTop: '56px' }}>
    <br />
    <br />
    <br />
    <Card className="mt-5">
      <CardBody>
        <CardTitle className="h5 font-grigio-scuro">Scopri Fiscomed 🩺</CardTitle>
        <div style={{ width: '100%' }}>
          <video
            controls
            autoPlay
            width="100%"
            src={'/fiscodemia/video/video_introduttivo.mp4'}
            type="video/mp4"
            poster="/img/copertina_video_introduttivo.jpg"
          />
        </div>
        <br />
      </CardBody>
    </Card>
  </Container>
)

export default VideoIntroduzione

import React, { useState } from 'react'
import { Container, Input, Button, Spinner, FormGroup, Label } from 'reactstrap'
import { leadGuida } from '../../../app/utility/QueryLanding'
import { guidaFiscaleGA } from '../../../app/utility/GA'

import swal from 'sweetalert'

const GuidaMobile = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const [consenso, setConsenso] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const scaricaGuida = async () => {
    if (email === '' || consenso === false || newsletter === false) {
      swal({
        title: 'Attenzione',
        text: "Compila tutti i campi, dai il tuo consenso al trattamento dei dati e all'isrizione alla newsletter",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    guidaFiscaleGA()
    await leadGuida({ nome, email }).then((res) => {
      setCaricamento(false)
      swal({
        title: 'Bene 😊',
        text: 'Entro pochi minuti riceverai una email da elisa@fiscomed.it contenente il link per la guida fiscale. Se non la trovi, controlla nelle cartelle spam, aggiornamenti e promozioni.',
        icon: 'success',
        button: 'Ok',
      })
    })
  }

  return (
    <>
      <div style={{ backgroundColor: '#f2b70526' }} className="pt-5">
        <Container className="mobile-container">
          <span className="titolo3-mobile">
            Hai già letto la nostra guida fiscale gratuita?
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Se non lo hai ancora fatto, compila i campi qui sotto per riceverla.
            Crediamo che con la giusta dose di informazioni non proverai più quel
            senso di spaesamento che si prova una volta entrati nel mondo del lavoro
          </h6>
          <br />
          <FormGroup>
            <h6 className="font-medium mb-3">Come ti chiami?</h6>
            <Input
              type="text"
              placeholder="Ippocrate di Coo"
              style={{ borderColor: '#f2b705' }}
              autoComplete="name"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
            />
          </FormGroup>
          <br />
          <FormGroup>
            <h6 className="font-medium mb-3">Quale email usi di più?</h6>
            <Input
              type="text"
              placeholder="ippocrate@email.com"
              style={{ borderColor: '#f2b705' }}
              autoComplete="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <br />
          <FormGroup check style={{ maxWidth: '300px' }}>
            <Input
              type="checkbox"
              name="privacy"
              id="privacy"
              style={{ marginTop: '2px' }}
              checked={consenso}
              onChange={(e) => setConsenso(e.target.checked)}
            />
            <Label for="privacy" className="consenso">
              Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
              <a
                href="/documenti/termini_e_condizioni.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>privacy policy</u>
              </a>
            </Label>
          </FormGroup>
          <br />
          <FormGroup check style={{ maxWidth: '300px' }}>
            <Input
              type="checkbox"
              name="newsletter"
              id="newsletter"
              style={{ marginTop: '2px' }}
              checked={newsletter}
              onChange={(e) => setNewsletter(e.target.checked)}
            />
            <Label for="newsletter" className="consenso">
              Scaricando la guida, accetto di iscrivermi alla newsletter
            </Label>
          </FormGroup>
          <br />
          {caricamento ? (
            <Button
              block
              className="bottone-blu px-5 mt-4"
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              disabled
            >
              <Spinner color="light" size="sm" />
            </Button>
          ) : (
            <Button
              block
              className="bottone-blu px-5 mt-4"
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              onClick={() => scaricaGuida()}
            >
              OTTIENI LA GUIDA
            </Button>
          )}
          <br />
          <br />
          <br />
          <br />
        </Container>
      </div>
    </>
  )
}

export default GuidaMobile

import React, { useState } from 'react'
import { Modal, ModalBody, ModalHeader, Button } from 'reactstrap'

const NonSaiRispondere = () => {
  const [modal, setModal] = useState(false)
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(false)
        }}
      >
        <ModalHeader
          className="font-bold"
          toggle={() => {
            setModal(false)
          }}
        >
          Ti aiutiamo noi 😊
        </ModalHeader>
        <ModalBody>
          <h6 className="font-regular interlinea-25">
            Se hai qualche difficoltà a rispondere a questa domanda, non
            preoccuparti.
            <br />
            <br />
            <b>Inviaci un messaggio</b> su WhatsApp e analizzeremo insieme la tua
            situazione.
            <br />
            <br />
            Componi il nostro numero: 339 5601221
            <br />
            O scannerizza il qr code 👇
            <br />
            <center>
              <img src="/img/qr_whatsapp.png" style={{ height: '150px' }} />
            </center>
          </h6>
          <br />
        </ModalBody>
      </Modal>
      <Button color="link" onClick={() => setModal(true)} className="float-end me-3">
        <u>Non sai rispondere a questa domanda?</u>
      </Button>
    </>
  )
}

export default NonSaiRispondere

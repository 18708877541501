import React from 'react'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const VariazioneTre = ({ setScheda }) => (
  <Card body className="h-100 d-flex d-column p-5">
    <Progress value={70} className="progress-gialla" />
    <br />
    <br />
    <h4 className="testo-verde font-bold mb-4">E ADESSO INVIA IL SECONDO MODULO</h4>

    <h6 className="font-regular mb-3 interlinea-30">
      Una volta inviato il primo modulo &quot;Contribuzione Ridotta&quot;, ne devi
      inviare un secondo:
      <br />
      <br />
      Fai così:
      <br />
      1. Accedi alla tua <b>Area Riservata</b>
      <br />
      2. Nella colonna di sinistra, clicca su &quot;
      <b>Domande e dichiarazioni online</b>&quot;
      <br />
      3. Sempre nella colonna a sinistra, clicca su &quot;
      <b>Contribuzione Ridotta</b>&quot;.
      <br />
      4. Clicca su &quot;<b>Compila un nuovo modulo</b>&quot;.
    </h6>
    <h6 className="font-regular mb-3 mt-4 interlinea-30">
      Come puoi vedere, c&apos;è un riquadro intitolato &quot;
      <b>
        Domanda di ammissione al versamento della contribuzione nella misura ridotta
      </b>
      &quot;. Quello che devi fare tu è inserire la <b>data</b> in cui hai iniziato a
      svolgere l&apos;attività per la quale puoi richiedere un&apos;aliquota
      vantaggiosa.
      <br />
      Una volta scritta la data, ti compariranno tre alternative: seleziona quella
      indicante l&apos;attività che stai svolgendo attualmente.
    </h6>
    <img
      src="/img/enpam/screen_enpam.webp"
      alt="screen enpam"
      style={{ width: '100%', maxWidth: '900px' }}
    />
    <h6 className="font-regular mb-3 mt-4 interlinea-30">
      Una volta inviata questa richiesta, puoi compilare e inviare il{' '}
      <b>Modello D</b>.
      <br />
      Nella prossima schermata ti spieghiamo come fare
    </h6>
    <br />
    <br />
    <div className="mt-auto mb-3">
      <Ricomincia setScheda={setScheda} />
      <Button
        className="bottone-blu float-end"
        style={{ width: '130px' }}
        onClick={() => setScheda('modello_d')}
      >
        AVANTI <i className="bi bi-arrow-right-short"></i>
      </Button>
    </div>
  </Card>
)

export default VariazioneTre

import React, { useContext } from 'react'
import { Alert, Table } from 'reactstrap'
import { ImportaFatturaContext } from '../../app/context/ImportaFattura'
import moment from 'moment'

const Tabella = () => {
  const importaFattureContext = useContext(ImportaFatturaContext)

  const cancellaFile = (uuidFile, nomeFile) => {
    if (window.confirm('Cancellare definitavemente il file ' + nomeFile + '?')) {
      importaFattureContext.cancellaFile(uuidFile)
    }
  }

  const tabella = importaFattureContext.dati.fatture.map((element, i) => (
    <tr key={i}>
      <td>
        <span className="testo-verde">
          {moment(element.data_incasso).format('DD/MM/YYYY')}
        </span>
      </td>
      <td>
        {element.tipologia.charAt(0).toUpperCase() + element.tipologia.slice(1)}
      </td>
      <td>{element.numero}</td>
      <td>{element.importo / 100}€</td>
      <td>
        {element.tipologia === 'fattura'
          ? '-'
          : element.trattenuta_enpam / 100 + '€'}
      </td>
      <td>
        {element.inviata_ts && <i className="bi bi-check-lg testo-verde"></i>}
        {element.tipologia === 'fattura' && !element.inviata_ts && (
          <i className="bi bi-x testo-rosso"></i>
        )}
      </td>
      <td
        className="text-primary"
        style={{ cursor: 'pointer' }}
        onClick={() =>
          importaFattureContext.scaricaFile(
            element.uuid_file,
            element.nome_originale_file
          )
        }
      >
        {element.nome_originale_file.substring(0, 20)}{' '}
        <i className="bi bi-cloud-arrow-down"></i>
      </td>
      <td className="font-regular">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() => importaFattureContext.modificaFattura(element.uuid_file)}
        >
          <i className="bi bi-pencil"></i>
        </center>
      </td>
      <td className="text-danger">
        <center
          style={{ cursor: 'pointer' }}
          onClick={() =>
            cancellaFile(element.uuid_file, element.nome_originale_file)
          }
        >
          <i className="bi bi-trash"></i>
        </center>
      </td>
    </tr>
  ))

  return (
    <>
      {importaFattureContext.dati.fatture.length === 0 ? (
        <Alert color="info">
          <i className="bi bi-info-circle"></i> Nessuna fattura importata, controlla
          negli anni precedenti
        </Alert>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca">
          <thead>
            <tr>
              <th className="h6 font-regular">Data incasso</th>
              <th className="h6 font-regular">Tipologia</th>
              <th className="h6 font-regular">Numero</th>
              <th className="h6 font-regular">Importo</th>
              <th className="h6 font-regular">Trattenute</th>
              <th className="h6 font-regular">TS</th>
              <th className="h6 font-regular">Scarica</th>
              <th className="h6 font-regular">Modifica</th>
              <th className="h6 font-regular">Cancella</th>
            </tr>
          </thead>
          <tbody>{tabella}</tbody>
        </Table>
      )}
    </>
  )
}

export default Tabella

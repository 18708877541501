import React, { useState, useEffect } from 'react'
import {
  Card,
  Button,
  Row,
  Col,
  FormGroup,
  Input,
  Spinner,
  Progress,
} from 'reactstrap'
import { Link } from 'react-router-dom'
import {
  scaricaFatturaDiCortesia,
  scaricaXMLFattura,
  scaricaPDFEle,
} from '../../../../app/utility/QueryRiepilogoFatture'
import { datiFattura } from '../../../../app/utility/QueryFattura'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'

const Fine = (props) => {
  const idFattura = props.idFattura
  const [loading, setLoading] = useState(false)
  const [tipoPDF, setTipoPDF] = useState('Fattura di cortesia')
  const user = useSelector(selectUser)
  const [fattura, setFattura] = useState({})
  const [valoreProgress, setValoreProgress] = useState(40)

  useEffect(() => {
    datiFattura(user, idFattura).then((res) => {
      setFattura(res.fattura)
    })
    setValoreProgress(100)
  }, [])

  const cambiaPDF = (tipo) => {
    setTipoPDF(tipo)
  }

  const scaricaPDF = () => {
    if (loading) return
    setLoading(true)
    if (tipoPDF === 'Fattura di cortesia') {
      scaricaFatturaDiCortesia(user, fattura)
      setLoading(false)
    }
    if (tipoPDF === 'XML') {
      scaricaXMLFattura(user, fattura.id)
      setLoading(false)
    }
    if (tipoPDF === 'Fattura formato ministeriale') {
      scaricaPDFEle(user, fattura)
      setLoading(false)
    }
  }

  return (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={valoreProgress} className="progress-gialla" />
          <h3 className="font-medium mt-4">
            Se vuoi, puoi scaricare la tua fattura 📃
          </h3>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Puoi scaricarla in formato PDF oppure XML, il linguaggio strano con cui
            sono scritte le fatture elettroniche.
          </h6>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Qualche informazione che ti potrebbe essere utile:
            <ul>
              <li>
                il <span className="font-bold">file XML</span> è quello che viene
                inviato al Sistema di Interscambio
              </li>
              <li>
                il{' '}
                <span className="font-bold">formato &quot;fattura di cortesia</span>
                &quot; è il documento riepilogativo che riporta solo i dati più
                importanti della fattura (è quello che ti facciamo inviare al
                cliente)
              </li>
              <li>
                il <span className="font-bold">formato &quot;ministeriale</span>
                &quot; è il documento dove sono scritti tutti i dati della fattura
                elettronica, in formato PDF. È però un file difficile da leggere
              </li>
            </ul>
            <br />
            Bene. Ora hai tutte le conoscenze per scaricare il file più adatto 😊
          </h6>
          <Row>
            <Col xs="4">
              <FormGroup>
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  onChange={(e) => cambiaPDF(e.target.value)}
                >
                  <option key="cortesia">Fattura di cortesia</option>
                  <option key="xml">XML</option>
                  <option key="ministeriale">Fattura formato ministeriale</option>
                </Input>
              </FormGroup>
            </Col>
            <Col xs="8">
              <Button className="bottone-blu" onClick={() => scaricaPDF()}>
                {loading ? (
                  <Spinner size="sm" />
                ) : (
                  <>
                    <i className="bi bi-cloud-download"></i> SCARICA FILE
                  </>
                )}
              </Button>
            </Col>
          </Row>
        </div>
      </Card>

      <Card body>
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Se adesso non hai bisogno di scaricare la tua fattura, non preoccuparti.
            Potrai farlo in <span className="font-bold">qualsiasi momento</span>{' '}
            dalla sezione{' '}
            <Link to="/riepilogo-fatture">
              <i className="bi bi-journals"></i> Riepilogo fatture
            </Link>
            .
          </h6>

          <br />

          <Button
            className="bottone-giallo float-end"
            tag={Link}
            to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
          >
            INVIA UN&apos;ALTRA FATTURA
          </Button>
        </div>
      </Card>
    </>
  )
}

export default Fine

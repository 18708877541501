import React from 'react'
import { Card, Button, Row, Col, CardTitle } from 'reactstrap'
import { Link } from 'react-router-dom'

const ErroreTrasmissione = () => (
  <div className="mt-4 mb-5">
    <Row>
      <Col xs={5}>
        <Card body style={{ height: '100%' }}>
          <center>
            <img
              src="/img/fattura/manutenzione.webp"
              alt="manutenzione"
              style={{ width: '60%' }}
            />
          </center>
        </Card>
      </Col>
      <Col xs={7}>
        <Card body className="px-5">
          <CardTitle className="h3 font-grigio-scuro">
            Si è verificato un errore durante la trasmissione della tua fattura allo
            SdI 🙁
          </CardTitle>
          <h6 className="font-regular interlinea-30">
            Sembra che qualcosa sia andato storto. Probabilmente il Sistema di
            Interscambio non è disponibile per manutenzione o per problemi tecnici
          </h6>
        </Card>
        <Card body className="mt-4 px-5">
          <h5 className="font-regular">Cosa fare?</h5>
          <h6 className="font-regular interlinea-25 my-3">
            Riprova fra qualche ora. Se il problema dovesse persistere contattaci su
            WhatsApp. Lo risolveremo insieme
          </h6>
          <Button
            tag={Link}
            className="bottone-blu text-white my-3"
            to="/chat-commercialista"
          >
            <i className="bi bi-headset"></i> VAI ALLA PAGINA ASSISTENZA
          </Button>
        </Card>
      </Col>
    </Row>
  </div>
)

export default ErroreTrasmissione

import React, { useState, useEffect, useCallback } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import {
  Button,
  Navbar,
  Collapse,
  Nav,
  NavbarToggler,
  NavbarBrand,
  Container,
} from 'reactstrap'
import classNames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { selectIsLoggedIn, selectIsLoadingUser } from '../app/store/user/selectors'
import { logout } from '../app/store/user/slice'

const NavBarComponent = () => {
  const dispatch = useDispatch()
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isLoading = useSelector(selectIsLoadingUser)

  const [collapsed, setCollapsed] = useState(false)
  const smallScreen = window.innerWidth <= 767
  const history = useHistory()
  const [opacity, setOpacity] = useState(0)
  const location = useLocation()

  const handleScroll = useCallback(() => {
    const scrollPosition = window.scrollY
    if (scrollPosition > 20) {
      setOpacity(1)
    } else {
      setOpacity(scrollPosition * 0.05)
    }
  }, [])

  useEffect(() => {
    if (isLoggedIn) {
      setOpacity(1)
      window.removeEventListener('scroll', handleScroll)
    } else {
      setOpacity(0)
      window.addEventListener('scroll', handleScroll)
    }
  }, [isLoading, isLoggedIn])

  const toggleNavbar = () => {
    if (smallScreen) {
      if (collapsed === false) {
        setOpacity(1)
      } else {
        handleScroll()
      }
      setCollapsed(!collapsed)
    }
  }

  const logoutAction = () => {
    toggleNavbar()
    dispatch(logout())
    history.push('/')
  }

  const loginAction = () => {
    toggleNavbar()
    history.push('/accesso')
  }

  return (
    <>
      {/* questa duplicazione è una soluzione provvisioria, la situazione del toggler è gestita bene nella prossima branch */}
      {smallScreen ? (
        <Navbar
          light
          style={{
            zIndex: '1000',
            backgroundColor: 'rgba(255, 255, 255,' + opacity + ')',
          }}
          expand="md"
          fixed={isLoggedIn ? 'none' : 'top'}
        >
          <NavbarBrand tag={Link} to={isLoggedIn ? '/fiscomed' : '/'}>
            <img
              src="/img_landing/logo.webp"
              alt="Fiscomed"
              style={{ height: '42px' }}
            />
          </NavbarBrand>
          <NavbarToggler
            onClick={toggleNavbar}
            style={{ backgroundColor: 'white' }}
          />
          <Collapse isOpen={collapsed} navbar>
            <Nav navbar>
              {isLoggedIn ? (
                <ul className="navbar-nav me-auto">
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
                      onClick={toggleNavbar}
                    >
                      <i className="bi bi-file-earmark-plus"></i> Nuova fattura
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/riepilogo-fatture"
                      onClick={toggleNavbar}
                    >
                      <i className="bi bi-journals"></i> Riepilogo fatture
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/scadenze" onClick={toggleNavbar}>
                      <i className="bi bi-hourglass-split"></i> Scadenze
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link"
                      to="/fiscodemia/home"
                      onClick={toggleNavbar}
                    >
                      <i className="bi bi-bank"></i> Fiscodèmia
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      href="#"
                    >
                      <i className="bi bi-people"></i> Commercialista
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-left"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        className="dropdown-item"
                        to="/chat-commercialista"
                        onClick={toggleNavbar}
                      >
                        Vuoi una mano?
                      </Link>
                      <div className="dropdown-divider"></div>

                      <Link
                        className="dropdown-item"
                        to="/dichiarazione"
                        onClick={toggleNavbar}
                      >
                        Dichiarazione
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/enpam"
                        onClick={toggleNavbar}
                      >
                        L&apos;angolo dell&apos;ENPAM
                      </Link>
                      <div className="dropdown-divider"></div>
                      <Link
                        className="dropdown-item"
                        to="/file-condivisi"
                        onClick={toggleNavbar}
                      >
                        File condivisi con commercialista
                      </Link>
                    </div>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      href="#"
                    >
                      <i className="bi bi-tools"></i> Strumenti
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-left"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        className="dropdown-item"
                        to="/importa-fattura"
                        onClick={toggleNavbar}
                      >
                        Importa fatture e cedolini
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/importa-borsa"
                        onClick={toggleNavbar}
                      >
                        Importa borsa di studio
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/statistiche"
                        onClick={toggleNavbar}
                      >
                        Previsione delle tasse
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/tessera-sanitaria"
                        onClick={toggleNavbar}
                      >
                        Sistema tessera sanitaria
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/fiscomed/proforma"
                        onClick={toggleNavbar}
                      >
                        Proforma
                      </Link>
                      <div className="dropdown-divider"></div>
                      <Link
                        className="dropdown-item"
                        to="/rubrica"
                        onClick={toggleNavbar}
                      >
                        Rubrica clienti
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/metodo-di-pagamento"
                        onClick={toggleNavbar}
                      >
                        Metodi di pagamento
                      </Link>
                    </div>
                  </li>
                </ul>
              ) : (
                <ul className="navbar-nav me-auto">
                  <li
                    className={classNames('nav-item mx-2 ', {
                      'link-nav-attivo': location.pathname === '/' && !smallScreen,
                      active: location.pathname !== '/',
                    })}
                  >
                    <Link className="nav-link" to="/" onClick={toggleNavbar}>
                      Fiscomed
                    </Link>
                  </li>
                  <li
                    className={classNames('nav-item mx-2 ', {
                      'link-nav-attivo':
                        location.pathname === '/prezzi' && !smallScreen,
                      active: location.pathname !== '/prezzi',
                    })}
                  >
                    <Link className="nav-link" to="/prezzi" onClick={toggleNavbar}>
                      Prezzi
                    </Link>
                  </li>
                  <li
                    className={classNames('nav-item mx-2 ', {
                      'link-nav-attivo':
                        location.pathname === '/guida-fiscale' && !smallScreen,
                      active: location.pathname !== '/guida-fiscale',
                    })}
                  >
                    <Link
                      className="nav-link"
                      to="/guida-fiscale"
                      onClick={toggleNavbar}
                    >
                      Guida fiscale
                    </Link>
                  </li>
                  <li
                    className={classNames('nav-item mx-2 ', {
                      'link-nav-attivo':
                        location.pathname === '/newsletter' && !smallScreen,
                      active: location.pathname !== '/newsletter',
                    })}
                  >
                    <Link
                      className="nav-link"
                      to="/newsletter"
                      onClick={toggleNavbar}
                    >
                      Newsletter
                    </Link>
                  </li>
                </ul>
              )}
            </Nav>
            <Nav navbar className="ms-auto">
              {isLoggedIn ? (
                <ul className="navbar-nav">
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      href="#"
                    >
                      Account
                    </a>
                    <div
                      className="dropdown-menu dropdown-menu-end"
                      aria-labelledby="navbarDropdown"
                    >
                      <Link
                        className="dropdown-item"
                        to="/informazioni-fiscali"
                        onClick={toggleNavbar}
                      >
                        Informazioni sulla partita IVA
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/abbonamento"
                        onClick={toggleNavbar}
                      >
                        Abbonamento
                      </Link>
                      <div className="dropdown-divider"></div>
                      <a
                        href="https://fiscomed.it/documenti/privacy_policy.pdf"
                        className="dropdown-item"
                        title="Privacy Policy"
                        target="blank"
                      >
                        Privacy policy
                      </a>

                      <a
                        href="https://fiscomed.it/documenti/termini_e_condizioni.pdf"
                        className="dropdown-item"
                        title="Termini e Condizioni"
                        target="blank"
                      >
                        Termini e condizioni
                      </a>
                      <a
                        href="https://fiscomed.it/documenti/cookie_policy.pdf"
                        className="dropdown-item"
                        title="Cookie Policy"
                        target="blank"
                      >
                        Cookie policy
                      </a>
                      <div className="dropdown-divider"></div>
                      <Link
                        className="dropdown-item"
                        to="/cambio-password"
                        onClick={toggleNavbar}
                      >
                        Cambio password
                      </Link>
                      <button
                        className="dropdown-item"
                        onClick={() => logoutAction()}
                      >
                        Logout
                      </button>
                    </div>
                  </li>
                </ul>
              ) : (
                <div className="navbar-nav">
                  <form className="form-inline">
                    <Button className="bottone-blu" onClick={() => loginAction()}>
                      ACCEDI
                    </Button>
                  </form>
                </div>
              )}
            </Nav>
          </Collapse>
        </Navbar>
      ) : (
        <Navbar
          light
          style={{
            zIndex: '1000',
            backgroundColor: 'rgba(255, 255, 255,' + opacity + ')',
          }}
          expand="md"
          fixed={isLoggedIn ? 'none' : 'top'}
        >
          <Container
            fluid={isLoggedIn}
            style={{
              display: 'flex',
            }}
            className={classNames({ 'container-landing': isLoggedIn === false })}
          >
            <NavbarBrand tag={Link} to={isLoggedIn ? '/fiscomed' : '/'}>
              <img
                src="/img_landing/logo.webp"
                alt="Fiscomed"
                style={{ height: '42px' }}
              />
            </NavbarBrand>
            <NavbarToggler
              onClick={toggleNavbar}
              style={{ backgroundColor: 'white' }}
            />
            <Collapse isOpen={collapsed} navbar>
              <Nav navbar>
                {isLoggedIn ? (
                  <ul className="navbar-nav me-auto">
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
                        onClick={toggleNavbar}
                      >
                        <i className="bi bi-file-earmark-plus"></i> Nuova fattura
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/riepilogo-fatture"
                        onClick={toggleNavbar}
                      >
                        <i className="bi bi-journals"></i> Riepilogo fatture
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/scadenze"
                        onClick={toggleNavbar}
                      >
                        <i className="bi bi-hourglass-split"></i> Scadenze
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link"
                        to="/fiscodemia/home"
                        onClick={toggleNavbar}
                      >
                        <i className="bi bi-bank"></i> Fiscodèmia
                      </Link>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="#"
                      >
                        <i className="bi bi-people"></i> Commercialista
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-left"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item"
                          to="/chat-commercialista"
                          onClick={toggleNavbar}
                        >
                          Vuoi una mano?
                        </Link>
                        <div className="dropdown-divider"></div>

                        <Link
                          className="dropdown-item"
                          to="/dichiarazione"
                          onClick={toggleNavbar}
                        >
                          Dichiarazione
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/enpam"
                          onClick={toggleNavbar}
                        >
                          L&apos;angolo dell&apos;ENPAM
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link
                          className="dropdown-item"
                          to="/file-condivisi"
                          onClick={toggleNavbar}
                        >
                          File condivisi con commercialista
                        </Link>
                      </div>
                    </li>
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="#"
                      >
                        <i className="bi bi-tools"></i> Strumenti
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-left"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item"
                          to="/importa-fattura"
                          onClick={toggleNavbar}
                        >
                          Importa fatture e cedolini
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/importa-borsa"
                          onClick={toggleNavbar}
                        >
                          Importa borsa di studio
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/statistiche"
                          onClick={toggleNavbar}
                        >
                          Previsione delle tasse
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/tessera-sanitaria"
                          onClick={toggleNavbar}
                        >
                          Sistema tessera sanitaria
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/fiscomed/proforma"
                          onClick={toggleNavbar}
                        >
                          Proforma
                        </Link>
                        <div className="dropdown-divider"></div>
                        <Link
                          className="dropdown-item"
                          to="/rubrica"
                          onClick={toggleNavbar}
                        >
                          Rubrica clienti
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/metodo-di-pagamento"
                          onClick={toggleNavbar}
                        >
                          Metodi di pagamento
                        </Link>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <ul className="navbar-nav me-auto">
                    <li
                      className={classNames('nav-item mx-2 ', {
                        'link-nav-attivo': location.pathname === '/' && !smallScreen,
                        active: location.pathname !== '/',
                      })}
                    >
                      <Link className="nav-link" to="/" onClick={toggleNavbar}>
                        Fiscomed
                      </Link>
                    </li>
                    <li
                      className={classNames('nav-item mx-2 ', {
                        'link-nav-attivo':
                          location.pathname === '/prezzi' && !smallScreen,
                        active: location.pathname !== '/prezzi',
                      })}
                    >
                      <Link className="nav-link" to="/prezzi" onClick={toggleNavbar}>
                        Prezzi
                      </Link>
                    </li>
                    <li
                      className={classNames('nav-item mx-2 ', {
                        'link-nav-attivo':
                          location.pathname === '/guida-fiscale' && !smallScreen,
                        active: location.pathname !== '/guida-fiscale',
                      })}
                    >
                      <Link
                        className="nav-link"
                        to="/guida-fiscale"
                        onClick={toggleNavbar}
                      >
                        Guida fiscale
                      </Link>
                    </li>
                    <li
                      className={classNames('nav-item mx-2 ', {
                        'link-nav-attivo':
                          location.pathname === '/newsletter' && !smallScreen,
                        active: location.pathname !== '/newsletter',
                      })}
                    >
                      <Link
                        className="nav-link"
                        to="/newsletter"
                        onClick={toggleNavbar}
                      >
                        Newsletter
                      </Link>
                    </li>
                  </ul>
                )}
              </Nav>
              <Nav navbar className="ms-auto">
                {isLoggedIn ? (
                  <ul className="navbar-nav">
                    <li className="nav-item dropdown">
                      <a
                        className="nav-link dropdown-toggle"
                        id="navbarDropdown"
                        role="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        href="#"
                      >
                        Account
                      </a>
                      <div
                        className="dropdown-menu dropdown-menu-end"
                        aria-labelledby="navbarDropdown"
                      >
                        <Link
                          className="dropdown-item"
                          to="/informazioni-fiscali"
                          onClick={toggleNavbar}
                        >
                          Informazioni sulla partita IVA
                        </Link>
                        <Link
                          className="dropdown-item"
                          to="/abbonamento"
                          onClick={toggleNavbar}
                        >
                          Abbonamento
                        </Link>
                        <div className="dropdown-divider"></div>
                        <a
                          href="https://fiscomed.it/documenti/privacy_policy.pdf"
                          className="dropdown-item"
                          title="Privacy Policy"
                          target="blank"
                        >
                          Privacy policy
                        </a>

                        <a
                          href="https://fiscomed.it/documenti/termini_e_condizioni.pdf"
                          className="dropdown-item"
                          title="Termini e Condizioni"
                          target="blank"
                        >
                          Termini e condizioni
                        </a>
                        <a
                          href="https://fiscomed.it/documenti/cookie_policy.pdf"
                          className="dropdown-item"
                          title="Cookie Policy"
                          target="blank"
                        >
                          Cookie policy
                        </a>
                        <div className="dropdown-divider"></div>
                        <Link
                          className="dropdown-item"
                          to="/cambio-password"
                          onClick={toggleNavbar}
                        >
                          Cambio password
                        </Link>
                        <button
                          className="dropdown-item"
                          onClick={() => logoutAction()}
                        >
                          Logout
                        </button>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <div className="navbar-nav">
                    <form className="form-inline">
                      <Button className="bottone-blu" onClick={() => loginAction()}>
                        ACCEDI
                      </Button>
                    </form>
                  </div>
                )}
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      )}
    </>
  )
}

export default NavBarComponent

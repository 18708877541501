import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'

import { useDispatch } from 'react-redux'

import {
  scaricaXML,
  scaricaPDFElettronica,
} from '../../../app/store/riepilogoFatture/actions'

const OpzioniAutofattura = (props) => {
  const dispatch = useDispatch()

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem onClick={() => dispatch(scaricaXML(props.dati.fattura.id))}>
            Scarica xml
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(scaricaPDFElettronica(props.dati.fattura.id))}
          >
            Scarica formato ministeriale
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniAutofattura

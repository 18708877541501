import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'

const Delega = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <>
      <h1 className="font-bold mt-5">
        Prima di cominciare, c&apos;è una cosa che dovresti fare
      </h1>
      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Per consentire {context.genere.alTuo} commercialista di preparare la
        dichiarazione dei redditi nel migliore dei modi, abbiamo bisogno che{' '}
        <i>deleghi Fiscomed alla consultazione del tuo cassetto fiscale</i>. Il
        cassetto fiscale è uno spazio dell&apos;Agenzia delle Entrate in cui ci sono
        tutti i tuoi dati e documenti fiscali.
        <br />
        <br />
        Fare la delega è davvero semplicissimo.{' '}
        <Link to="/fiscodemia/video/cassettofiscale">Guarda il video</Link> che
        Giulia ha preparato pet te, in cui mostra click per click i passaggi da
        seguire.
        <br />
        <br />
        Quando hai fatto, non ti resta che aspettare. Poiché ci potrebbero volere
        fino a 24 ore di tempo per verificare che la procedura sia andata a buon
        fine, ti consigliamo di <span className="font-bold">tornare domani</span>.
        <br />
        <br />
        Se è tutto ok, avrai accesso alla prima tappa del percorso verso la tua
        dichiarazione dei redditi 😊
        <br />
        <br />
      </h6>
    </>
  )
}

export default Delega

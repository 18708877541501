import React, { useState } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ListGroupItem,
  ListGroup,
} from 'reactstrap'
import classNames from 'classnames'
import ModalAggiungiPagamento from './ModalAggiungiPagamento'
import { useDispatch, useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaMetodoDiPagamentoAttivoDaID } from '../../../app/store/fiscomed/slice'

const ModalPagamento = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)
  const [modal, setModal] = useState(false)

  const selezionaMetodo = (id) => {
    dispatch(aggiornaMetodoDiPagamentoAttivoDaID(id))
    setModal(false)
  }

  const metodiDiPagamento = () =>
    fiscomedData?.metodoDiPagamento?.map((metodo, i) => {
      if (metodo === 'Contante') {
        return (
          <ListGroupItem
            key={i}
            style={{ cursor: 'pointer' }}
            className="contatto-rubrica"
            onClick={() => {
              selezionaMetodo('Contante')
            }}
          >
            Contante
          </ListGroupItem>
        )
      } else {
        return (
          <ListGroupItem
            key={i}
            style={{ cursor: 'pointer' }}
            className="contatto-rubrica"
            onClick={() => {
              selezionaMetodo(metodo.id)
            }}
          >
            {metodo.alias !== '' && (
              <>
                {metodo.alias}
                <br />
              </>
            )}
            <span className="text-muted">
              {metodo.intestatario} | {metodo.iban}
            </span>
          </ListGroupItem>
        )
      }
    })

  const componentModal = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        I tuoi metodi di pagamento
      </ModalHeader>
      <ModalBody>
        <br />
        <center>
          <ModalAggiungiPagamento
            tipologia="bottone"
            funzioneDiChiusura={() => setModal(false)}
          />
        </center>
        <br />
        <br />
        <ListGroup>{metodiDiPagamento()}</ListGroup>
      </ModalBody>
    </Modal>
  )

  return (
    <div className="mb-3">
      <Button
        onClick={() => setModal(true)}
        className={classNames({
          'bottone-blu':
            fiscomedData.tipologiaFattura === 'Fattura elettronica' ||
            fiscomedData.tipologiaFattura === 'Rettifica pr',
          'bottone-verde': fiscomedData.tipologiaFattura === 'Fattura cartacea',
          'bottone-giallo': fiscomedData.tipologiaFattura === 'Fattura PA',
          'bottone-bianco-blu-outline': fiscomedData.tipologiaFattura === 'Proforma',
          'bottone-blu-scuro': fiscomedData.tipologiaFattura === 'Autofattura',
        })}
      >
        SELEZIONA PAGAMENTO&nbsp;
      </Button>
      {componentModal}
    </div>
  )
}

export default ModalPagamento

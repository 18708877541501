import React from 'react'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const RinunciaAliquota = ({ setScheda }) => (
  <Card body className="h-100 d-flex d-column p-5">
    <Progress value={60} className="progress-gialla" />
    <br />
    <br />
    <h4 className="testo-verde font-bold mb-4">ECCO COSA DEVI FARE</h4>
    <h6 className="font-regular mb-3 interlinea-30">
      Per iniziare a usufruire dell&apos;aliquota intera ci sono degli <b>step</b>{' '}
      che devi seguire:
      <br />
      1. Accedi alla tua <b>Area Riservata</b>
      <br />
      2. Nella colonna di sinistra, clicca su &quot;
      <b>Domande e dichiarazioni online</b>&quot;
      <br />
      3. Sempre nella colonna a sinistra, clicca su &quot;
      <b>Contribuzione Ridotta</b>&quot;.
      <br />
      4. Clicca su &quot;<b>Compila un nuovo modulo</b>&quot;.
      <br />
      <br />A questo punto, scorrendo nella pagina, dovresti trovare un riquadro
      intitolato &quot;<b>Scelta irrevocabile della contribuzione intera</b>&quot;.
      Cliccando su &quot;Versamento di quota B con aliquota intera - RICHIESTA
      IRREVOCABILE&quot; ti si aprirà un modulo con le istruzioni da seguire per fare
      la richiesta.
      <br />
      <br />
      Una volta inviata la richiesta, aspetta una risposta dall&apos;ENPAM. Quando
      confermano la tua scelta, torna su questa pagina e vai avanti. Troverai le
      istruzioni per compilare, finalmente, il Modello D.
    </h6>
    <br />
    <br />
    <div className="mt-auto mb-3">
      <Ricomincia setScheda={setScheda} />
      <Button
        className="bottone-blu float-end"
        style={{ width: '130px' }}
        onClick={() => setScheda('modello_d')}
      >
        AVANTI <i className="bi bi-arrow-right-short"></i>
      </Button>
    </div>
  </Card>
)

export default RinunciaAliquota

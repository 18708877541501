import request from 'superagent'

export const idrataEnpam = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/enpam/idrata`)
    .send({ token: user.auth_token })
    .then((response) => response.body)

export const salvaSchedaSulServer = (user, scheda) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/enpam/salva-scheda`)
    .send({ token: user.auth_token, scheda })
    .catch((e) => console.error(e.message))

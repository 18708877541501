import React, { useState, useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import moment from 'moment'
import { Link } from 'react-router-dom'
import { BloccoF24Modal } from '../component/BloccoF24Modal'

const F24Caricati = () => {
  const [bloccoF24ModalOpen, setBloccoF24ModalOpen] = useState(false)
  const context = useContext(DichiarazioneContext)
  const ciSonoF24 = context.f24s.length > 0

  const handleDownload = (f24) => {
    if (moment(f24.data_scadenza, 'DD/MM/YYYY').add(1, 'day').isBefore(moment())) {
      setBloccoF24ModalOpen(true)
    } else {
      context.scaricaF24(
        f24.id,
        `F24_scadenza_${moment(f24.data_scadenza, 'DD/MM/YYYY').format(
          'DD-MM-YYYY'
        )}.pdf`
      )
    }
  }

  const f24 = () =>
    context.f24s.map((f24, i) => (
      <div key={i} className="mt-2">
        <span
          className="testo-blu"
          style={{ cursor: 'pointer' }}
          onClick={() => handleDownload(f24)}
        >
          <i className="bi bi-download"></i> F24 scadenza{' '}
          {moment(f24.data_scadenza, 'DD/MM/YYYY').format('DD-MM-YYYY')}
        </span>
      </div>
    ))

  return (
    <div>
      {ciSonoF24 ? (
        <div>
          <h1 className="font-bold mb-4">Ecco i tuoi F24 🎉</h1>
          {f24()}
          <br />
          <h6 className="font-regular mb-3 interlinea-30">
            Puoi scaricarli per averli sul tuo pc, oppure consultarli direttamente da
            qui. Sono nominati secondo la <span className="font-bold">scadenza</span>
            , così puoi facilmente ricordarti entro quando vanno pagati. Attenzione
            però: questa scadenza di solito viene <i>rimandata</i> e potrebbe
            succedere anche quest&apos;anno. Se pensi di voler approfittare della
            possibile proroga ti consigliamo di aspettare un po&apos; prima di
            pagare. Verso fine giugno lo sapremo e ti avviseremo con una email.
            <br />
            <br />
            Per aiutarti nela procedura, abbiamo preparato un video tutorial in cui
            Giulia ti mostra passo passo{' '}
            <span className="font-bold">come si pagano gli F24</span> dal sito
            dell&apos;Agenzia delle Entrate. Se vuoi vederlo,{' '}
            <Link to="/fiscodemia/video/f24_3">
              <u>clicca qui</u>
            </Link>
            .
          </h6>
          <BloccoF24Modal
            isOpen={bloccoF24ModalOpen}
            onClose={() => setBloccoF24ModalOpen(false)}
          />
        </div>
      ) : (
        <div>
          <h1 className="font-bold mb-4">Non hai F24 da pagare 🥳</h1>
          <br />
          <h6 className="font-regular mb-3 interlinea-30">
            Una buona notizia: {context.genere.ilTuo} commercialista ha controllato
            attentamente la tua situazione fiscale e ha confermato che non devi
            pagare le imposte. Quindi non c&apos;è bisogno di compilare e pagare
            alcun Modello F24.
          </h6>
        </div>
      )}
      <br />
      <h4 className="font-bold mb-4">E il Modello Redditi?</h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Durante le <span className="font-bold">prime due settimane di ottobre</span>{' '}
        {context.genere.il} commercialista che ti segue caricherà su Fiscomed il tuo
        Modello Redditi, cioè il documento che ha prodotto per la tua dichiarazione,
        con la sua ricevuta di trasmissione. Una volta caricato, potrai trovarlo
        nell&apos;
        <Link to="/dichiarazione/archivio">
          <u>Archivio</u>
        </Link>
        .
        <br />
        Tu non devi preoccuparti di nulla: sarà {context.genere.ilTuo} commercialista
        a inviarlo all&apos;Agenzia delle Entrate entro la scadenza 😌
      </h6>
    </div>
  )
}

export default F24Caricati

import React from 'react'
import OpzioniCartacea from './OpzioniCartacea'
import OpzioniConsegnata from './OpzioniConsegnata'
import OpzioniScarto from './OpzioniScarto'
import OpzioniConsegnataVariazione from './OpzioniConsegnataVariazione'
import OpzioniPA from './OpzioniPA'
import OpzioniVerificaSdi from './OpzioniVerificaSdI'
import OpzioniAutofattura from './OpzioniAutofattura'
import { getLast } from '../../../app/utility/array'

const ContainerOpzioni = (props) => {
  const opzioniCartacea = () => <OpzioniCartacea dati={props.dati} />

  const opzioniPA = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return null
      case 'impossibilita_recapito':
        return <OpzioniPA dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniPA dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniPA dati={props.dati} />
      case 'decorrenza_termini':
        return <OpzioniPA dati={props.dati} />
      case 'notifica_esito_positivo':
        return <OpzioniPA dati={props.dati} />
      case 'notifica_esito_negativo':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'attestazione_trasmissione':
        return <OpzioniPA dati={props.dati} />
      case undefined:
        return <OpzioniVerificaSdi dati={props.dati} />
      default:
        return null
    }
  }

  const opzioniAutofattura = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return <OpzioniAutofattura dati={props.dati} />
      case 'impossibilita_recapito':
        return <OpzioniAutofattura dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniAutofattura dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniAutofattura dati={props.dati} />
      case 'decorrenza_termini':
        return null
      case 'notifica_esito_positivo':
        return null
      case 'notifica_esito_negativo':
        return null
      case 'attestazione_trasmissione':
        return null
      case undefined:
        return null
      default:
        return null
    }
  }

  const opzioniPrivato = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return <OpzioniScarto dati={props.dati} />
      case 'impossibilita_recapito':
        return <OpzioniConsegnata dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniConsegnata dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniConsegnata dati={props.dati} />
      case 'decorrenza_termini':
        return null
      case 'notifica_esito_positivo':
        return null
      case 'notifica_esito_negativo':
        return null
      case 'attestazione_trasmissione':
        return null
      case undefined:
        return <OpzioniVerificaSdi dati={props.dati} />
      default:
        return null
    }
  }

  const opzioniDebito = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'impossibilita_recapito':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'decorrenza_termini':
        return null
      case 'notifica_esito_positivo':
        return null
      case 'notifica_esito_negativo':
        return null
      case 'attestazione_trasmissione':
        return null
      case undefined:
        return <OpzioniVerificaSdi dati={props.dati} />
      default:
        return null
    }
  }

  const opzioniCredito = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'impossibilita_recapito':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'decorrenza_termini':
        return null
      case 'notifica_esito_positivo':
        return null
      case 'notifica_esito_negativo':
        return null
      case 'attestazione_trasmissione':
        return null
      case undefined:
        return <OpzioniVerificaSdi dati={props.dati} />
      default:
        return null
    }
  }

  const opzioniCreditoPA = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'impossibilita_recapito':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'decorrenza_termini':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'notifica_esito_positivo':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'notifica_esito_negativo':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'attestazione_trasmissione':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case undefined:
        return <OpzioniVerificaSdi dati={props.dati} />
      default:
        return null
    }
  }

  const opzioniDebitoPA = () => {
    const lastEsito = getLast(props.dati.esito)
    switch (lastEsito?.esito) {
      case 'scarto':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'impossibilita_recapito':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'mancata_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'ricevuta_di_consegna':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'decorrenza_termini':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'notifica_esito_positivo':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'notifica_esito_negativo':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case 'attestazione_trasmissione':
        return <OpzioniConsegnataVariazione dati={props.dati} />
      case undefined:
        return <OpzioniVerificaSdi dati={props.dati} />
      default:
        return null
    }
  }

  return (
    <>
      {props.dati.fattura.tipologia === 'Fattura cartacea' && opzioniCartacea()}
      {props.dati.fattura.tipologia === 'Fattura elettronica' && opzioniPrivato()}
      {props.dati.fattura.tipologia === 'Fattura PA' && opzioniPA()}
      {props.dati.fattura.tipologia === 'Nota di debito' && opzioniDebito()}
      {props.dati.fattura.tipologia === 'Nota di credito' && opzioniCredito()}
      {props.dati.fattura.tipologia === 'Nota di debito PA' && opzioniDebitoPA()}
      {props.dati.fattura.tipologia === 'Nota di credito PA' && opzioniCreditoPA()}
      {props.dati.fattura.tipologia === 'Autofattura' && opzioniAutofattura()}
    </>
  )
}

export default ContainerOpzioni

import React from 'react'
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { apriModalIncasso } from '../../../app/store/riepilogoFatture/slice'

const ContainerIncasso = (props) => {
  const dispatch = useDispatch()

  return (
    <>
      {props.incassata === 'Si' && (
        <span className="testo-verde">
          {moment(props?.dati?.fattura?.data_incasso).format('DD/MM/YYYY')}
        </span>
      )}
      {props.incassata === 'No' && (
        <span
          onClick={() => {
            dispatch(apriModalIncasso(props.id))
          }}
          style={{ cursor: 'pointer' }}
          className="text-primary"
        >
          <i className="bi bi-piggy-bank"></i> Incassa
        </span>
      )}
    </>
  )
}
export default ContainerIncasso

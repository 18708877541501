import React from 'react'
import { Card, CardTitle } from 'reactstrap'

const Header = () => (
  <Card body style={{ height: '100%' }}>
    <CardTitle className="h3 font-grigio-scuro">
      <i className="bi bi-cash-coin"></i> La tua dichiarazione dei redditi
    </CardTitle>
    <h6 className="font-regular mb-3 interlinea-30">
      Abbiamo creato questa sezione per guidarti passo passo nella dichiarazione dei
      redditi. Da qui puoi consultare le dichiarazioni degli anni passati e seguire
      gli step previsti per la dichiarazione dell&apos;anno in corso. Vedila come un
      percorso diviso in 3 tappe principali: ogni volta che completi una tappa, puoi
      proseguire verso la successiva.
    </h6>
  </Card>
)

export default Header

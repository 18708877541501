import React from 'react'
import { Card, CardBody, Button, Row, Col } from 'reactstrap'
import classNames from 'classnames'
import request from 'superagent'
import { saveAs } from 'file-saver'
import swal from '@sweetalert/with-react'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const RisultatoStorico = (props) => {
  const user = useSelector(selectUser)

  const scaricaPdf = (protocollo) => {
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/scarica-pdf`)
      .send({ token: user.auth_token, protocollo })
      .then((response) => {
        if (response.body.error) {
          swal({
            icon: 'error',
            title: 'Errore',
            content: <div>{response.body.error_message}</div>,
            button: 'OK',
          })
        } else {
          const blob = new Blob([Buffer.from(response.body.pdf, 'base64')], {
            type: 'application/octet-stream',
          })
          saveAs(blob, 'ricevuta.pdf')
        }
      })
      .catch((e) => console.error(e.message))
  }

  const scaricaCSV = (protocollo) => {
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/scarica-csv`)
      .send({ token: user.auth_token, protocollo })
      .then((response) => {
        if (response.body.error) {
          swal({
            icon: 'error',
            title: 'Errore',
            content: <div>{response.body.error_message}</div>,
            button: 'OK',
          })
        } else {
          const blob = new Blob([Buffer.from(response.body.csv, 'base64')], {
            type: 'application/octet-stream',
          })
          saveAs(blob, 'ricevuta.zip')
        }
      })
      .catch((e) => console.error(e.message))
  }

  const esitiPositivi = () =>
    props.esitiPositivi.map((esito, i) => (
      <Card key={i} className="card-bordo-visibile mb-2">
        <CardBody>
          <h5
            className={classNames({
              'testo-verde': esito.stato === '2',
              'testo-arancione': esito.stato === '3',
              'testo-rosso': esito.stato === '1',
            })}
          >
            {esito.descrizione}
          </h5>
          <h6>
            Data invio {esito.data_invio} Numero protocollo {esito.protocollo}
          </h6>
          <hr className="my-3" />
          <Row>
            <Col md="9">
              <h6>Numero documenti inviati {esito.n_inviati}</h6>
              <h6 className="testo-verde">Accolti {esito.n_accolti}</h6>
              <h6 className="testo-arancione">
                Accolti con segnalazione {esito.n_warnings}
              </h6>
              <h6 className="testo-rosso">Scartati {esito.n_errori}</h6>
            </Col>
            <Col md="3">
              <Button
                onClick={() => scaricaPdf(esito.protocollo)}
                className="float-end"
                color="link"
              >
                <i className="bi bi-filetype-pdf"></i> SCARICA PDF
              </Button>
              <br />
              <Button
                onClick={() => scaricaCSV(esito.protocollo)}
                className="float-end"
                color="link"
              >
                <i className="bi bi-filetype-csv"></i> SCARICA CSV
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    ))

  return <div>{esitiPositivi()}</div>
}

export default RisultatoStorico

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, FormFeedback } from 'reactstrap'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaNota } from '../../../app/store/fiscomed/slice'

const Nota = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)

  const setNota = (nota) => dispatch(aggiornaNota(nota))

  return (
    <FormGroup>
      <Input
        type="textarea"
        name="text"
        id="exampleText"
        value={fiscomedData.nota}
        onChange={(e) => {
          if (e.target.value.length < 199) {
            setNota(e.target.value)
          }
        }}
        valid={/^[\w .,!?()]+$/.test(fiscomedData.nota)}
        invalid={!/^$|^[\w .,!?()]+$/.test(fiscomedData.nota)}
      />
      <FormFeedback valid>Sembra corretto</FormFeedback>
      <FormFeedback>
        Sembra che tu abbia usato dei caratteri non consentiti
      </FormFeedback>
    </FormGroup>
  )
}

export default Nota

import React, { useRef, useState } from 'react'
import { Container, Button, Input, FormGroup, Spinner, Label } from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import { leadNewsletter } from '../../../app/utility/QueryLanding'
import swal from 'sweetalert'
import { newsLetterGA } from '../../../app/utility/GA'

const NewsLetterMobile = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const refIniziaOra = useRef(null)
  const [consenso, setConsenso] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const iscrizioneNewsletter = async () => {
    if (email === '' || consenso === false || newsletter === false) {
      swal({
        title: 'Attenzione',
        text: "Compila tutti i campi, dai il tuo consenso al trattamento dei dati e all'isrizione alla newsletter",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    newsLetterGA()
    await leadNewsletter({ nome, email }).then((res) => {
      setCaricamento(false)
      swal({
        title: 'Ottimo 😊',
        text: "L'iscrizione alla newsletter di Fiscomed è andata a buon fine",
        icon: 'success',
        button: 'Ok',
      })
    })
  }

  return (
    <>
      <Helmet>
        <title>Newsletter</title>
        <meta property="title" content="Newsletter" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/newsletter" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Newsletter" />
        <meta
          property="og:description"
          content="Iscriviti gratuitamente alla newsletter fiscale per medici"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <meta
          property="description"
          content="Iscriviti gratuitamente alla newsletter fiscale per medici"
        />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }} className="mobile-container">
        <div className="immagine-background-landing-fiscomed-mobile"></div>
        <br />
        <br />
        <span className="titolo1-mobile">
          Tutto quello che devi sapere sulla fiscalità
        </span>
        <br />
        <br />
        <h6 className="h6-landing">
          Ti ricordiamo le scadenze che devi rispettare e ti spieghiamo come fare gli
          adempimenti della <span className="testo-blu">professione medica.</span>{' '}
          Tutto nel linguaggio più semplice possibile
        </h6>
        <br />
        <center>
          <Button
            className="bottone-verde px-4"
            onClick={() =>
              refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
            }
            style={{ paddingTop: '14px', paddingBottom: '14px' }}
          >
            ISCRIVITI - è gratis
          </Button>
        </center>
        <br />
        <br />
        <img
          src="/img_landing/immagine_newsletter.webp"
          alt="fiscomed"
          style={{ width: '100%' }}
        />
        <br />
        <br />
        <br />
        <center>
          <span className="etichetta-mobile">Perché una newsletter?</span>
          <br />
          <br />
          <br />
          <span className="titolo2-mobile">
            Avere un&apos;educazione fiscale{' '}
            <span className="testo-verde">
              è il primo passo per non compiere errori
            </span>
          </span>
        </center>
        <br />
        <br />
        <h6 className="h6-landing">
          La fiscalità è un argomento che spaventa molto. Quando si parla di Partita
          IVA, tasse e scadenze la faccia che tutti facciamo è più o meno questa: 😨
          <br />
          <br />
          Questo accade perché si viene catapultati nel mondo del lavoro senza avere
          la giusta dose di informazioni chiare, specifiche e di qualità.
        </h6>
        <br />
        <br />
        <div className="div-gradient-newsletter">
          <img
            src="/img_landing/newspaper.webp"
            alt="fiscomed"
            style={{ width: '30%', maxWidth: '100px' }}
          />
          <br />
          <br />
          <span className="titolo2-mobile">
            Così abbiamo creato la newsletter di Fiscomed. L&apos;obiettivo?
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Trasmettere le nostre <b>conoscenze</b> a tutti i giovani medici che
            desiderano capire come funziona il mondo fiscale e cosa bisogna fare per
            essere in regola
          </h6>
        </div>
        <br />
        <br />
        <center>
          <span className="etichetta-mobile">Siamo già in tanti 😊</span>
          <br />
          <br />
          <br />
          <span className="titolo2-mobile">
            3150 medici leggono e trovano utili le
            <br />
            <span className="testo-verde">le nostre email</span>
          </span>
          <br />
          <br />
          <img
            src="/img_landing/cuore_dritto.webp"
            alt="fiscomed"
            style={{ width: '40%', maxWidth: '170px' }}
          />
          <br />
          <br />
          <span className="etichetta-mobile">Cosa puoi imparare</span>
          <br />
          <br />
          <br /> <br />
          <div
            style={{
              borderRadius: '10px',
              backgroundColor: '#0583f2',
              padding: '10px',
              width: '100%',
              maxWidth: '250px',
            }}
          >
            <h6 className="h6-landing" style={{ color: 'white', textAlign: 'left' }}>
              Quali sono le <b>scadenze</b> fiscali della professione medica
            </h6>
          </div>
          <br />
          <div
            style={{
              borderRadius: '10px',
              backgroundColor: '#66d98e',
              padding: '10px',
              width: '100%',
              maxWidth: '250px',
            }}
          >
            <h6 className="h6-landing" style={{ color: 'white', textAlign: 'left' }}>
              Quando vanno pagati i <b>contributi</b> all&apos;ENPAM o alla Gestione
              Separata
            </h6>
          </div>
          <br />
          <br />
          <img
            src="/img_landing/telefono_mobile.webp"
            alt="Screen di un telefono cellulare"
            style={{ width: '70%', maxWidth: '300px' }}
          />
          <br />
          <br />
          <div
            style={{
              borderRadius: '10px',
              backgroundColor: '#66d98e',
              padding: '10px',
              width: '100%',
              maxWidth: '250px',
            }}
          >
            <h6 className="h6-landing" style={{ color: 'white', textAlign: 'left' }}>
              Tutte le <b>novità fiscali</b> che possono riguardare il tuo lavoro
            </h6>
          </div>
          <br />
          <div
            style={{
              borderRadius: '10px',
              backgroundColor: '#f2b705',
              padding: '10px',
              width: '100%',
              maxWidth: '250px',
            }}
          >
            <h6 className="h6-landing" style={{ color: 'white', textAlign: 'left' }}>
              Come rispettare gli <b>adempimenti</b> fiscali del lavoro da medico
            </h6>
          </div>
          <br />
          <br ref={refIniziaOra} />
          <span className="etichetta-mobile">Iscriviti alla newsletter</span>
          <br />
        </center>
        <br />
        <br />
        <div className="div-verde-guida py-5 px-4">
          <span className="titolo2-mobile">
            Impara a conoscere la fiscalità della professione medica
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Con la nostra newsletter ci impegniamo a darti tutte le informazioni
            necessarie scitte nel modo più semplice possibile. <i>Fiscalese</i> e{' '}
            <i>burocratese</i> non fanno per noi.
          </h6>
          <br />
          <center>
            <FormGroup>
              <h6 className="font-medium mb-3">Come ti chiami?</h6>
              <Input
                type="text"
                placeholder="Ippocrate di Coo"
                style={{ borderColor: '#66d98e', maxWidth: '300px' }}
                autoComplete="name"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <h6 className="font-medium mb-3">
                Qual è l&apos;email che usi di più?
              </h6>
              <Input
                type="text"
                placeholder="ippocrate@email.com"
                style={{ borderColor: '#66d98e', maxWidth: '300px' }}
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <br />
            <FormGroup check style={{ maxWidth: '300px' }}>
              <Input
                type="checkbox"
                name="privacy"
                id="privacy"
                style={{ marginTop: '2px' }}
                checked={consenso}
                onChange={(e) => setConsenso(e.target.checked)}
              />
              <Label for="privacy" className="consenso">
                Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
                <a
                  href="/documenti/termini_e_condizioni.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>privacy policy</u>
                </a>
              </Label>
            </FormGroup>
            <br />
            <FormGroup check style={{ maxWidth: '300px' }}>
              <Input
                type="checkbox"
                name="newsletter"
                id="newsletter"
                style={{ marginTop: '2px' }}
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
              />
              <Label for="newsletter" className="consenso">
                Scaricando la guida, accetto di iscrivermi alla newsletter
              </Label>
            </FormGroup>
            <br />
            <br />
            {caricamento ? (
              <Button
                className="bottone-verde px-4"
                disabled
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
              >
                <Spinner color="light" size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-verde px-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                onClick={() => iscrizioneNewsletter()}
              >
                ISCRIVITI ALLA NEWSLETTER
              </Button>
            )}
          </center>
        </div>
        <div className="px-4">
          <br />
          <br />
          <br />
          <div style={{ minHeight: '150px' }}>
            <img
              src="/img_landing//file-lock_verde.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Teniamo alla tua <b>privacy</b>, quindi non daremo a nessuno il tuo
              indirizzo email
            </h6>
            <br />
            <br />
            <br />
          </div>
          <div style={{ minHeight: '150px' }}>
            <img
              src="/img_landing/stoplights_verde.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Naturalmente, puoi <b>disiscriverti</b> quando vuoi
            </h6>
            <br />
            <br />
            <br />
          </div>
          <div style={{ minHeight: '150px' }}>
            <img
              src="/img_landing/chat-left-dot_sverde.webp"
              alt="icona"
              className="icona-guida-mobile"
            />
            <h6 className="h6-landing">
              Non ti bombarderemo mai di inutili email promozionali. Riceverai{' '}
              <b>al massimo</b> 3 email al mese per ricordarti le <b>scadenze</b>{' '}
              fiscali di quel periodo e spiegarti tutto ciò che devi sapere riguardo
              gli <b>adempimenti</b> più importanti
            </h6>
          </div>
        </div>
        <br />
        <br />
      </Container>
      <Footer />
    </>
  )
}
export default NewsLetterMobile

import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { Container, Card, CardText, CardBody, CardTitle, Button } from 'reactstrap'

const FiscoDemiaVideo = (props) => {
  const { nomeVideo } = useParams()
  const parametri = {
    introduzione: {
      titolo: 'Tutto quello che puoi fare da Fiscomed',
      descrizione:
        'Fiscomed non è solo un software di fatturazione, ma è un vero e proprio supporto digitale nella gestione della tua Partita IVA. In questo video Giulia ti mostra come sfruttare al meglio tutte le sue potenzialità 😊',
      url: '/fiscodemia/video/intro_piattaforma.mp4',
    },
    pagamentomarcadabollo: {
      titolo: 'Fatture cartacee, elettroniche e imposta di bollo',
      descrizione:
        "In questo video Giulia ti spiega in parole semplici come funziona l'imposta di bollo, sia per quanto riguarda le fatture cartacee che quelle elettroniche.",
      url: '/fiscodemia/video/impostadibollo.mp4',
    },
    pagamentomarcadabollo2: {
      titolo: 'Come pagare l’imposta di bollo dal sito dell’AdE',
      descrizione:
        'Per pagare l’imposta di bollo delle fatture elettroniche bisogna fare un versamento cumulativo trimestrale e il modo più facile e veloce è dal sito dell’Agenzia delle Entrate. Giulia ti fa vedere passo dopo passo come si fa.',
      url: '/fiscodemia/video/impostadibollo2.mp4',
    },
    conservazioneconsultazione: {
      titolo: 'Come attivare la conservazione delle fatture elettroniche',
      descrizione:
        'La conservazione è una funzionalità molto importante che devi attivare dal sito dell’Agenzia delle Entrate. Dai un’occhiata a questo video per scoprire quali passi devi fare.',
      url: '/fiscodemia/video/conservazione.mp4',
    },
    sts1: {
      titolo: 'Sistema TS: cos’è, scadenze e cosa fare se non si rispettano',
      descrizione:
        'Quando un paziente ti paga per una prestazione sanitaria, i tuoi adempimenti fiscali non finiscono con l’emissione della fattura. In questo video, Giulia ti spiega che cos’è il Sistema Tessera Sanitaria, come funziona e quali sono le sue scadenze',
      url: '/fiscodemia/video/sts_1.mp4',
    },
    sts2: {
      titolo: 'Sistema TS: come ottenere le credenziali',
      descrizione:
        'Nel primo video ti abbiamo spiegato quando e perché bisogna inviare i dati delle fatture al Sistema Tessera Sanitaria. Per farlo, però, ti servono delle credenziali. In questo video Giulia ti mostra come fare l’iscrizione',
      url: '/fiscodemia/video/sts_2.mp4',
    },
    sts3: {
      titolo: 'Sistema TS: come inviare i dati con Fiscomed',
      descrizione:
        'Dopo aver fatto l’iscrizione al Sistema Tessera Sanitaria, puoi iniziare a inviare i dati delle fatture da Fiscomed. Per farlo hai due scelte: usare la sezione dedicata, oppure compilare la schermata alla quale puoi accedere dopo aver fatto una fattura cartacea direttamente da Fiscomed',
      url: '/fiscodemia/video/sts_3.mp4',
    },
    fattura1: {
      titolo: 'Fattura cartacea, elettronica e proforma',
      descrizione:
        'Fattura cartacea o fattura elettronica? Questo è il dilemma. Dopo che avrai visto questo video saprai con sicurezza quale fattura va fatta in ogni occasione.',
      url: '/fiscodemia/video/fattura1.mp4',
    },
    fattura2: {
      titolo: 'Fiscomed: come fare una fattura',
      descrizione:
        'In questo video Giulia ti mostra come fare le fatture cartacee, elettroniche e verso la Pubblica Amministrazione. Anche se la prima fattura mette sempre un po’ d’ansia, sappi che con Fiscomed è molto più semplice di quello che pensi.',
      url: '/fiscodemia/video/fattura2.mp4',
    },
    fattura3: {
      titolo: "Cosa posso fare da 'Riepilogo fatture'?",
      descrizione:
        "'Riepilogo fatture' è una sezione che devi assolutamente imparare a conoscere. Da qui puoi controllare tutte le fatture che hai emesso, scaricarle e monitorare gli esiti dello SdI",
      url: '/fiscodemia/video/fattura3.mp4',
    },
    f24_1: {
      titolo: 'Modello F24: cos’è e come si paga',
      descrizione:
        'L’F24 è il modello con cui si pagano le imposte. Anche se dovrai solo ricopiare il facsimile preparato dal tuo commercialista, è importante che tu sappia che cos’è un F24 e come si paga.',
      url: '/fiscodemia/video/F24_1.mp4',
    },
    f24_2: {
      titolo: 'Come si paga un F24 con l’home banking',
      descrizione:
        'In questo video, Giulia ti da qualche suggerimento per pagare i tuoi F24 con il tuo home banking.',
      url: '/fiscodemia/video/F24_2.mp4',
    },
    f24_3: {
      titolo: 'F24 e Agenzia delle Entrate: tutti gli step da seguire',
      descrizione:
        'Guarda questo video per scoprire passo passo cosa devi fare per pagare i tuoi F24 dal sito dell’Agenzia delle Entrate',
      url: '/fiscodemia/video/F24_3.mp4',
    },
    modello_d1: {
      titolo: 'Modello D, aliquote e contribuzione ridotta',
      descrizione:
        "A luglio c'è una scadenza molto importante: l'invio del Modello D ENPAM. Ma di che cosa si tratta? La risposta è in questo video.",
      url: '/fiscodemia/video/modello_d1.mp4',
    },
    modello_d2: {
      titolo: 'Come compilare il Modello D ENPAM',
      descrizione:
        'In questo video tutorial, Giulia ti mostra i passaggi da fare per compilare e inviare correttamente il Modello D dalla tua area riservata ENPAM.',
      url: '/fiscodemia/video/modello_d2.mp4',
    },
    fatture_estere: {
      titolo: 'Fatture estere e IVA',
      descrizione:
        'Comprare da privato, quindi senza utilizzare la propria Partita IVA, è la scelta migliore per chi è in regime forfettario. Questo perché acquistare con la propria Partita IVA può significare dover fare degli adempimenti fiscali in più. Giulia te ne parla in questo video.',
      url: '/fiscodemia/video/fatture_estere.mp4',
    },
    autofatture: {
      titolo: "Come emettere un'autofattura da Fiscomed",
      descrizione:
        "Ogni volta che ricevi una fattura estera dove è indicata la tua Partita IVA o una fattura italiana con la dicitura 'inversione contabile', devi emettere un'autofattura. Come si fa? Guarda questo video per scoprirlo.",
      url: '/fiscodemia/video/autofatture.mp4',
    },
    cassettofiscale: {
      titolo: 'Come delegarci al tuo cassetto fiscale',
      descrizione:
        "Il cassetto fiscale è uno spazio dell'Agenzia delle Entrate in cui ci sono tutte le tue informazioni fiscali. Delegarci all'accesso è un passo super importante, perché ci permette di fare la dichiarazione in modo più semplice",
      url: '/fiscodemia/video/delega.mp4',
    },
    rimborsi: {
      titolo: 'Quando e come indicare i rimborsi spesa',
      descrizione:
        "Se ti capita di sostenere delle spese 'in nome e per conto del cliente', puoi inserire in fattura il rimborso che devi ricevere. Guarda questo video per imparare a farlo nel modo giusto",
      url: '/fiscodemia/video/rimborsi.mp4',
    },
    gestione_separata: {
      titolo: 'Gestione Separata INPS: cos’è e come iscriversi',
      descrizione:
        'Tutti i medici in formazione specialistica devono versare i contributi alla Gestione Separata INPS. Nonostante il versamento sia automatico, l’università potrebbe chiederti di fare l’iscrizione in autonomia. Guarda questo video per scoprire come fare',
      url: '/fiscodemia/video/gestione_separata.mp4',
    },
  }
  const [datiVideo, setDatiVideo] = useState({
    titolo: 'Caricamento...',
    descrizione: 'Caricamento...',
    url: '',
  })

  useEffect(() => {
    setDatiVideo(parametri[nomeVideo])
  }, [])

  return (
    <Container className="mt-4 mb-5">
      <Card>
        <CardBody>
          <Button tag={Link} to="/fiscodemia/home" className="bottone-blu">
            <i className="bi bi-arrow-left"></i> Fiscodèmia
          </Button>
          <br />
          <br />
          <CardTitle className="h4 font-grigio-scuro">{datiVideo.titolo}</CardTitle>

          <div style={{ width: '100%' }}>
            <video src={datiVideo.url} autoPlay controls width="100%" />
          </div>
          <br />
          <CardText
            className="font-grigio-scuro"
            dangerouslySetInnerHTML={{ __html: datiVideo.descrizione }}
          />
        </CardBody>
      </Card>
    </Container>
  )
}

export default FiscoDemiaVideo

import React from 'react'
import { FormFeedback, FormGroup, Input } from 'reactstrap'
import { aggiornaIdentificativoMarcaDaBollo } from '../../../app/store/fiscomed/slice'
import { useDispatch, useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const MarcaDaBollo = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)

  const setNumeroMarca = (valore) =>
    dispatch(aggiornaIdentificativoMarcaDaBollo(valore))

  return (
    <FormGroup className="mt-2 mb-3">
      <Input
        type="text"
        onChange={(e) => setNumeroMarca(e.target.value)}
        value={fiscomedData.numeroMarca}
        placeholder="Identificativo marca da bollo"
        valid={
          fiscomedData.identificativoMarcaDaBollo.length > 0 &&
          fiscomedData.identificativoMarcaDaBollo.length === 14
        }
        invalid={
          fiscomedData.identificativoMarcaDaBollo.length > 0 &&
          fiscomedData.identificativoMarcaDaBollo.length !== 14
        }
      />
      <FormFeedback valid>Sembra corretto</FormFeedback>
      <FormFeedback>
        L&apos;identificativo della marca da bollo è composto da 14 caratteri
      </FormFeedback>
    </FormGroup>
  )
}

export default MarcaDaBollo

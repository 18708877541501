import React, { useContext, useEffect, useState } from 'react'
import { Row, Col, Progress } from 'reactstrap'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import classNames from 'classnames'

const StepHeader = () => {
  const context = useContext(DichiarazioneContext)
  const [progressValue, setProgressValue] = useState(2)

  useEffect(() => {
    if (context.dati?.tappaAttiva === 'fatture') {
      setProgressValue(2)
    }
    if (context.dati?.tappaAttiva === 'contributi') {
      setProgressValue(25)
    }
    if (context.dati?.tappaAttiva === 'quiz') {
      setProgressValue(50)
    }
    if (context.dati?.tappaAttiva === 'f24inlavorazione') {
      setProgressValue(75)
    }
    if (context.dati?.tappaAttiva === 'modelloRedditi') {
      setProgressValue(100)
    }
  }, [context.dati?.tappaAttiva])

  const check = (completato) => (
    <i
      className={classNames(
        { 'bi bi-check-circle': !completato },
        { 'bi bi-check-circle-fill': completato }
      )}
    />
  )

  return (
    <div className="mb-4">
      <Row>
        <Col xs={12}>
          <Progress value={progressValue} className="progress-blu" />
        </Col>
        <br />
        <Col xs={3}>
          <h6
            className={classNames('font-regular mb-3 interlinea-30', {
              'testo-blu': context.dati.tappe.find(
                (tappa) => tappa.nome === 'fatture'
              ).completata,
            })}
          >
            {check(
              context.dati.tappe.find((tappa) => tappa.nome === 'fatture').completata
            )}{' '}
            Documenti
          </h6>
        </Col>
        <Col xs={3}>
          <h6
            className={classNames('font-regular mb-3 interlinea-30', {
              'testo-blu': context.dati.tappe.find(
                (tappa) => tappa.nome === 'contributi'
              ).completata,
            })}
          >
            {check(
              context.dati.tappe.find((tappa) => tappa.nome === 'contributi')
                .completata
            )}{' '}
            ENPAM
          </h6>
        </Col>
        <Col xs={3}>
          <h6
            className={classNames('font-regular mb-3 interlinea-30', {
              'testo-blu': context.dati.tappe.find((tappa) => tappa.nome === 'quiz')
                .completata,
            })}
          >
            {check(
              context.dati.tappe.find((tappa) => tappa.nome === 'quiz').completata
            )}{' '}
            Quiz
          </h6>
        </Col>
        <Col xs={3}>
          <h6
            className={classNames('font-regular mb-3 interlinea-30', {
              'testo-blu': context.dati.tappe.find(
                (tappa) => tappa.nome === 'f24inlavorazione'
              ).completata,
            })}
          >
            {check(
              context.dati.tappe.find((tappa) => tappa.nome === 'f24inlavorazione')
                .completata
            )}{' '}
            F24
          </h6>
        </Col>
      </Row>
    </div>
  )
}

export default StepHeader

import React, { useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from 'reactstrap'
import { ImportaBorsaContext } from '../../app/context/ImportaBorsa'

const ModalImporta = () => {
  const importaBorseContext = useContext(ImportaBorsaContext)

  return (
    <Modal
      isOpen={importaBorseContext.dati.modalImportOpen}
      toggle={() => importaBorseContext.statoModalImport(false)}
    >
      <ModalHeader
        className="h4 font-medium"
        toggle={() => importaBorseContext.statoModalImport(false)}
      >
        Importa la{' '}
        {importaBorseContext.dati.tipologia === 'borsa_mmg'
          ? 'borsa MMG'
          : 'borsa di specializzazione'}
      </ModalHeader>
      <ModalBody>
        <h6 className="font-regular interlinea-30">
          {importaBorseContext.dati.tipologia === 'borsa_mmg'
            ? "Seleziona la tipologia di borsa che vuoi importare, inserisci l'importo IRPEF lordo, specifica il mese a cui fa riferimento il cedolino e carica una foto o un pdf dello stesso 😊"
            : '3 semplici passi: seleziona la tipologia di borsa che vuoi importare, specifica il mese a cui fa riferimento il cedolino e carica una foto o un pdf dello stesso 😊'}
        </h6>
        <br />
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Tipologia</Label>
              <Input
                value={
                  importaBorseContext.dati.tipologia === 'borsa_mmg'
                    ? 'Borsa mmg'
                    : 'Borsa di specializzazione'
                }
                onChange={(e) => {
                  if (e.target.value.toLowerCase() === 'borsa di specializzazione') {
                    importaBorseContext.impostaTipologia('borsa_specializzazione')
                  } else {
                    importaBorseContext.impostaTipologia('borsa_mmg')
                  }
                }}
                type="select"
              >
                <option>Borsa di specializzazione</option>
                <option>Borsa mmg</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Mese</Label>
              <Input
                type="month"
                value={
                  importaBorseContext.dati.dataIncasso === null
                    ? ''
                    : importaBorseContext.dati.dataIncasso
                }
                onChange={(e) =>
                  importaBorseContext.impostaAnnoIncasso(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {importaBorseContext.dati.tipologia === 'borsa_mmg' && (
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">Importo IRPEF lordo</Label>
                <Input
                  type="number"
                  placeholder="120,23"
                  value={
                    importaBorseContext.dati.importo === null
                      ? ''
                      : importaBorseContext.dati.importo
                  }
                  onChange={(e) =>
                    importaBorseContext.impostaImporto(e.target.value)
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <FormGroup>
          <Label for="exampleEmail">Foto o pdf della borsa</Label>
          <Input
            type="file"
            onChange={(e) => importaBorseContext.impostaFileBorsa(e.target.files[0])}
          />
        </FormGroup>
        <br />
        {importaBorseContext.dati.upLoading ? (
          <Button className=" float-end bottone-blu" disabled>
            Caricamento...
          </Button>
        ) : (
          <Button
            className="bottone-blu float-end"
            onClick={() => importaBorseContext.aggiungiBorsa()}
          >
            IMPORTA LA BORSA
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalImporta

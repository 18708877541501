import React from 'react'
import { Container, Table, Card, Row, Col } from 'reactstrap'
import moment from 'moment'
import 'moment/locale/it'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'

const Scadenze = () => (
  <>
    <Helmet>
      <title>Scadenze</title>
      <meta property="title" content="Scadenze" />
      <meta name="robots" content="noindex" />
    </Helmet>

    <Container className="mt-4">
      <Row>
        <Col xs={4}>
          <Card body>
            <center>
              <img src="img/fisco/scadenze.png" style={{ width: '100%' }} />
            </center>
          </Card>
        </Col>
        <Col xs={8}>
          <Card body style={{ height: '100%' }}>
            <h4 className="font-medium mt-2">
              <i className="bi bi-hourglass-split"></i> Le scadenze più importanti
              per te
            </h4>
            <h6 className="mt-3 font-regular interlinea-30">
              In questa tabella abbiamo riportato{' '}
              <span className="font-bold">tuuutte</span> le eventuali scadenze che un
              giovane medico in regime forfettario potrebbe dover rispettare.
            </h6>
            <h6 className="mt-3 font-regular interlinea-30">
              Naturalmente, in base alla tua situazione alcune di queste scadenze{' '}
              <span className="font-bold">potrebbero non riguardarti</span>. Ad
              esempio, se non emetti fatture cartacee non dovrai mai inviare i dati
              al Sistema Tessera Sanitaria. Se non fai le fatture in formato
              elettronico, invece, non dovrai pagare l&apos;imposta di bollo.
            </h6>
          </Card>
        </Col>
      </Row>
      <br />
      <Row>
        <Col xs={8}>
          <Card body style={{ height: '100%' }}>
            <Table className="mt-3 table-gialla-chiaro" bordered>
              <thead>
                <tr>
                  <th
                    className="h4"
                    style={{
                      fontWeight: '500',
                    }}
                  >
                    Scadenza
                  </th>
                  <th
                    className="h4"
                    style={{
                      fontWeight: '500',
                    }}
                  >
                    Adempimento
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="h6 testo-verde" style={{ fontWeight: '400' }}>
                    31 gennaio {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Inserimento dati tessera sanitaria per fatture cartacee fatte dal
                    primo luglio {moment().year() - 1} al 31 dicembre{' '}
                    {moment().year() - 1}
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-arancione" style={{ fontWeight: '400' }}>
                    28 febbraio {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Pagamento imposta di bollo delle fatture elettroniche. Per sapere
                    come fare clicca{' '}
                    <Link to="/fiscodemia/video/pagamentomarcadabollo">qui</Link>
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-blu" style={{ fontWeight: '400' }}>
                    30 aprile {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Quota A {moment().year()}
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-verde-scuro" style={{ fontWeight: '400' }}>
                    31 luglio {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Pagamento saldo imposta sostitutiva relativa al{' '}
                    {moment().year() - 1} e primo acconto imposta relativa al{' '}
                    {moment().year()} per tutte le persone che nel{' '}
                    {moment().year() - 1} avevano già la Partita IVA
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-blu" style={{ fontWeight: '400' }}>
                    6 settembre {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Invio Modello D relativo al {moment().year() - 1}
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-verde" style={{ fontWeight: '400' }}>
                    30 settembre {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Inserimento dati tessera sanitaria per fatture cartacee fatte dal
                    primo gennaio {moment().year()} al 30 giugno {moment().year()}
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-blu" style={{ fontWeight: '400' }}>
                    31 ottobre {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Quota B relativa al {moment().year() - 1}
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-arancione" style={{ fontWeight: '400' }}>
                    30 novembre {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Pagamento imposta di bollo delle fatture elettroniche
                  </td>
                </tr>
                <tr>
                  <td className="h6 testo-verde-scuro" style={{ fontWeight: '400' }}>
                    30 novembre {moment().year()}
                  </td>
                  <td className="h6" style={{ fontWeight: '400' }}>
                    Pagamento secondo acconto imposta sostitutiva relativa al{' '}
                    {moment().year()}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Card>
        </Col>
        <Col xs={4}>
          <Card body style={{ border: '2px solid #f2b705' }}>
            <h4 className="font-bold mb-4">Ricevi delle fatture estere?</h4>
            <h6 className="font-regular mb-3 interlinea-30">
              <i>Allora per te le scadenze non finiscono qui.</i>
            </h6>
            <h6 className="font-regular mb-3 interlinea-30">
              Quando compri qualcosa da un&apos;azienda estera e ricevi la fattura
              con la tua <span className="font-bold">Partita IVA</span>, ci sono due
              cose che devi fare.
            </h6>
            <h6 className="font-regular mb-3 interlinea-30">
              La prima è emettere un&apos;
              <span className="font-bold">autofattura</span> entro il{' '}
              <span className="font-bold">15 del mese successivo</span>.
            </h6>
            <h6 className="font-regular mb-3 interlinea-30">
              La seconda è pagare l&apos;<span className="font-bold">IVA</span> entro
              il <span className="font-bold">16 del mese successivo</span>. Questo,
              però, solo se hai comprato un bene o servizio su cui deve essere
              applicata l&apos;IVA.
            </h6>
            <br />
            <h6 className="font-regular mb-3 interlinea-30">
              <i>
                Su Fiscodemia trovi due video che ti possono aiutare a capirci
                qualcosa di più:
                <ul>
                  <li>&quot;Fatture estere e IVA&quot;</li>
                  <li>&quot;Come fare un&apos;autofattura da Fiscomed&quot;</li>
                </ul>
              </i>
            </h6>
          </Card>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  </>
)

export default Scadenze

import React, { useState, useEffect } from 'react'
import { Card, Button, Progress } from 'reactstrap'
import { Link } from 'react-router-dom'
import { presignedUrlPdfProforma } from '../../../../app/utility/QueryFattura'
import { useSelector } from 'react-redux'
import {
  selectIsLoadingUser,
  selectUser,
} from '../../../../app/store/user/selectors'

const FineProforma = (props) => {
  const idProforma = props.idProforma
  const [urlProforma, setUrlProforma] = useState('')
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectIsLoadingUser)
  const [infoFetched, setInfoFetched] = useState(false)
  const [valoreProgress, setValoreProgress] = useState(40)

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        presignedUrlPdfProforma(user, idProforma).then((urlProformaResponse) =>
          setUrlProforma(urlProformaResponse),
        )
      }
    }
    setValoreProgress(100)
  }, [isLoading])

  return (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={valoreProgress} className="progress-gialla" />
          <h3 className="font-medium mt-4">
            Se vuoi, puoi scaricare la tua fattura proforma 📃
          </h3>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Puoi scaricarla solamente in formato PDF
          </h6>
          <div className="float-end">
            <a
              className="bottone-blu text-white btn btn-secondary my-3 me-3"
              role="button"
              rel="noreferrer"
              href={urlProforma}
              target="_blank"
            >
              <i className="bi bi-file-earmark-pdf"></i> SCARICA PDF FATTURA
            </a>
          </div>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <h6 className="font-regular interlinea-25 mt-3 mb-3">
            Se adesso non hai bisogno di scaricare la tua fattura, non preoccuparti.
            Potrai farlo in <span className="font-bold">qualsiasi momento</span>{' '}
            dalla sezione <Link to="/fiscomed/proforma">Proforma</Link>.
          </h6>

          <br />

          <Button
            className="bottone-giallo float-end"
            tag={Link}
            to="/fiscomed/nuova-fattura/fattura/invio-semplificato"
          >
            INVIA UN&apos;ALTRA FATTURA
          </Button>
        </div>
      </Card>
    </>
  )
}

export default FineProforma

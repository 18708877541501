import React, { useContext } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
} from 'reactstrap'
import { ImportaBorsaContext } from '../../app/context/ImportaBorsa'
import moment from 'moment'

const ModalUpdate = () => {
  const importaBorseContext = useContext(ImportaBorsaContext)

  return (
    <Modal
      isOpen={importaBorseContext.dati.modalUpdateOpen}
      toggle={() => importaBorseContext.statoModalUpdate(false)}
    >
      <ModalHeader
        className="h4 font-medium"
        toggle={() => importaBorseContext.statoModalUpdate(false)}
      >
        Inserisci le informazioni corrette 😊
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Tipologia</Label>
              <Input
                value={
                  importaBorseContext.dati.tipologia === 'borsa_mmg'
                    ? 'Borsa mmg'
                    : 'Borsa di specializzazione'
                }
                onChange={(e) => {
                  if (e.target.value.toLowerCase() === 'borsa di specializzazione') {
                    importaBorseContext.impostaTipologia('borsa_specializzazione')
                  } else {
                    importaBorseContext.impostaTipologia('borsa_mmg')
                  }
                }}
                type="select"
              >
                <option>Borsa di specializzazione</option>
                <option>Borsa mmg</option>
              </Input>
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label for="exampleEmail">Mese</Label>
              <Input
                type="month"
                value={
                  importaBorseContext.dati.dataIncasso === null
                    ? ''
                    : moment(importaBorseContext.dati.dataIncasso).format('yyyy-MM')
                }
                onChange={(e) =>
                  importaBorseContext.impostaAnnoIncasso(e.target.value)
                }
              />
            </FormGroup>
          </Col>
          {importaBorseContext.dati.tipologia === 'borsa_mmg' && (
            <Col xs={6}>
              <FormGroup>
                <Label for="exampleEmail">Importo IRPEF lordo</Label>
                <Input
                  type="number"
                  placeholder="120,23"
                  value={
                    importaBorseContext.dati.importo === null
                      ? ''
                      : importaBorseContext.dati.importo
                  }
                  onChange={(e) =>
                    importaBorseContext.impostaImporto(e.target.value)
                  }
                />
              </FormGroup>
            </Col>
          )}
        </Row>
        <br />
        <br />

        {importaBorseContext.dati.uploadLoading ? (
          <Button className=" float-end bottone-blu" disabled>
            Caricamento...
          </Button>
        ) : (
          <Button
            className="bottone-blu float-end"
            onClick={() => importaBorseContext.inviaModifica()}
          >
            MODIFICA
          </Button>
        )}
      </ModalBody>
    </Modal>
  )
}

export default ModalUpdate

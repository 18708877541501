import currency from 'currency.js'

export const totaleImporto = (prestazioni, addebitoMarcaDaBollo) => {
  let importoPerQuantita = currency(0)
  let somma = currency(0)
  prestazioni.forEach((pres) => {
    importoPerQuantita = currency(pres.importo).multiply(pres.quantita)
    somma = somma.add(importoPerQuantita)
  })
  if (addebitoMarcaDaBollo) somma = somma.add(2)
  return somma.value
}

export const totaleImportoAutofattura = (prestazioni) => {
  let somma = currency(0)
  let sommaIva = currency(0)
  prestazioni.forEach((pres) => {
    somma = somma.add(pres.importoTotale)
    sommaIva = sommaIva.add(pres.importoIVA)
  })
  return { somma: somma.value, sommaIva: sommaIva.value }
}

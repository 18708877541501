import React from 'react'
import { useDispatch } from 'react-redux'
import { apriModalMancataConsegnaPA } from '../../../app/store/riepilogoFatture/slice'

const MancataConsegnaPA = () => {
  const dispatch = useDispatch()

  return (
    <>
      Mancata consegna{' '}
      <i
        className="bi bi-info-circle-fill"
        onClick={() => dispatch(apriModalMancataConsegnaPA())}
        style={{ cursor: 'pointer' }}
      ></i>
    </>
  )
}

export default MancataConsegnaPA

import { createSlice } from '@reduxjs/toolkit'
import { emptyState } from './costants'
import * as actions from './actions'

const initialState = {
  ...emptyState,
}

const riepilogoFattureSlice = createSlice({
  name: 'riepilogoFatture',
  initialState,
  reducers: {
    apriModalIncasso(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalIncassoOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalIncasso(state) {
      state.modalIncassoOpen = false
      state.fatturaSelezionata = null
    },
    aggiornaDataIncasso(state, action) {
      state.dataIncasso = action.payload
    },
    apriModalEmail(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalEmailOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalEmail(state) {
      state.modalEmailOpen = false
      state.fatturaSelezionata = null
    },
    setEmailCliente(state, action) {
      state.fatturaSelezionata.email_cliente = action.payload
    },
    apriModalAttestazionePA(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalAttestazionePAOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalAttestazionePA(state) {
      state.modalAttestazionePAOpen = false
      state.fatturaSelezionata = null
    },
    apriModalDecorrenzaTerminiPA(state) {
      state.modalDecorrenzaTerminiPAOpen = true
    },
    chiudiModalDecorrenzaTerminiPA(state) {
      state.modalDecorrenzaTerminiPAOpen = false
    },
    apriModalEsitoNegativoPA(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalEsitoNegativoPAOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalEsitoNegativoPA(state) {
      state.modalEsitoNegativoPAOpen = false
      state.fatturaSelezionata = null
    },
    apriModalEsitoPositivoPA(state) {
      state.modalEsitoPositivoPAOpen = true
    },
    chiudiModalEsitoPositivoPA(state) {
      state.modalEsitoPositivoPAOpen = false
    },
    apriModalImpossibilitaDiRecapito(state) {
      state.modalImpossibilitaDiRecapitoOpen = true
    },
    chiudiModalImpossibilitaDiRecapito(state) {
      state.modalImpossibilitaDiRecapitoOpen = false
    },
    apriModalMancataConsegnaAutofattura(state) {
      state.modalMancataConsegnaAutofatturaOpen = true
    },
    chiudiModalMancataConsegnaAutofattura(state) {
      state.modalMancataConsegnaAutofatturaOpen = false
    },
    apriModalMancataConsegnaPA(state) {
      state.modalMancataConsegnaPAOpen = true
    },
    chiudiModalMancataConsegnaPA(state) {
      state.modalMancataConsegnaPAOpen = false
    },
    apriModalMancataConsegnaPR(state) {
      state.modalMancataConsegnaPROpen = true
    },
    chiudiModalMancataConsegnaPR(state) {
      state.modalMancataConsegnaPROpen = false
    },
    apriModalConsegnata(state) {
      state.modalConsegnataOpen = true
    },
    chiudiModalConsegnata(state) {
      state.modalConsegnataOpen = false
    },
    apriModalConsegnataPA(state) {
      state.modalConsegnataPAOpen = true
    },
    chiudiModalConsegnataPA(state) {
      state.modalConsegnataPAOpen = false
    },
    apriModalScarto(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalScartoOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalScarto(state) {
      state.modalScartoOpen = false
      state.fatturaSelezionata = null
    },
    apriModalScartoPA(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalScartoPAOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalScartoPA(state) {
      state.modalScartoPAOpen = false
      state.fatturaSelezionata = null
    },
    apriModalScartoVariazione(state, action) {
      const fatturaSelezionata = state.fatture.find(
        (fattura) => fattura.fattura.id === action.payload,
      )
      state.modalScartoVariazioneOpen = true
      state.fatturaSelezionata = fatturaSelezionata
    },
    chiudiModalScartoVariazione(state) {
      state.modalScartoVariazioneOpen = false
      state.fatturaSelezionata = null
    },
    apriModalVerificaSdi(state) {
      state.modalVerificaSdiOpen = true
    },
    chiudiModalVerificaSdi(state) {
      state.modalVerificaSdiOpen = false
    },
  },
  extraReducers: (builder) => {
    builder.addCase(actions.idrata.fulfilled, (state, action) => {
      state.loading = false
      state.fatture = action.payload.fatture
      state.anniConFattureEmesse = action.payload.anniConFattureEmesse
    })
    builder.addCase(actions.idrata.rejected, (state, action) => {
      state.loading = false
      console.log('Errore', action.payload)
    })
    builder.addCase(actions.impostaAnnoEIdrata.fulfilled, (state, action) => {
      state.loading = false
      state.fatture = action.payload.responseBody.fatture
      state.anniConFattureEmesse = action.payload.responseBody.anniConFattureEmesse
      state.annoDiRiferimento = action.payload.anno
    })
    builder.addCase(actions.impostaAnnoEIdrata.rejected, (state, action) => {
      state.loading = false
      console.log('Errore', action.payload)
    })
    builder.addCase(actions.inviaEmailFattura.fulfilled, (state, action) => {
      state.modalEmailOpen = false
    })
    builder.addCase(actions.modificaStatoSTS.pending, (state, action) => {
      const fatturaDaModificare = state.fatture.find(
        (fattura) => fattura.fattura.id === action.meta.arg,
      )
      fatturaDaModificare.fattura.inviata_ts =
        !fatturaDaModificare.fattura.inviata_ts
    })
  },
})

export const {
  apriModalIncasso,
  chiudiModalIncasso,
  aggiornaDataIncasso,
  apriModalEmail,
  chiudiModalEmail,
  setEmailCliente,
  apriModalAttestazionePA,
  chiudiModalAttestazionePA,
  apriModalDecorrenzaTerminiPA,
  chiudiModalDecorrenzaTerminiPA,
  apriModalEsitoNegativoPA,
  chiudiModalEsitoNegativoPA,
  apriModalEsitoPositivoPA,
  chiudiModalEsitoPositivoPA,
  apriModalImpossibilitaDiRecapito,
  chiudiModalImpossibilitaDiRecapito,
  apriModalMancataConsegnaAutofattura,
  chiudiModalMancataConsegnaAutofattura,
  apriModalMancataConsegnaPA,
  chiudiModalMancataConsegnaPA,
  apriModalMancataConsegnaPR,
  chiudiModalMancataConsegnaPR,
  apriModalConsegnata,
  chiudiModalConsegnata,
  apriModalConsegnataPA,
  chiudiModalConsegnataPA,
  apriModalScarto,
  chiudiModalScarto,
  apriModalScartoPA,
  chiudiModalScartoPA,
  apriModalScartoVariazione,
  chiudiModalScartoVariazione,
  apriModalVerificaSdi,
  chiudiModalVerificaSdi,
} = riepilogoFattureSlice.actions

export default riepilogoFattureSlice.reducer

import React, { useState } from 'react'
import { Button, Input, Label, Spinner, Col, Row } from 'reactstrap'
import RisultatoStorico from './RisultatoStorico'
import request from 'superagent'
import swal from '@sweetalert/with-react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const Storico = () => {
  const user = useSelector(selectUser)
  const [esitiPositivi, setEsitiPositivi] = useState([])
  const [esitiNegativi, setEsitiNegativi] = useState([])
  const [dataInizio, setDataInizio] = useState(moment().format('YYYY-MM-DD'))
  const [dataFine, setDataFine] = useState(moment().format('YYYY-MM-DD'))
  const [loadingButtonCerca, setLoadingButtonCerca] = useState(false)

  const cerca = () => {
    if (loadingButtonCerca) return
    const dataInizioFormatted = dataInizio.split('-').reverse().join('-')
    const dataFineFormatted = dataFine.split('-').reverse().join('-')
    setLoadingButtonCerca(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/cerca-data`)
      .send({
        token: user.auth_token,
        dataInizio: dataInizioFormatted,
        dataFine: dataFineFormatted,
      })
      .then((response) => {
        if (response.body.error) {
          swal({
            icon: 'error',
            title: 'Errore',
            content: <div>{response.body.error_message}</div>,
            button: 'OK',
          })
        } else {
          if (response.body.esito === '1') {
            swal({
              icon: 'error',
              title: 'Errore',
              content: <div>{response.body.esiti_negativi}</div>,
              button: 'OK',
            })
          } else {
            if (Array.isArray(response.body.esiti_positivi)) {
              setEsitiPositivi(response.body.esiti_positivi)
            } else {
              setEsitiPositivi([response.body.esiti_positivi])
            }
            setEsitiNegativi(response.body.esiti_negativi)
          }
        }
        setLoadingButtonCerca(false)
      })
      .catch((e) => {
        setLoadingButtonCerca(false)
        swal({
          icon: 'error',
          title: 'Errore',
          content: <div>{e}</div>,
          button: 'OK',
        })
      })
  }

  return (
    <div>
      <br />
      <h6 className="font-regular interlinea-30">
        Il Sistema Tessera Sanitaria, purtroppo, non permette di vedere in maniera
        semplice i dati delle fatture inviate. Infatti, invece di darti un semplice
        elenco dei documenti inviati, ti dà una lista infinita di esiti (sì, proprio
        quelli gialli, verdi e rossi). Per rendere il tutto ancora più scomodo, è
        possibile cercare gli esiti solo attraverso la data. Il nostro programmatore
        ha fatto il massimo, ma purtroppo dipende tutto dal Sistema Tessera
        Sanitaria.
      </h6>
      <br />
      <Row>
        <Col md="6">
          <Label>Data inizio</Label>
          <Input
            type="date"
            value={dataInizio}
            onChange={(e) => setDataInizio(e.target.value)}
          />
        </Col>
        <Col md="6">
          <Label>Data fine</Label>
          <Input
            type="date"
            value={dataFine}
            onChange={(e) => setDataFine(e.target.value)}
          />
          <Button onClick={() => cerca()} className="bottone-blu float-end mt-4">
            {loadingButtonCerca ? <Spinner size="sm" type="grow" /> : 'CERCA ESITI'}
          </Button>
        </Col>
      </Row>
      <br />
      <br />
      <RisultatoStorico
        esitiPositivi={esitiPositivi}
        esitiNegativi={esitiNegativi}
      />
    </div>
  )
}

export default Storico

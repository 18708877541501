import { useParams, useHistory } from 'react-router-dom'
import request from 'superagent'
import React, { useState } from 'react'
import { Container, Input, Button } from 'reactstrap'
import { Helmet } from 'react-helmet'
import swal from 'sweetalert'

function ConfermaPasswordMobile(props) {
  const { codice } = useParams()
  const history = useHistory()
  const [password, setPassword] = useState('')

  function confirmChange() {
    const errors = []
    if (password.length < 8) {
      errors.push('La nuova password deve contenere almeno 8 caratteri')
    }
    if (errors.length > 0) {
      alert(errors.join('\n'))
    } else {
      request
        .post(`${process.env.REACT_APP_API_URL}/confermaCambioPassword`)
        .send({ codice, newPassword: password })
        .end((err, res) => {
          if (res.body.error || err) {
            swal({
              title: 'Non siamo riusciti a cambiare la tua password',
              text: 'I motivi potrebbero essere tanti. Fai un altro tentativo, se non dovessi riuscire scrivi a pill@fiscomed.it',
              icon: 'error',
              button: 'OK',
            })
          } else {
            swal({
              title: 'La tua password è stata cambiata',
              text: "La prossima volta che fai l'accesso assicurati di inserire questa nuova password",
              icon: 'success',
              button: 'OK',
            }).then(() => {
              history.push('/accesso')
            })
          }
        })
    }
  }

  return (
    <>
      <Helmet>
        <title>Imposta nuova password</title>
        <meta property="title" content="Imposta nuova password" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta
          property="og:url"
          content="https://fiscomed.it/cambio_password/:code"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Imposta nuova password" />
        <meta
          property="og:description"
          content="Pagina per reimpostare la propria password"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <meta name="robots" content="noindex" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }}>
        <br />
        <br />
        <span className="titolo2-mobile">
          Vuoi cambiare la tua <span className="testo-verde">password</span>?
        </span>
        <br />
        <br />
        <h6 className="h6-landing">
          Scrivi qui la tua nuova password.
          <br />
          Fai attenzione: deve essere almeno di 8 caratteri
        </h6>
        <br />
        <div className="form-group">
          <Input
            type="text"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Ippocrate123"
          />
        </div>
        <br />
        <Button
          onClick={() => confirmChange()}
          className="bottone-blu px-4"
          style={{ paddingTop: '14px', paddingBottom: '14px' }}
        >
          CAMBIA PASSWORD
        </Button>
      </Container>
    </>
  )
}

export default ConfermaPasswordMobile

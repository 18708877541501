import React, { useState, useEffect } from 'react'
import {
  Container,
  Card,
  Button,
  Input,
  Label,
  Progress,
  Row,
  Col,
} from 'reactstrap'
import request from 'superagent'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

const Onboarding = (props) => {
  const user = useSelector(selectUser)
  const [password, setPassword] = useState('')
  const [pincode, setPincode] = useState('')
  const [valueProgress, setValueProgress] = useState(20)

  const [step, setStep] = useState(1)

  const nextStep = () => {
    setStep(step + 1)
  }

  const prevStep = () => {
    setStep(step - 1)
  }

  useEffect(() => {
    if (step === 1) {
      setValueProgress(20)
    }
    if (step === 2) {
      setValueProgress(40)
    }
    if (step === 3) {
      setValueProgress(60)
    }
    if (step === 4) {
      setValueProgress(80)
    }
  }, [step])

  const salvaPinEPassword = () => {
    if (password === '' || pincode === '') {
      swal({
        title: "C'è qualcosa che non va 🙁",
        text: 'Sembra che tu non abbia completato i due campi. Inserisci la password e il pincode prima di andare avanti.',
        icon: 'error',
        button: 'Ok',
      })
      return
    }
    const isnum = /^\d+$/.test(pincode)
    if (!isnum || pincode.length !== 10) {
      swal({
        title: "C'è qualcosa che non va 🙁",
        text: 'Non siamo riusciti ad aggiornare il tuo pincode. Ricorda che si tratta di un codice di 10 cifre (ad esempio: 1234567890)',
        icon: 'error',
        button: 'Ok',
      })
      return
    }
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/salva-pin-password`)
      .send({ token: user.auth_token, password, pincode })
      .then((response) => {
        props.termina()
      })
      .catch((e) => console.error(e.message))
  }

  const firstStep = (
    <>
      <center>
        <div style={{ width: '80%' }}>
          <video src="/fiscodemia/video/sts_1.mp4" autoPlay controls width="100%" />
        </div>
      </center>
      <h3 className="font-regular px-5 mt-4">
        Ti diamo il benvenuto nel Sistema Tessera Sanitaria 👋
      </h3>
      <h6 className="font-regular px-5 mt-3 interlinea-25">
        Abbiamo pensato che <span className="font-bold">3 brevi video</span> fossero
        il migliore strumento per aiutarti a capire come funziona il Sistema Tessera
        Sanitaria.
        <br />
        <br />
        In questo primo video, Giulia ti spiega che{' '}
        <span className="font-bold">cos’è</span> il Sistema Tessera Sanitaria,{' '}
        <span className="font-bold">come funziona</span> e quali sono le sue{' '}
        <span className="font-bold">scadenze</span>
      </h6>
      <br />
      <div>
        <Button onClick={() => nextStep()} className="bottone-blu float-end">
          <i className="bi bi-arrow-right-short"></i> VAI AVANTI
        </Button>
      </div>
    </>
  )

  const secondStep = (
    <>
      <center>
        <div style={{ width: '80%' }}>
          <video src="/fiscodemia/video/sts_2.mp4" autoPlay controls width="100%" />
        </div>
      </center>
      <h3 className="font-regular px-5 mt-4">Il primo step è andato 💪</h3>
      <h6 className="font-regular px-5 mt-3 interlinea-25">
        Ora dovresti aver capito quando e perché è necessario inviare i dati delle
        fatture al Sistema Tessera Sanitaria.
        <br />
        Ma come fare per iscriversi? In questo secondo video Giulia ti mostra{' '}
        <span className="font-bold">come ottenere le credenziali</span>.
      </h6>
      <br />
      <div>
        <Button onClick={() => prevStep()} className="bottone-blu">
          <i className="bi bi-arrow-left-short"></i> VAI INDIETRO
        </Button>
        <Button onClick={() => nextStep()} className="bottone-blu float-end">
          <i className="bi bi-arrow-right-short"></i> VAI AVANTI
        </Button>
      </div>
    </>
  )

  const thirdStep = (
    <>
      <center>
        <div style={{ width: '80%' }}>
          <video src="/fiscodemia/video/sts_3.mp4" autoPlay controls width="100%" />
        </div>
      </center>
      <h3 className="font-regular px-5 mt-4">Ci siamo quasi 👀</h3>
      <h6 className="font-regular px-5 mt-3 interlinea-25">
        Dopo aver fatto l’iscrizione al Sistema Tessera Sanitaria, puoi iniziare a
        inviare i dati delle fatture da Fiscomed. Per farlo hai due scelte: usare la
        sezione dedicata, oppure compilare la schermata alla quale puoi accedere dopo
        aver fatto una fattura cartacea direttamente da Fiscomed.
        <br />
        <br />
        Guarda questo video per scoprire{' '}
        <span className="font-bold">tutto ciò che puoi fare</span> dalla nostra
        piattaforma.
      </h6>
      <br />
      <div>
        <Button onClick={() => prevStep()} className="bottone-blu">
          <i className="bi bi-arrow-left-short"></i> VAI INDIETRO
        </Button>
        <Button onClick={() => nextStep()} className="bottone-blu float-end">
          <i className="bi bi-arrow-right-short"></i> VAI AVANTI
        </Button>
      </div>
    </>
  )

  const fourthStep = (
    <>
      <h3 className="font-regular px-5">
        Un ultimo sforzo - Inserisci le tue credenziali 😊
      </h3>
      <h6 className="font-regular px-5 mt-3 interlinea-25">
        Per fare l&apos;invio dei dati al Sistema TS da Fiscomed hai bisogno di 4
        cose: il tuo codice fiscale, la tua Partita IVA, la tua password e il tuo
        pincode. Il codice fiscale e la Partita IVA li abbiamo già, ma ci servono la{' '}
        <b>password</b> e il <b>pincode</b>. Se ancora non li hai,{' '}
        <i>puoi richiederli al tuo Ordine</i>.
        <br />
        <br />
        Scrivi qui sotto la password che usi per accedere al{' '}
        <a
          href="https://sistemats1.sanita.finanze.it/portale/area-riservata-operatore"
          target="_blank"
          rel="noreferrer"
        >
          <u>sito del Sistema Tessera Sanitaria.</u>
        </a>{' '}
        Se non ricordi il tuo pincode, lo puoi recuperare così: accedi al Sistema TS,
        clicca su &quot;<i>Profilo utente</i>&quot; e poi su &quot;
        <i>Stampa pincode</i>&quot;. Ti verrà generato un pdf con questo{' '}
        <b>codice di 10 cifre</b> (ad esempio: 1234567890).
        <br />
        <br />
        Conosci già la password e il tuo pincode? Bene 🎉
        <br />
        Inseriscili qui sotto e clicca sul tasto &quot;Salva le credenziali e
        inizia&quot;.
        <br />
      </h6>
      <center>
        <div className="px-5 mt-3">
          <Row>
            <Col md="6">
              <Label for="password" className="float-start">
                Password
              </Label>
              <Input
                type="text"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Col>
            <Col md="6">
              <Label for="pincode" className="float-start">
                Pincode
              </Label>
              <Input
                type="text"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
              />
            </Col>
          </Row>
        </div>
      </center>
      <br />
      <br />
      <div>
        <Button onClick={() => prevStep()} className="bottone-blu">
          <i className="bi bi-arrow-left-short"></i> VAI INDIETRO
        </Button>
        <Button
          onClick={() => salvaPinEPassword()}
          className="bottone-blu float-end"
        >
          SALVA LE CREDENZIALI E INIZIA
        </Button>
      </div>
    </>
  )

  return (
    <Container>
      <Card body className="mt-4 mb-5">
        <center>
          <Progress
            value={valueProgress}
            barClassName="barra-blu"
            className="mb-5 mt-3 mx-5"
          />
        </center>
        {step === 1 && firstStep}
        {step === 2 && secondStep}
        {step === 3 && thirdStep}
        {step === 4 && fourthStep}
      </Card>
    </Container>
  )
}

export default Onboarding

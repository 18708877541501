import React from 'react'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { apriModalAttestazionePA } from '../../../app/store/riepilogoFatture/slice'

const Attestazione = (props) => {
  const dispatch = useDispatch()

  return (
    <center>
      <Button
        className="bottone-blu"
        size="sm"
        onClick={() => dispatch(apriModalAttestazionePA(props.dati.fattura.id))}
      >
        ATTESTAZIONE
      </Button>
    </center>
  )
}

export default Attestazione

import React, { useState, useEffect } from 'react'
import request from 'superagent'
import {
  Container,
  CardHeader,
  Card,
  CardBody,
  Button,
  Col,
  Row,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  ModalFooter,
  Spinner,
} from 'reactstrap'
import ModalAggiungiCliente from '../Fattura/Componenti/ModalAggiungiCliente'
import swal from 'sweetalert'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectIsLoadingUser, selectUser } from '../../app/store/user/selectors'

const Rubrica = () => {
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectIsLoadingUser)
  const [infoFetched, setInfoFetched] = useState(false)
  const [contatti, setContatti] = useState([])
  const [stringaRicerca, setStringaRicerca] = useState('')
  const [modalPersonaOpen, setModalPersonaOpen] = useState(false)
  const [contattoDaModificare, setContattoDaModificare] = useState(null)
  const [modalAziendaOpen, setModalAziendaOpen] = useState(false)
  const [modalPAOpen, setModalPAOpen] = useState(false)
  const [loadignUpdate, setLoadingUpdate] = useState(false)

  const rimuoviDallaRubrica = (idCliente, ragioneSociale) => {
    swal({
      title: 'Vuoi cancellare ' + ragioneSociale + '?',
      icon: 'warning',
      buttons: ['ANNULLA', 'SÌ, CANCELLA'],
    }).then((isConfirm) => {
      if (isConfirm) {
        request
          .post(`${process.env.REACT_APP_API_URL}/rubrica/delete`)
          .send({ token: user.auth_token, idCliente })
          .then((response) => {
            setContatti(response.body.contatti)
          })
          .catch((e) => console.error(e.message))
      }
    })
  }

  const modificaPersona = () => {
    setLoadingUpdate(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/modifica/persona`)
      .send({
        token: user.auth_token,
        idClienteDaModificare: contattoDaModificare.id,
        nome: contattoDaModificare?.ragione_sociale,
        codFiscale: contattoDaModificare?.codice_fiscale,
        cap: contattoDaModificare?.cap,
        comune: contattoDaModificare?.comune,
        indirizzo: contattoDaModificare?.indirizzo,
      })
      .then((response) => {
        setModalPersonaOpen(false)
        setContatti(response.body.contatti)
        setLoadingUpdate(false)
      })
      .catch((e) => {
        console.error(e.message)
        setLoadingUpdate(false)
      })
  }

  const modificaAzienda = () => {
    setLoadingUpdate(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/modifica/azienda`)
      .send({
        token: user.auth_token,
        idClienteDaModificare: contattoDaModificare.id,
        nome: contattoDaModificare?.ragione_sociale,
        codFiscale: contattoDaModificare?.codice_fiscale,
        cap: contattoDaModificare?.cap,
        comune: contattoDaModificare?.comune,
        indirizzo: contattoDaModificare?.indirizzo,
        partitaIva: contattoDaModificare?.partita_iva,
        codiceDestinatario: contattoDaModificare?.codice_sdi,
        pec: contattoDaModificare?.pec,
        provincia: contattoDaModificare?.provincia,
        nazione: contattoDaModificare?.nazione,
      })
      .then((response) => {
        setModalAziendaOpen(false)
        setContatti(response.body.contatti)
        setLoadingUpdate(false)
      })
      .catch((e) => {
        console.error(e.message)
        setLoadingUpdate(false)
      })
  }

  const modificaPA = () => {
    setLoadingUpdate(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/modifica/pa`)
      .send({
        token: user.auth_token,
        idClienteDaModificare: contattoDaModificare.id,
        nome: contattoDaModificare?.ragione_sociale,
        codFiscale: contattoDaModificare?.codice_fiscale,
        cap: contattoDaModificare?.cap,
        comune: contattoDaModificare?.comune,
        indirizzo: contattoDaModificare?.indirizzo,
        partitaIva: contattoDaModificare?.partita_iva,
        codiceDestinatario: contattoDaModificare?.codice_pa,
        pec: contattoDaModificare?.pec,
        provincia: contattoDaModificare?.provincia,
        cig: contattoDaModificare?.cig,
        cup: contattoDaModificare?.cup,
      })
      .then((response) => {
        setModalPAOpen(false)
        setContatti(response.body.contatti)
        setLoadingUpdate(false)
      })
      .catch((e) => {
        console.error(e.message)
        setLoadingUpdate(false)
      })
  }

  const apriModalModifica = (contatto) => {
    if (contatto.role === 'persona') {
      setContattoDaModificare(contatto)
      setModalPersonaOpen(true)
    }
    if (contatto.role === 'azienda') {
      setContattoDaModificare(contatto)
      setModalAziendaOpen(true)
    }
    if (contatto.role === 'pa') {
      setContattoDaModificare(contatto)
      setModalPAOpen(true)
    }
  }

  const componentContatti = contatti.map((contatto, index) => {
    if (
      contatto.ragione_sociale.toLowerCase().includes(stringaRicerca.toLowerCase())
    ) {
      return (
        <Card key={index} className="mt-4">
          <CardHeader
            className="d-flex justify-content-between"
            style={{
              backgroundColor: '#f2b705',
              borderRadius: '10px 10px 0px 0px',
              border: 'none',
            }}
          >
            <h4 className="font-regular text-white mt-2">
              {contatto.ragione_sociale}
            </h4>
            <div>
              <Button
                className="bottone-bianco-blu me-2"
                onClick={() => apriModalModifica(contatto)}
              >
                <i
                  className="bi bi-pencil text-warning"
                  style={{ fontSize: '1.2rem' }}
                ></i>
              </Button>
              <Button
                className="bottone-bianco-blu"
                onClick={() =>
                  rimuoviDallaRubrica(contatto.id, contatto.ragione_sociale)
                }
              >
                <i
                  className="bi bi-trash3 text-danger"
                  style={{ fontSize: '1.2rem' }}
                ></i>
              </Button>
            </div>
          </CardHeader>
          <CardBody
            style={{
              backgroundColor: '#f2b70540',
              borderRadius: '0px 0px 10px 10px',
              color: '#373d3f',
            }}
          >
            {contatto.cap && (
              <h6>
                Cap: <span className="text-muted">{contatto.cap}</span>
              </h6>
            )}
            {contatto.cig && (
              <h6>
                Cig: <span className="text-muted">{contatto.cig}</span>
              </h6>
            )}
            {contatto.codice_fiscale && (
              <h6>
                Codice fiscale:{' '}
                <span className="text-muted">{contatto.codice_fiscale}</span>
              </h6>
            )}
            {contatto.codice_pa && (
              <h6>
                Codice PA: <span className="text-muted">{contatto.codice_pa}</span>
              </h6>
            )}
            {contatto.codice_sdi && (
              <h6>
                Codice destinatario:{' '}
                <span className="text-muted">{contatto.codice_sdi}</span>
              </h6>
            )}
            {contatto.comune && (
              <h6>
                Comune: <span className="text-muted">{contatto.comune}</span>
              </h6>
            )}
            {contatto.cup && (
              <h6>
                Cup: <span className="text-muted">{contatto.cup}</span>
              </h6>
            )}
            {contatto.indirizzo && (
              <h6>
                Indirizzo: <span className="text-muted">{contatto.indirizzo}</span>
              </h6>
            )}
            {contatto.nazione && (
              <h6>
                Nazione: <span className="text-muted">{contatto.nazione}</span>
              </h6>
            )}
            {contatto.partita_iva && (
              <h6>
                Partita IVA:{' '}
                <span className="text-muted">{contatto.partita_iva}</span>
              </h6>
            )}
            {contatto.pec && (
              <h6>
                Pec: <span className="text-muted">{contatto.pec}</span>
              </h6>
            )}
            {contatto.provincia && (
              <h6>
                Provincia: <span className="text-muted">{contatto.provincia}</span>
              </h6>
            )}
          </CardBody>
        </Card>
      )
    } else {
      return null
    }
  })

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
      }
    }
  }, [isLoading])

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/rubrica/list`)
      .send({ token: user.auth_token })
      .then((response) => {
        setContatti(response.body.contatti)
      })
      .catch((e) => console.error(e.message))
  }

  const modalPersona = () => (
    <Modal isOpen={modalPersonaOpen} toggle={() => setModalPersonaOpen(false)}>
      <ModalHeader
        className="font-regular"
        toggle={() => setModalPersonaOpen(false)}
      >
        Modifica cliente
      </ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label className="font-grigio-scuro">Nome e cognome</Label>
            <Input
              type="text"
              value={contattoDaModificare?.ragione_sociale}
              onChange={(e) =>
                setContattoDaModificare({
                  ...contattoDaModificare,
                  ragione_sociale: e.target.value,
                })
              }
            />
          </FormGroup>
          <FormGroup className="font-grigio-scuro">
            <Label>Codice fiscale</Label>
            <Input
              type="text"
              value={contattoDaModificare?.codice_fiscale}
              onChange={(e) =>
                setContattoDaModificare({
                  ...contattoDaModificare,
                  codice_fiscale: e.target.value,
                })
              }
            />
          </FormGroup>
        </Form>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label className="font-grigio-scuro">CAP</Label>
              <Input
                type="text"
                value={contattoDaModificare?.cap}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    cap: e.target.value,
                  })
                }
              />
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label className="font-grigio-scuro">Comune</Label>
              <Input
                type="text"
                value={contattoDaModificare?.comune}
                onChange={(e) => {
                  if (e.target.value.length < 60) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      comune: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
        <Form>
          <FormGroup>
            <Label className="font-grigio-scuro">Indirizzo</Label>
            <Input
              type="text"
              value={contattoDaModificare?.indirizzo}
              onChange={(e) => {
                if (e.target.value.length < 60) {
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    indirizzo: e.target.value,
                  })
                }
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline me-3"
          onClick={() => {
            setModalPersonaOpen(false)
          }}
        >
          Annulla
        </Button>
        <Button
          className="bottone-blu"
          disabled={loadignUpdate}
          onClick={() => {
            modificaPersona()
          }}
        >
          {loadignUpdate ? <Spinner size="sm" /> : 'Salva'}
        </Button>
      </ModalFooter>
    </Modal>
  )

  const modalAzienda = () => (
    <Modal
      size="xl"
      isOpen={modalAziendaOpen}
      toggle={() => setModalAziendaOpen(false)}
    >
      <ModalHeader
        className="font-regular"
        toggle={() => setModalAziendaOpen(false)}
      >
        Modifica cliente
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label>Ragione sociae</Label>
              <Input
                type="text"
                value={contattoDaModificare?.ragione_sociale}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    ragione_sociale: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Partita IVA</Label>
              <Input
                type="text"
                value={contattoDaModificare?.partita_iva}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    partita_iva: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Codice fiscale</Label>
              <Input
                type="text"
                value={contattoDaModificare?.codice_fiscale}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    codice_fiscale: e.target.value,
                  })
                }
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>CAP</Label>
                  <Input
                    type="text"
                    value={contattoDaModificare?.cap}
                    onChange={(e) =>
                      setContattoDaModificare({
                        ...contattoDaModificare,
                        cap: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Comune</Label>
                  <Input
                    type="text"
                    value={contattoDaModificare?.comune}
                    onChange={(e) => {
                      if (e.target.value.length < 60) {
                        setContattoDaModificare({
                          ...contattoDaModificare,
                          comune: e.target.value,
                        })
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <FormGroup>
              <Label>
                Nazione{' '}
                <small>
                  <a
                    href="https://it.wikipedia.org/wiki/ISO_3166-1_alpha-2"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Lista abbreviazioni
                  </a>
                </small>
              </Label>
              <Input
                type="text"
                value={contattoDaModificare?.nazione}
                onChange={(e) => {
                  if (e.target.value.length < 3) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      nazione: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Indirizzo</Label>
              <Input
                type="text"
                value={contattoDaModificare?.indirizzo}
                onChange={(e) => {
                  if (e.target.value.length < 60) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      indirizzo: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Provincia <small className="text-muted">(due lettere)</small>
              </Label>
              <Input
                type="text"
                value={contattoDaModificare?.provincia}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      provincia: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Codice destinatario o codice SdI</Label>
              <Input
                type="text"
                value={contattoDaModificare?.codice_sdi}
                onChange={(e) => {
                  if (e.target.value.length < 8) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      codice_sdi: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Pec</Label>
              <Input
                type="text"
                value={contattoDaModificare?.pec}
                onChange={(e) => {
                  if (e.target.value.length < 60) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      pec: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline me-3"
          onClick={() => {
            setModalAziendaOpen(false)
          }}
        >
          Annulla
        </Button>
        <Button
          className="bottone-blu"
          disabled={loadignUpdate}
          onClick={() => {
            modificaAzienda()
          }}
        >
          {loadignUpdate ? <Spinner size="sm" /> : 'Salva'}
        </Button>
      </ModalFooter>
    </Modal>
  )

  const modalPA = () => (
    <Modal size="xl" isOpen={modalPAOpen} toggle={() => setModalPAOpen(false)}>
      <ModalHeader className="font-regular" toggle={() => setModalPAOpen(false)}>
        Modifica cliente
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label>Ragione sociae</Label>
              <Input
                type="text"
                value={contattoDaModificare?.ragione_sociale}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    ragione_sociale: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Partita IVA</Label>
              <Input
                type="text"
                value={contattoDaModificare?.partita_iva}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    partita_iva: e.target.value,
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Codice fiscale</Label>
              <Input
                type="text"
                value={contattoDaModificare?.codice_fiscale}
                onChange={(e) =>
                  setContattoDaModificare({
                    ...contattoDaModificare,
                    codice_fiscale: e.target.value,
                  })
                }
              />
            </FormGroup>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>CAP</Label>
                  <Input
                    type="text"
                    value={contattoDaModificare?.cap}
                    onChange={(e) =>
                      setContattoDaModificare({
                        ...contattoDaModificare,
                        cap: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>Comune</Label>
                  <Input
                    type="text"
                    value={contattoDaModificare?.comune}
                    onChange={(e) => {
                      if (e.target.value.length < 60) {
                        setContattoDaModificare({
                          ...contattoDaModificare,
                          comune: e.target.value,
                        })
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Indirizzo</Label>
              <Input
                type="text"
                value={contattoDaModificare?.indirizzo}
                onChange={(e) => {
                  if (e.target.value.length < 60) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      indirizzo: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>
                Provincia <small className="text-muted">(due lettere)</small>
              </Label>
              <Input
                type="text"
                value={contattoDaModificare?.provincia}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      provincia: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
            <FormGroup>
              <Label>Codice PA</Label>
              <Input
                type="text"
                value={contattoDaModificare?.codice_pa}
                onChange={(e) => {
                  if (e.target.value.length < 7) {
                    setContattoDaModificare({
                      ...contattoDaModificare,
                      codice_pa: e.target.value,
                    })
                  }
                }}
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline me-3"
          onClick={() => {
            setModalPAOpen(false)
          }}
        >
          Annulla
        </Button>
        <Button
          className="bottone-blu"
          disabled={loadignUpdate}
          onClick={() => {
            modificaPA()
          }}
        >
          {loadignUpdate ? <Spinner size="sm" /> : 'Salva'}
        </Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <Container className="mb-5 mt-4">
      <Helmet>
        <title>Rubrica</title>
        <meta property="title" content="Rubrica" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {modalPersona()}
      {modalAzienda()}
      {modalPA()}
      <Card body>
        <Card
          body
          className="mt-2"
          style={{
            borderRadius: '10px',
            border: 'none',
            backgroundColor: '#0583f2',
          }}
        >
          <Row>
            <Col xs={9}>
              <h2 className="font-regular text-white mt-2">
                <i className="bi bi-person-lines-fill"></i> Rubrica clienti e
                fornitori
              </h2>
              <h6 className="mt-2 interlinea-30 font-regular text-white">
                Qui puoi vedere tutti i clienti che hai aggiunto per emettere delle
                fatture nei loro confronti in modo più semplice e veloce
              </h6>
              <br />
              <Input
                type="text"
                placeholder="🔍 Cerca un cliente"
                onChange={(e) => setStringaRicerca(e.target.value)}
                style={{ maxWidth: '300px' }}
              />
            </Col>
            <Col xs={3}>
              <div color="link" className="float-end">
                <ModalAggiungiCliente
                  tipologia="bottone"
                  refreshClienti={() => fetchInfo()}
                />
              </div>
            </Col>
          </Row>
        </Card>
        {componentContatti}
      </Card>
    </Container>
  )
}
export default Rubrica

import React, { useRef, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormGroup,
  Spinner,
  Label,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import { leadGuida } from '../../../app/utility/QueryLanding'
import swal from 'sweetalert'
import { guidaFiscaleGA } from '../../../app/utility/GA'

const GuidaFiscaleDesktop = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const refIniziaOra = useRef(null)
  const [consenso, setConsenso] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const scaricaGuida = async () => {
    if (email === '' || consenso === false || newsletter === false) {
      swal({
        title: 'Attenzione',
        text: "Compila tutti i campi, dai il tuo consenso al trattamento dei dati e all'isrizione alla newsletter",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    guidaFiscaleGA()
    await leadGuida({ nome, email }).then((res) => {
      setCaricamento(false)
      swal({
        title: 'Bene 😊',
        text: 'Entro pochi minuti riceverai una email da pill@fiscomed.it contenente il link per la guida fiscale. Se non la trovi, controlla nelle cartelle spam, aggiornamenti e promozioni.',
        icon: 'success',
        button: 'Ok',
      })
    })
  }

  return (
    <>
      <Helmet>
        <title>Guida fiscale</title>
        <meta property="title" content="Guida fiscale" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/guida-fiscale" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Guida fiscale" />
        <meta
          property="og:description"
          content="Scarica gratuitamente la guida fiscale per il giovane medico"
        />
        <meta
          property="description"
          content="Scarica gratuitamente la guida fiscale per il giovane medico"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container
        style={{ marginTop: '56px' }}
        className="container-landing landing-container-height"
      >
        <br />
        <br />
        <div className="header-landing-desktop-guida" />
        <Row>
          <Col md={6}>
            <h1 className="titolo-landing-fiscomed font-medium mt-5">
              La guida fiscale dei giovani medici
            </h1>
            <br />
            <h4 className="font-bold">
              VERSIONE <span className="testo-blu">2024</span>
            </h4>
            <br />
            <h5 className="testo-landing-fiscomed">
              Ricevi <span className="testo-blu">gratuitamente</span> l&apos;ebook di
              Fiscomed
              <br />
              e risolvi tutti i tuoi dubbi riguardo gli <br />
              adempimenti fiscali della tua professione
            </h5>
            <br />
            <Button
              className="bottone-blu px-5"
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
            >
              OTTIENI LA GUIDA
            </Button>
          </Col>
          <Col md={6}>
            <img
              src="/img_landing/ebook2024_header.webp"
              alt="fiscomed"
              className="mt-4"
              style={{ width: '100%' }}
            />
          </Col>
        </Row>
        <br />
        <center>
          <img
            src="/img_landing/freccia.webp"
            alt="fiscomed"
            className="mt-5 mb-5"
            style={{ zIndex: 1000 }}
          />
        </center>
        <div className="div-celeste-guida py-5">
          <center>
            <div className="titolo-3">Cosa c&apos;è nell&apos;ebook?</div>
            <br />
            <h5 className="testo-landing-fiscomed">
              In questa guida troverai tutte le informazioni che ti possono essere
              utili per approcciarti al <br />
              mondo del lavoro. In particolare avrai a disposizione in un unico posto
              le risposte alle tue <br />
              domande riguardanti il complicato mondo del fisco
            </h5>
          </center>
          <br />
          <br />
          <Row>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/1.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  I primi
                  <br />
                  passi
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/2.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  I regimi
                  <br />
                  fiscali
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/3.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  Il regime
                  <br />
                  forfettario
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/4.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  L&apos;Ente di
                  <br />
                  Previdenza
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/5.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  La
                  <br />
                  fatturazione
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/6.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  Il Sistema
                  <br />
                  Tessera
                  <br />
                  Sanitaria
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/7.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  Il lavoro del
                  <br />
                  commercialista
                </h4>
              </div>
            </Col>
            <Col md={4} className="my-5">
              <center>
                <img
                  src="/img_landing/8.webp"
                  alt="nuvola di colore"
                  className="numero-guida"
                />
              </center>
              <div style={{ width: '50%' }} className="float-end">
                <h4 className="testo-blu font-bold">
                  Ti presentiamo
                  <br />
                  Fiscomed
                </h4>
              </div>
            </Col>
            <Col md={4} />
          </Row>
          <br />
          <br />
          <center>
            <Button
              className="bottone-blu px-5"
              onClick={() =>
                refIniziaOra.current.scrollIntoView({ behavior: 'smooth' })
              }
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              OTTIENI LA GUIDA
            </Button>
          </center>
        </div>
        <br />
        <br />
        <center>
          <div className="titolo-2">Cosa imparerai?</div>
          <br />
          <br />
          <img
            src="/img_landing/ebook2024_desktop.webp"
            alt="immagine libro"
            style={{ width: '100%', maxWidth: '800px' }}
          />
        </center>
        <br />
        <br />
        <div className="div-verde-guida py-5 px-5">
          <center>
            <div className="titolo-3">
              Il nostro ebook è stato utile a molte persone
            </div>
            <br />
            <h5 className="testo-landing-fiscomed">
              Questa guida nasce dall&apos;ascolto di tanti dottori e dottoresse in
              Medicina come te. Ed è stata scritta dopo aver capito che vorresti
              avere <b>informazioni sicure e facili</b> da capire riguardo il mondo
              fiscale
            </h5>
          </center>
          <br />
          <Row>
            <Col md={5} />
            <Col md={7} className="pr-4">
              <div className="card-guida d-inline-flex" style={{ zIndex: 2 }}>
                <img
                  src="/img_landing/cuore_dx.webp"
                  alt="cuore"
                  className="cuore-2-guida"
                />
                <img
                  src="/img_landing/giulia.webp"
                  alt="Dottoressa"
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '150px',
                    marginTop: '20px',
                  }}
                />
                <div className="p-3">
                  <h3>Giulia</h3>
                  <h5 className="testo-landing-fiscomed">
                    Ho sempre avuto tantissima ansia riguardo la gestione fiscale del
                    mio lavoro. Questa guida mi ha fatto sentire meno spaesata
                  </h5>
                </div>
              </div>
            </Col>
            <Col md={7} style={{ marginTop: '-30px', zIndex: '1' }}>
              <div className="card-guida d-inline-flex ms-3">
                <img
                  src="/img_landing/like.webp"
                  alt="cuore"
                  className="like-guida"
                />
                <img
                  src="/img_landing/samantha.webp"
                  alt="Dottoressa"
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '150px',
                    marginTop: '20px',
                  }}
                />
                <div className="p-3">
                  <h3>Sara</h3>
                  <h5 className="testo-landing-fiscomed">
                    Finalmente una guida scritta con parole semplici e un linguaggio
                    chiaro. I numerosi esempi ti aiutano a capire moltissime cose!
                  </h5>
                </div>
              </div>
            </Col>
            <Col md={5} />
            <Col md={5} />
            <Col md={7} style={{ marginTop: '-30px', zIndex: '2' }} className="pr-4">
              <div className="card-guida d-inline-flex">
                <img
                  src="/img_landing/cuore_sx.webp"
                  alt="cuore"
                  className="cuore-1-guida"
                />
                <img
                  src="/img_landing/mario.webp"
                  alt="Dottore"
                  style={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '150px',
                    marginTop: '20px',
                  }}
                />
                <div className="p-3">
                  <h3>Valerio</h3>
                  <h5 className="testo-landing-fiscomed">
                    All&apos;università di certo non ti insegnano cosa fare per
                    essere a posto con il fisco dopo il lavoro, questo ebook sì
                  </h5>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <center>
          <div className="titolo-2">Chi ha scritto l&apos;ebook?</div>
          <br />
          <br />
          <h5 className="testo-landing-fiscomed">
            Questa guida è stata scritta da due Dottori Commercialisti e una
            Dottoressa Commercialista del team di Fiscomed <b>esperti</b> nella
            fiscalità della <b>professione medica</b> e molto vicini ai bisogni,
            dubbi e difficoltà dei giovani medici come te
          </h5>
        </center>
        <br />
        <br />
        <Row>
          <Col md={6} className="my-5">
            <center className="float-end me-5">
              <img
                src="/img_landing/cg.png"
                alt="foto di Cristina"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Maria Cristina
              </h5>
              <h5 className="testo-landing-fiscomed">Dottoressa Commercialista</h5>
            </center>
          </Col>
          <Col md={6} className="mt-5">
            <center className="float-start ms-5">
              <img
                src="/img_landing/ji.png"
                alt="foto di Jacopo"
                style={{ maxWidth: '200px' }}
              />
              <br />
              <br />
              <h5 className="font-regular" style={{ fontSize: '1.9rem' }}>
                Jacopo
              </h5>
              <h5 className="testo-landing-fiscomed">Dottore Commercialista</h5>
            </center>
          </Col>
        </Row>
        <br ref={refIniziaOra} />
        <br />
        <br />
        <div className="div-giallo-guida py-5">
          <center>
            <div className="titolo-3">
              Scrivi qui la tua email.
              <br /> Riceverai subito il nostro ebook
            </div>
            <br />
            <h5 className="testo-landing-fiscomed">
              Crediamo che con la <b>giusta dose di informazioni</b> non proverai più
              quel senso di spaesamento
              <br />
              che si prova una volta entrati nel mondo del lavoro
            </h5>
            <br />
            <FormGroup>
              <h6 className="font-medium mb-3">Come ti chiami?</h6>
              <Input
                type="text"
                placeholder="Ippocrate di Coo"
                style={{ borderColor: '#f2b705', width: '300px' }}
                autoComplete="name"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <h6 className="font-medium mb-3">Quale email usi di più?</h6>
              <Input
                type="text"
                placeholder="ippocrate@email.com"
                style={{ borderColor: '#f2b705', width: '300px' }}
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>
            <div style={{ height: '20px' }} />
            <FormGroup check style={{ width: '300px' }}>
              <Input
                type="checkbox"
                name="privacy"
                id="privacy"
                style={{ marginTop: '2px' }}
                checked={consenso}
                onChange={(e) => setConsenso(e.target.checked)}
              />
              <Label for="privacy" className="consenso">
                Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
                <a
                  href="/documenti/termini_e_condizioni.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  <u>privacy policy</u>
                </a>
              </Label>
            </FormGroup>
            <div style={{ height: '12px' }} />
            <FormGroup check style={{ width: '300px' }}>
              <Input
                type="checkbox"
                name="newsletter"
                id="newsletter"
                style={{ marginTop: '2px' }}
                checked={newsletter}
                onChange={(e) => setNewsletter(e.target.checked)}
              />
              <Label for="newsletter" className="consenso">
                Scaricando la guida, accetto di iscrivermi alla newsletter
              </Label>
            </FormGroup>
            {caricamento ? (
              <Button
                className="bottone-blu px-5 mt-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                disabled
              >
                <Spinner color="light" size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-blu px-5 mt-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                onClick={() => scaricaGuida()}
              >
                OTTIENI LA GUIDA
              </Button>
            )}
            <br />
          </center>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col md={6}>
            <div
              className="float-end"
              style={{ width: '60%', maxWidth: '400px', marginRight: '120px' }}
            >
              <img
                src="/img_landing/bookmarks.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Otterrai subito la nostra <b>guida</b> e poi <b>email educative</b>{' '}
                riguardanti la fiscalità della professione medica
              </h5>
            </div>
            <br />
            <br />
          </Col>
          <Col md={6}>
            <div
              className="mb-5"
              style={{ width: '60%', maxWidth: '400px', marginLeft: '120px' }}
            >
              <img
                src="/img_landing/file-lock.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Teniamo alla tua <b>privacy</b>, quindi non daremo a nessuno il tuo
                indirizzo email
              </h5>
            </div>
            <br />
            <br />
          </Col>
          <Col md={6}>
            <div
              className="float-end mt-5"
              style={{ width: '60%', maxWidth: '400px', marginRight: '120px' }}
            >
              <img
                src="/img_landing/chat-left-dots.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Non ti bombarderemo mai di inutili email promozionali. Riceverai{' '}
                <b>al massimo</b> 3 email al mese per ricordarti le <b>scadenze</b>{' '}
                fiscali di quel periodo e spiegarti tutto ciò che devi sapere
                riguardo gli <b>adempimenti</b> più importanti
              </h5>
            </div>
          </Col>
          <Col md={6} style={{ width: '60%', maxWidth: '400px' }}>
            <div className="mt-5" style={{ marginLeft: '120px' }}>
              <img
                src="/img_landing/stoplights.webp"
                alt="icona"
                className="icona-guida"
              />
              <h5 className="testo-landing-fiscomed">
                Naturalmente, puoi <b>smettere</b> di ricevere le nostre email quando
                vuoi
              </h5>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  )
}
export default GuidaFiscaleDesktop

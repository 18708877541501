export const emptyErrorState = {
  error: undefined,
  message_error: undefined,
}

export const emptyUserObject = {
  auth_token: undefined,
  biography: undefined,
  first_name: undefined,
  last_name: undefined,
  nickname: undefined,
  profile_image_url: undefined,
  public_uuid: undefined,
  role: undefined,
  username: undefined,
  role_in_app: undefined,
  ...emptyErrorState,
}

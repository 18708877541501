import React, { useState } from 'react'
import { Container, Input, Button, Spinner, FormGroup, Label } from 'reactstrap'
import { leadNewsletter } from '../../../app/utility/QueryLanding'
import { newsLetterGA } from '../../../app/utility/GA'

import swal from 'sweetalert'

const NewsLetterMobile = () => {
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [caricamento, setCaricamento] = useState(false)
  const [consenso, setConsenso] = useState(false)
  const [newsletter, setNewsletter] = useState(false)

  const iscrizioneNewsletter = async () => {
    if (email === '' || consenso === false || newsletter === false) {
      swal({
        title: 'Attenzione',
        text: "Compila tutti i campi, dai il tuo consenso al trattamento dei dati e all'isrizione alla newsletter",
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    newsLetterGA()
    await leadNewsletter({ nome, email }).then((res) => {
      setCaricamento(false)
      swal({
        title: 'Ottimo 😊',
        text: "L'iscrizione alla newsletter di Fiscomed è andata a buon fine",
        icon: 'success',
        button: 'Ok',
      })
    })
  }

  return (
    <>
      <div style={{ backgroundColor: '#66d98e26' }} className="pt-5">
        <Container className="mobile-container">
          <span className="titolo3-mobile">
            Impara a conoscere la fiscalità della professione medica
          </span>
          <br />
          <br />
          <h6 className="h6-landing">
            Con la nostra newsletter ci impegniamo a darti tutte le informazioni
            necessarie scritte nel modo più semplice possibile. <i>Fiscalese</i> e{' '}
            <i>burocratese</i> non fanno per noi
          </h6>
          <br />
          <br />
          <h6 className="font-medium mb-3">Come ti chiami?</h6>
          <Input
            type="text"
            placeholder="Ippocrate di Coo"
            style={{ borderColor: '#66d98e' }}
            autoComplete="name"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
          <br />
          <br />
          <h6 className="font-medium mb-3">Qual è l&apos;email che usi di più?</h6>
          <Input
            type="text"
            placeholder="ippocrate@email.com"
            style={{ borderColor: '#66d98e' }}
            autoComplete="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <br />
          <br />
          <FormGroup check style={{ maxWidth: '300px' }}>
            <Input
              type="checkbox"
              name="privacy"
              id="privacy"
              style={{ marginTop: '2px' }}
              checked={consenso}
              onChange={(e) => setConsenso(e.target.checked)}
            />
            <Label for="privacy" className="consenso">
              Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
              <a
                href="/documenti/termini_e_condizioni.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>privacy policy</u>
              </a>
            </Label>
          </FormGroup>
          <br />
          <FormGroup check style={{ maxWidth: '300px' }}>
            <Input
              type="checkbox"
              name="newsletter"
              id="newsletter"
              style={{ marginTop: '2px' }}
              checked={newsletter}
              onChange={(e) => setNewsletter(e.target.checked)}
            />
            <Label for="newsletter" className="consenso">
              Scaricando la guida, accetto di iscrivermi alla newsletter
            </Label>
          </FormGroup>
          <br />
          <br />
          {caricamento ? (
            <Button
              block
              className="bottone-verde px-4"
              disabled
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              <Spinner color="light" size="sm" />
            </Button>
          ) : (
            <Button
              block
              className="bottone-verde px-4"
              onClick={() => iscrizioneNewsletter()}
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              ISCRIVITI ALLA NEWSLETTER
            </Button>
          )}

          <br />
          <br />
          <br />
        </Container>
      </div>
    </>
  )
}

export default NewsLetterMobile

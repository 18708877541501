import React, { useState } from 'react'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const VariazioneUno = ({ setScheda }) => {
  const [aliquotaVantaggiosa, setAliquotaVantaggiosa] = useState(null)

  const avanti = () => {
    if (aliquotaVantaggiosa) {
      setScheda('variazione_2')
    } else {
      setScheda('variazione_4')
    }
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={50} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        BENE. SE VUOI, PUOI BENEFICIARE DI UN&apos;ALTRA ALIQUOTA AGEVOLATA
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Benché tu abbia perso un requisito per la contribuzione ridotta, sembra che
        tu ne abbia un altro.
        <br />
        Questo significa che puoi continuare ad avere una contribuzione ridotta.
        <br />
        Fare questa richiesta <b>non rappresenta un obbligo</b>: puoi benissimo
        scegliere di versare i contributi con l&apos;aliquota intera del 19,50%.
        <br />
        <br />
        La scelta dell&apos;aliquota da versare dipende dalla tua propensione al{' '}
        <b>risparmio</b> o all&quot;<b>investimento</b>. Se preferisci risparmiare
        nell&apos;immediato, ti consigliamo di usufruire di un&apos;aliquota
        vantaggiosa. Se, al contrario, desideri investire nella tua pensione futura,
        allora dovresti versare l&apos;aliquota intera.
        <br />
        <br />
        Che cosa vuoi fare?
      </h6>
      <br />
      <div>
        <Button
          className={classNames(
            { 'bottone-verde': aliquotaVantaggiosa === true },
            {
              'bottone-verde-outline':
                aliquotaVantaggiosa === false || aliquotaVantaggiosa == null,
            },
            'p-3',
          )}
          onClick={() => setAliquotaVantaggiosa(true)}
        >
          Voglio usufruire di un&apos;aliquota
          vantaggiosa&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </Button>
        <br />
        <Button
          className={classNames(
            { 'bottone-verde': !aliquotaVantaggiosa },
            {
              'bottone-verde-outline':
                aliquotaVantaggiosa === null || aliquotaVantaggiosa === true,
            },
            'mt-4 p-3',
          )}
          onClick={() => setAliquotaVantaggiosa(false)}
        >
          Voglio usufruire dell&apos;aliquota intera del 19,50%
        </Button>
      </div>

      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia setScheda={setScheda} />
        <Button
          className="bottone-blu float-end"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default VariazioneUno

import React from 'react'

const VerificatoMobile = (props) => {
  let immagine
  let nome
  let titolo
  switch (props.commercialista) {
    case 0:
      immagine = '/blog/jacopo.webp'
      nome = 'Jacopo'
      titolo = 'Dottore'
      break
    case 1:
      immagine = '/blog/giulia.webp'
      nome = 'Giulia'
      titolo = 'Dottoressa'
      break
    case 2:
      immagine = '/blog/francesco.webp'
      nome = 'Francesco'
      titolo = 'Dottore'
      break
    case 3:
      immagine = '/blog/cristina.webp'
      nome = 'Cristina'
      titolo = 'Dottoressa'
      break
    case 4:
      immagine = '/blog/giuseppe.webp'
      nome = 'Giuseppe'
      titolo = 'Dottore'
      break
  }

  return (
    <div
      style={{
        backgroundColor: '#66d98e26',
        borderRadius: '7px',
      }}
    >
      <center>
        <img
          src={immagine}
          alt="Avatar"
          style={{ width: '70px', marginTop: '-35px', marginLeft: 'auto' }}
        />
        <div className="p-3">
          <h6 className="testo-landing interlinea-30">
            Questo articolo è stato approvato da {nome}, {titolo} Commercialista del
            team di Fiscomed
          </h6>
        </div>
      </center>
    </div>
  )
}

export default VerificatoMobile

export const trackPage = (value) => {
  window.gtag('event', 'page_view', {
    page_title: value,
    page_path: value,
    debug_mode: process.env.NODE_ENV === 'development',
    page_location: value,
    send_to: 'G-G6HHJEE5S2',
  })
}

export const loginGA = (user) => {
  window.gtag('event', 'login', {
    user_id: user.public_uuid,
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

export const setUserId = (user) => {
  window.gtag('set', {
    user_id: user.public_uuid,
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

export const leadChiamataGA = () => {
  window.gtag('event', 'lead_chiamata', {
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

export const guidaFiscaleGA = () => {
  window.gtag('event', 'guida_fiscale', {
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

export const newsLetterGA = () => {
  window.gtag('event', 'newsletter', {
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

export const datiFiscaliGA = () => {
  window.gtag('event', 'datiFiscali', {
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

export const pagamentoCompletoGA = () => {
  window.gtag('event', 'pagamentoCompletato', {
    debug_mode: process.env.NODE_ENV === 'development',
    send_to: 'G-G6HHJEE5S2',
  })
}

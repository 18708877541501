import request from 'superagent'
import { saveAs } from 'file-saver'

// Idrata fattura
export const idrataFattura = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura/idrata-dati-fattura`)
    .send({ token: user.auth_token })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Idrata nota di variazione
export const idrataNotaDiVariazionePR = (user, idFattura) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/fattura/idrata-dati-nota-di-variazione-pr`
    )
    .send({ token: user.auth_token, id_fattura: idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Idrata nota di variazione PA
export const idrataNotaDiVariazionePA = (user, idFattura) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/fattura/idrata-dati-nota-di-variazione-pa`
    )
    .send({ token: user.auth_token, id_fattura: idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Idrata fattura di rettifica
export const idrataFatturaRettifica = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura/idrata-rettifica-fattura`)
    .send({ token: user.auth_token, id_fattura: idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Genera proforma
export const finalizzaProforma = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/proforma/crea`)
    .send({ token: user.auth_token, data })
    .then((response) => response.body.id_proforma)
    .catch((e) => console.error(e.message))

// Richiedi presigned url pdf proforma dalla pagina esito
export const presignedUrlPdfProforma = (user, idProforma) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/proforma/presigned-url-pdf`)
    .send({ token: user.auth_token, idProforma })
    .then((response) => response.body.url)
    .catch((e) => console.error(e.message))

export const presignedUrlPdfFatturaCartacea = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura-cartacea/presigned-url-pdf`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => response.body.url)
    .catch((e) => console.error(e.message))

// Scarica file proforma (pdf)
export const scaricaProformaPdf = (user, idProforma) => {
  presignedUrlPdfProforma(user, idProforma).then((url) => {
    fetch(url)
      .then((r) => r.blob())
      .then((blobFile) => {
        const file = new File([blobFile], 'proforma.pdf')
        saveAs(file)
      })
  })
}

// Lista tutti i proforma
export const listProforma = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/proforma/list`)
    .send({ token: user.auth_token })
    .then((response) => response.body.list)
    .catch((e) => console.error(e.message))

export const cancellaProforma = (user, idProforma) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/proforma/cancella`)
    .send({ token: user.auth_token, idProforma })
    .then((response) => response.body.list)
    .catch((e) => console.error(e.message))

// Genera fattura cartacea
export const finalizzaFatturaCartacea = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura-cartacea/crea`)
    .send({ token: user.auth_token, data })
    .then((response) => response.body.id_fattura)
    .catch((e) => console.error(e.message))

// Invia fatture elettronica
export const inviaFattureElettronica = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura-elettronica/invia`)
    .send({ token: user.auth_token, ...data })
    .then((response) => response.body.id_fattura)
    .catch(() => false)

// Invia fattura PA
export const inviaFatturaPA = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura-pa/invia`)
    .send({ token: user.auth_token, ...data })
    .then((response) => response.body.id_fattura)
    .catch(() => false)

// Invio nota di credito pr
export const inviaNotaDiCreditoPa = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/nota-di-credito-pa/invia`)
    .send({ token: user.auth_token, ...data })
    .then((response) => response.body.id_fattura)
    .catch(() => false)

// Invio nota di credito pr
export const inviaNotaDiCreditoPr = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/nota-di-credito-pr/invia`)
    .send({ token: user.auth_token, ...data })
    .then((response) => response.body.id_fattura)
    .catch(() => false)

// Invio nota di debito pr
export const inviaNotaDiDebitoPr = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/nota-di-debito-pr/invia`)
    .send({ token: user.auth_token, ...data })
    .then((response) => response.body.id_fattura)
    .catch(() => false)

// Invia autofattura
export const inviaAutofattura = (user, data) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/autofattura/invia`)
    .send({ token: user.auth_token, ...data })
    .then((response) => response.body.id_fattura)
    .catch(() => false)

// Richiedi presigned url pdf f24 autofattura dalla pagina esito
export const presignedUrlF24Autofattura = (user, idAutofattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/autofattura/presigned-url-f24`)
    .send({ token: user.auth_token, idAutofattura })
    .then((response) => response.body.url)
    .catch((e) => console.error(e.message))

// Idrata email cliente su esito fattura
export const emailCliente = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/miscellanea/email-cliente-fattura`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Idrata email cliente su esito fattura
export const emailClienteProforma = (user, idProforma) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/proforma/email-cliente-proforma`)
    .send({ token: user.auth_token, idProforma })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Idrata fattura su esito fattura
export const datiFattura = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/miscellanea/dati-fattura`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const inviaEmailProforma = (user, idProforma, email) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/proforma/invia-email`)
    .send({ token: user.auth_token, idProforma, email })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const queryAggiornaMetodoDiPagamento = (user, metodoDiPagamento) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/metodo-di-pagamento/update`)
    .send({ token: user.auth_token, metodoDiPagamento })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

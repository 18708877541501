import React, { useEffect } from 'react'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { pagamentoCompletoGA } from '../../app/utility/GA'

const ThankYouPage = () => {
  const smallScreen = window.innerWidth <= 767

  useEffect(() => {
    pagamentoCompletoGA()
  }, [])

  return (
    <>
      {smallScreen ? (
        <>
          <Helmet>
            <title>Grazie</title>
            <meta property="title" content="Grazie" />
            <meta name="robots" content="noindex" />
            <style>
              {
                'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
              }
            </style>
          </Helmet>
          <Container style={{ marginTop: '56px' }} className="mobile-container">
            <div className="header-landing-mobile-guida" />
            <br />
            <br />
            <span className="titolo2-mobile mb-5">Grazie per l&apos;acquisto</span>
            <br />
            <br />
            <h6 className="h6-landing">Il pagamento è andato a buon fine 🎉</h6>
            <br />
            <h6 className="h6-landing">
              Entro 24 ore <span className="testo-blu"> ti assegneremo</span> a un
              Dottore o una Dottoressa Commercialista del nostro team. Riceverai una
              <span className="testo-blu"> email</span> di presentazione del/la
              commercialista, dalla quale potrai{' '}
              <span className="testo-blu"> prenotare</span> la tua prima
              videoconsulenza.
            </h6>
            <br />
            <h6 className="h6-landing">
              Ricorda che potrai accedere a Fiscomed solo dopo che il/la
              commercialista ti attiverà il profilo, dopo il primo incontro.
            </h6>
            <br />
            <br />
            <br />
          </Container>
        </>
      ) : (
        <>
          <Helmet>
            <title>Grazie</title>
            <meta property="title" content="Grazie" />
            <meta name="robots" content="noindex" />
          </Helmet>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: '100000',
              overflow: 'hidden',
              background: 'white',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: '100000',
                overflow: 'hidden',
                backgroundImage: "url('/img_landing/copertina_desktop.webp')",
                backgroundSize: '100% auto',
                backgroundRepeat: 'repeat-y',
                backgroundPosition: 'left top',
                marginTop: 0,
                margin: '0',
              }}
            ></div>
            <Container className="mt-5">
              <div className="header-landing-desktop-guida" />
              <div style={{ marginTop: '3vh' }} />
              <img
                src="/img_landing/logo.webp"
                alt="Logo"
                style={{ width: '150px' }}
              />
              <br />
              <br />
              <br />
              <span className="titolo-2 mb-5">Grazie per l&apos;acquisto</span>
              <br />
              <br />
              <br />
              <h5 className="testo-landing-fiscomed">
                Il pagamento è andato a buon fine 🎉
              </h5>
              <br />
              <br />
              <h5 className="testo-landing-fiscomed">
                Entro 24 ore <span className="testo-blu"> ti assegneremo</span> a un
                Dottore o una Dottoressa Commercialista del nostro team. Riceverai
                una
                <span className="testo-blu"> email</span> di presentazione del/la
                commercialista, dalla quale potrai{' '}
                <span className="testo-blu"> prenotare</span> la tua prima
                videoconsulenza.
              </h5>
              <br />
              <br />
              <h5 className="testo-landing-fiscomed">
                Ricorda che potrai accedere a Fiscomed solo dopo che il/la
                commercialista ti attiverà il profilo, dopo il primo incontro.
              </h5>
            </Container>
          </div>
        </>
      )}
    </>
  )
}

export default ThankYouPage

import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Spinner } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { loginCommercialista } from '../../app/store/user/actions'
import { logout } from '../../app/store/user/slice'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectIsLoadingUser,
  selectIsLoggedIn,
} from '../../app/store/user/selectors'

const LoginCommercialista = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoadingUser)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const { uuidUtente, token } = useParams()
  const history = useHistory()
  const [logoutAttempted, setLogoutAttempted] = useState(false)
  const [loginAttempted, setLoginAttempted] = useState(false)

  useEffect(() => {
    if (!isLoading && !logoutAttempted) {
      dispatch(logout())
      setTimeout(setLogoutAttempted(true), 1000)
    }
  }, [isLoading])

  useEffect(() => {
    if (logoutAttempted && !isLoading) {
      dispatch(loginCommercialista({ uuidUtente, token }))
      setTimeout(setLoginAttempted(true), 1500)
    }
  }, [logoutAttempted])

  useEffect(() => {
    if (loginAttempted && isLoggedIn) {
      history.push('/fiscomed')
    }
  }, [loginAttempted])

  return (
    <>
      <Helmet>
        <title>Fiscomed.it</title>
        <meta property="title" content="Fiscomed.it" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fiscomed.it" />
        <meta
          property="og:description"
          content="Pubblica il tuo annuncio e candidati alle offerte lavorative della tua zona"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>

      <div style={{ marginTop: '14vh' }}>
        <center>
          <Spinner
            className="testo-blu"
            style={{ width: '100px', height: '100px' }}
          />
        </center>
      </div>
    </>
  )
}

export default LoginCommercialista

import React, { useState } from 'react'
import moment from 'moment'
import {
  idrataFatture,
  caricaFattura,
  cancellaFattura,
  inviaModificaFattura,
  scaricaFattura,
} from '../utility/QueryImportaFattura'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/user/selectors'

const ImportaFatturaContext = React.createContext()

const emptyState = {
  fatture: [],
  annoDiRiferimento: moment().year(),
  annoCorrente: moment().year(),
  anniConFattureEmesse: [],
  loading: true,
  modalImportOpen: false,
  modalUpdateOpen: false,
  tipologia: 'fattura',
  fatturaSelezionata: null,
  trattenutaEnpam: 0,
  dataIncasso: null,
  numeroFattura: null,
  importo: null,
  trasmessaTS: false,
  fileFattura: null,
  upLoading: false,
}

// TODO: Alcuni setState settavano solo un campo ignorando ...state (precedenti valori)
// è stato cambiato a setState({...state, nomeCampo}), controllare se funziona tutto ok
// TODO: Alcune funzioni sono state rese async per aggiungere try/catch interno e settare loading: false
// controllare se i consumer devono essere aggiornati di conseguenza
const ImportaFatturaProvider = ({ children }) => {
  const [state, setState] = useState(emptyState)
  const user = useSelector(selectUser)

  const idrata = async () => {
    try {
      const responseBody = await idrataFatture(user, state.annoDiRiferimento)
      setState({
        ...state,
        loading: false,
        fatture: responseBody.fatture,
        anniConFattureEmesse: responseBody.anniConFattureEmesse,
      })
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const impostaAnnoEIdrata = async (anno) => {
    setState({
      ...state,
      loading: true,
    })
    try {
      const responseBody = await idrataFatture(user, anno)
      setState({
        ...state,
        annoDiRiferimento: anno,
        fatture: responseBody.fatture,
        anniConFatturaEmessa: responseBody.anni_con_fattura_emessa,
        loading: false,
      })
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const statoModalImport = (stato) => {
    setState({
      ...state,
      dataIncasso: null,
      tipologia: 'fattura',
      numeroFattura: null,
      importo: null,
      trattenutaEnpam: 0,
      fileFattura: null,
      modalImportOpen: stato,
      trasmessaTS: false,
    })
  }

  const statoModalUpdate = (stato) => {
    setState({
      ...state,
      dataIncasso: null,
      numeroFattura: null,
      importo: null,
      trattenutaEnpam: 0,
      fileFattura: null,
      modalUpdateOpen: stato,
      trasmessaTS: false,
    })
  }

  const impostaTipologia = (tipologia) => {
    setState({
      ...state,
      tipologia,
    })
  }

  const impostaAnnoIncasso = (anno) => {
    setState({
      ...state,
      dataIncasso: anno,
    })
  }

  const impostaNumeroFattura = (numero) => {
    setState({
      ...state,
      numeroFattura: numero,
    })
  }

  const impostaImporto = (importo) => {
    setState({
      ...state,
      importo,
    })
  }

  const impostaTrattenutaEnpam = (trattenutaEnpam) => {
    setState({
      ...state,
      trattenutaEnpam,
    })
  }

  const impostaFileFattura = (file) => {
    setState({
      ...state,
      fileFattura: file,
    })
  }

  const impostaTrasmessaTS = (trasmessaTS) => {
    setState({
      ...state,
      trasmessaTS,
    })
  }

  const aggiungiFattura = async () => {
    if (state.dataIncasso < 2010 || state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    if (state.importo < 0) {
      alert("L'importo della fattura deve essere un numero positivi")
      return
    }
    if (state.fileFattura === '' || state.fileFattura === null) {
      alert('Seleziona un file fattura')
      return
    }
    if (state.fileFattura.size / 1073741824 > 0.05) {
      alert('La dimensione del file non può superare i 20MB')
      return
    }
    const formData = new FormData()
    formData.append('tipologia', state.tipologia)
    formData.append('fileFattura', state.fileFattura)
    formData.append('token', user.auth_token)
    formData.append('dataIncasso', state.dataIncasso)
    formData.append('numeroFattura', state.numeroFattura)
    formData.append('importo', state.importo)
    formData.append('trattenutaEnpam', (state.trattenutaEnpam * 100).toFixed(0))
    formData.append('anno', state.annoDiRiferimento)
    formData.append('trasmessaTS', state.trasmessaTS)
    setState({ ...state, upLoading: true })
    try {
      const response = await caricaFattura(formData)
      if (response) {
        setState({
          ...state,
          upLoading: false,
          fatture: response.fatture,
          anniConFattureEmesse: response.anniConFattureEmesse,
          modalImportOpen: false,
          dataIncasso: null,
          numeroFattura: null,
          importo: null,
          fileFattura: null,
          tipologia: 'fattura',
          trattenutaEnpam: 0,
          trasmessaTS: false,
        })
      }
      return response
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        upLoading: false,
      })
    }
  }

  const cancellaFile = async (uuidFile) => {
    setState({ ...state, upLoading: true })
    try {
      const response = await cancellaFattura(user, uuidFile, state.annoDiRiferimento)
      if (response) {
        setState({
          ...state,
          upLoading: false,
          fatture: response.fatture,
          anniConFattureEmesse: response.anniConFattureEmesse,
        })
      }
      return response
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        upLoading: false,
      })
    }
  }

  const modificaFattura = async (uuidFile) => {
    const fatturaSelezionata = state.fatture.find(
      (fattura) => fattura.uuid_file === uuidFile
    )
    setState({
      ...state,
      modalUpdateOpen: true,
      fatturaSelezionata,
      dataIncasso: fatturaSelezionata.data_incasso,
      numeroFattura: fatturaSelezionata.numero,
      importo: fatturaSelezionata.importo / 100,
      trattenutaEnpam: fatturaSelezionata.trattenuta_enpam / 100,
      tipologia: fatturaSelezionata.tipologia,
      trasmessaTS: fatturaSelezionata.inviata_ts,
    })
  }

  const inviaModifica = async () => {
    if (state.dataIncasso < 2010 || state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    if (state.importo < 0) {
      alert("L'importo deve essere un numero positivi")
      return
    }
    if (state.trattenutaEnpam < 0 && state.tipologia === 'cedolino') {
      alert('La trattenuta enpam deve essere un numero positivo')
      return
    }
    setState({ ...state, upLoading: true })
    try {
      const response = await inviaModificaFattura(
        user,
        state.fatturaSelezionata.uuid_file,
        state.dataIncasso,
        state.numeroFattura,
        state.importo,
        state.annoDiRiferimento,
        state.tipologia,
        (state.trattenutaEnpam * 100).toFixed(0),
        state.trasmessaTS
      )
      if (response) {
        setState({
          ...state,
          upLoading: false,
          fatture: response.fatture,
          anniConFattureEmesse: response.anniConFattureEmesse,
          modalUpdateOpen: false,
          dataIncasso: null,
          numeroFattura: null,
          importo: null,
          trattenutaEnpam: 0,
          fileFattura: null,
          tipologia: 'fattura',
          trasmessaTS: false,
        })
      }
      return response
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        upLoading: false,
      })
    }
  }

  const scaricaFile = async (uuidFile, nomeFile) => {
    const response = await scaricaFattura(user, uuidFile, nomeFile)
    return response
  }

  const context = {
    dati: state,
    idrata,
    statoModalImport,
    impostaAnnoEIdrata,
    impostaTipologia,
    impostaAnnoIncasso,
    impostaNumeroFattura,
    impostaImporto,
    impostaTrattenutaEnpam,
    impostaFileFattura,
    impostaTrasmessaTS,
    aggiungiFattura,
    cancellaFile,
    modificaFattura,
    statoModalUpdate,
    inviaModifica,
    scaricaFile,
  }

  return (
    <ImportaFatturaContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </ImportaFatturaContext.Provider>
  )
}

export { ImportaFatturaProvider as default, ImportaFatturaContext }

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalVerificaSdi } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalVerificaSdI = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalVerificaSdiOpen}
      toggle={() => dispatch(chiudiModalVerificaSdi())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalVerificaSdi())}
        className="h4 font-regular font-grigio-scuro"
      >
        Lo SdI sta verificando la tua fattura
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Che significa? 🤔 <br /> <br /> Che la tua fattura è stata inviata al
          Sistema Di Interscambio, il quale sta ancora controllando che tutti i dati
          siano corretti. Alcune volte lo SdI ci mette davvero poco a fare i
          controlli, ma in altri casi possono volerci fino a 5 giorni.
          <br /> <br />
          Quindi non allarmarti se non vedi ancora l&apos;esito della tua fattura, lo
          SdI ha i suoi tempi 🥱
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalVerificaSdI

import { useParams, useHistory } from 'react-router-dom'
import request from 'superagent'
import React, { useState } from 'react'
import { Container, Input, Button, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'
import swal from 'sweetalert'

function ConfermaPassword(props) {
  const { codice } = useParams()
  const history = useHistory()
  const [password, setPassword] = useState('')

  function confirmChange() {
    const errors = []
    if (password.length < 8) {
      errors.push('La nuova password deve contenere almeno 8 caratteri')
    }
    if (errors.length > 0) {
      alert(errors.join('\n'))
    } else {
      request
        .post(`${process.env.REACT_APP_API_URL}/confermaCambioPassword`)
        .send({ codice, newPassword: password })
        .end((err, res) => {
          if (res.body.error || err) {
            swal({
              title: 'Non siamo riusciti a cambiare la tua password',
              text: 'I motivi potrebbero essere tanti. Fai un altro tentativo, se non dovessi riuscire scrivi a pill@fiscomed.it',
              icon: 'error',
              button: 'OK',
            })
          } else {
            swal({
              title: 'La tua password è stata cambiata',
              text: "La prossima volta che fai l'accesso assicurati di inserire questa nuova password",
              icon: 'success',
              button: 'OK',
            }).then(() => {
              history.push('/accesso')
            })
          }
        })
    }
  }

  return (
    <>
      <Helmet>
        <title>Imposta nuova password</title>
        <meta property="title" content="Imposta nuova password" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta
          property="og:url"
          content="https://fiscomed.it/cambio_password/:code"
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Imposta nuova password" />
        <meta
          property="og:description"
          content="Pagina per reimpostare la propria password"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <meta name="robots" content="noindex" />
        <style>
          {
            'body {   background: linear-gradient(to right,white 0%,white 50%,#66d98e26 50%,#66d98e26 100%); }'
          }
        </style>
      </Helmet>
      <div
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          zIndex: '10',
          overflow: 'hidden',
          background: 'white',
        }}
      >
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            zIndex: '11',
            background:
              'linear-gradient(to right,white 0%,white 50%,#66d98e26 50%,#66d98e26 100%)',
          }}
        >
          <Container style={{ marginTop: '56px' }} fluid>
            <Row style={{ marginTop: '25vh' }}>
              <Col md={6}>
                <Row>
                  <Col md={3}></Col>
                  <Col md={9} className="pr-5">
                    <span className="titolo-3">Vuoi cambiare la tua password?</span>
                    <br />
                    <br />
                    <h5 className="testo-landing-fiscomed">
                      Scrivi qui la tua nuova password.
                      <br />
                      Fai attenzione: deve essere almeno di 8 caratteri
                    </h5>
                    <br />
                    <div className="form-group">
                      <Input
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        style={{ maxWidth: '400px' }}
                        placeholder="Ippocrate.123"
                      />
                    </div>
                    <br />
                    <Button
                      onClick={() => confirmChange()}
                      className="bottone-blu px-5"
                      style={{ paddingTop: '14px', paddingBottom: '14px' }}
                    >
                      CAMBIA PASSWORD
                    </Button>
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <center>
                  <img
                    src="/img_landing/immagine_fiscomed.webp"
                    alt="Mano che saluta"
                    style={{ width: '500px' }}
                  />
                </center>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default ConfermaPassword

import React from 'react'
import { Container, Card } from 'reactstrap'

const Loading = () => (
  <Container>
    <Card body className="mt-4 mb-5">
      <center>
        <span
          className="spinner-grow spinner-grow-lg"
          role="status"
          aria-hidden="true"
          style={{ color: '#0583f2' }}
        ></span>
        <h6 className="mt-3">Caricamento in corso...</h6>
      </center>
    </Card>
  </Container>
)

export default Loading

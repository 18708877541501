import request from 'superagent'

// Sincronizza metodi di pagamento
export const syncMetodiDiPagamento = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/metodo-di-pagamento/list`)
    .send({ token: user.auth_token })
    .then((response) => response.body.metodidipagamento)
    .catch((e) => console.error(e.message))

// Cancella metodo di pagamento
export const cancellaMetodoDiPagamento = (user, idPagamento) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/metodo-di-pagamento/delete`)
    .send({ token: user.auth_token, id_pagamento: idPagamento })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Ottieni url lettera di incarico del commercialista
export const ottieniURLLetteraDiIncarico = (user) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/contratto/url-lettera-di-incarico`)
    .send({ token: user.auth_token })
    .then((response) => response.body.url_lettera)
    .catch((e) => console.error(e.message))

export const urlLetteraDaUUID = (uuid) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/contratto/url-lettera-di-incarico-da-uuid`
    )
    .send({ uuid })
    .then((response) => response.body)
    .catch((e) => console.log('error', e.message))

// Firma contratti
export const firmaContratti = (user, firma) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/contratto/firma-contratti`)
    .send({ token: user.auth_token, firma })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const firmaLetteraDaUUID = (uuid) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/contratto/firma-contratti-da-uuid`)
    .send({ uuid })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

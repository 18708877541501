import React, { useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import Fatture from '../Step/Fatture'
import Contributi from '../Step/Contributi'
import Quiz from '../Step/Quiz'
import F24InLavorazione from '../Step/F24InLavorazione'
import F24Caricati from '../Step/F24Caricati'
import Chiuso from '../Step/Chiuso'

const StepSelector = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <>
      {context.dati.tappaAttiva === 'fatture' && <Fatture />}
      {context.dati.tappaAttiva === 'contributi' && <Contributi />}
      {context.dati.tappaAttiva === 'quiz' && <Quiz />}
      {context.dati.tappaAttiva === 'f24inlavorazione' && <F24InLavorazione />}
      {context.dati.tappaAttiva === 'modelloRedditi' && <F24Caricati />}
      {context.dati.tappaAttiva === 'chiuso' && <Chiuso />}
    </>
  )
}

export default StepSelector

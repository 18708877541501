import React from 'react'
import { Card, Container } from 'reactstrap'

const CardCaricamento = () => (
  <Container>
    <Card body className="my-5">
      <br />
      <center>
        <h1 className="font-regular">Solo un attimo...</h1>
        <br />
        <h5 className="font-regular">Stiamo controllando il documento per te</h5>
        <br />
        <br />
        <div
          className="spinner-border"
          style={{ width: '5rem', height: '5rem', color: '#0583f2' }}
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </center>
      <br />
    </Card>
  </Container>
)

export default CardCaricamento

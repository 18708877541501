import React from 'react'
import { Container } from 'reactstrap'
import { Helmet } from 'react-helmet'

const ConfermaPrenotazione = () => {
  const smallScreen = window.innerWidth <= 767
  return (
    <>
      {smallScreen ? (
        <>
          <Helmet>
            <title>Grazie</title>
            <meta property="title" content="Grazie" />
            <meta name="robots" content="noindex" />
            <style>
              {
                'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
              }
            </style>
          </Helmet>
          <Container style={{ marginTop: '56px' }} className="mobile-container">
            <div className="header-landing-mobile-guida" />
            <br />
            <br />
            <span className="titolo2-mobile mb-5">
              Abbiamo ricevuto la tua prenotazione 😊
            </span>
            <br />
            <br />
            <h6 className="h6-landing">
              A breve ti arriverà una email di conferma con il{' '}
              <span className="testo-blu">link</span> per accedere alla videochiamata
              con il tuo commercialista.
            </h6>
            <br />
            <h6 className="h6-landing">
              La consulenza si svolgerà su{' '}
              <span className="testo-blu">Google Meet</span>. Per questo motivo ti
              consigliamo di avere con te un computer con webcam o un telefono per
              accedere all&apos;incontro.
            </h6>
            <br />
            <br />
          </Container>
        </>
      ) : (
        <>
          <Helmet>
            <title>Grazie</title>
            <meta property="title" content="Grazie" />
            <meta name="robots" content="noindex" />
          </Helmet>
          <div
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              zIndex: '100',
              overflow: 'hidden',
              background: 'white',
            }}
          >
            <div
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                zIndex: '1001',
                overflow: 'hidden',
                backgroundImage: "url('/img_landing/copertina_desktop.webp')",
                backgroundSize: '100% auto',
                backgroundRepeat: 'repeat-y',
                backgroundPosition: 'left top',
                marginTop: 0,
                margin: '0',
              }}
            ></div>
            <Container className="mt-5">
              <div className="header-landing-desktop-guida" />
              <div style={{ marginTop: '3vh' }} />
              <br />
              <br />
              <br />
              <span className="titolo-2 mb-5">
                Abbiamo ricevuto la tua prenotazione 😊
              </span>
              <br />
              <br />
              <br />
              <h5 className="testo-landing-fiscomed">
                A breve ti arriverà una email di conferma con il{' '}
                <span className="testo-blu">link</span> per accedere alla
                videochiamata con il tuo commercialista.
              </h5>
              <br />
              <h5 className="testo-landing-fiscomed">
                La consulenza si svolgerà su{' '}
                <span className="testo-blu">Google Meet</span>. Per questo motivo ti
                consigliamo di avere con te un computer con webcam o un telefono per
                accedere all&apos;incontro.
              </h5>
              <br />
              <br />
            </Container>
          </div>
        </>
      )}
    </>
  )
}

export default ConfermaPrenotazione

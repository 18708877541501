import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import request from 'superagent'
import { Container, Card, Button } from 'reactstrap'
import { useSelector } from 'react-redux'
import { selectIsLoadingUser, selectUser } from '../../../app/store/user/selectors'

const FatturaEmessa = () => {
  const { idFattura } = useParams()
  const [urlFattura, setUrlFattura] = useState('')
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectIsLoadingUser)
  const [infoFetched, setInfoFetched] = useState(false)

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
      }
    }
  }, [isLoading])

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}"/fattura-cartacea/presigned-url-pdf"`)
      .send({ token: user.auth_token, idFattura })
      .then((response) => {
        setUrlFattura(response.body.url_fattura)
      })
      .catch((e) => console.error(e.message))
  }

  return (
    <Container className="mt-5">
      <Card body>
        <div>
          <Button tag={Link} color="link" to="/fiscomed">
            <i className="bi bi-house"></i> <u>Home</u>
          </Button>
          <br />
          <br />
          <center>
            <span className="testo-verde">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
              </svg>
            </span>
            <br />
            <br />
            <h3 className="font-medium">Fattura salvata 🎉</h3>
          </center>
          <br />
          <h6 className="font-regular interlinea-25">
            Potrai visualizzare il pdf della tua fattura quando vuoi consultando la
            sezione &ldquo;Storico fatture&ldquo;. Per agevolare il corretto lavoro
            al tuo commercialista ricordati di segnare la fattura come
            &ldquo;incassata&ldquo; quando riceverai il pagamento dal tuo cliente.
          </h6>
          <br />
          <center>
            <a
              className="bottone-blu text-white"
              role="button"
              rel="noreferrer"
              href={urlFattura}
              target="_blank"
            >
              <i className="bi bi-file-earmark-arrow-down"></i> SCARICA PDF FATTURA
            </a>
          </center>
        </div>
      </Card>
    </Container>
  )
}

export default FatturaEmessa

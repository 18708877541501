import React, { useEffect, useContext } from 'react'
import { Spinner, Container, Card, Button, Alert } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { ImportaBorsaContext } from '../../app/context/ImportaBorsa'
import SelettoreAnno from './SlettoreAnno'
import Tabella from './Tabella'
import ModalImporta from './ModalImporta'
import ModalUpdate from './ModalUpdate'

const ContainerImportaBorsa = () => {
  const importContext = useContext(ImportaBorsaContext)

  useEffect(() => {
    importContext.idrata()
  }, [])

  const caricamento = (
    <center>
      <Spinner size="sm" className="testo-blu" />
    </center>
  )

  return (
    <Container className="mt-4 mb-5">
      <Helmet>
        <title>Importa Borsa</title>
        <meta property="title" content="Importa borsa" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ModalImporta />
      <ModalUpdate />
      <>
        <Card body>
          <div className="d-flex justify-content-between">
            <h4 className="font-medium">
              <i className="bi bi-handbag"></i> Importa la tua borsa di studio
            </h4>
            <div>
              <Button
                onClick={() => importContext.statoModalImport(true)}
                className="bottone-blu float-end"
              >
                <i className="bi bi-cloud-arrow-up"></i> IMPORTA LA BORSA
              </Button>
            </div>
          </div>
          <h6 className="mt-3 font-regular interlinea-30">
            Se frequenti il corso di formazione specifica in medicina generale o la
            scuola di specializzazione, questa sezione fa sicuramente al caso tuo.
            Infatti, da qui puoi caricare facilmente i cedolini della tua borsa di
            studio.
            <br />
            <br />
            Ti diamo due buone ragioni per farlo:
            <ul>
              <li>
                il tuo commercialista può consultarli e inserirli correttamente nella
                dichiarazione dei redditi
              </li>
              <li>
                se si tratta di cedolini per la borsa MMG, possiamo considerarli nel
                calcolo delle tasse che dovrai pagare
              </li>
            </ul>
          </h6>
          <br />
          {importContext.dati.loading ? (
            caricamento
          ) : importContext.dati.anniConBorseEmesse.length === 0 ? (
            <Alert color="info" className="mt-4">
              <i className="bi bi-info-circle"></i> Non hai ancora importato nessuna
              borsa
            </Alert>
          ) : (
            <>
              <SelettoreAnno />
              <br />
              <Tabella />
            </>
          )}
        </Card>
      </>
    </Container>
  )
}

export default ContainerImportaBorsa

import React from 'react'
import { useDispatch } from 'react-redux'
import { apriModalEsitoPositivoPA } from '../../../app/store/riepilogoFatture/slice'

const EsitoPositivoPA = () => {
  const dispatch = useDispatch()

  return (
    <>
      Esito positivo{' '}
      <i
        className="bi bi-info-circle-fill"
        onClick={() => dispatch(apriModalEsitoPositivoPA())}
        style={{ cursor: 'pointer' }}
      ></i>
    </>
  )
}

export default EsitoPositivoPA

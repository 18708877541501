import React, { useState } from 'react'
import { Button, Card, Tooltip } from 'reactstrap'
import Nota from '../Componenti/Nota'
import { Link, useHistory } from 'react-router-dom'
import AddebitoMarcaDaBollo from '../Componenti/AddebitoMarcaDaBollo'
import Ordine from '../Componenti/Ordine'
import Prestazioni from '../Componenti/Prestazioni'
import ModalCampiAggiuntivi from '../Componenti/ModalCampiAggiuntivi'
import ModalPagamento from '../Componenti/ModalPagamento'
import ModalCliente from '../Componenti/ModalCliente'
import { useDispatch, useSelector } from 'react-redux'
import { selectIsLoadingFiscomed } from '../../../app/store/fiscomed/selectors'
import { setLoading } from '../../../app/store/fiscomed/slice'
import { creaFatturaPA } from '../../../app/store/fiscomed/actions'

// TODO: testare se creaFatturaPA funziona
const PannelloPA = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoadingFiscomed)
  const [tooltip, setTooltip] = useState(false)
  const [tooltipOrdine, setTooltipOrdine] = useState(false)
  const history = useHistory()

  const salvaEStampa = () => {
    if (!isLoading) {
      dispatch(setLoading(true))
      dispatch(creaFatturaPA())
        .unwrap()
        .then((idFattura) => {
          dispatch(setLoading(false))
          if (idFattura) {
            history.push(
              '/fiscomed/nuova-fattura/esito-fattura-elettronica/trasmessa/' +
                idFattura,
            )
          } else {
            history.push(
              '/fiscomed/nuova-fattura/esito-fattura-elettronica/non-trasmessa/id',
            )
          }
        })
        .catch(() => {
          dispatch(setLoading(false))
        })
    }
  }

  return (
    <Card body className="mt-2 mb-5">
      <div className="d-flex flex-row">
        <h4 className="font-medium">
          <i className="bi bi-person-video2"></i> Cliente
        </h4>
      </div>
      <ModalCliente />
      <h4 className="font-medium mt-4">
        <i className="bi bi-file-text"></i> Ordine di acquisto{' '}
        <i className="bi bi-question-circle" id="tooltipOrdine"></i>
      </h4>{' '}
      <Tooltip
        placement="top"
        isOpen={tooltipOrdine}
        target="tooltipOrdine"
        toggle={() => setTooltipOrdine(!tooltipOrdine)}
      >
        Se il tuo cliente è la Pubblica Amministrazione, è probabile che ti invii un
        documento particolare che si chiama &quot;Ordine di acquisto&quot;. Da questo
        documento puoi prendere tutti i dati che ti servono per emettere la fattura.
      </Tooltip>
      <Ordine />
      <h4 className="font-medium mt-4">
        <i className="bi bi-clipboard2-pulse"></i> Prestazioni
      </h4>{' '}
      <Prestazioni />
      <div className="d-flex flex-row mt-4">
        <h4 className="d-inline align-bottom font-medium ">
          <i className="bi bi-credit-card"></i> Pagamento{' '}
        </h4>
      </div>
      <ModalPagamento />
      <h4 className="mt-4 font-medium">
        <i className="bi bi-sticky" style={{ marginLeft: '-2px' }}></i> Marca da
        bollo <i className="bi bi-question-circle" id="tooltip"></i>
      </h4>
      <Tooltip
        placement="top"
        isOpen={tooltip}
        target="tooltip"
        toggle={() => setTooltip(!tooltip)}
      >
        Nelle fatture elettroniche verso pubblica amministrazione non devi
        specificare l&apos;identificativo della marca da bollo. Verrà generato in
        automatico dal SdI e dovrai pagare l&apos;imposta di bollo dal sito
        dell&apos;Agenzia delle Entrate. Guarda il video &quot;Fattura elettronica e
        imposta di bollo&quot; nella sezione Fiscodèmia per vedere come fare.
      </Tooltip>
      <AddebitoMarcaDaBollo />
      <h4 className="d-inline align-bottom font-medium mt-4">
        <i className="bi bi-pencil-square"></i> Nota{' '}
      </h4>
      <Nota />
      <h6 className="font-medium mt-4">
        Hai bisogno di aggiungere altri dati?
      </h6>{' '}
      <ModalCampiAggiuntivi />
      <div className="mt-4 mb-1">
        <Button className="bottone-giallo float-end" onClick={() => salvaEStampa()}>
          INVIA FATTURA
        </Button>
        <Button
          className="bottone-rosso-outline me-3 float-end"
          tag={Link}
          to="/fiscomed"
        >
          ANNULLA
        </Button>
      </div>
    </Card>
  )
}

export default PannelloPA

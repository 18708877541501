import React, { useState } from 'react'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const DueDue = ({ dati, setScheda }) => {
  const [requisitiMantenuti, setRequisitiMantenuti] = useState(null)

  const avanti = () => {
    if (requisitiMantenuti) {
      setScheda(dati.scheda + '_requisitiMantenuti')
    } else {
      setScheda(dati.scheda + '_requisitiNonMantenuti')
    }
  }

  const requisito = () => {
    if (dati.scheda === '.22_mmg') {
      return 'tirocinante nel corso di formazione di medicina generale'
    }
    if (dati.scheda === '.22_dipendente') {
      return "lavoratore dipendente e aver percepito compensi per l'attività intramoenia"
    }
    if (dati.scheda === '.22_altrifondi') {
      return "soggetto a un'altra forma di previdenza obbligatoria (INPS, Fondo della medicina convenzionata e accreditata ENPAM)"
    }
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={30} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        AD OGGI, HAI ANCORA GLI STESSI REQUISITI?
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Se avevi dichiarato di essere &quot;<b>{requisito()}</b>&quot;, lo sei
        ancora?
      </h6>
      <br />
      <div>
        <Button
          className={classNames(
            { 'bottone-verde': requisitiMantenuti },
            { 'bottone-verde-outline': !requisitiMantenuti },
            'me-5',
          )}
          onClick={() => setRequisitiMantenuti(true)}
        >
          Sì
        </Button>
        <Button
          className={classNames(
            { 'bottone-verde': !requisitiMantenuti },
            {
              'bottone-verde-outline':
                requisitiMantenuti == null || requisitiMantenuti,
            },
            'me-5',
          )}
          onClick={() => setRequisitiMantenuti(false)}
        >
          No
        </Button>
      </div>

      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia setScheda={setScheda} />
        <Button
          className="bottone-blu float-end"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default DueDue

import React, { useState } from 'react'
import { Button, Progress, Card } from 'reactstrap'
import classNames from 'classnames'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const DueUno = ({ setScheda }) => {
  const [borsaMMg, setBorsaMMg] = useState(false)
  const [intramoenia, setIntramoenia] = useState(false)
  const [altriFondi, setAltriFondi] = useState(false)

  const avanti = () => {
    if (borsaMMg) setScheda('.22_mmg')
    if (intramoenia) setScheda('.22_dipendente')
    if (altriFondi) setScheda('.22_altrifondi')
  }

  return (
    <Card body className="h-100 d-flex d-column p-5">
      <Progress value={20} className="progress-gialla" />
      <br />
      <br />
      <h4 className="testo-verde font-bold mb-4">
        PER QUALE MOTIVO HAI RICHIESTO LA CONTRIBUZIONE RIDOTTA?
      </h4>
      <h6 className="font-regular mb-3 interlinea-30">
        Ti riportiamo le voci fra cui è possibile scegliere nel modulo &quot;
        <b>Contribuzione Ridotta</b>&quot;.
        <br /> Indica la voce che avevi selezionato al momento della tua richiesta.
      </h6>
      <br />

      <Button
        className={classNames(
          { 'bottone-verde': borsaMMg },
          { 'bottone-verde-outline': !borsaMMg },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(true)
          setIntramoenia(false)
          setAltriFondi(false)
        }}
      >
        Sono tirocinante nel corso di formazione di medicina generale
      </Button>
      <Button
        className={classNames(
          { 'bottone-verde': intramoenia },
          { 'bottone-verde-outline': !intramoenia },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(false)
          setIntramoenia(true)
          setAltriFondi(false)
        }}
      >
        Sono un lavoratore dipendente e ho percepito compensi per l&apos;attività
        intramoenia
      </Button>
      <Button
        className={classNames(
          { 'bottone-verde': altriFondi },
          { 'bottone-verde-outline': !altriFondi },
          'mb-4 py-3'
        )}
        style={{ width: '800px' }}
        onClick={() => {
          setBorsaMMg(false)
          setIntramoenia(false)
          setAltriFondi(true)
        }}
      >
        Sono soggetto a un&apos;altra forma di previdenza obbligatoria (INPS, Fondo
        della medicina convenzionata e accreditata ENPAM...)
      </Button>
      <br />
      <br />
      <div className="mt-auto mb-3">
        <Ricomincia setScheda={setScheda} />
        <Button
          className="bottone-blu float-end"
          style={{ width: '130px' }}
          onClick={() => avanti()}
        >
          AVANTI <i className="bi bi-arrow-right-short"></i>
        </Button>
        <NonSaiRispondere />
      </div>
    </Card>
  )
}

export default DueUno

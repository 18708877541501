import React, { useState, useEffect } from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  FormGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from 'reactstrap'
import classNames from 'classnames'
import ModalAggiungiCliente from './ModalAggiungiCliente'
import { useDispatch, useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaClienteAttivoDaId } from '../../../app/store/fiscomed/slice'

const ModalCliente = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)
  const [modal, setModal] = useState(false)
  const [query, setQuery] = useState('')
  const [clientiCombacianti, setClientiCombacianti] = useState(fiscomedData.clienti)

  useEffect(() => {
    setClientiCombacianti(
      fiscomedData.clienti.filter((cliente) =>
        cliente.ragione_sociale.toLowerCase().includes(query.toLowerCase())
      )
    )
  }, [fiscomedData.clienti, query])

  const ricercaCliente = (query) => {
    setQuery(query)
  }

  const selezionaCliente = (id) => {
    dispatch(aggiornaClienteAttivoDaId(id))
    setQuery('')
    setModal(false)
  }

  const clienti = () => {
    let elementi = []
    elementi = clientiCombacianti.map((cliente, i) => (
      <ListGroupItem
        key={i}
        style={{ cursor: 'pointer' }}
        className="contatto-rubrica"
        onClick={() => {
          selezionaCliente(cliente.id)
        }}
      >
        {cliente.ragione_sociale}
        <br />
        <span className="text-muted">
          {cliente.indirizzo}
          <br />
          {cliente.comune}, {cliente.cap}{' '}
          {cliente.provincia && `(${cliente.provincia})`}
        </span>
      </ListGroupItem>
    ))
    if (elementi.length === 0) {
      elementi.push(
        <ListGroupItem key={0} color="info">
          Nessun cliente trovato, prova ad affinare la ricerca oppure aggiungi un
          cliente in rubrica 😊
        </ListGroupItem>
      )
    }
    return elementi.slice(0, 3)
  }

  const componentModal = (
    <Modal isOpen={modal} toggle={() => setModal(false)} style={{ width: '485px' }}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        Rubrica clienti e fornitori
      </ModalHeader>
      <ModalBody>
        <br />
        <center>
          <ModalAggiungiCliente
            tipologia="bottone"
            funzioneDiChiusura={() => setModal(false)}
          />
        </center>
        <br />
        <br />
        <FormGroup>
          <Label className="text-muted">
            <i className="bi bi-search"></i> Oppure cerca tra i clienti salvati
          </Label>
          <Input
            type="text"
            value={query}
            onChange={(e) => ricercaCliente(e.target.value)}
            placeholder="Mario Rossi..."
          />
        </FormGroup>
        <ListGroup>{clienti()}</ListGroup>
      </ModalBody>
    </Modal>
  )

  return (
    <div className="mb-3">
      <Button
        onClick={() => setModal(true)}
        className={classNames({
          'bottone-blu':
            fiscomedData.tipologiaFattura === 'Fattura elettronica' ||
            fiscomedData.tipologiaFattura === 'Rettifica pr',
          'bottone-verde': fiscomedData.tipologiaFattura === 'Fattura cartacea',
          'bottone-giallo': fiscomedData.tipologiaFattura === 'Fattura PA',
          'bottone-bianco-blu-outline': fiscomedData.tipologiaFattura === 'Proforma',
          'bottone-blu-scuro': fiscomedData.tipologiaFattura === 'Autofattura',
        })}
      >
        {fiscomedData.tipologiaFattura === 'Autofattura' ? (
          <>&nbsp;SELEZIONA FORNITORE&nbsp;</>
        ) : (
          <>&nbsp;&nbsp;&nbsp;&nbsp;SELEZIONA CLIENTE&nbsp;&nbsp;&nbsp;&nbsp;</>
        )}
      </Button>
      {componentModal}
    </div>
  )
}

export default ModalCliente

import React, { useState, useEffect } from 'react'
import {
  idrataDichiarazione,
  chiudiStepFatture,
  chiudiStepContributi,
  chiudiStepQuiz,
  scaricaF24DaServer,
  queryIdrataArchivio,
  scaricaModelloDaServer,
  inviaFile,
  scaricaFileAllegatoUtente,
  eliminaFile,
  salvaRisp,
} from '../utility/QueryDichiarazione'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/user/selectors'

const DichiarazioneContext = React.createContext()

const genereMaschile = {
  il: 'il',
  ilTuo: 'il tuo',
  lui: 'lui',
  ilDottore: 'il Dottore',
  dalTuo: 'dal tuo',
  luiStesso: 'lui stesso',
  alTuo: 'al tuo',
}

const genereFemminile = {
  il: 'la',
  ilTuo: 'la tua',
  lui: 'lei',
  ilDottore: 'la Dottoressa',
  dalTuo: 'dalla tua',
  luiStesso: 'lei stessa',
  alTuo: 'alla tua',
}

const emptyState = {
  attivo: false,
  caricamento: true,
  archivio: [],
  genere: genereMaschile,
  tasse: {},
  dati: undefined,
  delega: false,
  modalFileUpload: {
    aperto: false,
    caricamento: false,
    file: undefined,
    tappa: undefined,
    domanda: undefined,
  },
  modalViewFile: {
    aperto: false,
    url: undefined,
  },
}

// TODO: Alcune funzioni sono state rese async per aggiungere try/catch interno e settare loading: false
// controllare se i consumer devono essere aggiornati di conseguenza
const DichiarazioneProvider = ({ children }) => {
  const [state, setState] = useState(emptyState)
  const user = useSelector(selectUser)

  useEffect(() => {
    idrata().then(() => {
      if (user.commercilista_uomo === false) {
        setState({
          ...state,
          genere: genereFemminile,
        })
      }
    })
  }, [user.commercialista_uomo])

  const aggiornaDatiDichiarazioneEStep = (dichiarazione) => {
    setState({
      ...state,
      ...dichiarazione,
      caricamento: false,
    })
  }

  const idrata = async () => {
    try {
      const responseBody = await idrataDichiarazione(user)
      setState({
        ...state,
        ...responseBody.dichiarazione,
        tasse: responseBody.tasse,
        delega: responseBody.delega,
        caricamento: false,
      })
    } catch (e) {
      console.error(e.message)
      setState({
        ...state,
        caricamento: false,
      })
    }
  }

  const completaStepFatture = (caricatiEIncassati) => {
    chiudiStepFatture(user, caricatiEIncassati).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const completaStepContributi = (contributiVersati) => {
    chiudiStepContributi(user, contributiVersati).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const completaStepQuiz = (risposte) => {
    chiudiStepQuiz(user, risposte).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const scaricaF24 = (idF24, nomeFile) => {
    scaricaF24DaServer(user, idF24, nomeFile)
  }

  const scaricaModelloRedditi = (idModelloRedditi) => {
    scaricaModelloDaServer(user, idModelloRedditi)
  }

  const idrataArchivio = () =>
    queryIdrataArchivio(user).then((archivio) => setState({ ...state, archivio }))

  const apriFileUpload = (tappa, domanda) => {
    setState({
      ...state,
      modalFileUpload: {
        aperto: true,
        caricamento: false,
        file: undefined,
        tappa,
        domanda,
      },
    })
  }

  const chiudiFileUpload = () => {
    setState({
      ...state,
      modalFileUpload: {
        aperto: false,
        caricamento: false,
        file: undefined,
        tappa: undefined,
        domanda: undefined,
      },
    })
  }

  const impostaFile = (file) => {
    setState({
      ...state,
      modalFileUpload: {
        ...state.modalFileUpload,
        file,
      },
    })
  }

  const uploadFile = async () => {
    setState({
      ...state,
      modalFileUpload: {
        ...state.modalFileUpload,
        caricamento: true,
      },
    })
    try {
      const responseBody = await inviaFile(user, state.modalFileUpload)
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
      setState({
        ...state,
        modalFileUpload: {
          aperto: false,
          caricamento: false,
          file: undefined,
          tappa: undefined,
          domanda: undefined,
        },
      })
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        caricamento: false,
      })
    }
  }

  const visualizzaFile = (uuid) => {
    setState({
      ...state,
      modalViewFile: {
        aperto: true,
        url: undefined,
      },
    })
    scaricaFileAllegatoUtente(user, uuid).then((response) => {
      setState({
        ...state,
        modalViewFile: {
          aperto: true,
          url: response.url,
        },
      })
    })
  }

  const chiudiModalViewFile = () => {
    setState({
      ...state,
      modalViewFile: {
        aperto: false,
        url: undefined,
      },
    })
  }

  const cancellaFile = (tappa, domanda, uuid) => {
    swal({
      title: 'Vuoi eliminare questo documento?',
      icon: 'warning',
      buttons: ['NO, CHIUDI', 'SÌ, CANCELLA'],
    }).then((isConfirm) => {
      if (isConfirm) {
        eliminaFile(user, tappa, domanda, uuid).then((responseBody) => {
          aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
        })
      }
    })
  }

  const salvaRisposta = (tappa, domanda, risposta) => {
    salvaRisp(user, tappa, domanda, risposta).then((responseBody) => {
      aggiornaDatiDichiarazioneEStep(responseBody.dichiarazione)
    })
  }

  const context = {
    ...state,
    completaStepFatture,
    completaStepContributi,
    completaStepQuiz,
    scaricaF24,
    idrataArchivio,
    scaricaModelloRedditi,
    apriFileUpload,
    chiudiFileUpload,
    impostaFile,
    uploadFile,
    visualizzaFile,
    chiudiModalViewFile,
    cancellaFile,
    salvaRisposta,
  }

  return (
    <DichiarazioneContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </DichiarazioneContext.Provider>
  )
}

export { DichiarazioneProvider as default, DichiarazioneContext }

import React, { useState } from 'react'
import {
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  ModalFooter,
} from 'reactstrap'
import classNames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaDatiAggiuntivi } from '../../../app/store/fiscomed/slice'

const Ordine = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)
  const [modal, setModal] = useState(false)

  const aggiornaData = (data) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        data,
      }),
    )
  }

  const aggiornaIdOrdine = (idDocumento) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        idDocumento,
      }),
    )
  }

  const aggiornaCUP = (codiceCUP) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        codiceCUP,
      }),
    )
  }

  const aggiornaCIG = (codiceCIG) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        codiceCIG,
      }),
    )
  }

  const aggiornaCodiceCommessa = (codiceCommessa) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        codiceCommessa,
      }),
    )
  }

  const cancellaDati = () => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        data: '',
        idDocumento: '',
        codiceCUP: '',
        codiceCIG: '',
        codiceCommessa: '',
      }),
    )
    setModal(false)
  }

  const modalOrdine = (
    <Modal isOpen={modal} toggle={() => setModal(false)}>
      <ModalHeader className="font-medium" toggle={() => setModal(false)}>
        Aggiungi dati dell&apos;ordine alla fattura
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label className="font-regular">Identificativo dell&apos;ordine</Label>
          <Input
            type="text"
            onChange={(e) => aggiornaIdOrdine(e.target.value)}
            placeholder="DG2738112"
            value={fiscomedData.datiAggiuntivi.idDocumento}
            invalid={
              fiscomedData.datiAggiuntivi.idDocumento.length > 20 &&
              fiscomedData.datiAggiuntivi.idDocumento.length !== 0
            }
            valid={
              fiscomedData.datiAggiuntivi.idDocumento.length > 0 &&
              fiscomedData.datiAggiuntivi.idDocumento.length <= 20
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo ha caratteri non ammessi oppure è più lungo di 20 caratteri
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">Data dell&apos;ordine</Label>
          <Input
            type="date"
            onChange={(e) => aggiornaData(e.target.value)}
            placeholder="01/01/2024"
            disabled={fiscomedData.datiAggiuntivi.idDocumento === ''}
            value={fiscomedData.datiAggiuntivi.data}
            invalid={
              fiscomedData.datiAggiuntivi.data !== '' &&
              fiscomedData.datiAggiuntivi.idDocumento === ''
            }
          />
          <FormFeedback>
            Non puoi inserire questo campo se non compili l&apos;identificativo
            dell&apos;ordine. Sei sicuro di aver ricevuto il documento &quot;Ordine
            di acquisto&quot; dal tuo cliente? Se non hai questo documento non
            compilare nessuno di questi campi
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">
            CUP <span className="text-muted">(Codice Unico di Progetto)</span>
          </Label>
          <Input
            type="text"
            onChange={(e) => aggiornaCUP(e.target.value)}
            value={fiscomedData.datiAggiuntivi.codiceCUP}
            disabled={fiscomedData.datiAggiuntivi.idDocumento === ''}
            invalid={
              fiscomedData.datiAggiuntivi.codiceCUP.length > 10 &&
              fiscomedData.datiAggiuntivi.codiceCUP.length !== 0
            }
            valid={
              fiscomedData.datiAggiuntivi.codiceCUP.length <= 10 &&
              fiscomedData.datiAggiuntivi.codiceCUP.length !== 0
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo deve essere lungo 10 caratteri e non deve contenere caratteri
            diversi da lettere e numeri
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">
            CIG <span className="text-muted">(Codice Identificativo di Gara)</span>
          </Label>
          <Input
            type="text"
            onChange={(e) => aggiornaCIG(e.target.value)}
            value={fiscomedData.datiAggiuntivi.codiceCIG}
            disabled={fiscomedData.datiAggiuntivi.idDocumento === ''}
            invalid={
              fiscomedData.datiAggiuntivi.codiceCIG.length > 15 &&
              fiscomedData.datiAggiuntivi.codiceCIG.length !== 0
            }
            valid={
              fiscomedData.datiAggiuntivi.codiceCIG.length <= 15 &&
              fiscomedData.datiAggiuntivi.codiceCIG.length !== 0
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo deve essere lungo 15 caratteri e non deve contenere caratteri
            diversi da lettere e numeri
          </FormFeedback>
        </FormGroup>
        <FormGroup>
          <Label className="font-regular">Codice commessa o convenzione</Label>
          <Input
            type="text"
            onChange={(e) => aggiornaCodiceCommessa(e.target.value)}
            value={fiscomedData.datiAggiuntivi.codiceCommessa}
            disabled={fiscomedData.datiAggiuntivi.idDocumento === ''}
            invalid={
              fiscomedData.datiAggiuntivi.codiceCommessa.length > 100 &&
              fiscomedData.datiAggiuntivi.codiceCommessa.length !== 0
            }
            valid={
              fiscomedData.datiAggiuntivi.codiceCommessa.length <= 100 &&
              fiscomedData.datiAggiuntivi.codiceCommessa.length !== 0
            }
          />
          <FormFeedback valid>Sembra corretto</FormFeedback>
          <FormFeedback>
            Il campo deve essere lungo 15 caratteri e non deve contenere caratteri
            diversi da lettere e numeri
          </FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button
          className="bottone-rosso-outline me-3"
          onClick={() => cancellaDati()}
        >
          CANCELLA DATI
        </Button>
        <Button
          className={classNames({
            'bottone-blu':
              fiscomedData.tipologiaFattura === 'Fattura elettronica' ||
              fiscomedData.tipologiaFattura === 'Rettifica pr',
            'bottone-verde': fiscomedData.tipologiaFattura === 'Fattura cartacea',
            'bottone-giallo': fiscomedData.tipologiaFattura === 'Fattura PA',
            'bottone-bianco-blu-outline':
              fiscomedData.tipologiaFattura === 'Proforma',
          })}
          onClick={() => setModal(false)}
        >
          AGGIUNGI DATI
        </Button>
      </ModalFooter>
    </Modal>
  )

  return (
    <div className="mb-3">
      <Button
        onClick={() => setModal(true)}
        className={classNames({
          'bottone-blu':
            fiscomedData.tipologiaFattura === 'Fattura elettronica' ||
            fiscomedData.tipologiaFattura === 'Rettifica pr',
          'bottone-verde': fiscomedData.tipologiaFattura === 'Fattura cartacea',
          'bottone-giallo': fiscomedData.tipologiaFattura === 'Fattura PA',
          'bottone-bianco-blu-outline': fiscomedData.tipologiaFattura === 'Proforma',
        })}
      >
        AGGIUNGI DATI ORDINE&nbsp;
      </Button>
      {modalOrdine}
    </div>
  )
}

export default Ordine

import React, { useState } from 'react'
import {
  Col,
  Container,
  Row,
  Button,
  Input,
  Spinner,
  Label,
  FormGroup,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import DomandaRisposta from '../DomandaRisposta'
import { leadChiamata } from '../../../app/utility/QueryLanding'
import swal from '@sweetalert/with-react'
import { Link, useHistory } from 'react-router-dom'
import { leadChiamataGA } from '../../../app/utility/GA'
import { lead } from '../../../app/utility/SwalContent'

const PrezziDesktop = () => {
  const [caricamento, setCaricamento] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [message, setMessage] = useState('')
  const history = useHistory()
  const [consenso, setConsenso] = useState(false)

  const prenotaChiamata = async () => {
    if (
      nome === '' ||
      email === '' ||
      telefono === '' ||
      consenso === false ||
      message === ''
    ) {
      swal({
        title: 'Attenzione',
        text: 'Compila tutti i campi',
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    leadChiamataGA()
    await leadChiamata({ nome, email, telefono, message }).then((res) => {
      setCaricamento(false)
      swal(lead)
    })
  }

  const pagaMensile = () => {
    swal({
      title: 'Una domanda prima di andare avanti',
      text: "Il piano mensile è dedicato esclusivamente a chi ha aperto la Partita IVA nell'anno in corso o a chi vuole aprirla con noi. Rientri in uno di questi casi?",
      icon: 'warning',
      buttons: ['No, torna a Prezzi', 'Sì, vado avanti'],
    }).then((value) => {
      if (value) history.push('/pagamento/mensile')
    })
  }

  return (
    <>
      <Helmet>
        <title>Prezzi</title>
        <meta property="title" content="Prezzi" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/prezzi" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prezzi" />
        <meta property="og:description" content="Scopri i prezzi di Fiscomed" />
        <meta property="description" content="Scopri i prezzi di Fiscomed" />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_desktop.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container
        style={{ marginTop: '56px' }}
        className="container-landing landing-container-height"
      >
        <br />
        <br />
        <br />
        <br />
        <div className="header-landing-desktop-prezzi " />
        <center>
          <h1 className="titolo-landing-fiscomed font-medium mt-5">
            C&apos;è un abbonamento
            <br /> <span className="testo-verde">adatto a te</span>
          </h1>
          <br />
          <h5 className="testo-landing-fiscomed">
            Piano mensile o piano annuale: scegli quello migliore per le tue esigenze
          </h5>
        </center>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col xs={12} md={6}>
            <div className="card-annuale float-end">
              <div className="header-card-annuale pt-3">
                <h5 style={{ color: 'white', fontSize: '0.9rem', fontWeight: 300 }}>
                  <b>PAGA DI MENO E NON PENSARCI PIÙ</b>
                </h5>
              </div>
              <h3
                style={{ fontWeight: 600, fontSize: '3rem' }}
                className="testo-verde"
              >
                Annuale
              </h3>
              <h5 className="testo-landing-fiscomed mt-3">
                Se vuoi levarti il pensiero puoi
                <br />
                pagare subito un anno e <br />
                risparmiare il 10%
              </h5>
              <br />
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: '4rem',
                  display: 'inline-block',
                  position: 'relative',
                }}
                className="testo-verde"
              >
                <span
                  style={{ fontSize: '1.2rem', top: '8px', position: 'absolute' }}
                >
                  €
                </span>
                &nbsp;400
                <span style={{ fontSize: '1.2rem', marginLeft: '3px' }}>
                  IVA INCLUSA
                </span>
              </h3>
              <h5 className="testo-landing-fiscomed mt-3">
                <span className="font-medium">Dedicato a →</span>
              </h5>
              <div className="d-flex testo-landing-fiscomed">
                <span className="d-inline me-2">
                  <i className="bi bi-check-circle testo-verde"></i>
                </span>
                <span className="d-inline">
                  chi ha già la Partita IVA aperta dallo <i>scorso anno</i>
                </span>
              </div>
              <div className="d-flex mt-2 testo-landing-fiscomed">
                <span className="d-inline me-2">
                  <i className="bi bi-check-circle testo-verde"></i>
                </span>
                <span className="d-inline">
                  chi vuole <i>aprire la Partita IVA</i> e risparmiare qualcosa
                </span>
              </div>
              <Button
                className="bottone-verde px-5 mt-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                tag={Link}
                to="/pagamento/annuale"
              >
                ACQUISTA ORA
              </Button>
            </div>
          </Col>
          <Col xs={12} md={6}>
            <div className="card-annuale">
              <div className="header-card-mensile pt-3">
                <h5 style={{ color: 'white', fontSize: '0.9rem', fontWeight: 300 }}>
                  <b>UNISCITI A NOI, POCO PER VOLTA</b>
                </h5>
              </div>
              <h3
                style={{ fontWeight: 600, fontSize: '3rem' }}
                className="testo-blu"
              >
                Mensile
              </h3>
              <h5 className="testo-landing-fiscomed mt-3">
                Se sei agli inizi della tua attività
                <br />e hai già tante spese, ecco il piano per te
              </h5>
              <br />
              <h3
                style={{
                  fontWeight: 600,
                  fontSize: '4rem',
                  display: 'inline-block',
                  position: 'relative',
                }}
                className="testo-blu"
              >
                <span
                  style={{ fontSize: '1.2rem', top: '8px', position: 'absolute' }}
                >
                  €
                </span>
                &nbsp;37
                <span style={{ fontSize: '1.2rem', marginLeft: '3px' }}>
                  IVA INCLUSA
                </span>
              </h3>
              <h5 className="testo-landing-fiscomed mt-3">
                <span className="font-medium">Dedicato a →</span>
              </h5>
              <div className="d-flex testo-landing-fiscomed">
                <span className="d-inline me-2">
                  <i className="bi bi-check-circle testo-blu"></i>
                </span>
                <span className="d-inline">
                  chi ha aperto la Partita IVA <i>nell&apos;anno in corso</i>
                </span>
              </div>
              <div className="d-flex mt-2 testo-landing-fiscomed">
                <span className="d-inline me-2">
                  <i className="bi bi-check-circle testo-blu"></i>
                </span>
                <span className="d-inline">
                  chi vuole <i>aprire la Partita IVA</i> senza prosciugare il conto
                </span>
              </div>
              <Button
                className="bottone-blu px-5 mt-4"
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
                onClick={() => pagaMensile()}
              >
                ACQUISTA ORA
              </Button>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <br />
        <br />
        <center>
          <span className="etichetta">Cosa è compreso nel prezzo?</span>
          <br />
          <br />
          <br />
          <span className="titolo-2">
            Tutto quello che ti serve per gestire la tua
            <br />
            <span className="testo-verde">Partita IVA da medico</span>
          </span>
        </center>
        <br />
        <div className="div-giallo-guida p-3 mt-5">
          <h3 className="my-3 px-3">Commercialista</h3>
          <Row>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-arancione mt-3">
                  <i className="bi bi-tv"></i>
                </h3>
                <h5 className="font-bold">Prima consulenza</h5>
                <h5 className="font-regular mt-3 interlinea-30">
                  Non appena sarai affidato a un commercialista farai una prima
                  consulenza conoscitiva online
                </h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-arancione mt-3">
                  <i className="bi bi-briefcase"></i>
                </h3>
                <h5 className="font-bold">Apertura Partita IVA</h5>
                <h5 className="font-regular mt-3 interlinea-30">
                  Nel caso in cui tu non ce l&apos;abbia già, il tuo commercialista
                  si occuperà di aprirti la P.IVA
                  <br />
                </h5>
              </div>
            </Col>
            <Col xs={12} md={3} className="px-3">
              <div className="px-3">
                <h3 className="testo-arancione mt-3">
                  <i className="bi bi-file-earmark-check"></i>
                </h3>
                <h5 className="font-bold">Dichiarazione dei redditi</h5>
                <h5 className="font-regular interlinea-30 mt-3">
                  La dichiarazione è compresa nel servizio. Dai un&apos;occhiata
                  <a
                    href="https://fiscomed.it/documenti/lettera_incarico_professionale.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {' '}
                    qui{' '}
                  </a>
                  per i dettagli
                  <br />
                </h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-arancione mt-3">
                  <i className="bi bi-person-square"></i>
                </h3>
                <h5 className="font-bold">Consulenze fiscali</h5>
                <h5 className="font-regular interlinea-30 mt-3">
                  Il tuo commercialista è sempre presente per le consulenze relative
                  alla tua professione da medico
                  <br />
                </h5>
              </div>
            </Col>
          </Row>
          <br />
          <br />
        </div>
        <div className="div-verde-guida p-3 mt-5">
          <h3 className="my-3 px-3">Piattaforma</h3>
          <Row>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-verde mt-3">
                  <i className="bi bi-window-desktop"></i>
                </h3>
                <h5 className="font-bold">Area riservata</h5>
                <h5 className="font-regular interlinea-30 mt-3">
                  Dalla tua area riservata
                  <br />
                  puoi creare le fatture,
                  <br />
                  visualizzare le tue
                  <br />
                  statistiche e molto altro
                  <br />
                </h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-verde mt-3">
                  <i className="bi bi-send-check"></i>
                </h3>
                <h5 className="font-bold">Invio dei dati al STS</h5>
                <h5 className="font-regular interlinea-30 mt-3">
                  Puoi inviare i dati al
                  <br />
                  Sistema Tessera Sanitaria
                  <br />
                  direttamente da
                  <br />
                  Fiscomed. È facilissimo
                  <br />
                </h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-verde mt-3">
                  <i className="bi bi-camera-video"></i>
                </h3>
                <h5 className="font-bold">Fiscodèmia</h5>
                <h5 className="font-regular interlinea-30 mt-3">
                  I nostri commercialisti ti mostreranno
                  <br />
                  in dei video
                  <br />
                  come fare le procedure
                  <br />
                  fiscali da medico
                  <br />
                </h5>
              </div>
            </Col>
            <Col xs={12} md={3}>
              <div className="px-3">
                <h3 className="testo-verde mt-3">
                  <i className="bi bi-heart"></i>
                </h3>
                <h5 className="font-bold">Assistenza</h5>
                <h5 className="font-regular interlinea-30 mt-3">
                  Per email o WhatsApp ci
                  <br />
                  sarà sempre qualcuno
                  <br />
                  pronto a rispondere ai
                  <br />
                  tuoi dubbi e ad aiutarti
                  <br />
                </h5>
              </div>
            </Col>
          </Row>
          <br />
          <br />
        </div>
        <br />
        <br />
        <br />
        <br />
        <Row>
          <Col xs={2} />
          <Col xs={3}>
            <center>
              <span className="etichetta">Ti serve altro?</span>
            </center>
          </Col>
          <Col xs={5}>
            <h5 className="font-regular interlinea-30">
              Se hai già la Partita IVA e devi fare una{' '}
              <b>dichiarazione dei redditi passata, modificare</b>o <b>chiudere</b>{' '}
              la tua Partita IVA contattaci pure. Ce ne possiamo occupare noi
            </h5>
          </Col>
          <Col xs={1} />
        </Row>
        <br />
        <br />
        <center>
          <h1 className="titolo-2">
            Prima di acquistare vuoi{' '}
            <span className="testo-verde">parlare con noi</span>?
          </h1>
          <br />
          <br />
          <h5 className="font-regular interlinea-30">
            Scegliere il commercialista non è una decisione semplice, lo sappiamo. Se
            vuoi avere qualche informazione in più sui
            <br />
            nostri abbonamenti e su quello che facciamo{' '}
            <b>scrivi qui i tuoi contatti e il momento</b> in cui preferisci ricevere
            la chiamata.
            <br />
            Saremo lieti di rispondere alle tue domande e capire con te se Fiscomed è
            il servizio giusto. Naturalmente, non cederemo
            <br />a nessuno i tuoi dati
          </h5>
        </center>
        <br />
        <br />
        <div className="outer-center mt-5">
          <div className="card-contatto-prezzi p-5">
            <Row>
              <Col md={6} className="pr-5">
                <h6 className="font-medium mb-3">Come ti chiami?</h6>
                <Input
                  type="text"
                  placeholder="Ippocrate di Coo"
                  style={{ border: 'none' }}
                  autoComplete="name"
                  onChange={(e) => setNome(e.target.value)}
                  value={nome}
                />
                <br />
                <h6 className="font-medium mb-3">Qual è la tua email?</h6>
                <Input
                  type="text"
                  placeholder="ippocrate@email"
                  style={{ border: 'none' }}
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <br />
                <h6 className="font-medium mb-3">
                  A quale numero possiamo chiamarti?
                </h6>
                <Input
                  type="text"
                  placeholder="3399957060"
                  style={{ border: 'none' }}
                  autoComplete="tel"
                  onChange={(e) => setTelefono(e.target.value)}
                  value={telefono}
                />
              </Col>
              <Col md={6} className="pl-5">
                <h6 className="font-medium mb-3">Quando vuoi che ti chiamiamo?</h6>
                <Input
                  type="textarea"
                  placeholder="Indica un giorno compreso tra lunedì e venerdì e un orario tra le 9 e le 18. Per favore, dai più disponibilità in modo che possiamo accontentare tutte le persone che desiderano essere chiamate"
                  rows="6"
                  style={{ border: 'none' }}
                  onChange={(e) => setMessage(e.target.value)}
                  value={message}
                />
                <br />
                <FormGroup check>
                  <Input
                    type="checkbox"
                    name="privacy"
                    id="privacy"
                    style={{ marginTop: '2px' }}
                    checked={consenso}
                    onChange={(e) => setConsenso(e.target.checked)}
                  />
                  <Label for="privacy" className="consenso">
                    Acconsento al trattamento dei miei dati e dichiaro di aver letto
                    la{' '}
                    <a
                      href="/documenti/termini_e_condizioni.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <u>privacy policy</u>
                    </a>
                  </Label>
                </FormGroup>

                {caricamento ? (
                  <Button
                    className="bottone-blu px-5 mt-5"
                    disabled
                    style={{ paddingTop: '14px', paddingBottom: '14px' }}
                  >
                    <Spinner color="light" size="sm" />
                  </Button>
                ) : (
                  <Button
                    className="bottone-blu px-5 mt-5"
                    onClick={() => prenotaChiamata()}
                    style={{ paddingTop: '14px', paddingBottom: '14px' }}
                  >
                    INIZIA ORA
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </div>
        <br />
        <br />
        <center>
          <h1 className="titolo-2">
            Conosciamo i <span className="testo-verde">tuoi dubbi</span>
          </h1>
          <h5 className="font-regular interlinea-30">Ecco le nostre risposte</h5>
        </center>
        <br />
        <br />
        <DomandaRisposta>
          <>Sono un medico: nel servizio è compreso tutto quello che mi serve?</>
          <>
            I servizi che riceverai sono tutti quelli necessari a gestire gli
            adempimenti fiscali della professione medica
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Il mensile è un vero mensile?</>
          <>
            Sì, il nostro abbonamento mensile è davvero un mensile, proprio come
            Netflix. Non è, come in altri casi, una rateizzazione dell’annuale.
            Questo significa che non hai l’obbligo di rimanere con noi un anno, ma
            puoi smettere di pagare quando vuoi. È naturale, però, che se vuoi che
            siamo noi a fare la tua dichiarazione dei redditi è necessario che tu sia
            un cliente pagante in quel momento. Per riprendere Netflix: quando smetti
            di pagarlo, non hai più l’accesso alle sue serie e film.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Parlerò sempre con lo stesso commercialista?</>
          <>
            Certamente. Non appena acquisti Fiscomed vieni affidato/a a un Dottore o
            una Dottoressa Commercialista del nostro team con cui firmerai una
            lettera di incarico. Da quel momento in poi, la persona con cui hai fatto
            la prima videoconsulenza sarà il/la commercialista di riferimento. Avrai
            la sua email e sarà lui o lei a occuparsi della tua gestione fiscale,
            fino a quando lo vorrai. Naturalmente, se dovessi avere un’urgenza quando
            il/la tua commercialista è in ferie, potrai parlare con un altro
            professionista del nostro team.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Posso rescindere il contratto quando voglio?</>
          <>
            Certo. Il contratto è rescindibile in qualsiasi momento, senza penali,
            sia che tu sottoscriva l’abbonamento mensile sia che tu scelga
            l’abbonamento annuale. Dal momento in cui smetti di pagare, smetti di
            ricevere i nostri servizi e si interrompe il contratto fra te e il tuo o
            la tua commercialista.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Se inizio con il mensile poi posso passare all&apos;annuale?</>
          <>
            Certo. Chiamaci o scrivici una email e modificheremo la tipologia di
            abbonamento.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            E se ho bisogno di consulenze al di fuori della mia attività da medico?
          </>
          <>
            Puoi rivolgerti tranquillamente al Dottore o alla Dottoressa
            Commercialista che ti segue. Naturalmente, però, tutto ciò che non
            rientra nella gestione della fiscalità della professione medica non è
            compreso nel prezzo.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            Nella lettera di incarico c&apos;è scritto che non mi viene fatta
            assistenza nell&apos;emissione delle fatture elettroniche. È vero?
          </>
          <>
            In realtà no, non è vero. Ti spiego meglio: la lettera di incarico è il
            contratto che definisce la relazione fra te e il tuo commercialista, non
            fra te e noi di Fiscomed.it. Infatti affidandoti a Fiscomed hai due tipi
            di assistenza: (1) un’assistenza via WhatsApp con il team di consulenti
            di Fiscomed.it e (2) un’assistenza via email o videoconsulenza con il
            Dottore Commercialista del team al quale ti affidiamo. Per quanto
            riguarda l’emissione delle fatture elettroniche, siamo noi di Fiscomed.it
            ad assisterti e non il tuo Dottore Commercialista. Questo perché si
            tratta di una procedura davvero semplice che, con la nostra piattaforma,
            diventa ancora più facile da portare a termine. Solitamente, se i nostri
            clienti lo richiedono, quando devono emettere la loro prima fattura li
            chiamiamo per fare questo processo insieme. Ogni volta la chiamata
            finisce con: “Ah, ma è facilissimo!” 😊
          </>
        </DomandaRisposta>
      </Container>
      <br />
      <br />
      <br />
      <Footer />
    </>
  )
}
export default PrezziDesktop

import React from 'react'
import { Modal, ModalHeader, ModalBody, Alert } from 'reactstrap'
import { Link } from 'react-router-dom'
import { getLast } from '../../../app/utility/array'

import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalScartoPA } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalScarto = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)
  const lastEsito = getLast(dati.fatturaSelezionata?.esito)
  return (
    <Modal
      isOpen={dati.modalScartoPAOpen}
      toggle={() => dispatch(chiudiModalScartoPA())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalScartoPA())}
        className="h4 font-regular font-grigio-scuro"
      >
        Fattura scartata
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          La fattura è stata è stata scartata perché lo SdI ha trovato degli errori
          😞
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Entro 5 giorni dovrai fare una nuova fattura con lo stesso numero univoco e
          la stessa data di emissione. Se hai bisogno di una mano{' '}
          <Link to="/chat-commercialista">contattaci</Link>: risolveremo insieme
          l&apos;errore
          <br />
          <br /> Ecco gli errori che hanno fatto scartare la fattura:
        </h6>
        <br />
        <Alert color="danger">{lastEsito?.suggerimento}</Alert>
      </ModalBody>
    </Modal>
  )
}

export default ModalScarto

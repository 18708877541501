import React from 'react'
import { Container, Card } from 'reactstrap'

const SoloDesktop = () => (
  <Container>
    <Card body className="mt-5">
      Fiscomed è utilizzabile solo sui dispositivi con schermo grande come quello di
      un computer.
    </Card>
  </Container>
)

export default SoloDesktop

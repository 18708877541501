import React, { useState } from 'react'
import { Input, Button, Container, Label } from 'reactstrap'
import request from 'superagent'
import swal from 'sweetalert'
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectIsLoggedIn } from '../../app/store/user/selectors'

const CambioPasswordMobile = () => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const [recoverEmail, setRecoverEmail] = useState('')
  const [loading, setLoad] = useState(false)
  const history = useHistory()

  const inviaCambioPassword = () => {
    setLoad(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/request-change-password`)
      .send({ email: recoverEmail })
      .end((err, res) => {
        if (err) {
          alert('Errore generico')
        } else {
          if (res.body.error) {
            alert(res.body.message_error)
            console.log(err)
          } else {
            swal({
              title:
                'Segui le istruzioni che ti abbiamo inviato per email per completare il cambio della tua password',
              icon: 'success',
              button: 'OK',
            })
            history.push('/accesso')
          }
        }
        setLoad(false)
      })
  }

  return (
    <>
      <Helmet>
        <title>Cambio password</title>
        <meta property="title" content="Cambio password" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/cambio-password" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Cambio password" />
        <meta
          property="og:description"
          content="Pagina per reimpostare la propria password"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>{'body {   background: white }'}</style>
      </Helmet>

      <Container style={{ marginTop: '15vh' }}>
        {isLoggedIn === false && (
          <Link to="/accesso">
            <i className="bi bi-arrow-left" /> Torna al login
          </Link>
        )}
        <br />
        <br />
        <h2 className="font-medium">Hai dimenticato la tua password?</h2>
        <br />
        <h6 className="h6-landing">
          Non preoccuparti, ti invieremo una mail con un link che puoi utilizzare per{' '}
          <b>cambiare la tua password</b> in modo sicuro
        </h6>
        <br />

        <Label className="h6-landing">Indirizzo email</Label>
        <Input
          type="email"
          value={recoverEmail}
          placeholder="Email"
          onChange={(e) => setRecoverEmail(e.target.value)}
          style={{ maxWidth: '400px' }}
        />
        <br />
        <br />
        {loading ? (
          ''
        ) : (
          <Button className="bottone-blu px-3" onClick={() => inviaCambioPassword()}>
            AVANTI
          </Button>
        )}
      </Container>
    </>
  )
}

export default CambioPasswordMobile

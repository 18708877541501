import React from 'react'
import { Card, Row, Col } from 'reactstrap'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const PreviewProforma = () => {
  const user = useSelector(selectUser)
  const fiscomedData = useSelector(selectFiscomedData)
  const dataCreazione = moment().format('YYYY-MM-DD')

  const tabellaImporti = () => {
    const tabella = fiscomedData.prestazioni.map((prestazione, i) => (
      <tr key={i}>
        <th scope="row">{i + 1}</th>
        <td className="text-muted h6">{prestazione.descrizione}</td>
        <td className="h6 font-bold">{prestazione.importo} €</td>
      </tr>
    ))
    if (fiscomedData.addebitoMarcaDaBollo) {
      const contatore = tabella.length + 1
      tabella.push(
        <tr key={contatore}>
          <th scope="row">{contatore}</th>
          <td className="text-muted h6">Marca da bollo</td>
          <td className="h6 font-bold">2 €</td>
        </tr>
      )
    }
    return tabella
  }

  return (
    <Card body className="mt-2 mb-4" style={{ maxWidth: '900px' }}>
      <div className="d-flex justify-content-between">
        <div>
          <small className="text-muted">Documento senza valore fiscale</small>
        </div>
        <small className="text-muted">
          Data emissione {moment(dataCreazione).format('DD/MM/YYYY')}
        </small>
      </div>
      <br />
      <br />
      <Row>
        <Col xs="6">
          <span className="text-muted">Emittente</span>
          <h5 className="font-medium">
            {user.fisco_nome} {user.fisco_cognome}
          </h5>
          <h6 className="text-muted">{user.fisco_indirizzo}</h6>
          <h6 className="text-muted">
            {user.fisco_comune}, {user.fisco_cap} ({user.fisco_provincia})
          </h6>
          <h6 className="text-muted">CF: {user.fisco_cf}</h6>
          <h6 className="text-muted">P.IVA: {user.fisco_partita_iva}</h6>
        </Col>
        <Col xs="6">
          <span className="text-muted">Destinatario</span>
          <h5 className="font-medium">
            {fiscomedData.clienteAttivo?.ragione_sociale}
          </h5>
          <h6 className="text-muted">{fiscomedData.clienteAttivo?.indirizzo}</h6>
          <h6 className="text-muted">
            {fiscomedData.clienteAttivo?.comune}, {fiscomedData.clienteAttivo?.cap}{' '}
            {fiscomedData.clienteAttivo?.provincia &&
              `(${fiscomedData.clienteAttivo?.provincia})`}
          </h6>
          <h6 className="text-muted">
            CF: {fiscomedData.clienteAttivo?.codice_fiscale}
          </h6>
          {fiscomedData.clienteAttivo?.partita_iva !== null && (
            <h6 className="text-muted">
              P.IVA: {fiscomedData.clienteAttivo?.partita_iva}
            </h6>
          )}
        </Col>
      </Row>
      <div className="my-4">
        <br />
        <br />
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col"></th>
              <th scope="col" className="h6 font-medium">
                Descrizione
              </th>
              <th scope="col" className="h6 font-medium">
                Importo
              </th>
            </tr>
          </thead>
          <tbody>{tabellaImporti()}</tbody>
        </table>
        <br />
        <div className="d-flex justify-content-end" style={{ minHeight: '280px' }}>
          <h4>Totale: {fiscomedData.totaleImporto} €</h4>
        </div>
      </div>

      <Row>
        <Col xs="6">
          <h5 className="font-medium">Pagamento</h5>
        </Col>
        <Col xs="6">
          <h5 className="font-medium">Note</h5>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs="6">
          {fiscomedData.metodoDiPagamentoAttivo === 'Contante' ? (
            <h5>Contante</h5>
          ) : (
            <>
              Intestatario: {fiscomedData.metodoDiPagamentoAttivo.intestatario}
              <br />
              IBAN: {fiscomedData.metodoDiPagamentoAttivo.iban}
            </>
          )}
        </Col>
        <Col xs="6">
          <small className="text-muted">
            {fiscomedData.notaPresente && (
              <>
                <b>{fiscomedData.nota}</b>
                <br />
              </>
            )}
            Prestazione svolta in regime forfetario ex articolo 1, commi da 54 a 89,
            della Legge n. 190/2014 come modificata dalla L. n. 208/2015 e dalla L.
            n. 145/2018 e pertanto non soggetta a IVA. Operazione non assoggettata a
            ritenuta d&apos;acconto ai sensi dell&apos;art. 1 comma 67 della Legge n.
            190/2014.
          </small>
        </Col>
      </Row>
    </Card>
  )
}

export default PreviewProforma

import React, { useState } from 'react'
import { Button, Progress, Card, Modal, ModalHeader, ModalBody } from 'reactstrap'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const ModelloD = ({ dati, setScheda }) => {
  const [modal, setModal] = useState(false)

  const modale = () => (
    <Modal
      isOpen={modal}
      toggle={() => {
        setModal(false)
      }}
      size="xl"
    >
      <ModalHeader
        className="font-bold"
        toggle={() => {
          setModal(false)
        }}
      >
        Come compilare il Modello D 📝
      </ModalHeader>
      <ModalBody>
        <div style={{ width: '100%' }}>
          <video
            src="/fiscodemia/video/modello_d1.mp4"
            autoPlay
            controls
            width="100%"
          />
        </div>
      </ModalBody>
    </Modal>
  )

  return (
    <>
      {modale()}

      <Card body className="h-100 d-flex d-column p-5">
        <Progress value={90} className="progress-gialla" />
        <br />
        <br />
        <h4 className="testo-verde font-bold mb-4">
          PUOI FINALMENTE COMPILARE IL MODELLO D
        </h4>
        <h6 className="font-regular mb-3 interlinea-30">
          Per guidarti nella procedura, abbiamo realizzato un breve <b>video</b> in
          cui Giulia ti mostra passo passo come compilare il Modello D.{' '}
          <u
            className="testo-blu"
            style={{ cursor: 'pointer' }}
            onClick={() => setModal(true)}
          >
            Clicca qui
          </u>{' '}
          per guardarlo.
          <br />
          <br />
          Come hai potuto imparare dal video, per inviare il Modello D devi
          dichiarare l&apos;importo imponibile relativo allo scorso anno. Non
          preoccuparti: non sei tu a dover stabilire questa cifra, perché è{' '}
          {dati.genere.ilTuo} commercialista ad averlo fatto per te.
          <br />
          <br />
          Ecco l&apos;importo che devi inserire nel campo &quot;Reddito per
          l&apos;anno {new Date().getFullYear() - 1} (€)&quot;
        </h6>
        <br />
        <div
          style={{
            width: '200px',
            border: '2px solid #f2b705',
            padding: '15px 15px 15px 15px',
            borderRadius: '25px',
          }}
        >
          <center>
            <h2
              className="font-bold"
              style={{ padding: '0px 0px 0px 0px', margin: '3px 0px 0px 0px' }}
            >
              {dati.importo_modello_d < 0 ? '?' : dati.importo_modello_d}
            </h2>
          </center>
        </div>
        <br />
        <br />
        <br />
        <h6 className="font-regular mb-3 mt-4 interlinea-30">
          Non appena hai compilato e inviato il Modello D, clicca sul tasto
          &quot;UN&apos;ULTIMA COSA&quot;
        </h6>
        <br />
        <br />
        <div className="mt-auto mb-3">
          <Ricomincia setScheda={setScheda} />
          <Button
            className="bottone-blu float-end"
            style={{ width: '210px' }}
            onClick={() => setScheda('fine')}
          >
            UN&apos;ULTIMA COSA <i className="bi bi-arrow-right-short"></i>
          </Button>
          <NonSaiRispondere />
        </div>
      </Card>
    </>
  )
}

export default ModelloD

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Label, Input } from 'reactstrap'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaAddebitoMarcaDaBollo } from '../../../app/store/fiscomed/slice'

const AddebitoMarcaDaBollo = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)

  const setMarcaDaBollo = (valore) => dispatch(aggiornaAddebitoMarcaDaBollo(valore))

  return (
    <FormGroup check className="mb-2">
      <Label check>
        <Input
          type="checkbox"
          defaultChecked={fiscomedData.addebitoMarcaDaBollo}
          onChange={() => setMarcaDaBollo(!fiscomedData.addebitoMarcaDaBollo)}
        />
        <span className="text-muted">
          Addebita il costo della marca da bollo al cliente
        </span>
      </Label>
    </FormGroup>
  )
}

export default AddebitoMarcaDaBollo

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label } from 'reactstrap'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaDatiAggiuntivi } from '../../../app/store/fiscomed/slice'

const ScadenzaPagamento = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)
  const setScadenzaPagamento = (scadenzaPagamento) => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        scadenzaPagamento,
      })
    )
  }

  return (
    <FormGroup>
      <Label>Scadenza Pagamento</Label>
      <Input
        type="date"
        value={fiscomedData.datiAggiuntivi.scadenzaPagamento}
        onChange={(e) => setScadenzaPagamento(e.target.value)}
      />
    </FormGroup>
  )
}

export default ScadenzaPagamento

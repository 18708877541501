import React from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import InviaEmailCartacea from './component/InviaEmailCartacea'
import InviaTS from './component/InviaTS'
import FineCartacea from './component/FineCartacea'

const EsitoFatturaCartacea = () => {
  const { idFattura } = useParams()
  const [schermata, setSchermata] = React.useState('email')

  return (
    <Container className="mt-5">
      {schermata === 'email' && (
        <InviaEmailCartacea
          idFattura={idFattura}
          emailInviata={() => setSchermata('sistemaTS')}
        />
      )}
      {schermata === 'sistemaTS' && (
        <InviaTS TSInviato={() => setSchermata('fine')} idFattura={idFattura} />
      )}
      {schermata === 'fine' && <FineCartacea idFattura={idFattura} />}
    </Container>
  )
}

export default EsitoFatturaCartacea

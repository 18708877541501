export const selectIsLoggedIn = (state) =>
  state.user.user.auth_token !== undefined && !state.user.isLoading

export const selectUser = (state) => state.user.user

export const selectIsLoadingUser = (state) => state.user.isLoading

export const selectUserError = (state) => ({
  error: state.user.user.error,
  message_error: state.user.user.message_error,
})

import moment from 'moment'

export const emptyState = {
  fatture: [],
  annoDiRiferimento: moment().year(),
  annoCorrente: moment().year(),
  anniConFattureEmesse: [],
  loading: true,
  modalIncassoOpen: false,
  modalImpossibilitaDiRecapitoOpen: false,
  modalConsegnataOpen: false,
  modalConsegnataPAOpen: false,
  modalScartoOpen: false,
  modalScartoVariazioneOpen: false,
  modalMancataConsegnaPAOpen: false,
  modalMancataConsegnaPROpen: false,
  modalMancataConsegnaAutofatturaOpen: false,
  modalDecorrenzaTerminiPAOpen: false,
  modalEsitoPositivoPAOpen: false,
  modalEsitoNegativoPAOpen: false,
  modalAttestazionePAOpen: false,
  modalScartoPAOpen: false,
  modalEmailOpen: false,
  modalVerificaSdiOpen: false,
  fatturaSelezionata: null,
  dataIncasso: moment().format('YYYY-MM-DD'),
}

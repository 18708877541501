import React from 'react'
import { Card } from 'reactstrap'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const HeaderNDCpa = () => {
  const fiscomedData = useSelector(selectFiscomedData)

  return (
    <Card
      className="mt-4"
      body
      style={{
        borderRadius: '10px',
        border: 'none',
        backgroundColor: '#0583f2',
      }}
    >
      <div className="d-flex justify-content-between">
        <h3 className="font-regular text-white">Nota di credito PA</h3>

        <h3 className="font-regular text-white">
          Numero {fiscomedData.numeroFattura}/{moment().year()}
        </h3>
      </div>
    </Card>
  )
}

export default HeaderNDCpa

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Container,
  Input,
  Label,
  Button,
  Spinner,
  FormGroup,
  FormFeedback,
} from 'reactstrap'
import { creaLinkPagamento } from '../../app/utility/QueryLoginEPagamento'
import swal from 'sweetalert'
import { Helmet } from 'react-helmet'
import { datiFiscaliGA } from '../../app/utility/GA'
import classNames from 'classnames'

const Pagamento = () => {
  const { periodo } = useParams()
  const [dati, setDati] = useState({
    email: '',
    email2: '',
    password: '',
    nome: '',
    cognome: '',
    indirizzo: '',
    codiceFiscale: '',
    cap: '',
    comune: '',
    provincia: '',
    codiceCollega: '',
    typePassword: 'password',
    pagina: 1,
    loading: false,
    erroreCF: false,
    terminiECondizioni: false,
    articoli: false,
  })

  const controlloCFPersona = (value) => {
    value = value.toUpperCase()
    if (value.length > 16) {
      return
    }
    const regex =
      /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i
    if (value.length === 16 && value.match(regex)) {
      setDati({ ...dati, erroreCF: false, codiceFiscale: value })
      return
    }
    setDati({ ...dati, erroreCF: true, codiceFiscale: value })
  }

  const hideViewPassord = () => {
    if (dati.typePassword === 'password') {
      setDati({ ...dati, typePassword: 'text' })
    } else {
      setDati({ ...dati, typePassword: 'password' })
    }
  }

  const vaiPagina2 = () => {
    if (!dati.email || !dati.password || dati.email !== dati.email2) {
      swal({
        title: 'Manca qualcosa',
        text: "Compila tutti i campi per proseguire con l'acquisto",
        icon: 'warning',
        button: 'OK',
      })
    } else {
      setDati({ ...dati, pagina: 2 })
      window.scrollTo({ top: 0, left: 0 })
    }
  }

  const vaiPagina3 = () => {
    if (
      !dati.nome ||
      !dati.cognome ||
      !dati.indirizzo ||
      !dati.codiceFiscale ||
      !dati.cap ||
      !dati.comune ||
      !dati.provincia
    ) {
      swal({
        title: 'Manca qualcosa',
        text: "Compila tutti i campi per proseguire con l'acquisto",
        icon: 'warning',
        button: 'OK',
      })
    } else {
      setDati({ ...dati, pagina: 3 })
      window.scrollTo({ top: 0, left: 0 })
    }
  }

  const vaiPagina4 = () => {
    if (!dati.terminiECondizioni || !dati.articoli) {
      swal({
        title: 'Manca qualcosa',
        text: "Accetta i termini e condizioni per proseguire con l'acquisto",
        icon: 'warning',
        button: 'OK',
      })
    } else {
      setDati({ ...dati, pagina: 4 })
      window.scrollTo({ top: 0, left: 0 })
    }
  }

  const toPaginaPagamento = async () => {
    if (
      !dati.email ||
      !dati.password ||
      !dati.nome ||
      !dati.cognome ||
      !dati.indirizzo ||
      !dati.codiceFiscale ||
      !dati.cap ||
      !dati.comune ||
      !dati.provincia
    ) {
      swal({
        title: 'Manca qualcosa',
        text: "Compila tutti i campi e accetta i termini e condizioni per proseguire con l'acquisto",
        icon: 'warning',
        button: 'OK',
      })
    } else {
      setDati({ ...dati, loading: true })
      datiFiscaliGA()
      const response = await creaLinkPagamento({
        email: dati.email,
        password: dati.password,
        nome: dati.nome,
        cognome: dati.cognome,
        indirizzo: dati.indirizzo,
        codiceFiscale: dati.codiceFiscale,
        cap: dati.cap,
        comune: dati.comune,
        codiceCollega: dati.codiceCollega,
        provincia: dati.provincia,
        tipo_abbonamento: periodo,
      })
      window.location.replace(response.url_pagamento)
    }
  }

  return (
    <>
      <Helmet>
        <title>Pagamento {periodo}</title>
        <meta property="title" content={'Pagamento ' + periodo} />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content={'https://fiscomed.it/' + periodo} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={'Pagamento ' + periodo} />
        <meta
          property="og:description"
          content="Compila i dati prima di procedere"
        />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <style>{'body { background-color: white; }'}</style>
      </Helmet>
      <Container className="container-landing">
        {dati.pagina === 1 && (
          <div style={{ marginTop: '56px' }}>
            <br />
            <span className="titolo3-mobile">
              Prima di comprare il tuo abbonamento {periodo},
              <br />è necessario che tu compili alcuni campi 😊
            </span>
            <br />
            <br />
            <h6 className="h6-landing">
              Scrivi l&apos;<span className="testo-blu">email</span> e la{' '}
              <span className="testo-blu">password</span> con cui vorrai accedere a
              Fiscomed una volta sottoscritto l&apos;abbonamento.
              <br />
              Questi dati ci servono per creare il tuo account.
            </h6>
            <br />

            <FormGroup className="mt-3">
              <Label for="email" className="font-medium">
                L&apos;email che usi di più
              </Label>
              <Input
                type="email"
                value={dati.email}
                onChange={(e) => setDati({ ...dati, email: e.target.value })}
                autoComplete="email"
                name="email"
                id="email"
                placeholder="ippocrate@dicoo.it"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <Label
                for="confirm"
                className={classNames('font-medium', {
                  'testo-verde':
                    dati.email === dati.email2 && dati.email2.length > 0,
                })}
              >
                L&apos;email che usi di più, di nuovo
              </Label>
              <Input
                type="text"
                value={dati.email2}
                onChange={(e) => setDati({ ...dati, email2: e.target.value })}
                autoComplete="stop-autofill"
                name="confirm"
                id="confirm"
                placeholder="ippocrate@dicoo.it"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <br />
            <FormGroup>
              <Label
                for="password"
                onClick={() => hideViewPassord()}
                className="font-medium"
              >
                La password che preferisci{' '}
                {dati.typePassword === 'password' ? (
                  <i className="bi bi-eye-fill"></i>
                ) : (
                  <i className="bi bi-eye"></i>
                )}
              </Label>
              <Input
                type={dati.typePassword}
                value={dati.password}
                onChange={(e) => setDati({ ...dati, password: e.target.value })}
                autoComplete="new-password"
                name="password"
                id="password"
                placeholder="èunsegretodiippocrate"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <br />
            <br />
            <Button
              className="bottone-blu float-end px-4"
              onClick={() => vaiPagina2()}
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              AVANTI <i className="bi bi-arrow-right"></i>
            </Button>
            <br />
            <br />
            <br />
          </div>
        )}
        {dati.pagina === 2 && (
          <div style={{ marginTop: '56px' }}>
            <br />
            <i
              className="bi bi-arrow-left h3 testo-blu"
              onClick={() => setDati({ ...dati, pagina: 1 })}
            ></i>
            <br />
            <br />
            <h6 className="h6-landing mb-3">
              Devi sapere che anche noi come azienda abbiamo degli obblighi fiscali
              da rispettare. Ogni volta che una persona acquista dobbiamo inviare una{' '}
              <span className="testo-blu">fattura</span>. Per farlo abbiamo bisogno
              di alcuni dati.
              <br />
              <br />
              Ti abbiamo facilitato il lavoro con la compilazione automatica.
            </h6>
            <br />
            <FormGroup>
              <Label for="nome" className="font-medium">
                Nome
              </Label>
              <Input
                type="text"
                value={dati.nome}
                onChange={(e) => setDati({ ...dati, nome: e.target.value })}
                name="nome"
                id="nome"
                placeholder="Il tuo nome"
                autoComplete="given-name"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="cognome" className="font-medium">
                Cognome
              </Label>
              <Input
                type="text"
                placeholder="Il tuo cognome"
                value={dati.cognome}
                onChange={(e) => setDati({ ...dati, cognome: e.target.value })}
                name="cognome"
                id="cognome"
                autoComplete="family-name"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="indirizzo" className="font-medium">
                Indirizzo
              </Label>
              <Input
                type="text"
                value={dati.indirizzo}
                onChange={(e) => setDati({ ...dati, indirizzo: e.target.value })}
                name="indirizzo"
                id="indirizzo"
                placeholder="La tua via di residenza"
                autoComplete="street-address"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="codiceFiscale" className="font-medium">
                Codice Fiscale
              </Label>
              <Input
                type="text"
                value={dati.codiceFiscale}
                onChange={(e) => controlloCFPersona(e.target.value)}
                name="codiceFiscale"
                placeholder="Il tuo codice fiscale"
                id="codiceFiscale"
                style={{ maxWidth: '350px' }}
                valid={dati.codiceFiscale.length > 0 && !dati.erroreCF}
                invalid={dati.codiceFiscale.length > 0 && dati.erroreCF}
              />
              <FormFeedback valid>Sembra corretto</FormFeedback>
              <FormFeedback>Sembra non sia corretto</FormFeedback>
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="cap" className="font-medium">
                CAP
              </Label>
              <Input
                type="text"
                value={dati.cap}
                onChange={(e) => setDati({ ...dati, cap: e.target.value })}
                name="CAP"
                id="cap"
                placeholder="Il cap di residenza"
                autoComplete="postal-code"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="comune" className="font-medium">
                Comune
              </Label>
              <Input
                type="text"
                value={dati.comune}
                onChange={(e) => setDati({ ...dati, comune: e.target.value })}
                name="comune"
                id="comune"
                placeholder="Il comune di residenza"
                autoComplete="address-level2"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <FormGroup className="mt-4">
              <Label for="provincia" className="font-medium">
                Provincia (sigla)
              </Label>
              <Input
                type="text"
                value={dati.provincia}
                onChange={(e) => {
                  if (e.target.value.length <= 2) {
                    setDati({ ...dati, provincia: e.target.value.toUpperCase() })
                  }
                }}
                name="provincia"
                placeholder="RM"
                id="provincia"
                autoComplete="address-level1"
                style={{ maxWidth: '350px' }}
              />
            </FormGroup>
            <br />
            <br />
            <Button
              className="bottone-blu float-end px-4"
              onClick={() => vaiPagina3()}
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              AVANTI <i className="bi bi-arrow-right"></i>
            </Button>
            <br />
            <br />
            <br />
          </div>
        )}
        {dati.pagina === 3 && (
          <div style={{ marginTop: '56px' }}>
            <br />
            <i
              className="bi bi-arrow-left h3 testo-blu"
              onClick={() => setDati({ ...dati, pagina: 2 })}
            ></i>
            <br />
            <br />
            <span className="titolo3-mobile">Le solite formalità</span>
            <br />
            <br />
            <h6 className="h6-landing mb-3">
              I dati che ti abbiamo chiesto saranno usati e conservati solo per
              procedere all&apos;acquisto e supportarti al meglio nella gestione
              della tua Partita IVA.
              <br />
              Se vuoi saperne di più, puoi leggere la nostra{' '}
              <a href="/documenti/privacy_policy.pdf">
                <u>privacy policy</u>
              </a>{' '}
              (un terribile documento in legalese).
              <br />
              <br />E per concludere le faccende burocratiche, ti chiediamo
              gentilmente di{' '}
              <span className="font-medium">spuntare queste due caselle</span>. È
              solo un piccolo passaggio per assicurarci che tu abbia dato
              un&apos;occhiata ai nostri documenti legali.
            </h6>
            <br />
            <FormGroup check>
              <Input
                type="checkbox"
                name="termini-e-condizioni"
                id="termini-e-condizioni"
                className="mt-2"
                checked={dati.terminiECondizioni}
                onChange={(e) =>
                  setDati({ ...dati, terminiECondizioni: e.target.checked })
                }
              />
              <Label for="termini-e-condizioni" className="h6-landing">
                Dichiaro di accettare i{' '}
                <a href="/documenti/termini_e_condizioni.pdf" className="testo-blu">
                  <u>termini e condizioni</u>
                </a>{' '}
                di acquisto
              </Label>
            </FormGroup>
            <FormGroup check>
              <Input
                type="checkbox"
                name="articoli"
                id="articoli"
                className="mt-2"
                checked={dati.articoli}
                onChange={(e) => setDati({ ...dati, articoli: e.target.checked })}
              />
              <Label for="articoli" className="h6-landing">
                Dichiaro di accettare espressamente gli articoli 4, 5, 6, 7, 8, 9,
                10, 11, 12, 13, 15 e 16 dei termini e condizioni
              </Label>
            </FormGroup>
            <br />
            <br />
            <Button
              className="bottone-blu float-end px-4"
              onClick={() => vaiPagina4()}
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
            >
              AVANTI <i className="bi bi-arrow-right"></i>
            </Button>
            <br />
            <br />
            <br />
          </div>
        )}
        {dati.pagina === 4 && (
          <div style={{ marginTop: '56px' }}>
            <br />
            <i
              className="bi bi-arrow-left h3 testo-blu"
              onClick={() => setDati({ ...dati, pagina: 3 })}
            ></i>
            <br />
            <br />
            <span className="titolo3-mobile mt-5">Ci siamo quasi</span>
            <br />
            <br />
            <h6 className="h6-landing mb-4">
              Hai un <span className="testo-blu">codice collega</span>? Se una
              persona a te vicina ti ha suggerito di passare a Fiscomed e ti ha dato
              un codice che ti permette di avere subito uno sconto di 27 €, scrivilo
              nel campo qui sotto.
              <br />
              Se non ce l&apos;hai, lascia pure questo campo vuoto e{' '}
              <b>vai avanti</b>. Potrai inserire coupon diversi dal codice collega
              nella schermata successiva
            </h6>
            <FormGroup>
              <Input
                type="text"
                value={dati.codiceCollega}
                onChange={(e) =>
                  setDati({ ...dati, codiceCollega: e.target.value.toLowerCase() })
                }
                name="codiceCollega"
                placeholder="Il codice collega"
                id="codiceCollega"
                style={{ maxWidth: '350px' }}
                autoComplete="new-password"
              />
            </FormGroup>
            <br />
            <h6 className="h6-landing mb-4">
              Abbiamo finito 😊. Clicca sul tasto qui sotto per andare alla schermata
              di acquisto del tuo{' '}
              <span className="testo-blu">abbonamento {periodo}</span>
            </h6>
            <br />
            <Button
              className="bottone-blu px-4"
              onClick={() => toPaginaPagamento()}
              style={{ paddingTop: '14px', paddingBottom: '14px' }}
              block
            >
              {dati.loading ? <Spinner size="sm" /> : 'ACQUISTA ORA'}
            </Button>
            <br />
            <br />
            <br />
          </div>
        )}
      </Container>
    </>
  )
}

export default Pagamento

import React from 'react'
import { Card, Row, Col } from 'reactstrap'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const PreviewAutofattura = () => {
  const fiscomedData = useSelector(selectFiscomedData)
  const user = useSelector(selectUser)

  const tabellaImporti = () => {
    const tabella = fiscomedData.prestazioniAutofattura.map((prestazione, i) => (
      <tr key={i}>
        <td className="text-muted h6">{prestazione.descrizione}</td>
        <td className="text-muted h6">{prestazione.importoNetto}€</td>
        <td className="text-muted h6">{prestazione.iva}%</td>
        <td className="text-muted h6">{prestazione.importoIVA}€</td>
        <td className="h6 font-bold">{prestazione.importoTotale}€</td>
      </tr>
    ))
    return tabella
  }

  return (
    <Card body className="mt-2 mb-3" style={{ maxWidth: '900px' }}>
      <div className="d-flex justify-content-between">
        <small className="text-muted">
          Autofattura numero {fiscomedData.numeroAutofattura}A
        </small>
        <small className="text-muted">
          Data autofattura {moment(fiscomedData.dataFattura).format('DD/MM/YYYY')}
        </small>
      </div>
      <br />
      <br />
      <Row>
        <Col xs="6">
          <span className="text-muted">Fornitore</span>
          <h5 className="font-medium">
            {fiscomedData.clienteAttivo?.ragione_sociale}
          </h5>
          <h6 className="text-muted">{fiscomedData.clienteAttivo?.indirizzo}</h6>
          <h6 className="text-muted">
            {fiscomedData.clienteAttivo?.comune}, {fiscomedData.clienteAttivo?.cap}{' '}
            {fiscomedData.clienteAttivo?.provincia &&
              `(${fiscomedData.clienteAttivo?.provincia})`}
          </h6>
          <h6 className="text-muted">
            CF: {fiscomedData.clienteAttivo?.codice_fiscale}
          </h6>
          {fiscomedData.clienteAttivo?.partita_iva !== null && (
            <h6 className="text-muted">
              P.IVA: {fiscomedData.clienteAttivo?.partita_iva}
            </h6>
          )}
        </Col>
        <Col xs="6">
          <span className="text-muted">Committente</span>
          <h5 className="font-medium">
            {user.fisco_nome} {user.fisco_cognome}
          </h5>
          <h6 className="text-muted">{user.fisco_indirizzo}</h6>
          <h6 className="text-muted">
            {user.fisco_comune}, {user.fisco_cap} ({user.fisco_provincia})
          </h6>
          <h6 className="text-muted">CF: {user.fisco_cf}</h6>
          <h6 className="text-muted">P.IVA: {user.fisco_partita_iva}</h6>
        </Col>
      </Row>
      <div className="my-4">
        <br />
        <small className="font-regular">
          Tipologia documento: {fiscomedData.tipologiaAutofattura}, Fattura collegata{' '}
          {fiscomedData.idDocumentoAutofattura} del{' '}
          {fiscomedData.dataDocumentoAutofattura}
        </small>
        <br />
        <table className="table table-striped table-sm mt-2">
          <thead>
            <tr>
              <th scope="col" className="h6 font-medium">
                Descrizione
              </th>
              <th scope="col" className="h6 font-medium">
                Netto
              </th>
              <th scope="col" className="h6 font-medium">
                Aliquota
              </th>
              <th scope="col" className="h6 font-medium">
                IVA
              </th>
              <th scope="col" className="h6 font-medium">
                Totale
              </th>
            </tr>
          </thead>
          <tbody>{tabellaImporti()}</tbody>
        </table>
        <br />
        <div className="d-flex justify-content-end" style={{ minHeight: '240px' }}>
          <h4>Importo totale: {fiscomedData.totaleImportoAutofattura} €</h4>
        </div>
      </div>
      <Row>
        <Col xs="6">
          <h5 className="font-medium">Pagamento</h5>
        </Col>
        <Col xs="6" />
      </Row>

      <hr />
      <Row>
        <Col xs="6">
          {fiscomedData.metodoDiPagamentoAttivo === 'Contante' ? (
            <h5>Contante</h5>
          ) : (
            <>
              Intestatario: {fiscomedData.metodoDiPagamentoAttivo.intestatario}
              <br />
              IBAN: {fiscomedData.metodoDiPagamentoAttivo.iban}
            </>
          )}
        </Col>
        <Col xs="6" />
      </Row>
    </Card>
  )
}

export default PreviewAutofattura

import React, { useState } from 'react'
import moment from 'moment'
import {
  idrataBorse,
  caricaBorsa,
  cancellaBorsa,
  inviaModificaBorsa,
  scaricaBorsa,
} from '../utility/QueryImportaBorsa'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/user/selectors'

const ImportaBorsaContext = React.createContext()

const emptyState = {
  borse: [],
  annoDiRiferimento: moment().year(),
  annoCorrente: moment().year(),
  anniConBorseEmesse: [],
  loading: true,
  modalImportOpen: false,
  modalUpdateOpen: false,
  tipologia: 'borsa_specializzazione',
  borsaSelezionata: null,
  dataIncasso: null,
  importo: null,
  fileBorsa: null,
  upLoading: false,
}

// TODO: Alcuni setState settavano solo un campo ignorando ...state (precedenti valori)
// è stato cambiato a setState({...state, nomeCampo}), controllare se funziona tutto ok
// TODO: Alcune funzioni sono state rese async per aggiungere try/catch interno e settare loading: false
// controllare se i consumer devono essere aggiornati di conseguenza
const ImportaBorsaProvider = ({ children }) => {
  const [state, setState] = useState(emptyState)
  const user = useSelector(selectUser)

  const idrata = async () => {
    try {
      const responseBody = await idrataBorse(user, state.annoDiRiferimento)
      setState({
        ...state,
        loading: false,
        borse: responseBody.borse,
        anniConBorseEmesse: responseBody.anniConBorseEmesse,
      })
    } catch (e) {
      console.error(e.message)
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const impostaAnnoEIdrata = async (anno) => {
    setState({
      ...state,
      loading: true,
    })
    try {
      const responseBody = await idrataBorse(user, anno)
      setState({
        ...state,
        annoDiRiferimento: anno,
        borse: responseBody.borse,
        anniConBorsaEmessa: responseBody.anni_con_borsa_emessa,
        loading: false,
      })
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        loading: false,
      })
    }
  }

  const statoModalImport = (stato) => {
    setState({
      ...state,
      dataIncasso: null,
      tipologia: 'borsa_specializzazione',
      importo: null,
      fileBorsa: null,
      modalImportOpen: stato,
    })
  }

  const statoModalUpdate = (stato) => {
    setState({
      ...state,
      dataIncasso: null,
      importo: null,
      fileBorsa: null,
      modalUpdateOpen: stato,
    })
  }

  const impostaTipologia = (tipologia) => {
    setState({
      ...state,
      tipologia,
    })
  }

  const impostaAnnoIncasso = (anno) => {
    setState({
      ...state,
      dataIncasso: anno,
    })
  }

  const impostaImporto = (importo) => {
    setState({
      ...state,
      importo,
    })
  }

  const impostaFileBorsa = (file) => {
    setState({
      ...state,
      fileBorsa: file,
    })
  }

  const aggiungiBorsa = async () => {
    if (state.dataIncasso < 2010 || state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    if (state.fileBorsa === '' || state.fileBorsa === null) {
      alert('Seleziona un file borsa')
      return
    }
    if (state.fileBorsa.size / 1073741824 > 0.05) {
      alert('La dimensione del file non può superare i 20MB')
      return
    }
    const formData = new FormData()
    formData.append('tipologia', state.tipologia)
    formData.append('fileBorsa', state.fileBorsa)
    formData.append('token', user.auth_token)
    formData.append('dataIncasso', state.dataIncasso)
    formData.append(
      'importo',
      state.tipologia === 'borsa_specializzazione' ? 0 : state.importo
    )
    formData.append('anno', state.annoDiRiferimento)
    setState({ ...state, upLoading: true })
    try {
      const response = await caricaBorsa(formData)
      if (response) {
        setState({
          ...state,
          upLoading: false,
          borse: response.borse,
          anniConBorseEmesse: response.anniConBorseEmesse,
          modalImportOpen: false,
          dataIncasso: null,
          importo: null,
          fileBorsa: null,
          tipologia: 'borsa_specializzazione',
        })
      }
      return response
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        upLoading: false,
      })
    }
  }

  const cancellaFile = async (uuidFile) => {
    setState({ ...state, upLoading: true })
    try {
      const response = await cancellaBorsa(user, uuidFile, state.annoDiRiferimento)
      if (response) {
        setState({
          ...state,
          upLoading: false,
          borse: response.borse,
          anniConBorseEmesse: response.anniConBorseEmesse,
        })
      }
      return response
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        upLoading: false,
      })
    }
  }

  const modificaBorsa = async (uuidFile) => {
    const borsaSelezionata = state.borse.find(
      (borsa) => borsa.uuid_file === uuidFile
    )
    setState({
      ...state,
      modalUpdateOpen: true,
      borsaSelezionata,
      dataIncasso: borsaSelezionata.data_incasso,
      importo: borsaSelezionata.importo / 100,
      tipologia: borsaSelezionata.tipologia,
    })
  }

  const inviaModifica = async () => {
    if (state.dataIncasso < 2010 || state.dataIncasso > 2050) {
      alert('Imposta un anno di incasso compreso tra il 2010 e il 2050')
      return
    }
    setState({ ...state, upLoading: true })
    try {
      const response = await inviaModificaBorsa(
        user,
        state.borsaSelezionata.uuid_file,
        state.dataIncasso,
        state.importo,
        state.annoDiRiferimento,
        state.tipologia
      )
      if (response) {
        setState({
          ...state,
          upLoading: false,
          borse: response.borse,
          anniConBorseEmesse: response.anniConBorseEmesse,
          modalUpdateOpen: false,
          dataIncasso: null,
          importo: null,
          fileBorsa: null,
          tipologia: 'borsa',
        })
      }
      return response
    } catch (e) {
      console.error(e)
      setState({
        ...state,
        upLoading: false,
      })
    }
  }

  const scaricaFile = async (uuidFile, nomeFile) => {
    const response = await scaricaBorsa(user, uuidFile, nomeFile)
    return response
  }

  const context = {
    dati: state,
    idrata,
    statoModalImport,
    impostaAnnoEIdrata,
    impostaTipologia,
    impostaAnnoIncasso,
    impostaImporto,
    impostaFileBorsa,
    aggiungiBorsa,
    cancellaFile,
    modificaBorsa,
    statoModalUpdate,
    inviaModifica,
    scaricaFile,
  }

  return (
    <ImportaBorsaContext.Provider value={context}>
      {typeof children === 'function' ? children(context) : children}
    </ImportaBorsaContext.Provider>
  )
}

export { ImportaBorsaProvider as default, ImportaBorsaContext }

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsLoadingUser, selectIsLoggedIn } from '../app/store/user/selectors'

const OnlyNotLogged = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isLoading = useSelector(selectIsLoadingUser)
  const history = useHistory()
  const [attivo, setAttivo] = useState(false)

  useEffect(() => {
    if (isLoading === false) {
      setAttivo(false)
      if (isLoggedIn) {
        history.push('/fiscomed')
      } else {
        setAttivo(true)
      }
    }
  }, [isLoading, isLoggedIn])

  return <>{attivo && children}</>
}

export default OnlyNotLogged

import * as Sentry from '@sentry/react'

export const userMiddleware = (store) => (next) => (action) => {
  const result = next(action)

  if (action.type === 'user/saveUserInfo') {
    const state = store.getState()
    const user = state.user.user
    localStorage.setItem('userContext', JSON.stringify(user))
    Sentry.setUser({ email: user.email })
  }

  if (action.type === 'user/logout') {
    localStorage.removeItem('userContext')
    Sentry.configureScope((scope) => scope.setUser(null))
  }

  return result
}

import React from 'react'
import { useHistory } from 'react-router-dom'
import {
  Container,
  Card,
  Col,
  Row,
  CardBody,
  FormGroup,
  Button,
  CardTitle,
} from 'reactstrap'
import request from 'superagent'
import swal from 'sweetalert'
import { Helmet } from 'react-helmet'
import { useSelector } from 'react-redux'
import { selectUser } from '../../app/store/user/selectors'

// Descrizione di 184 caratteri più ... (3 caratteri)
const FiscoDemiaHome = () => {
  const user = useSelector(selectUser)
  const history = useHistory()
  const [testo, setTesto] = React.useState('')
  const [view, setView] = React.useState('video')

  const openVideo = (title) => {
    history.push('/fiscodemia/video/' + title)
  }

  const inviaSuggerimento = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/fiscodemia/mi-piacerebbe-vedere`)
      .send({ token: user.auth_token, testo })
      .then(() => {
        setTesto('')
        swal({
          icon: 'success',
          title: 'Grazie',
          text: 'Siamo contenti che tu abbia condiviso con noi la tua idea. Ne parliamo con i nostri commercialisti e, chissà, fra qualche tempo potresti trovare un nuovo video in Fiscodèmia',
        })
      })
      .catch((e) => console.error(e.message))
  }

  return (
    <Container>
      <Helmet>
        <title>Fiscodèmia</title>
        <meta property="title" content="Fiscodèmia" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Card className="my-4">
        <CardBody>
          <div className="float-end">
            <Button
              className={view === 'video' ? 'bottone-blu' : 'bottone-blu-outline'}
              onClick={() => setView('video')}
              size={view === 'video' ? 'lg' : 'sm'}
            >
              Video
            </Button>
            <Button
              onClick={() => setView('guide')}
              className={
                view === 'guide' ? 'ms-3 bottone-blu' : 'ms-3 bottone-blu-outline'
              }
              size={view === 'guide' ? 'lg' : 'sm'}
            >
              Guide
            </Button>
          </div>
          <h4 className="font-medium">
            <i className="bi bi-bank"></i> Fiscodèmia
          </h4>
          <h6 className=" mt-3 font-light interlinea-30">
            Fiscodèmia è l&apos;accademia fiscale di Fiscomed.
            <br />
            Qui puoi trovare:
            <ul>
              <li>
                tanti video educativi con l&apos;obiettivo di aiutarti a capire come
                fare le procedure più importanti per te
              </li>
              <li>
                le guide scritte dalle Dottoresse e dai Dottori Commercialisti del
                nostro team
              </li>
            </ul>
          </h6>
        </CardBody>
      </Card>
      {view === 'video' ? (
        <Row>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/introduzione.png"
                className="rounded-top"
                onClick={() => openVideo('introduzione')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Tutto quello che puoi fare da Fiscomed
                </h4>
                <h6 className="font-light interlinea-30">
                  Fiscomed non è solo un software di fatturazione, ma è un vero e
                  proprio supporto digitale nella gestione della tua Partita IVA. In
                  questo video Giulia ti mostra come sfruttare al meglio tutte le sue
                  potenzialità 😊
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/marcadabollo.png"
                className="rounded-top"
                onClick={() => openVideo('pagamentomarcadabollo')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Fatture cartacee, elettroniche e imposta di bollo
                </h4>
                <h6 className="font-light interlinea-30">
                  In questo video Giulia ti spiega in parole semplici come funziona
                  l&apos;imposta di bollo, sia per quanto riguarda le fatture
                  cartacee che quelle elettroniche.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/marcadabollo2.png"
                className="rounded-top"
                onClick={() => openVideo('pagamentomarcadabollo2')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Come pagare l&apos;imposta di bollo dal sito dell&apos;AdE
                </h4>
                <h6 className="font-light interlinea-30">
                  Per pagare l&apos;imposta di bollo delle fatture elettroniche
                  bisogna fare un versamento cumulativo trimestrale e il modo più
                  facile e veloce è dal sito dell&apos;Agenzia delle Entrate. Giulia
                  ti fa vedere passo dopo passo come si fa.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/conservazione.webp"
                className="rounded-top"
                onClick={() => openVideo('conservazioneconsultazione')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Come attivare la conservazione delle fatture elettroniche
                </h4>
                <h6 className="font-light interlinea-30">
                  La conservazione è una funzionalità molto importante che devi
                  attivare dal sito dell&apos;Agenzia delle Entrate. Dai
                  un&apos;occhiata a questo video per scoprire quali passi devi fare.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/sts1.png"
                className="rounded-top"
                onClick={() => openVideo('sts1')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Sistema TS: cos’è, scadenze e cosa fare se non si rispettano
                </h4>
                <h6 className="font-light interlinea-30">
                  Quando un paziente ti paga per una prestazione sanitaria, i tuoi
                  adempimenti fiscali non finiscono con l’emissione della fattura. In
                  questo video, Giulia ti spiega che cos’è il Sistema Tessera
                  Sanitaria, come funziona e quali sono le sue scadenze
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/sts2.png"
                className="rounded-top"
                onClick={() => openVideo('sts2')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Sistema TS: come ottenere le credenziali
                </h4>
                <h6 className="font-light interlinea-30">
                  Nel primo video ti abbiamo spiegato quando e perché bisogna inviare
                  i dati delle fatture al Sistema Tessera Sanitaria. Per farlo, però,
                  ti servono delle credenziali. In questo video Giulia ti mostra come
                  fare l’iscrizione
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/sts3.png"
                className="rounded-top"
                onClick={() => openVideo('sts3')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Sistema TS: come inviare i dati con Fiscomed
                </h4>
                <h6 className="font-light interlinea-30">
                  Dopo aver fatto l’iscrizione al Sistema Tessera Sanitaria, puoi
                  iniziare a inviare i dati delle fatture da Fiscomed. Per farlo hai
                  due scelte: usare la sezione dedicata, oppure compilare la
                  schermata alla quale puoi accedere dopo aver fatto una fattura
                  cartacea direttamente da Fiscomed
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/fattura1.png"
                className="rounded-top"
                onClick={() => openVideo('fattura1')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Fattura cartacea, elettronica e proforma
                </h4>
                <h6 className="font-light interlinea-30">
                  Fattura cartacea o fattura elettronica? Questo è il dilemma. Dopo
                  che avrai visto questo video saprai con sicurezza quale fattura va
                  fatta in ogni occasione.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/fattura2.png"
                className="rounded-top"
                onClick={() => openVideo('fattura2')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">Fiscomed: come fare una fattura</h4>
                <h6 className="font-light interlinea-30">
                  In questo video Giulia ti mostra come fare le fatture cartacee,
                  elettroniche e verso la Pubblica Amministrazione. Anche se la prima
                  fattura mette sempre un po&apos; d&apos;ansia, sappi che con
                  Fiscomed è molto più semplice di quello che pensi.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/fattura3.png"
                className="rounded-top"
                onClick={() => openVideo('fattura3')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Cosa posso fare da &quot;Riepilogo fatture&quot;?
                </h4>
                <h6 className="font-light interlinea-30">
                  &quot;Riepilogo fatture&quot; è una sezione che devi assolutamente
                  imparare a conoscere. Da qui puoi controllare tutte le fatture che
                  hai emesso, scaricarle e monitorare gli esiti dello SdI
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/F24_1.png"
                className="rounded-top"
                onClick={() => openVideo('f24_1')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Modello F24: cos&apos;è e come si paga
                </h4>
                <h6 className="font-light interlinea-30">
                  L&apos;F24 è il modello con cui si pagano le imposte. Anche se
                  dovrai solo ricopiare il facsimile preparato dal tuo
                  commercialista, è importante che tu sappia che cos&apos;è un F24 e
                  come si paga.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/F24_2.png"
                className="rounded-top"
                onClick={() => openVideo('f24_2')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Come si paga un F24 con l&apos;home banking
                </h4>
                <h6 className="font-light interlinea-30">
                  In questo video, Giulia ti da qualche suggerimento per pagare i
                  tuoi F24 con il tuo home banking.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/F24_3.png"
                className="rounded-top"
                onClick={() => openVideo('f24_3')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  F24 e Agenzia delle Entrate: tutti gli step da seguire
                </h4>
                <h6 className="font-light interlinea-30">
                  Guarda questo video per scoprire passo passo cosa devi fare per
                  pagare i tuoi F24 dal sito dell&apos;Agenzia delle Entrate
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/modello_d1.webp"
                className="rounded-top"
                onClick={() => openVideo('modello_d1')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Modello D, aliquote e contribuzione ridotta
                </h4>
                <h6 className="font-light interlinea-30">
                  A luglio c&apos;è una scadenza molto importante: l&apos;invio del
                  Modello D ENPAM. Ma di che cosa si tratta? La risposta è in questo
                  video.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/modello_d2.webp"
                className="rounded-top"
                onClick={() => openVideo('modello_d2')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">Come compilare il Modello D ENPAM</h4>
                <h6 className="font-light interlinea-30">
                  In questo video tutorial, Giulia ti mostra i passaggi da fare per
                  compilare e inviare correttamente il Modello D dalla tua area
                  riservata ENPAM.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/fatture_estere.png"
                className="rounded-top"
                onClick={() => openVideo('fatture_estere')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">Fatture estere e IVA</h4>
                <h6 className="font-light interlinea-30">
                  Comprare da privato, quindi senza utilizzare la propria Partita
                  IVA, è la scelta migliore per chi è in regime forfettario. Questo
                  perché acquistare con la propria Partita IVA può significare dover
                  fare degli adempimenti fiscali in più. Giulia te ne parla in questo
                  video.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/autofatture.png"
                className="rounded-top"
                onClick={() => openVideo('autofatture')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Come emettere un&apos;autofattura da Fiscomed
                </h4>
                <h6 className="font-light interlinea-30">
                  Ogni volta che ricevi una fattura estera dove è indicata la tua
                  Partita IVA o una fattura italiana con la dicitura &quot;inversione
                  contabile&quot;, devi emettere un&apos;autofattura. Come si fa?
                  Guarda questo video per scoprirlo.
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/delega.png"
                className="rounded-top"
                onClick={() => openVideo('cassettofiscale')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Come delegarci al tuo cassetto fiscale
                </h4>
                <h6 className="font-light interlinea-30">
                  Il cassetto fiscale è uno spazio dell&apos;Agenzia delle Entrate in
                  cui ci sono tutte le tue informazioni fiscali. Delegarci
                  all&apos;accesso è un passo super importante, perché ci permette di
                  fare la dichiarazione in modo più semplice
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/rimborsi.png"
                className="rounded-top"
                onClick={() => openVideo('rimborsi')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Quando e come indicare i rimborsi spesa
                </h4>
                <h6 className="font-light interlinea-30">
                  Se ti capita di sostenere delle spese &quot;in nome e per conto del
                  cliente&quot;, puoi inserire in fattura il rimborso che devi
                  ricevere. Guarda questo video per imparare a farlo nel modo giusto
                </h6>
              </CardBody>
            </Card>
          </Col>
          <Col md={6}>
            <Card className="rounded-top mb-5">
              <img
                alt="Sample"
                src="/fiscodemia/cover/gestione_separata.png"
                className="rounded-top"
                onClick={() => openVideo('gestione_separata')}
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
              <CardBody>
                <h4 className="font-regular">
                  Gestione Separata INPS: cos’è e come iscriversi
                </h4>
                <h6 className="font-light interlinea-30">
                  Tutti i medici in formazione specialistica devono versare i
                  contributi alla Gestione Separata INPS. Nonostante il versamento
                  sia automatico, l’università potrebbe chiederti di fare
                  l’iscrizione in autonomia. Guarda questo video per scoprire come
                  fare.
                </h6>
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col md={4}>
            <a
              href="/documenti/guida_fiscale.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Sample"
                src="/fiscodemia/cover/guida_fiscale.webp"
                className="rounded"
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
            </a>
          </Col>
          <Col md={4}>
            <a
              href="/documenti/riscatto_di_laurea.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Sample"
                src="/fiscodemia/cover/riscatto_di_laurea.webp"
                className="rounded"
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
            </a>
          </Col>
          <Col md={4}>
            <a
              href="/documenti/riepilogo_prima_consulenza.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Sample"
                src="/fiscodemia/cover/riepilogo.jpg"
                className="rounded"
                style={{ cursor: 'pointer', height: '100%', width: '100%' }}
              />
            </a>
          </Col>
        </Row>
      )}

      <Card className="my-5">
        <CardBody>
          <CardTitle className="h3 font-grigio-scuro">
            Hai bisogno di un video o di una guida in particolare?
          </CardTitle>
          <p className="font-grigio-scuro">
            Se c&apos;è una procedura che vorresti spiegata o, in generale, un
            argomento fiscale che vorresti capire, inviaci un suggerimento con questo
            form. Prenderemo in considerazione la tua idea: potrebbe essere utile a
            tante persone 😊
          </p>
          <FormGroup className="mt-4">
            <textarea
              className="form-control mt-4"
              rows="5"
              value={testo}
              placeholder="Mi piacerebbe vedere un video sulla compilazione del modello D..."
              onChange={(e) => setTesto(e.target.value)}
            />
          </FormGroup>
          <br />
          <Button
            className="bottone-blu float-end"
            onClick={() => inviaSuggerimento()}
          >
            INVIA IL TUO SUGGERIMENTO
          </Button>
        </CardBody>
      </Card>
    </Container>
  )
}

export default FiscoDemiaHome

import moment from 'moment'

export const emptyState = {
  metodoDiPagamento: [],
  metodoDiPagamentoAttivo: 'Contante',
  clienti: [],
  clienteAttivo: {},
  prestazioni: [],
  prestazioniAutofattura: [],
  tipologiaFattura: 'Fattura elettronica',
  numeroFattura: 0,
  numeroAutofattura: 0,
  nota: '',
  notaPresente: false,
  addebitoMarcaDaBollo: false,
  identificativoMarcaDaBollo: '',
  loading: false,
  totaleImporto: 0,
  totaleImportoAutofattura: 0,
  IVATotale: 0,
  tipologiaAutofattura: 'TD17',
  idDocumentoAutofattura: '',
  dataDocumentoAutofattura: '',
  datiAggiuntivi: {
    idDocumento: '',
    data: '',
    codiceCIG: '',
    codiceCUP: '',
    codiceCommessa: '',
    contributoCassa: '',
    aliquotaCassa: '',
    rifAmministrazione: '',
    scadenzaPagamento: '',
    allegato: false,
    allegatoFile: {
      nome: '',
      base64: '',
      formato: '',
    },
  },
  fatturaDiRiferimento: {
    id: '',
  },
  clienteDiRiferimento: {
    id: '',
  },
  descrizioneNotaDiVariazione: 'Errore di calcolo',
  importoNotaDiVariazione: 0,
  dataFattura: moment().format('YYYY-MM-DD'),
}

export const idrataFatturaObject = (response) => ({
  clienti: response.clienti,
  metodoDiPagamento: response.metodo_di_pagamento,
  numeroFattura: response.numero_fattura,
  numeroAutofattura: response.numero_autofattura,
  clienteAttivo: response.clienti[0],
})

export const idrataNDVariazioneObject = (response) => ({
  clienti: response.clienti,
  metodoDiPagamento: response.metodo_di_pagamento,
  numeroFattura: response.numero_fattura,
  numeroAutofattura: response.numero_autofattura,
  clienteAttivo: response.clienti[0],
  fatturaDiRiferimento: response.fattura_di_riferimento,
  clienteDiRiferimento: response.cliente_di_riferimento,
})

export const idrataFatturaDiRettificaObject = (response) => ({
  clienti: response.clienti,
  metodoDiPagamento: response.metodo_di_pagamento,
  numeroFattura: response.fattura_di_riferimento.numero,
  clienteAttivo: response.clienti[0],
  fatturaDiRiferimento: response.fattura_di_riferimento,
})

export const aggiornaTipologiaFatturaObject = (tipologiaFattura) => ({
  tipologiaFattura,
  totaleImporto: 0,
  prestazioni: [],
  metodoDiPagamentoAttivo: 'Contante',
  nota: '',
  notaPresente: false,
  addebitoMarcaDaBollo: false,
  identificativoMarcaDaBollo: '',
  datiAggiuntivi: {
    idDocumento: '',
    data: '',
    codiceCIG: '',
    codiceCUP: '',
    codiceCommessa: '',
    contributoCassa: '',
    aliquotaCassa: '',
    rifAmministrazione: '',
    scadenzaPagamento: '',
  },
})

export const emptyStateIdratato = (stato) => ({
  ...stato,
  totaleImporto: 0,
  prestazioni: [],
  nota: '',
  notaPresente: false,
  addebitoMarcaDaBollo: false,
  identificativoMarcaDaBollo: '',
  datiAggiuntivi: {
    idDocumento: '',
    data: '',
    codiceCIG: '',
    codiceCUP: '',
    codiceCommessa: '',
    contributoCassa: '',
    aliquotaCassa: '',
    rifAmministrazione: '',
    scadenzaPagamento: '',
  },
  prestazioniAutofattura: [],
  totaleImportoAutofattura: 0,
  IVATotale: 0,
  tipologiaAutofattura: 'TD17',
  idDocumentoAutofattura: '',
  dataDocumentoAutofattura: '',
  dataFattura: moment().format('YYYY-MM-DD'),
})

export const proformaObject = (stato) => ({
  cliente: stato.clienteAttivo,
  prestazioni: stato.prestazioni,
  metodo_di_pagamento: stato.metodoDiPagamentoAttivo,
  nota: stato.nota,
  addebito_marca_da_bollo: stato.addebitoMarcaDaBollo,
})

export const fatturaCartaceaObject = (stato) => ({
  cliente: stato.clienteAttivo,
  prestazioni: stato.prestazioni,
  metodo_di_pagamento: stato.metodoDiPagamentoAttivo,
  nota: stato.nota,
  nota_presente: stato.notaPresente,
  addebito_marca_da_bollo: stato.addebitoMarcaDaBollo,
  identificativo_marca_da_bollo: stato.identificativoMarcaDaBollo,
  numeroFattura: stato.numeroFattura,
  dataCreazione: stato.dataFattura,
})

export const fatturaElettronicaObject = (stato) => ({
  numeroFattura: stato.numeroFattura,
  dataCreazione: stato.dataFattura,
  cliente: stato.clienteAttivo,
  pagamentoAttivo: stato.metodoDiPagamentoAttivo,
  totaleImporto: stato.totaleImporto,
  prestazioni: stato.prestazioni,
  marcaDaBollo: stato.addebitoMarcaDaBollo,
  numeroMarca: stato.identificativoMarcaDaBollo,
  notaPresente: stato.notaPresente,
  nota: stato.nota,
  tipoFattura: 'Fattura Elettronica',
  datiAggiuntivi: stato.datiAggiuntivi,
})

export const fatturaPAObject = (stato) => ({
  numeroFattura: stato.numeroFattura,
  dataCreazione: stato.dataFattura,
  cliente: stato.clienteAttivo,
  pagamentoAttivo: stato.metodoDiPagamentoAttivo,
  totaleImporto: stato.totaleImporto,
  prestazioni: stato.prestazioni,
  marcaDaBollo: stato.addebitoMarcaDaBollo,
  numeroMarca: stato.identificativoMarcaDaBollo,
  notaPresente: stato.notaPresente,
  nota: stato.nota,
  tipoFattura: 'Fattura PA',
  datiAggiuntivi: stato.datiAggiuntivi,
})

export const rettificaPrObject = (stato) => ({
  numeroFattura: stato.numeroFattura,
  cliente: stato.clienteAttivo,
  pagamentoAttivo: stato.metodoDiPagamentoAttivo,
  totaleImporto: stato.totaleImporto,
  prestazioni: stato.prestazioni,
  marcaDaBollo: stato.addebitoMarcaDaBollo,
  numeroMarca: stato.identificativoMarcaDaBollo,
  notaPresente: stato.notaPresente,
  nota: stato.nota,
  tipoFattura: 'Rettifica Pr',
  datiAggiuntivi: stato.datiAggiuntivi,
})

export const notaDiCreditoPrObject = (stato) => ({
  numeroFattura: stato.numeroFattura,
  totaleImporto: stato.importoNotaDiVariazione,
  marcaDaBollo: stato.addebitoMarcaDaBollo,
  notaPresente: stato.notaPresente,
  nota: stato.nota,
  numeroFatturaDiRiferimento: stato.fatturaDiRiferimento.numero,
  dataFatturaDiRiferimento: moment(stato.fatturaDiRiferimento.data_emissione).format(
    'DD/MM/YYYY'
  ),
  descrizioneNota: stato.descrizioneNotaDiVariazione,
  cliente: stato.clienteDiRiferimento,
  datiAggiuntivi: stato.datiAggiuntivi,
})

export const notaDiCreditoPAObject = (stato) => ({
  numeroFattura: stato.numeroFattura,
  totaleImporto: stato.importoNotaDiVariazione,
  marcaDaBollo: stato.addebitoMarcaDaBollo,
  notaPresente: stato.notaPresente,
  nota: stato.nota,
  numeroFatturaDiRiferimento: stato.fatturaDiRiferimento.numero,
  dataFatturaDiRiferimento: moment(stato.fatturaDiRiferimento.data_emissione).format(
    'DD/MM/YYYY'
  ),
  descrizioneNota: stato.descrizioneNotaDiVariazione,
  cliente: stato.clienteDiRiferimento,
})

export const notaDiDebitoPrObject = (stato) => ({
  numeroFattura: stato.numeroFattura,
  totaleImporto: stato.importoNotaDiVariazione,
  marcaDaBollo: stato.addebitoMarcaDaBollo,
  notaPresente: stato.notaPresente,
  nota: stato.nota,
  numeroFatturaDiRiferimento: stato.fatturaDiRiferimento.numero,
  dataFatturaDiRiferimento: moment(stato.fatturaDiRiferimento.data_emissione).format(
    'DD/MM/YYYY'
  ),
  descrizioneNota: stato.descrizioneNotaDiVariazione,
  cliente: stato.clienteDiRiferimento,
})

export const autofatturaObject = (stato) => ({
  numeroAutofattura: stato.numeroAutofattura,
  dataCreazione: stato.dataFattura,
  cliente: stato.clienteAttivo,
  pagamentoAttivo: stato.metodoDiPagamentoAttivo,
  totaleImporto: stato.totaleImportoAutofattura,
  prestazione: stato.prestazioniAutofattura[0],
  tipoFattura: 'Autofattura',
  IVATotale: stato.IVATotale,
  tipoDocumento: stato.tipologiaAutofattura,
  numeroFatturaDiRiferimento: stato.idDocumentoAutofattura,
  dataFatturaDiRiferimento: stato.dataDocumentoAutofattura,
})

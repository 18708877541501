import React, { useEffect, useState } from 'react'
import request from 'superagent'
import Loading from './Loading'
import { Helmet } from 'react-helmet'
import Onboarding from './Onboarding'
import TsContainer from './TsContainer'
import { useSelector } from 'react-redux'
import { selectIsLoadingUser, selectUser } from '../../app/store/user/selectors'

const MainContainer = () => {
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectIsLoadingUser)
  const [infoFetched, setInfoFetched] = useState(false)
  const [credenziali, setCredenziali] = useState({
    codiceFiscale: '',
    password: '',
    pincode: '',
    sistemaAttivo: false,
  })
  const [caricamentoCredenziali, setCaricamentoCredenziali] = useState(true)

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
      }
    }
  }, [isLoading])

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/sistemats/credenziali`)
      .send({ token: user.auth_token })
      .then((response) => {
        setCredenziali(response.body.credenziali)
        setCaricamentoCredenziali(false)
      })
      .catch((e) => console.error(e.message))
  }

  const terminaOnboarding = () => {
    setCredenziali({ ...credenziali, sistemaAttivo: true })
  }

  return (
    <div>
      <Helmet>
        <title>Sistema TS</title>
        <meta property="title" content="Sistema TS" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {caricamentoCredenziali ? (
        <Loading />
      ) : credenziali.sistemaAttivo ? (
        <TsContainer credenziali={credenziali} impostaCredenziali={setCredenziali} />
      ) : (
        <Onboarding termina={() => terminaOnboarding()} />
      )}
    </div>
  )
}

export default MainContainer

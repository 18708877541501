import React, { useState, useEffect } from 'react'
import request from 'superagent'
import { Button, Container, Spinner, Row, Col, Card } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectIsLoadingUser, selectUser } from '../../app/store/user/selectors'

const Abbonamento = () => {
  const user = useSelector(selectUser)
  const isLoading = useSelector(selectIsLoadingUser)
  const [reindirizzamento, setReindirizzamento] = useState(false)
  const [infoFetched, setInfoFetched] = useState(false)
  const [azzeraAbbonamento, setAzzeraAbbonamento] = useState({})

  useEffect(() => {
    if (!isLoading) {
      if (infoFetched === false) {
        setInfoFetched(true)
        fetchInfo()
      }
    }
  }, [isLoading])

  const fetchInfo = () => {
    request
      .post(`${process.env.REACT_APP_API_URL}/abbonamento/info`)
      .send({ token: user.auth_token })
      .then((response) => {
        setAzzeraAbbonamento(response.body)
      })
      .catch((e) => console.error(e.message))
  }

  const reindirizza = () => {
    setReindirizzamento(true)
    request
      .post(`${process.env.REACT_APP_API_URL}/abbonamento/url-portale-utente`)
      .send({ token: user.auth_token })
      .then((response) => {
        window.location.replace(response.body.url_portale)
      })
      .catch((e) => console.error(e.message))
  }

  return (
    <Container className="mt-4 mb-5">
      <Helmet>
        <title>Abbonamento</title>
        <meta property="title" content="Abbonamento" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Card body>
        {reindirizzamento ? (
          <center>
            <div className="mt-5">
              <h1>
                <Spinner type="grow" color="success" />
              </h1>
              <br />
              <br />
              <h3 className="font-medium font-grigio-scuro">
                Ti stiamo reindirizzando in un&apos;area sicura di Stripe, il portale
                di pagamento utilizzato da fiscomed.it
              </h3>
            </div>
          </center>
        ) : (
          <Row>
            <Col xs={3}>
              <img src="/img/fisco/abbonamento.webp" />
            </Col>
            <Col xs={9}>
              <h4 className="font-medium">Abbonamento</h4>
              <h6 className="mt-4 interlinea-30 font-regular">
                {user.moroso ? (
                  <>
                    <span className="testo-rosso">PAGAMENTO NON RIUSCITO</span>
                  </>
                ) : (
                  <>
                    l tuo abbonamento è{' '}
                    <span className="testo-verde font-bold">ATTIVO</span>
                  </>
                )}
                <br />
                <br />
                Per modificare il <b className="font-bold">metodo di pagamento</b>,
                cambiare l&apos;
                <b className="font-bold">indirizzo di fatturazione</b>, vedere
                l&apos;<b className="font-bold">importo</b> dell&apos; abbonamento
                attivo, visualizzare la <b className="font-bold">data</b> del
                prossimo accredito e{' '}
                <b className="font-bold">scaricare le fatture</b> clicca sul tasto
                &quot;Gestisci abbonamento&quot;. Sarai reindirizzato sul sito di
                Stripe, il servizio di pagamento sicuro al quale si affida
                Fiscomed.it
              </h6>
              <Button className="bottone-blu mt-3" onClick={() => reindirizza()}>
                GESTISCI ABBONAMENTO
              </Button>
              <h4 className="font-medium mt-5">Azzera il tuo abbonamento</h4>
              <h6 className="mt-4 interlinea-30 font-regular">
                Invita in Fiscomed colleghi, amici, conoscenti e chi vuoi tu e
                risparmiate sulla vostra gestione fiscale.
                <br />
                Per scoprire come funziona l&apos;iniziativa, clicca{' '}
                <Link to="/codice-collega">qui</Link>.
              </h6>
              <br />
              <Row>
                <Col xs={4}>
                  <div
                    style={{
                      textAlign: 'center',
                      border: 'solid 3px #f2b705',
                      borderRadius: '10px',
                    }}
                    className="mx-3"
                  >
                    <span style={{ fontSize: '60px' }} className="mt-3">
                      💸
                    </span>
                    <h2 className="mt-3 interlinea-30 font-regular">
                      {azzeraAbbonamento.credito_residuo}
                    </h2>
                    <h6 className="mb-3 interlinea-30">Euro accumulati</h6>
                  </div>
                </Col>
                <Col xs={4}>
                  <div
                    style={{
                      textAlign: 'center',
                      border: 'solid 3px #f2b705',
                      borderRadius: '10px',
                    }}
                    className="mx-3"
                  >
                    <span style={{ fontSize: '60px' }} className="mt-3">
                      👩‍⚕️
                    </span>
                    <h2 className="mt-3 interlinea-30 font-regular">
                      {azzeraAbbonamento.colleghi_convinti}
                    </h2>
                    <h6 className="mb-3 interlinea-30">Persone invitate</h6>
                  </div>
                </Col>
                <Col xs={4}>
                  <div
                    style={{
                      textAlign: 'center',
                      border: 'solid 3px #f2b705',
                      borderRadius: '10px',
                    }}
                    className="mx-3"
                  >
                    <span style={{ fontSize: '60px' }} className="mt-3">
                      💰
                    </span>
                    <h2 className="mt-3 interlinea-30 font-regular">
                      {azzeraAbbonamento.soldi_risparmiati}
                    </h2>
                    <h6 className="mb-3 interlinea-30">Euro risparmiati</h6>
                  </div>
                </Col>
              </Row>
              <h5 className="mt-5 interlinea-30 font-medium">
                Vuoi disattivare il tuo abbonamento?
              </h5>
              <h6 className="mt-4 interlinea-30 font-regular">
                Scrivici un&apos;email o inviaci un messaggio su WhatsApp:
                raccoglieremo tutte le tue fatture per inviartele e comunicheremo la
                cessazione della consulenza fiscale al tuo commercialista
              </h6>
              <br />
            </Col>
          </Row>
        )}
      </Card>
    </Container>
  )
}

export default Abbonamento

import React from 'react'
import { Button, Progress, Card } from 'reactstrap'
import NonSaiRispondere from '../NonSaiRispondere'
import Ricomincia from '../Ricomincia'

const PerditaRequisiti = ({ setScheda }) => (
  <Card body className="h-100 d-flex d-column p-5">
    <Progress value={80} className="progress-gialla" />
    <br />
    <br />
    <h4 className="testo-verde font-bold mb-4">
      SEMBRA CHE SIA ARRIVATO PER TE IL MOMENTO DI PAGARE L&apos;ALIQUOTA INTERA DEL
      19,50%
    </h4>

    <h6 className="font-regular mb-3 interlinea-30">
      Dato che in passato hai beneficiato di un&apos;aliquota differente, ma non hai
      più i requisiti per farlo, <b>devi fare una comunicazione all&apos;ENPAM</b>.
      <br />
      <br />
      Ecco gli <b>step</b> da seguire:
      <br />
      1. Accedi alla tua <b>Area Riservata</b>
      <br />
      2. Nella colonna di sinistra, clicca su &quot;
      <b>Domande e dichiarazioni online</b>&quot;
      <br />
      3. Sempre nella colonna a sinistra, clicca su &quot;
      <b>Contribuzione Ridotta</b>&quot;.
      <br />
      4. Clicca su &quot;<b>Compila un nuovo modulo</b>&quot;.
      <br />
      5. Compila il campo relativo alla perdita del diritto alla contribuzione
      ridotta, scrivendo il <b>mese</b> e l&apos;<b>anno</b> in cui hai terminato
      l&apos;attività.
      <br />
      6. <b>Invia</b> il modulo.
      <br />
      <br />
      Dopo che hai fatto questa procedura, clicca sul tasto &quot;<b>avanti</b>
      &quot;.
    </h6>
    <br />
    <br />
    <div className="mt-auto mb-3">
      <Ricomincia setScheda={setScheda} />
      <Button
        className="bottone-blu float-end"
        style={{ width: '130px' }}
        onClick={() => setScheda('modello_d')}
      >
        AVANTI <i className="bi bi-arrow-right-short"></i>
      </Button>
      <NonSaiRispondere />
    </div>
  </Card>
)

export default PerditaRequisiti

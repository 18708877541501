import React from 'react'
import { Alert, Table } from 'reactstrap'
import ContainerEsito from './Esiti/ContainerEsito'
import ContainerOpzioni from './Opzioni/ContainerOpzioni'
import ContainerIncasso from './Incasso/ContainerIncasso'
import { useSelector } from 'react-redux'
import { selectRiepilogoFattureData } from '../../app/store/riepilogoFatture/selectors'

const Tabella = () => {
  const dati = useSelector(selectRiepilogoFattureData)

  const tabella = dati.fatture.map((element, i) => (
    <tr key={i}>
      <td>
        {element.fattura.numero}
        {element.fattura.tipologia === 'Autofattura' && 'A'}
      </td>
      <td>
        {element.cliente.length >= 30
          ? element.cliente.substring(0, 21) + '...'
          : element.cliente}
      </td>
      <td>{element.fattura.totale} €</td>
      <td>
        <ContainerIncasso
          incassata={element.fattura.incassata}
          id={element.fattura.id}
          dati={element}
        />
      </td>
      <td>
        {element.fattura.tipologia === 'Fattura elettronica' && 'Elettronica'}
        {element.fattura.tipologia === 'Fattura cartacea' && 'Cartacea'}
        {element.fattura.tipologia === 'Fattura PA' && 'PA'}
        {element.fattura.tipologia === 'Nota di credito' && 'NDC'}
        {element.fattura.tipologia === 'Nota di debito' && 'NDD'}
        {element.fattura.tipologia === 'Nota di debito PA' && 'NDD PA'}
        {element.fattura.tipologia === 'Nota di credito PA' && 'NDC PA'}
        {element.fattura.tipologia === 'Autofattura' && 'Autofattura'}
      </td>
      <td>
        {element.fattura.inviata_ts && (
          <i className="bi bi-check-lg testo-verde"></i>
        )}
        {element.fattura.tipologia === 'Fattura cartacea' &&
          !element.fattura.inviata_ts && <i className="bi bi-x testo-rosso"></i>}
      </td>
      <td>
        <ContainerEsito dati={element} />
      </td>
      <td>
        <ContainerOpzioni dati={element} />
      </td>
    </tr>
  ))

  return (
    <>
      {dati.fatture.length === 0 ? (
        <Alert color="info">
          <i className="bi bi-info-circle"></i> Nessuna fattura emessa, controlla
          negli anni precedenti
        </Alert>
      ) : (
        <Table size="sm" bordered className="table-gialla-bianca">
          <thead>
            <tr>
              <th className="h6 font-regular">Numero</th>
              <th className="h6 font-regular">Cliente</th>
              <th className="h6 font-regular">Importo</th>
              <th className="h6 font-regular">Incasso</th>
              <th className="h6 font-regular">Formato</th>
              <th className="h6 font-regular">TS</th>
              <th className="h6 font-regular">Esito</th>
              <th className="h6 font-regular">Strumenti</th>
            </tr>
          </thead>
          <tbody>{tabella}</tbody>
        </Table>
      )}
    </>
  )
}

export default Tabella

import React from 'react'
import { Container, Row, Col } from 'reactstrap'

const Chiuso = () => (
  <Container>
    <Row>
      <Col xs={5}>
        <center>
          <img
            src="/img/dichiarazione/fine.webp"
            className="mt-5"
            style={{ width: '100%' }}
          />
        </center>
      </Col>
      <Col xs={7}>
        <br />
        <h1 className="font-bold mt-5">Non è il momento</h1>
        <h6 className="font-regular mb-3 mt-4 interlinea-30">
          In questo riquadro, a partire dal 15 marzo, troverai un percorso a step per
          la dichiarazione dei redditi.
          <br />
          Ancora, però, non è il momento di pensarci: ecco perché questa sezione è
          &quot;chiusa&quot; da una copertina.
          <br />
          Ci vediamo a marzo 😊
          <br />
          <br />
        </h6>
        <h4 className="font-grigio-scuro font-bold">
          Hai aperto la Partita IVA quest&apos;anno?
        </h4>
        <h6 className="font-regular mt-4 mb-5 interlinea-30">
          In questo caso, puoi ignorare la sezione dedicata alla dichiarazione dei
          redditi fino al prossimo anno.
          <br />
          La dichiarazione, infatti, si fa a partire dall&apos;anno successivo a
          quello di apertura della Partita IVA.
        </h6>
        <br />
      </Col>
    </Row>
  </Container>
)

export default Chiuso

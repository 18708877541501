import request from 'superagent'
import swal from 'sweetalert'
import { saveAs } from 'file-saver'
import moment from 'moment'

// Sincronizza fatture
export const idrataFatture = (user, anno) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/list`)
    .send({ token: user.auth_token, anno })
    .then((response) => response.body)

// Incassa fattura
export const queryIncassaFattura = (user, idFatturaDaIncassare, dataIncasso) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/incassa`)
    .send({ token: user.auth_token, idFatturaDaIncassare, dataIncasso })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Scarica pdf è un metodo duplicato anche con qeury fattura, è necessario unificare
export const scaricaPDF = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura-cartacea/presigned-url-pdf`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => {
      window.open(response.body.url, '_blank')
    })
    .catch((e) => console.error(e.message))

// Modifica in da incassare
export const modificaDaIncassare = (user, idFattura) =>
  request
    .post(
      `${process.env.REACT_APP_API_URL}/storico-fatture/modifica-fattura-in-da-incassare`
    )
    .send({ token: user.auth_token, idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Ellimina fattura
export const eliminaFattura = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/fattura-cartacea/cancella`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

// Scarica xml
export const scaricaXMLFattura = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/scarica-xml`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => {
      window.open(response.body.url, '_blank')
    })
    .catch((e) => console.error(e.message))

// Scarica formato ministeriale
export const scaricaPDFEle = (user, fattura) => {
  swal({
    title: 'Il PDF della fattura è in preparazione',
    text: 'Tra pochi istanti inizierà il download',
    icon: 'success',
    button: 'OK',
  })
  request
    .post(
      `${process.env.REACT_APP_API_URL}/storico-fatture/scarica-pdf-fattura-elettronica`
    )
    .send({ token: user.auth_token, idFattura: fattura.id })
    .responseType('blob')
    .then((res) => {
      const nome =
        fattura.tipologia +
        '_' +
        moment(fattura.data_emissione).year() +
        '_' +
        fattura.numero +
        '.pdf'
      const file = new File([res.body], nome, {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(file)
    })
    .catch((e) => console.error(e.message))
}

export const scaricaAttestazione = (user, fattura) => {
  swal({
    title: "L'attestazione è in preparazione",
    text: 'Tra pochi istanti inizierà il download',
    icon: 'success',
    button: 'OK',
  })
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/scarica-attestazione`)
    .send({ token: user.auth_token, idFattura: fattura.id })
    .then((response) => {
      window.open(response.body.url, '_blank')
    })
    .catch((e) => console.error(e.message))
}

// Scarica fattura di cortesia
export const scaricaFatturaDiCortesia = (user, fattura) => {
  swal({
    title: 'La fattura di cortesia è in preparazione',
    text: 'Tra pochi istanti inizierà il download',
    icon: 'success',
    button: 'OK',
  })
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/fattura-di-cortesia`)
    .send({ token: user.auth_token, idFattura: fattura.id })
    .responseType('blob')
    .then((res) => {
      const nome =
        'documento_' +
        moment(fattura.data_emissione).year() +
        '_' +
        fattura.numero +
        '.pdf'
      const file = new File([res.body], nome, {
        type: 'text/plain;charset=utf-8',
      })
      saveAs(file)
    })
    .catch((e) => console.error(e.message))
}

// Iniva fattura per email al cliente
export const queryInviaEmail = (user, idFattura, emailCliente) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/invia-email`)
    .send({ token: user.auth_token, idFattura, emailCliente })
    .then((response) => {
      if (response.body.email_inviata) {
        swal({
          title: 'Fattura inviata',
          text: 'Il tuo cliente riceverà la fattura a breve 😊',
          icon: 'success',
          button: 'Ok',
        })
      } else {
        swal({
          title: 'Errore',
          text: "Non siamo riusciti ad inviare la tua fattura al cliente. Riprova tra un'ora oppure scrivici su WhatsApp",
          icon: 'error',
          button: 'Ok',
        })
      }
      return response.body
    })
    .catch((e) => {
      console.error(e.message)
      return { email_inviata: false }
    })

// Modifica stato STS per fattura
export const queryModificaStatoSTS = (user, idFattura) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/storico-fatture/modifica-stato-sts`)
    .send({ token: user.auth_token, idFattura })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

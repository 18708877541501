import React from 'react'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const VariazioneDue = ({ setScheda }) => (
  <Card body className="h-100 d-flex d-column p-5">
    <Progress value={60} className="progress-gialla" />
    <br />
    <br />
    <h4 className="testo-verde font-bold mb-4">OKAY, HAI FATTO LA TUA SCELTA</h4>

    <h6 className="font-regular mb-3 interlinea-30">
      Per usufruire di un&apos;aliquota vantaggiosa, dato che hai cambiato attività,
      devi inviare <b>due moduli</b> &quot;Contribuzione Ridotta&quot;. Nel primo
      bisogna indicare la data in cui hai cessato la precedente attività, cioè quella
      che ti dava diritto all&apos;aliquota di prima. Nel secondo modulo, invece,
      devi indicare la data di inizio della nuova attività.
      <br />
      <br />
      Ecco gli <b>step</b> da seguire per inviare il primo modulo:
      <br />
      1. Accedi alla tua <b>Area Riservata</b>
      <br />
      2. Nella colonna di sinistra, clicca su &quot;
      <b>Domande e dichiarazioni online</b>&quot;
      <br />
      3. Sempre nella colonna a sinistra, clicca su &quot;
      <b>Contribuzione Ridotta</b>&quot;.
      <br />
      4. Clicca su &quot;<b>Compila un nuovo modulo</b>&quot;.
      <br />
      5. Compila il campo relativo alla perdita del diritto alla contribuzione
      ridotta, scrivendo il <b>mese</b> e l&apos;<b>anno</b> in cui hai terminato
      l&apos;attività.
      <br />
      6. <b>Invia</b> il modulo.
      <br />
      <br />
      Dopo che hai fatto questa procedura, clicca sul tasto &quot;<b>avanti</b>
      &quot;.
    </h6>
    <br />
    <br />
    <div className="mt-auto mb-3">
      <Ricomincia setScheda={setScheda} />
      <Button
        className="bottone-blu float-end"
        style={{ width: '130px' }}
        onClick={() => setScheda('variazione_3')}
      >
        AVANTI <i className="bi bi-arrow-right-short"></i>
      </Button>
    </div>
  </Card>
)

export default VariazioneDue

import React from 'react'
import { Helmet } from 'react-helmet'
import { Container, Button } from 'reactstrap'
import { Link } from 'react-router-dom'

export const LoginMobile = () => (
  <>
    <Helmet>
      <title>Login</title>
      <meta property="title" content="Login" />
      <meta property="og:site_name" content="Fiscomed.it" />
      <meta property="og:url" content="https://fiscomed.it/accesso" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Login" />
      <meta property="og:description" content="Accedi a Fiscomed" />
      <meta property="description" content="Accedi a Fiscomed" />
      <meta property="og:image" content="https://fiscomed.it/logo.webp" />
      <style>
        {
          'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
        }
      </style>
    </Helmet>
    <Container style={{ marginTop: '56px' }} className="mobile-container">
      <br />
      <br />
      <span className="titolo2-mobile">
        Mi dispiace, ma si può accedere solo{' '}
        <span className="testo-verde">da computer</span>
      </span>
      <br />
      <br />
      <h6 className="h6-landing">
        Questo perché il software Fiscomed è stato pensato esclusivamente per desktop
      </h6>
      <br />
      <br />
      <Button
        tag={Link}
        to="/"
        className="bottone-verde px-4"
        style={{ paddingTop: '14px', paddingBottom: '14px' }}
      >
        TORNA ALLA HOME
      </Button>
    </Container>
  </>
)

export default LoginMobile

import React from 'react'
import { Col, Row } from 'reactstrap'

const VerificatoDesktop = (props) => {
  let immagine
  let nome
  let titolo
  switch (props.commercialista) {
    case 0:
      immagine = '/blog/jacopo.webp'
      nome = 'Jacopo'
      titolo = 'Dottore'
      break
    case 1:
      immagine = '/blog/giulia.webp'
      nome = 'Giulia'
      titolo = 'Dottoressa'
      break
    case 2:
      immagine = '/blog/francesco.webp'
      nome = 'Francesco'
      titolo = 'Dottore'
      break
    case 3:
      immagine = '/blog/cristina.webp'
      nome = 'Cristina'
      titolo = 'Dottoressa'
      break
    case 4:
      immagine = '/blog/giuseppe.webp'
      nome = 'Giuseppe'
      titolo = 'Dottore'
      break
  }

  return (
    <div
      style={{
        backgroundColor: '#66d98e26',
        borderRadius: '7px',
      }}
      className="p-4"
    >
      <Row>
        <Col xs="4">
          <img
            src={immagine}
            alt="Avatar"
            style={{ width: '70px', marginTop: '-5px' }}
            className="float-end"
          />
        </Col>
        <Col xs="8">
          <h6 className="testo-landing interlinea-30">
            Questo articolo è stato approvato da {nome}, <br /> {titolo}{' '}
            Commercialista del team di Fiscomed
          </h6>
        </Col>
      </Row>
    </div>
  )
}

export default VerificatoDesktop

import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  selectIsLoadingUser,
  selectIsLoggedIn,
} from '../../app/store/user/selectors'

const ControllerStatusFisco = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isLoading = useSelector(selectIsLoadingUser)
  const history = useHistory()
  const [attivo, setAttivo] = useState(false)
  const smallScreen = window.innerWidth <= 1023

  useEffect(() => {
    setAttivo(false)
    if (isLoading === false) {
      if (isLoggedIn === false) {
        history.push('/')
      } else {
        if (smallScreen) {
          history.push('/disponibile-sul-desktop')
        } else {
          setAttivo(true)
        }
      }
    }
  }, [isLoading, isLoggedIn])

  return <>{attivo && children}</>
}

export default ControllerStatusFisco

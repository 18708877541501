import React from 'react'
import { Row, Col, Card } from 'reactstrap'

const NonInviare = ({ dati }) => (
  <Card body className="h-100 d-flex d-column">
    <Row>
      <Col xs={5}>
        <center>
          <img
            src="/img/enpam/non_devi_inviare.webp"
            className="mt-5"
            style={{ width: '90%' }}
          />
        </center>
      </Col>
      <Col xs={7}>
        <br />
        <h1 className="font-bold mt-5">
          Nel {new Date().getFullYear()} non devi inviare il Modello D
        </h1>
        <h6 className="font-regular mb-3 mt-4 interlinea-30">
          {dati.genere.ilTuo[0].toUpperCase() + dati.genere.ilTuo.slice(1)}{' '}
          commercialista ha analizzato la tua situazione e ha visto che nel{' '}
          {new Date().getFullYear() - 1} non hai prodotto reddito libero
          professionale.
          <br />
          <br /> Questo vuol dire che non devi pagare la Quota B relativa al{' '}
          {new Date().getFullYear() - 1} e, di conseguenza, non c&apos;è bisogno che
          compili il Modello D. Ecco perché questa schermata resterà chiusa.
          <br />
          <br />
        </h6>
      </Col>
    </Row>
  </Card>
)

export default NonInviare

import React from 'react'
import { useParams } from 'react-router-dom'
import { Container } from 'reactstrap'
import InviaEmailProforma from './component/InviaEmailProforma'
import FineProforma from './component/FineProforma'

const EsitoProforma = () => {
  const { idProforma } = useParams()
  const [schermata, setSchermata] = React.useState('email')

  const emailInviata = () => {
    setSchermata('fine')
  }

  return (
    <Container className="mt-5">
      {schermata === 'email' ? (
        <InviaEmailProforma idProforma={idProforma} emailInviata={emailInviata} />
      ) : (
        <FineProforma idProforma={idProforma} />
      )}
    </Container>
  )
}

export default EsitoProforma

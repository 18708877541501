import React, { useEffect, useState } from 'react'
import { Card, Button, Spinner, Progress } from 'reactstrap'
import { presignedUrlF24Autofattura } from '../../../../app/utility/QueryFattura'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../../app/store/user/selectors'

const IstruzioniAutofattura = (props) => {
  const user = useSelector(selectUser)
  const [url, setUrl] = useState(null)

  useEffect(() => {
    presignedUrlF24Autofattura(user, props.idAutofattura).then((url) => {
      setUrl(url)
    })
  }, [])

  const conIva = (
    <>
      <Card body className="mb-3">
        <div>
          <h3 className="font-medium mt-4">La tua autofattura è stata inviata 🎉</h3>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <h6 className="mt-3 font-regular interlinea-30">
            La tua autofattura è stata correttamente inviata allo SdI, ma c&apos;è
            un&apos;altra cosa che devi fare.
          </h6>
          <h6 className="mt-3 font-regular interlinea-30">
            Infatti, sembra che tu debba{' '}
            <span className="font-bold">pagare l&apos;IVA</span> sul bene o servizio
            che hai comprato.
          </h6>
          <h6 className="mt-3 font-regular interlinea-30">
            Per aiutarti a farlo, abbiamo preparato per te il{' '}
            <span className="font-bold">facsimile dell&apos;F24</span> che devi
            pagare. Lo puoi <span className="font-bold">scaricare</span>{' '}
            dall&apos;anteprima qui sotto, oppure dall&apos;
            <span className="font-bold">email</span> che hai appena ricevuto. Lì
            trovi tutte le <span className="font-bold">istruzioni</span> per pagarlo
            entro la <span className="font-bold">scadenza</span> del 16 del mese
            successivo a quello di ricezione della fattura.
          </h6>
          <h6 className="mt-3 font-regular interlinea-30">
            Se vuoi portarti avanti, dai un’occhiata ai video &quot;
            <Link to="/fiscodemia/video/fatture_estere">Fatture estere e IVA</Link>
            &quot; e &quot;
            <Link to="/fiscodemia/video/f24_3">
              F24 e Agenzia delle Entrate: tutti gli step da seguire
            </Link>
            &quot;. Ti aiuteranno a capirci qualcosa di più 😊
          </h6>
          <div className="float-end">
            <Button
              className="bottone-blu-outline mt-3 mb-3"
              tag={Link}
              to="/fiscomed"
            >
              VAI ALLA DASHBOARD
            </Button>
          </div>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          {url ? (
            <object data={url} width="100%" height="900px"></object>
          ) : (
            <center>
              <Spinner color="primary" />
            </center>
          )}
        </div>
      </Card>
    </>
  )

  const senzaIva = (
    <>
      <Card body className="mb-3">
        <div>
          <Progress value={40} className="progress-gialla" />
          <h3 className="font-medium mt-4">La tua autofattura è stata inviata 🎉</h3>
        </div>
      </Card>

      <Card body className="mb-3">
        <div>
          <h6 className="mt-3 font-regular interlinea-30">
            E anche questo adempimento lo abbiamo superato 😌
          </h6>
          <div className="float-end">
            <Button
              className="bottone-blu-outline mt-3 mb-3"
              tag={Link}
              to="/fiscomed"
            >
              VAI ALLA DASHBOARD
            </Button>
          </div>
        </div>
      </Card>
    </>
  )

  return <>{props.conIva ? conIva : senzaIva}</>
}

export default IstruzioniAutofattura

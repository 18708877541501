import React from 'react'
import { Card, Row, Col } from 'reactstrap'
import moment from 'moment/moment'
import { useSelector } from 'react-redux'
import { selectUser } from '../../../app/store/user/selectors'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import currency from 'currency.js'

const PreviewRettificaPr = () => {
  const user = useSelector(selectUser)
  const fiscomedData = useSelector(selectFiscomedData)

  const tabellaImporti = () => {
    const tabella = fiscomedData.prestazioni.map((prestazione, i) => (
      <tr key={i}>
        <td className="font-regular h6">{prestazione.quantita}</td>
        <td className="font-regular h6">{prestazione.codiceValore}</td>
        <td className="text-muted h6">
          {prestazione.descrizione} {prestazione.altriDatiGestionali.tipoDato}{' '}
          {prestazione.altriDatiGestionali.riferimentoTesto}{' '}
          {prestazione.natura === 'N1' && 'N1'}
        </td>
        <td className="h6 font-bold">{prestazione.importo}</td>
      </tr>
    ))
    if (fiscomedData.addebitoMarcaDaBollo) {
      const contatore = tabella.length + 1
      tabella.push(
        <tr key={contatore}>
          <td className="font-regular h6">1</td>
          <td className="font-regular h6"></td>
          <td className="text-muted h6">Marca da bollo</td>
          <td className="h6 font-bold">2</td>
        </tr>
      )
    }
    return tabella
  }

  const ordinePresente =
    fiscomedData.datiAggiuntivi.idDocumento.length > 0 ||
    fiscomedData.datiAggiuntivi.codiceCUP.length > 0 ||
    fiscomedData.datiAggiuntivi.codiceCIG.length > 0 ||
    fiscomedData.datiAggiuntivi.data.length > 0 ||
    fiscomedData.datiAggiuntivi.codiceCommessa.length > 0

  return (
    <Card body className="mt-2 mb-4" style={{ maxWidth: '900px' }}>
      <div className="d-flex justify-content-between">
        <small className="text-muted">
          Fattura numero {fiscomedData.numeroFattura}/
          {moment(fiscomedData.fatturaDiRiferimento.data_emissione).year()}
        </small>
        <small className="text-muted">
          Data emissione fattura{' '}
          {moment(fiscomedData.fatturaDiRiferimento.data_emissione).format(
            'DD/MM/YYYY'
          )}
        </small>
      </div>
      <br />
      <br />
      <Row>
        <Col xs="6">
          <span className="text-muted">Emittente</span>
          <h5 className="font-medium">
            {user.fisco_nome} {user.fisco_cognome}
          </h5>
          <h6 className="text-muted">{user.fisco_indirizzo}</h6>
          <h6 className="text-muted">
            {user.fisco_comune}, {user.fisco_cap} ({user.fisco_provincia})
          </h6>
          <h6 className="text-muted">CF: {user.fisco_cf}</h6>
          <h6 className="text-muted">P.IVA: {user.fisco_partita_iva}</h6>
        </Col>
        <Col xs="6">
          <span className="text-muted">Destinatario</span>
          <h5 className="font-medium">
            {fiscomedData.clienteAttivo?.ragione_sociale}
          </h5>
          <h6 className="text-muted">{fiscomedData.clienteAttivo?.indirizzo}</h6>
          <h6 className="text-muted">
            {fiscomedData.clienteAttivo?.comune}, {fiscomedData.clienteAttivo?.cap}{' '}
            {fiscomedData.clienteAttivo?.provincia &&
              `(${fiscomedData.clienteAttivo?.provincia})`}
          </h6>
          <h6 className="text-muted">
            CF: {fiscomedData.clienteAttivo?.codice_fiscale}
          </h6>
          {fiscomedData.clienteAttivo?.partita_iva !== null && (
            <h6 className="text-muted">
              P.IVA: {fiscomedData.clienteAttivo?.partita_iva}
            </h6>
          )}
        </Col>
      </Row>
      <div className="my-4">
        <br />
        <br />
        <table className="table table-striped table-sm">
          <thead>
            <tr>
              <th scope="col" className="h6 font-medium">
                Quantità
              </th>
              <th scope="col" className="h6 font-medium">
                Codice
              </th>
              <th scope="col" className="h6 font-medium">
                Descrizione
              </th>
              <th scope="col" className="h6 font-medium">
                Importo
              </th>
            </tr>
          </thead>
          <tbody>{tabellaImporti()}</tbody>
        </table>
        {fiscomedData.datiAggiuntivi.contributoCassa !== '' && (
          <h6 className="font-grigio">
            Trattenuta cassa {fiscomedData.datiAggiuntivi.contributoCassa}€ (
            {fiscomedData.datiAggiuntivi.aliquotaCassa}%)
          </h6>
        )}
        <br />
        <div className="d-flex justify-content-end" style={{ minHeight: '280px' }}>
          {fiscomedData.datiAggiuntivi.contributoCassa === '' ? (
            <h4>Totale: {fiscomedData.totaleImporto} €</h4>
          ) : (
            <h4>
              Totale:{' '}
              {
                currency(fiscomedData.totaleImporto).subtract(
                  fiscomedData.datiAggiuntivi.contributoCassa
                ).value
              }{' '}
              €
            </h4>
          )}
        </div>
      </div>

      {ordinePresente && (
        <div className="mb-5">
          <h6 className="font-regular">Ordine di acquisto</h6>
          <small className="text-muted">
            {fiscomedData.datiAggiuntivi.data.length > 0 && (
              <>
                <span className="font-regular">Data: </span>
                {fiscomedData.datiAggiuntivi.data}
              </>
            )}
            {fiscomedData.datiAggiuntivi.idDocumento.length > 0 && (
              <>
                <span className="font-regular"> Identificativo: </span>
                {fiscomedData.datiAggiuntivi.idDocumento}
              </>
            )}
            {fiscomedData.datiAggiuntivi.codiceCUP.length > 0 && (
              <>
                <span className="font-regular"> CUP: </span>
                {fiscomedData.datiAggiuntivi.codiceCUP}
              </>
            )}
            {fiscomedData.datiAggiuntivi.codiceCIG.length > 0 && (
              <>
                <span className="font-regular"> CIG: </span>
                {fiscomedData.datiAggiuntivi.codiceCIG}
              </>
            )}
            {fiscomedData.datiAggiuntivi.codiceCommessa.length > 0 && (
              <>
                <span className="font-regular"> Codice commessa: </span>
                {fiscomedData.datiAggiuntivi.codiceCommessa}
              </>
            )}
          </small>
        </div>
      )}

      <Row>
        <Col xs="6">
          <h5 className="font-medium">Pagamento</h5>
        </Col>
        <Col xs="6">
          <h5 className="font-medium">Note</h5>
        </Col>
      </Row>

      <hr />
      <Row>
        <Col xs="6">
          {fiscomedData.metodoDiPagamentoAttivo === 'Contante' ? (
            <h5>Contante</h5>
          ) : (
            <>
              Intestatario: {fiscomedData.metodoDiPagamentoAttivo.intestatario}
              <br />
              IBAN: {fiscomedData.metodoDiPagamentoAttivo.iban}
              <br />
              {fiscomedData.datiAggiuntivi.scadenzaPagamento !== '' && (
                <>
                  Scadenza pagamento:{' '}
                  {moment(fiscomedData.datiAggiuntivi.scadenzaPagamento).format(
                    'DD/MM/YYYY'
                  )}
                </>
              )}
            </>
          )}
        </Col>
        <Col xs="6">
          <small className="text-muted">
            {fiscomedData.notaPresente && (
              <>
                <b>{fiscomedData.nota}</b>
                <br />
              </>
            )}
            Prestazione svolta in regime forfetario ex articolo 1, commi da 54 a 89,
            della Legge n. 190/2014 come modificata dalla L. n. 208/2015 e dalla L.
            n. 145/2018 e pertanto non soggetta a IVA. Operazione non assoggettata a
            ritenuta d&apos;acconto ai sensi dell&apos;art. 1 comma 67 della Legge n.
            190/2014.
          </small>
        </Col>
      </Row>
    </Card>
  )
}

export default PreviewRettificaPr

import React from 'react'
import { Spinner } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { apriModalVerificaSdi } from '../../../app/store/riepilogoFatture/slice'

const VerificaSdI = () => {
  const dispatch = useDispatch()

  return (
    <>
      <Spinner animation="grow" size="sm" /> Verifica SdI{' '}
      <i
        className="bi bi-info-circle-fill"
        onClick={() => dispatch(apriModalVerificaSdi())}
        style={{ cursor: 'pointer' }}
      ></i>
    </>
  )
}

export default VerificaSdI

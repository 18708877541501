import React from 'react'

export const lead = {
  title: 'Nell’attesa di sentirci, ecco un regalo per te 🎁',
  content: (
    <div>
      Abbiamo ricevuto la tua richiesta e ti abbiamo inviato un’email con un video
      super importante da parte dei founder di Fiscomed. In pochi minuti,{' '}
      <strong>rispondono alle domande più frequenti</strong> e ti raccontano cosa
      possiamo fare per te.
      <br />
      <br />
      <strong>Dai un’occhiata al video</strong> prima della chiamata, così possiamo
      renderla più efficace e personalizzata
    </div>
  ),
  icon: 'success',
  button: 'Vado subito 🚀',
}

// latin-1 regex validator function
export const latin1Valid = (value) => {
  if (value === undefined || value === null || value === '') {
    return false
  }
  return !/[^\u0000-\u00ff]/g.test(value)
}

export const latin1Invalid = (value) => {
  if (value === undefined || value === null || value === '') {
    return false
  }
  return /[^\u0000-\u00ff]/g.test(value)
}

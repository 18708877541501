import React, { useState } from 'react'
import {
  Col,
  Container,
  Row,
  Button,
  Input,
  FormGroup,
  Spinner,
  Label,
} from 'reactstrap'
import { Helmet } from 'react-helmet'
import Footer from '../Footer'
import DomandaRisposta from '../DomandaRisposta'
import { leadChiamata } from '../../../app/utility/QueryLanding'
import swal from '@sweetalert/with-react'
import { Link, useHistory } from 'react-router-dom'
import { leadChiamataGA } from '../../../app/utility/GA'
import { lead } from '../../../app/utility/SwalContent'

const PrezziMobile = () => {
  const [caricamento, setCaricamento] = useState(false)
  const [nome, setNome] = useState('')
  const [email, setEmail] = useState('')
  const [telefono, setTelefono] = useState('')
  const [message, setMessage] = useState('')
  const history = useHistory()
  const [consenso, setConsenso] = useState(false)

  const prenotaChiamata = async () => {
    if (
      nome === '' ||
      email === '' ||
      telefono === '' ||
      consenso === false ||
      message === ''
    ) {
      swal({
        title: 'Attenzione',
        text: 'Compila tutti i campi',
        icon: 'warning',
        button: 'Ok',
      })
      return
    }
    setCaricamento(true)
    leadChiamataGA()
    await leadChiamata({ nome, email, telefono, message }).then((res) => {
      setCaricamento(false)
      swal(lead)
    })
  }

  const pagaMensile = () => {
    swal({
      title: 'Una domanda prima di andare avanti',
      text: "Il piano mensile è dedicato esclusivamente a chi ha aperto la Partita IVA nell'anno in corso o a chi vuole aprirla con noi. Rientri in uno di questi casi?",
      icon: 'warning',
      buttons: ['No, torna a Prezzi', 'Sì, vado avanti'],
    }).then((value) => {
      if (value) history.push('/pagamento/mensile')
    })
  }

  return (
    <>
      <Helmet>
        <title>Prezzi</title>
        <meta property="title" content="Prezzi" />
        <meta property="og:site_name" content="Fiscomed.it" />
        <meta property="og:url" content="https://fiscomed.it/prezzi" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Prezzi" />
        <meta property="og:description" content="Scopri i prezzi di Fiscomed" />
        <meta property="og:image" content="https://fiscomed.it/logo.webp" />
        <meta property="description" content="Scopri i prezzi di Fiscomed" />
        <style>
          {
            'body { background-color: white;background-image: url("/img_landing/copertina_mobile.webp");background-size: 100% auto;background-repeat: repeat-y;background-position: left top;margin-top: 0;position: absolute;height: 100%;width:100%;margin: 0; }'
          }
        </style>
      </Helmet>
      <Container style={{ marginTop: '56px' }} className="mobile-container">
        <div className="immagine-background-landing-fiscomed-mobile"></div>
        <br />
        <br />
        <div className="header-landing-mobile-prezzi" />
        <h1 className="titolo1-mobile" style={{ fontSize: '2.5rem' }}>
          C&apos;è un abbonamento <span className="testo-verde">adatto a te</span>
        </h1>
        <br />
        <h6 className="font-regular interlinea-30 h6-landing">
          Piano mensile o piano annuale: scegli quello migliore per le tue esigenze
        </h6>
        <br />
        <div className="card-mensile-mobile" style={{ margin: '0 auto' }}>
          <div className="header-card-mensile-mobile" />
          <h3 style={{ fontWeight: 600, fontSize: '3rem' }} className="testo-blu">
            Mensile
          </h3>
          <h6 className="h6-landing mt-4">
            Se sei agli inizi della tua attività e hai già tante spese, ecco il piano
            per te
          </h6>
          <br />
          <h3
            style={{
              fontWeight: 600,
              fontSize: '3rem',
              display: 'inline-block',
              position: 'relative',
            }}
            className="testo-blu"
          >
            <span style={{ fontSize: '1rem', top: '8px', position: 'absolute' }}>
              €
            </span>
            &nbsp;37
            <span style={{ fontSize: '1rem', marginLeft: '3px' }}>IVA INCLUSA</span>
          </h3>
          <h6 className="h6-landing  mt-3">
            <span className="font-medium">Dedicato a →</span>
          </h6>
          <div className="d-flex h6-landing ">
            <span className="d-inline me-2">
              <i className="bi bi-check-circle testo-blu"></i>
            </span>
            <span className="d-inline">
              chi ha aperto la Partita IVA <i>nell&apos;anno in corso</i>
            </span>
          </div>
          <div className="d-flex mt-2 h6-landing ">
            <span className="d-inline me-2">
              <i className="bi bi-check-circle testo-blu"></i>
            </span>
            <span className="d-inline">
              chi vuole <i>aprire la Partita IVA</i> senza prosciugare il conto
            </span>
          </div>
          <Button
            className="bottone-blu px-5 mt-4"
            style={{ paddingTop: '14px', paddingBottom: '14px' }}
            block
            onClick={() => pagaMensile()}
          >
            ACQUISTA ORA
          </Button>
        </div>
        <br />
        <br />
        <div className="card-annuale-mobile" style={{ margin: '0 auto' }}>
          <div className="header-card-annuale-mobile" />
          <h3 style={{ fontWeight: 600, fontSize: '3rem' }} className="testo-verde">
            Annuale
          </h3>
          <h6 className="h6-landing mt-4">
            Se vuoi levarti il pensiero puoi pagare subito un anno e risparmiare il
            10%
          </h6>
          <br />
          <h3
            style={{
              fontWeight: 600,
              fontSize: '3rem',
              display: 'inline-block',
              position: 'relative',
            }}
            className="testo-verde"
          >
            <span style={{ fontSize: '1rem', top: '8px', position: 'absolute' }}>
              €
            </span>
            &nbsp;400
            <span style={{ fontSize: '1rem', marginLeft: '3px' }}>IVA INCLUSA</span>
          </h3>
          <h6 className="h6-landing mt-3">
            <span className="font-medium">Dedicato a →</span>
          </h6>
          <div className="d-flex h6-landing">
            <span className="d-inline me-2">
              <i className="bi bi-check-circle testo-verde"></i>
            </span>
            <span className="d-inline">
              chi ha già la Partita IVA aperta dallo <i>scorso anno</i>
            </span>
          </div>
          <div className="d-flex mt-2 h6-landing">
            <span className="d-inline me-2">
              <i className="bi bi-check-circle testo-verde"></i>
            </span>
            <span className="d-inline">
              chi vuole <i>aprire la Partita IVA</i> e risparmiare qualcosa
            </span>
          </div>
          <Button
            className="bottone-verde px-5 mt-4"
            style={{ paddingTop: '14px', paddingBottom: '14px' }}
            block
            tag={Link}
            to="/pagamento/annuale"
          >
            ACQUISTA ORA
          </Button>
        </div>

        <br />
        <br />
        <br />
        <center>
          <span className="etichetta-mobile">Cosa è compreso nel prezzo?</span>
          <br />
          <br />
          <br />
          <span className="titolo2-mobile">
            Tutto quello che ti serve per gestire la tua{' '}
            <span className="testo-verde">Partita IVA da medico</span>
          </span>
        </center>
        <br />
        <div className="div-giallo-guida p-3 mt-5 px-4">
          <br />
          <span className="titolo3-mobile">Commercialista</span>
          <br />
          <Row>
            <Col xs={12} md={3}>
              <h3 className="testo-arancione mt-3">
                <i className="bi bi-tv"></i>
              </h3>
              <h5 className="font-bold mb-3">Prima consulenza</h5>
              <h6 className="h6-landing">
                Non appena sarai affidato a un commercialista farai una prima
                consulenza conoscitiva online
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="testo-arancione mt-3">
                <i className="bi bi-briefcase"></i>
              </h3>
              <h5 className="font-bold mb-3">Apertura Partita IVA</h5>
              <h6 className="h6-landing">
                Nel caso in cui tu non ce l&apos;abbia già, il tuo commercialista si
                occuperà di aprirti la P.IVA
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="testo-arancione mt-3">
                <i className="bi bi-file-earmark-check"></i>
              </h3>
              <h5 className="font-bold mb-3">Dichiarazione dei redditi</h5>
              <h6 className="h6-landing">
                La dichiarazione è compresa nel servizio. Dai un&apos;occhiata qui
                per i dettagli
                <br />
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="testo-arancione mt-3">
                <i className="bi bi-person-square"></i>
              </h3>
              <h5 className="font-bold mb-3">Consulenze fiscali</h5>
              <h6 className="h6-landing">
                Il tuo commercialista è sempre presente per le consulenze relative
                alla tua professione da medico
                <br />
              </h6>
            </Col>
          </Row>
        </div>
        <div className="div-verde-guida p-3 mt-5 px-4">
          <br />
          <span className="titolo3-mobile">Piattaforma</span>
          <Row>
            <Col xs={12} md={3}>
              <h3 className="testo-verde mt-3">
                <i className="bi bi-window-desktop"></i>
              </h3>
              <h5 className="font-bold mb-3">Area riservata</h5>
              <h6 className="h6-landing">
                Dalla tua area riservata puoi creare le fatture, visualizzare le tue
                statistiche e molto altro
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="testo-verde mt-3">
                <i className="bi bi-send-check"></i>
              </h3>
              <h5 className="font-bold mb-3">Invio dei dati al STS</h5>
              <h6 className="h6-landing">
                Puoi inviare i dati al Sistema Tessera Sanitaria direttamente da
                Fiscomed. È facilissimo
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="testo-verde mt-3">
                <i className="bi bi-camera-video"></i>
              </h3>
              <h5 className="font-bold mb-3">Fiscodèmia</h5>
              <h6 className="h6-landing">
                I nostri commercialisti ti mostreranno in dei video come fare le
                procedure fiscali da medico
              </h6>
            </Col>
            <Col xs={12} md={3}>
              <h3 className="testo-verde mt-3">
                <i className="bi bi-heart"></i>
              </h3>
              <h5 className="font-bold mb-3">Assistenza</h5>
              <h6 className="h6-landing">
                Per email o WhatsApp ci sarà sempre qualcuno pronto a rispondere ai
                tuoi dubbi e ad aiutarti
              </h6>
            </Col>
          </Row>
        </div>
        <br />
        <br />
        <center>
          <span className="etichetta-mobile">Ti serve altro?</span>
          <br />
          <br />
          <br />
        </center>
        <h6 className="h6-landing">
          Se hai già la Partita IVA e devi fare una{' '}
          <b>dichiarazione dei redditi passata, modificare</b> o <b>chiudere</b> la
          tua Partita IVA contattaci pure. Ce ne possiamo occupare noi
        </h6>
        <br />
        <br />
        <span className="titolo2-mobile">
          Prima di acquistare vuoi{' '}
          <span className="testo-verde">parlare con noi</span>?
        </span>
        <br />
        <br />
        <h6 className="h6-landing">
          Scegliere il commercialista non è una decisione semplice, lo sappiamo.
          <br />
          <br /> Se vuoi avere qualche informazione in più sui nostri abbonamenti e
          su quello che facciamo <b>scrivi qui i tuoi contatti e il momento</b> in
          cui preferisci ricevere la chiamata.
          <br />
          <br />
          Saremo lieti di rispondere alle tue domande e capire con te se Fiscomed è
          il servizio giusto. Naturalmente, non cederemo a nessuno i tuoi dati
        </h6>
        <br />
        <br />
        <div className="card-contatto-prezzi-mobile px-4 py-5">
          <FormGroup>
            <h6 className="font-medium mb-3">Come ti chiami?</h6>
            <Input
              type="text"
              placeholder="Ippocrate di Coo"
              style={{ border: 'none' }}
              autoComplete="name"
              onChange={(e) => {
                setNome(e.target.value)
              }}
              value={nome}
            />
          </FormGroup>
          <br />
          <FormGroup>
            <h6 className="font-medium mb-3">Qual è la tua email?</h6>
            <Input
              type="text"
              placeholder="ippocrate@email.com"
              style={{ border: 'none' }}
              autoComplete="email"
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
            />
          </FormGroup>
          <br />
          <FormGroup>
            <h6 className="font-medium mb-3">A quale numero possiamo chiamarti?</h6>
            <Input
              type="text"
              placeholder="3399957060"
              style={{ border: 'none' }}
              autoComplete="tel"
              onChange={(e) => {
                setTelefono(e.target.value)
              }}
              value={telefono}
            />
          </FormGroup>
          <br />
          <FormGroup>
            <h6 className="font-medium mb-3">Quando vuoi che ti chiamiamo?</h6>
            <Input
              type="textarea"
              placeholder="Indica un giorno compreso tra lunedì e venerdì e un orario tra le 9 e le 18. Per favore, dai più disponibilità in modo che possiamo accontentare tutte le persone che desiderano essere chiamate"
              rows="6"
              style={{ border: 'none' }}
              onChange={(e) => {
                setMessage(e.target.value)
              }}
              value={message}
            />
          </FormGroup>
          <br />
          <FormGroup check>
            <Input
              type="checkbox"
              name="privacy"
              id="privacy"
              style={{ marginTop: '2px' }}
              checked={consenso}
              onChange={(e) => setConsenso(e.target.checked)}
            />
            <Label for="privacy" className="consenso">
              Acconsento al trattamento dei miei dati e dichiaro di aver letto la{' '}
              <a
                href="/documenti/termini_e_condizioni.pdf"
                target="_blank"
                rel="noreferrer"
              >
                <u>privacy policy</u>
              </a>
            </Label>
          </FormGroup>
          <br />
          <center>
            {caricamento ? (
              <Button
                className="bottone-blu px-2 mt-4"
                disabled
                style={{ paddingTop: '14px', paddingBottom: '14px', width: '170px' }}
              >
                <Spinner color="light" size="sm" />
              </Button>
            ) : (
              <Button
                className="bottone-blu px-2 mt-4 px-4"
                onClick={() => prenotaChiamata()}
                style={{ paddingTop: '14px', paddingBottom: '14px' }}
              >
                PRENOTA LA TUA CHIAMATA
              </Button>
            )}
          </center>
        </div>
        <br />
        <br />
        <span className="titolo2-mobile">
          Conosciamo i <span className="testo-verde">tuoi dubbi</span>
        </span>
        <br />
        <br />
        <h6 className="h6-landing">Ecco le nostre risposte</h6>
        <br />
        <br />
      </Container>
      <Container>
        <DomandaRisposta>
          <>Sono un medico: nel servizio è compreso tutto quello che mi serve?</>
          <>
            I servizi che riceverai sono tutti quelli necessari a gestire gli
            adempimenti fiscali della professione medica
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Il mensile è un vero mensile?</>
          <>
            Sì, il nostro abbonamento mensile è davvero un mensile, proprio come
            Netflix. Non è, come in altri casi, una rateizzazione dell’annuale.
            Questo significa che non hai l’obbligo di rimanere con noi un anno, ma
            puoi smettere di pagare quando vuoi. È naturale, però, che se vuoi che
            siamo noi a fare la tua dichiarazione dei redditi è necessario che tu sia
            un cliente pagante in quel momento. Per riprendere Netflix: quando smetti
            di pagarlo, non hai più l’accesso alle sue serie e film.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Parlerò sempre con lo stesso commercialista?</>
          <>
            Certamente. Non appena acquisti Fiscomed vieni affidato/a a un Dottore o
            una Dottoressa Commercialista del nostro team con cui firmerai una
            lettera di incarico. Da quel momento in poi, la persona con cui hai fatto
            la prima videoconsulenza sarà il/la commercialista di riferimento. Avrai
            la sua email e sarà lui o lei a occuparsi della tua gestione fiscale,
            fino a quando lo vorrai. Naturalmente, se dovessi avere un’urgenza quando
            il/la tua commercialista è in ferie, potrai parlare con un altro
            professionista del nostro team.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Posso rescindere il contratto quando voglio?</>
          <>
            Certo. Il contratto è rescindibile in qualsiasi momento, senza penali,
            sia che tu sottoscriva l’abbonamento mensile sia che tu scelga
            l’abbonamento annuale. Dal momento in cui smetti di pagare, smetti di
            ricevere i nostri servizi e si interrompe il contratto fra te e il tuo o
            la tua commercialista.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>Se inizio con il mensile poi posso passare all&apos;annuale?</>
          <>
            Certo. Chiamaci o scrivici una email e modificheremo la tipologia di
            abbonamento.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            E se ho bisogno di consulenze al di fuori della mia attività da medico?
          </>
          <>
            Puoi rivolgerti tranquillamente al Dottore o alla Dottoressa
            Commercialista che ti segue. Naturalmente, però, tutto ciò che non
            rientra nella gestione della fiscalità della professione medica non è
            compreso nel prezzo.
          </>
        </DomandaRisposta>
        <DomandaRisposta>
          <>
            Nella lettera di incarico c&apos;è scritto che non mi viene fatta
            assistenza nell&apos;emissione delle fatture elettroniche. È vero?
          </>
          <>
            In realtà no, non è vero. Ti spiego meglio: la lettera di incarico è il
            contratto che definisce la relazione fra te e il tuo commercialista, non
            fra te e noi di Fiscomed.it. Infatti affidandoti a Fiscomed hai due tipi
            di assistenza: (1) un’assistenza via WhatsApp con il team di consulenti
            di Fiscomed.it e (2) un’assistenza via email o videoconsulenza con il
            Dottore Commercialista del team al quale ti affidiamo. Per quanto
            riguarda l’emissione delle fatture elettroniche, siamo noi di Fiscomed.it
            ad assisterti e non il tuo Dottore Commercialista. Questo perché si
            tratta di una procedura davvero semplice che, con la nostra piattaforma,
            diventa ancora più facile da portare a termine. Solitamente, se i nostri
            clienti lo richiedono, quando devono emettere la loro prima fattura li
            chiamiamo per fare questo processo insieme. Ogni volta la chiamata
            finisce con: “Ah, ma è facilissimo!” 😊
          </>
        </DomandaRisposta>
        <br />
        <br />
        <br />
      </Container>
      <Footer />
    </>
  )
}
export default PrezziMobile

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, Input, Label } from 'reactstrap'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'
import { aggiornaDatiAggiuntivi } from '../../../app/store/fiscomed/slice'

const AllegaFile = () => {
  const dispatch = useDispatch()
  const fiscomedData = useSelector(selectFiscomedData)

  const allega = (e) => {
    if (e.target.files[0].size / 1024 / 1024 > 0.9) {
      alert('Il file è troppo grande, la dimensione massima è 0.9 MB')
      return
    }
    const reader = new FileReader()
    reader.readAsDataURL(e.target.files[0])
    reader.onload = () => {
      dispatch(
        aggiornaDatiAggiuntivi({
          ...fiscomedData.datiAggiuntivi,
          allegato: true,
          allegatoFile: {
            nome: e.target.files[0].name,
            base64: reader.result.split(',')[1],
            formato: e.target.files[0].name.split('.').pop(),
          },
        })
      )
    }
  }

  const rimuoviFile = () => {
    dispatch(
      aggiornaDatiAggiuntivi({
        ...fiscomedData.datiAggiuntivi,
        allegato: false,
        allegatoFile: {
          nome: '',
          base64: '',
          formato: '',
        },
      })
    )
  }

  return (
    <FormGroup>
      <Label for="file_allegato">
        <i className="bi bi-paperclip"></i>Allega file
        {!fiscomedData.datiAggiuntivi.allegato && (
          <>
            <br />
            <span className="text-muted">
              Sono accettati file di tipo PDF, PNG, GIF, JPG, JPEG, ZIP, DOC, DOCX,
              DOCM, XLS, XLSX, XLSM, XML
            </span>
          </>
        )}
      </Label>
      {fiscomedData.datiAggiuntivi.allegato ? (
        <>
          <br />
          {fiscomedData.datiAggiuntivi.allegatoFile.nome}{' '}
          <i
            className="bi bi-x-lg"
            style={{ color: '#f2421b', cusrsor: 'pointer' }}
            onClick={() => rimuoviFile()}
          ></i>
        </>
      ) : (
        <Input
          type="file"
          name="file"
          id="file_allegato"
          accept=".pdf,.png,.gif,.jpg,.jpeg,.zip,.doc,.docx,.docm,.xls,.xlsx,.xlsm,.xml"
          onChangeCapture={(e) => allega(e)}
        />
      )}
    </FormGroup>
  )
}

export default AllegaFile

import React, { useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
} from 'reactstrap'

const modalFileUpload = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <Modal
      isOpen={context.modalFileUpload.aperto}
      toggle={() => context.chiudiFileUpload()}
    >
      <ModalHeader
        toggle={() => context.chiudiFileUpload()}
        className="font-regular"
      >
        Carica un documento
      </ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label for="exampleEmail">
            Scegli un documento da condividere con {context.genere.ilTuo}{' '}
            commercialista
          </Label>
          <br />
          <br />
          <Input
            type="file"
            name="file_condiviso"
            onChange={(e) => context.impostaFile(e.target.files[0])}
          />
        </FormGroup>
        <br />
      </ModalBody>
      {context.modalFileUpload.caricamento ? (
        <ModalFooter>
          <Button color="secondary" disabled>
            Caricamento...
          </Button>
        </ModalFooter>
      ) : (
        <ModalFooter>
          <Button className="bottone-blu" onClick={() => context.uploadFile()}>
            <i className="bi bi-cloud-arrow-up"></i> CARICA
          </Button>
        </ModalFooter>
      )}
    </Modal>
  )
}

export default modalFileUpload

import React from 'react'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const UnoTre = ({ setScheda }) => (
  <Card body className="h-100 d-flex d-column p-5">
    <Progress value={40} className="progress-gialla" />
    <br />
    <br />
    <h4 className="testo-verde font-bold mb-4">
      ALLORA HAI BISOGNO DI UNO SPIEGONE
    </h4>
    <h5 className="testo-verde font-bold">Terza parte</h5>

    <h6 className="font-regular mb-3 mt-4 interlinea-30">
      Tuttavia, alcune situazioni prevedono l&apos;obbligo di versare l&apos;
      <b>aliquota intera</b>.
      <br />
      <br />
      Quindi non hai scelta se, nell&apos;anno per cui stai richiedendo la
      contribuzione ridotta:
      <br />
      <br />
      <ul type="round">
        <li>
          hai un rapporto di lavoro per{' '}
          <b>sostituzione di un medico convenzionato</b>
        </li>
        <li>
          hai un <b>contratto temporaneo di meno di sei mesi</b>, senza rinnovo alla
          scadenza
        </li>
        <li>
          hai frequentato un corso di specializzazione o di formazione in Medicina
          Generale per <b>meno di 6 mesi</b> continuativi.
        </li>
      </ul>
      <br />
      Lo sappiamo, queste cose possono essere difficili da capire. Inoltre, molte
      situazioni possono essere al limite.
      <br />
      Per questo motivo, se c&apos;è qualcosa che non ti è chiaro, contattaci:
      analizzeremo il tuo caso insieme 😊
    </h6>
    <br />
    <br />
    <div className="mt-auto mb-3">
      <Ricomincia setScheda={setScheda} />
      <Button
        className="bottone-blu float-end"
        style={{ width: '130px' }}
        onClick={() => setScheda('.14')}
      >
        AVANTI <i className="bi bi-arrow-right-short"></i>
      </Button>
    </div>
  </Card>
)

export default UnoTre

// Empty Footer for Desktop
import React from 'react'
import { Container, Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'

const FooterDesktop = () => (
  <div className="footer">
    <br />
    <br />
    <Container className="container-landing no-text-decoration">
      <Row>
        <Col md="3">
          <br />
          <div className="logo-footer mb-3">
            <img
              src="/img_landing/logo_sinistra_bianco.webp"
              alt="Logo Fiscomed"
              width="100%"
            />
          </div>
          <h6 className="interlinea-30 text-white">
            Il punto di riferimento per i<br /> giovani medici
          </h6>
          <br />
          <i className="bi bi-facebook text-white h3"></i>&nbsp; &nbsp;&nbsp;
          <i className="bi bi-instagram text-white h3"></i>
        </Col>
        <Col md={1} />
        <Col md="2">
          <br />
          <h5 className="interlinea-30 text-white">Risorse</h5>
          <br />
          <Link to="/guida-fiscale">
            <h6 className="interlinea-30 text-white">Guida fiscale</h6>
          </Link>
          <Link to="/newsletter">
            <h6 className="interlinea-30 text-white">Newsletter</h6>
          </Link>
          <Link to="/blog/home">
            <h6 className="interlinea-30 text-white">Blog</h6>
          </Link>
        </Col>
        <Col md={2}>
          <br />
          <h5 className="interlinea-30 text-white">Documenti</h5>
          <br />
          <a
            href="https://fiscomed.it/documenti/termini_e_condizioni.pdf"
            title="Termini e Condizioni"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Termini e Condizioni</h6>
          </a>
          <a
            href="https://fiscomed.it/documenti/lettera_incarico_professionale.pdf"
            title="Cookie Policy"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Lettera di incarico</h6>
          </a>
          <a
            href="https://fiscomed.it/documenti/cookie_policy.pdf"
            title="Cookie Policy"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Cookie policy</h6>
          </a>
          <a
            href="https://fiscomed.it/documenti/privacy_policy.pdf"
            title="Privacy Policy"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Privacy Policy</h6>
          </a>
          <a
            href="https://fiscomed.it/documenti/prezzi_aggiuntivi.pdf"
            title="Prezzi Aggiuntivi"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Prezzi aggiuntivi</h6>
          </a>
        </Col>
        <Col md={4}>
          <br />
          <h5 className="interlinea-30 text-white">Contatti</h5>
          <br />
          <h6 className="interlinea-30 text-white">
            <i className="bi bi-envelope h4"></i>&nbsp; pill@fiscomed.it
          </h6>
          <h6 className="interlinea-30 text-white">
            <i className="bi bi-whatsapp h4"></i>&nbsp; 339 5601221
          </h6>
        </Col>
      </Row>
      <br />
      <br />
      <br />
      <h6 className="interlinea-30 text-white" style={{ fontWeight: 200 }}>
        Memosoft SRL Corso Guglielmo Marconi 00053, Civitavecchia (RM), P.IVA
        16612071007
      </h6>
      <br />
    </Container>
  </div>
)

const FooterMobile = () => (
  <div className="footer">
    <br />
    <br />
    <Container className="mobile-container no-text-decoration">
      <Row>
        <Col md="3">
          <center>
            <div className="logo-footer mb-3">
              <img
                src="/img_landing/logo_sinistra_bianco.webp"
                alt="Logo Fiscomed"
                width="100%"
              />
            </div>
            <h6 className="interlinea-30 text-white">
              Il punto di riferimento per i<br /> giovani medici
            </h6>
          </center>
          <br />
        </Col>
        <Col md={1} />
        <Col md="2">
          <h5 className="interlinea-30 font-bold-white mb-3">Risorse</h5>
          <Link to="/guida-fiscale">
            <h6 className="interlinea-30 text-white">Guida fiscale</h6>
          </Link>
          <Link to="/newsletter">
            <h6 className="interlinea-30 text-white">Newsletter</h6>
          </Link>
          <Link to="/blog/home">
            <h6 className="interlinea-30 text-white">Blog</h6>
          </Link>
          <br />
        </Col>
        <Col md={2}>
          <h5 className="interlinea-30 font-bold-white mb-3">Documenti</h5>
          <a
            href="https://fiscomed.it/documenti/termini_e_condizioni.pdf"
            title="Termini e Condizioni"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Termini e Condizioni</h6>
          </a>

          <a
            href="https://fiscomed.it/documenti/lettera_incarico_professionale.pdf"
            title="Cookie Policy"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Lettera di incarico</h6>
          </a>

          <a
            href="https://fiscomed.it/documenti/cookie_policy.pdf"
            title="Cookie Policy"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Cookie policy</h6>
          </a>
          <a
            href="https://fiscomed.it/documenti/privacy_policy.pdf"
            title="Privacy Policy"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Privacy Policy</h6>
          </a>
          <a
            href="https://fiscomed.it/documenti/prezzi_aggiuntivi.pdf"
            title="Prezzi Aggiuntivi"
            target="blank"
          >
            <h6 className="interlinea-30 text-white">Prezzi aggiuntivi</h6>
          </a>
          <br />
        </Col>
        <Col md={4}>
          <h5 className="font-bold-white mb-3">Contatti</h5>
          <h6 className="interlinea-30 text-white">
            <i className="bi bi-envelope h4"></i>&nbsp; pill@fiscomed.it
          </h6>
          <h6 className="interlinea-30 text-white">
            <i className="bi bi-whatsapp h4"></i>&nbsp; 339 5601221
          </h6>
        </Col>
      </Row>
      <br />
      <br />
      <center>
        <a
          href="https://www.facebook.com/fiscomed.it"
          title="Facebook"
          target="blank"
        >
          <i className="bi bi-facebook text-white h3"></i>&nbsp; &nbsp;&nbsp;
        </a>
        <a
          href="https://www.instagram.com/fiscomed.it/"
          title="Cookie Policy"
          target="blank"
        >
          <i className="bi bi-instagram text-white h3"></i>
        </a>
        <br />
        <br />
        <h6 className="interlinea-30 text-white">
          Memosoft SRL
          <br /> Corso Guglielmo Marconi 00053,
          <br /> Civitavecchia (RM)
          <br /> P.IVA 16612071007
        </h6>
      </center>
      <br />
    </Container>
  </div>
)

const Footer = () => {
  const smallScreen = window.innerWidth <= 767
  return smallScreen ? <FooterMobile /> : <FooterDesktop />
}

export default Footer

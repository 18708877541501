import React, { useEffect, useContext } from 'react'
import { Spinner, Container, Card, Button, Alert } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { ImportaFatturaContext } from '../../app/context/ImportaFattura'
import SelettoreAnno from './SlettoreAnno'
import Tabella from './Tabella'
import ModalImporta from './ModalImporta'
import ModalUpdate from './ModalUpdate'

const ContainerImportaFattura = () => {
  const importContext = useContext(ImportaFatturaContext)

  useEffect(() => {
    importContext.idrata()
  }, [])

  const caricamento = (
    <center>
      <Spinner size="sm" className="testo-blu" />
    </center>
  )

  return (
    <Container className="mt-4 mb-5">
      <Helmet>
        <title>Importa fatture</title>
        <meta property="title" content="Importa fatture" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ModalImporta />
      <ModalUpdate />
      <>
        <Card body>
          <div className="d-flex justify-content-between">
            <h4 className="font-medium">
              <i className="bi bi-receipt"></i> Importa fatture e cedolini
            </h4>
            <div>
              <Button
                onClick={() => importContext.statoModalImport(true)}
                className="bottone-blu float-end"
              >
                <i className="bi bi-cloud-arrow-up"></i> IMPORTA FATTURA O CEDOLINO
              </Button>
            </div>
          </div>
          <h6 className="mt-3 font-regular interlinea-30">
            Alcuni medici, durante le loro visite, emettono le fatture compilando un
            libretto cartaceo. Altri diventano clienti Fiscomed dopo aver utilizzato
            altri software per l&apos;emissione delle fatture, oppure ricevono dei
            cedolini dalle ATS.
            <br />
            <br />
            Se hai delle fatture o cedolini non emessi da Fiscomed, importali. In
            questo modo:
            <ul>
              <li>
                il tuo commercialista può vederli e fare la dichiarazione con
                semplicità
              </li>
              <li>
                noi possiamo aggiornare in automatico le previsioni delle tasse che
                dovrai pagare
              </li>
            </ul>
          </h6>
          <br />
          {importContext.dati.loading ? (
            caricamento
          ) : importContext.dati.anniConFattureEmesse.length === 0 ? (
            <Alert color="info" className="mt-4">
              <i className="bi bi-info-circle"></i> Non hai ancora importato nessuna
              fattura o cedolino
            </Alert>
          ) : (
            <>
              <SelettoreAnno />
              <br />
              <Tabella />
            </>
          )}
        </Card>
      </>
    </Container>
  )
}

export default ContainerImportaFattura

import React, { useEffect, useState } from 'react'
import { withRouter, useHistory } from 'react-router-dom'
import { setUserId, trackPage } from './GA'
import { useSelector } from 'react-redux'
import {
  selectUser,
  selectIsLoggedIn,
  selectIsLoadingUser,
} from '../store/user/selectors'

const AnalyticsPageSentinel = withRouter((props) => <Analytics {...props} />)

// TODO: questo si può convertire in hook, non serve che sia un component (useAnalytics.js)
const Analytics = (props) => {
  const history = useHistory()
  const user = useSelector(selectUser)
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const isLoading = useSelector(selectIsLoadingUser)
  const [idSetted, setIdSetted] = useState(false)
  const [firstLoad, setFirstLoad] = useState(true)

  useEffect(() => {
    if (firstLoad) {
      trackPage(props.location.pathname)
      setFirstLoad(false)
    }
    history.listen((location) => {
      trackPage(location.pathname)
    })
  }, [history])

  // set userId
  useEffect(() => {
    if (!isLoading && isLoggedIn && !idSetted) {
      setUserId(user)
      setIdSetted(true)
    }
  }, [isLoading])

  return <></>
}
export default AnalyticsPageSentinel

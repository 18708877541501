import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalMancataConsegnaPA } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalMancataConsegnaPA = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalMancataConsegnaPAOpen}
      toggle={() => dispatch(chiudiModalMancataConsegnaPA())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalMancataConsegnaPA())}
        className="h4 font-regular font-grigio-scuro"
      >
        Mancata consegna
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Lo SdI non riesce a consegnare la fattura ai sistemi informatici della
          pubblica amministrazione perché il destinatario non è raggiungibile 😕
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Ci proverà per altri 10 giorni, dopodichè ti recapiterà l&apos;attestazione
          di mancata consegna. Potrai inviare questo documento alla pubblica
          amministrazione come prova di invio.
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalMancataConsegnaPA

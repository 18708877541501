import { useState, useEffect } from 'react'
import { idrataEnpam, salvaSchedaSulServer } from '../utility/QueryEnpam'
import { useSelector } from 'react-redux'
import { selectUser } from '../store/user/selectors'

const genereMaschile = {
  il: 'il',
  ilTuo: 'il tuo',
  lui: 'lui',
  ilDottore: 'il Dottore',
  dalTuo: 'dal tuo',
  luiStesso: 'lui stesso',
}

const genereFemminile = {
  il: 'la',
  ilTuo: 'la tua',
  lui: 'lei',
  ilDottore: 'la Dottoressa',
  dalTuo: 'dalla tua',
  luiStesso: 'lei stessa',
}

const emptyState = {
  caricamento: true,
  scheda: 'chiuso',
  genere: genereMaschile,
  importo_modello_d: 0,
  quota_a: 0,
}

const useEnpam = () => {
  const [state, setState] = useState(emptyState)
  const user = useSelector(selectUser)

  useEffect(() => {
    idrata()
    if (user.commercilista_uomo === false) {
      setState((prevState) => ({
        ...prevState,
        genere: genereFemminile,
      }))
    }
  }, [user.commercilista_uomo])

  const idrata = async () => {
    try {
      const responseBody = await idrataEnpam(user)
      setState((prevState) => ({
        ...prevState,
        scheda: responseBody.scheda,
        caricamento: false,
        importo_modello_d: responseBody.importo_modello_d,
        quota_a: responseBody.quota_a,
      }))
    } catch (error) {
      console.error(error.message)
      setState((prevState) => ({
        ...prevState,
        caricamento: false,
      }))
    }
  }

  const setScheda = (scheda) => {
    setState((prevState) => ({
      ...prevState,
      scheda,
    }))
    salvaSchedaSulServer(user, scheda)
  }

  return {
    dati: state,
    setScheda,
  }
}

export default useEnpam

import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalMancataConsegnaPR } from '../../../app/store/riepilogoFatture/slice'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const ModalMancataConsegnaPR = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalMancataConsegnaPROpen}
      toggle={() => dispatch(chiudiModalMancataConsegnaPR())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalMancataConsegnaPR())}
        className="h4 font-regular font-grigio-scuro"
      >
        Mancata consegna
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Lo SdI non riesce a consegnare la fattura al cliente perché non è
          raggiungibile. Probabilmente c&apos;è un errore nel codice destinatario o
          nella PEC, che potrebbe essere anche piena. 😕
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Scarica il PDF della fattura di cortesia e il PDF in formato ministeriale.
          Poi inviali per email al tuo cliente, facendo presente che la fattura è
          stata inviata anche tramite lo SdI.
        </h6>
      </ModalBody>
    </Modal>
  )
}

export default ModalMancataConsegnaPR

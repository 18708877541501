import React, { useContext, useEffect, useState } from 'react'
import { DichiarazioneContext } from '../../app/context/Dichiarazione'
import { Row, Col, Card, Container, CardTitle, Alert } from 'reactstrap'
import { BloccoF24Modal } from './component/BloccoF24Modal'
import moment from 'moment'

const Archivio = () => {
  const context = useContext(DichiarazioneContext)
  const [bloccoF24ModalOpen, setBloccoF24ModalOpen] = useState(false)

  useEffect(() => {
    context.idrataArchivio()
  }, [])

  const handleDownload = (f24) => {
    if (
      moment(f24.data_scadenza, 'DD/MM/YYYY').add(1000, 'day').isBefore(moment())
    ) {
      setBloccoF24ModalOpen(true)
    } else {
      context.scaricaF24(
        f24.id,
        `F24_scadenza_${moment(f24.data_scadenza, 'DD/MM/YYYY').format(
          'DD-MM-YYYY',
        )}.pdf`,
      )
    }
  }

  const documenti = () =>
    context.archivio?.map((dichiarazione, index) => (
      <div key={index} className="mt-4">
        <h4 className="testo-verde" style={{ fontWeight: 600 }}>
          {dichiarazione.anno} - Documenti della dichiarazione relativa al{' '}
          {dichiarazione.anno - 1}
        </h4>
        {dichiarazione.f24s?.map((f24, index) => (
          <div key={index} className="interlinea-35 font-grigio-scuro">
            F24 con scadenza {f24.data_scadenza} &nbsp; &nbsp;
            <i
              className="bi bi-download testo-blu"
              style={{ cursor: 'pointer' }}
              onClick={() => handleDownload(f24)}
            ></i>
          </div>
        ))}
        {dichiarazione.f24s?.length === 0 &&
          'Non ci sono F24 per questa dichiarazione'}
        {dichiarazione.modello_redditi !== null && (
          <div className="interlinea-35 font-grigio-scuro">
            Modello Redditi &nbsp; &nbsp;
            <i
              className="bi bi-download testo-blu"
              style={{ cursor: 'pointer' }}
              onClick={() =>
                context.scaricaModelloRedditi(
                  dichiarazione.modello_redditi.id,
                  'Modello_Redditi.pdf',
                )
              }
            ></i>
          </div>
        )}
      </div>
    ))

  return (
    <Container>
      <div className="mt-4 mb-5">
        <Row>
          <Col xs={5}>
            <Card body style={{ height: '100%' }}>
              <center>
                <img
                  src="/img/dichiarazione/archivio.webp"
                  alt="archivio"
                  style={{ width: '60%' }}
                />
              </center>
            </Card>
          </Col>
          <Col xs={7}>
            <Card body>
              <CardTitle className="h3 font-grigio-scuro">
                <i className="bi bi-archive"></i> Archivio delle dichiarazioni
              </CardTitle>
              <h6 className="font-regular interlinea-30">
                In questa sezione sono conservati gli F24 e i Modelli Redditi degli
                anni passati. Se vuoi dargli un&apos;occhiata, li puoi scaricare
                facilmente.
              </h6>
            </Card>
            <Card
              body
              className="mt-4 px-5"
              style={{ color: '#fff', backgroundColor: '#66d98e' }}
            >
              <center>
                <h6
                  className="interlinea-30"
                  style={{ fontWeight: 600, fontSize: '1.1rem' }}
                >
                  <i>
                    Il Modello Redditi è la dichiarazione dei redditi delle persone
                    che hanno la Partita IVA
                  </i>
                </h6>
              </center>
            </Card>
          </Col>
        </Row>
        <Card body className="mt-4">
          {context.dati?.archivio?.length === 0 ? (
            <Alert color="warning" className="mt-5">
              Non ci sono documenti nell&apos;archivio perché non hai mai fatto una
              dichiarazione con Fiscomed 😊
            </Alert>
          ) : (
            documenti()
          )}
          <br />
          <br />
        </Card>
      </div>
      <BloccoF24Modal
        isOpen={bloccoF24ModalOpen}
        onClose={() => setBloccoF24ModalOpen(false)}
      />
    </Container>
  )
}

export default Archivio

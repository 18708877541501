import React from 'react'
import { Button, Progress, Card } from 'reactstrap'
import Ricomincia from '../Ricomincia'

const UnoSei = ({ setScheda }) => (
  <Card body className="h-100 d-flex d-column p-5">
    <Progress value={70} className="progress-gialla" />
    <br />
    <br />
    <h4 className="testo-verde font-bold mb-4">TI SPIEGHIAMO COME FARE</h4>
    <h6 className="font-regular mb-3 interlinea-30">
      Per richiedere l&apos;aliquota agevolata devi compilare il modulo &quot;
      <b>Contribuzione Ridotta</b>&quot; prima dell&apos;invio del Modello D.
      <br />
      <br />
      Ecco gli <b>step</b> da seguire:
      <br />
      <br />
      1. Accedi alla tua <b>Area Riservata</b>
      <br />
      2. Nella colonna di sinistra, clicca su &quot;
      <b>Domande e dichiarazioni online</b>&quot;
      <br />
      3. Sempre nella colonna a sinistra, clicca su &quot;
      <b>Contribuzione Ridotta</b>&quot;.
      <br />
      4. Clicca su &quot;<b>Compila un nuovo modulo</b>&quot;.
    </h6>
    <br />
    <br />
    <div className="mt-auto mb-3">
      <Ricomincia setScheda={setScheda} />
      <Button
        className="bottone-blu float-end"
        style={{ width: '130px' }}
        onClick={() => setScheda('.17')}
      >
        AVANTI <i className="bi bi-arrow-right-short"></i>
      </Button>
    </div>
  </Card>
)

export default UnoSei

import React from 'react'
import { Row, Col, Card } from 'reactstrap'

const Chiuso = ({ dati }) => (
  <Card body className="h-100 d-flex d-column">
    <Row>
      <Col xs={5}>
        <center>
          <img
            src="/img/enpam/chiuso.webp"
            className="mt-5"
            style={{ width: '90%' }}
          />
        </center>
      </Col>
      <Col xs={7}>
        <br />
        <h1 className="font-bold mt-5">Non è il momento</h1>
        <h6 className="font-regular mb-3 mt-4 interlinea-30">
          Ogni anno {dati.genere.ilTuo} commercialista valuta se devi o meno inviare
          il Modello D.
          <br />
          <br />
          Se la risposta è sì, a partire dal mese di luglio troverai qui la procedura
          guidata per la richiesta di contribuzione ridotta e l&apos;invio del
          Modello D.
          <br />
          <br />
          Al contrario, se non devi inviare il Modello D, questa sezione resterà
          chiusa ancora per un po&apos;. Infatti, il Modello D si compila per
          dichiarare i redditi libero-professionali dell&apos;anno precedente 😊
          <br />
          <br />
        </h6>
      </Col>
    </Row>
  </Card>
)

export default Chiuso

import React from 'react'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { apriModalScartoVariazione } from '../../../app/store/riepilogoFatture/slice'

const ScartoVariazione = (props) => {
  const dispatch = useDispatch()
  return (
    <center>
      <Button
        className="bottone-rosso"
        size="sm"
        onClick={() => dispatch(apriModalScartoVariazione(props.dati.fattura.id))}
      >
        SCARTATA
      </Button>
    </center>
  )
}

export default ScartoVariazione

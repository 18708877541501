import React, { useContext } from 'react'
import { DichiarazioneContext } from '../../../app/context/Dichiarazione'

const F24InLavorazione = () => {
  const context = useContext(DichiarazioneContext)

  return (
    <>
      <h1 className="font-bold mt-3">
        Stiamo lavorando <br />
        alla tua dichiarazione 👷‍♀️
      </h1>
      <h6 className="font-regular mb-3 mt-4 interlinea-30">
        Grazie ai <i>documenti caricati</i>, al <i>questionario compilato</i> e alla
        consultazione del <i>cassetto fiscale</i>, {context.genere?.ilTuo}{' '}
        commercialista può preparare per te il documento per la dichiarazione e il
        facsimile degli <span className="font-bold">F24</span> che devi pagare.
        <br />
        <br />
        Nel caso il cui {context.genere?.ilTuo} commercialista avesse bisogno di
        chiarimenti o qualche informazione in più, sarà {context.genere?.lui} a
        contattarti per email o con una chiamata telefonica.{' '}
        <span className="font-bold">Se non si farà sentire, meglio</span>: vuol diche
        ste sta filando tutto liscio 😊
        <br />
        <br />
        Fra il <span className="font-bold testo-verde">primo e il 14 luglio</span> i
        tuoi F24 saranno pronti. A quel punto, riceverai una email che ti avviserà
        del fatto che puoi trovare gli F24 in questa sezione di Fiscomed. Non solo:
        in quella email troverai tutte le{' '}
        <span className="font-bold">istruzioni da seguire</span> per pagare gli F24 e
        portare a termine questo adempimento.
        <br />
        <br />
        <span className="font-bold">Attenzione</span>: non è detto che tu abbia degli
        F24 da pagare. In questo caso, ti invieremo un avviso tramite email e qui non
        troverai alcun documento.
        <br />
        <br />
      </h6>
    </>
  )
}

export default F24InLavorazione

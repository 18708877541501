import React from 'react'
import { Container } from 'reactstrap'
import { useParams } from 'react-router-dom'
import ErroreTrasmissione from './component/ErroreTrasmissione'
import IstruzioniAutofattura from './component/IstruzioniAutofattura'
import { Helmet } from 'react-helmet'

const EsitoAutofattura = () => {
  const { esito, iva, idFattura } = useParams()

  return (
    <Container className="mt-5">
      <Helmet>
        <title>Esito autofattura</title>
        <meta property="title" content="Esito autofattura" />
        <meta name="robots" content="noindex" />
      </Helmet>
      {esito === 'trasmessa' ? (
        <IstruzioniAutofattura conIva={iva === '1'} idAutofattura={idFattura} />
      ) : (
        <ErroreTrasmissione />
      )}
    </Container>
  )
}

export default EsitoAutofattura

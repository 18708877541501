import React from 'react'
import { Button, Card } from 'reactstrap'
import Nota from '../Componenti/Nota'
import { Link, useHistory } from 'react-router-dom'
import DescrizioneImportoNotaDiVariazionePR from '../Componenti/DescrizioneImportoNotaDiVariazionePR'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectFiscomedData,
  selectIsLoadingFiscomed,
} from '../../../app/store/fiscomed/selectors'
import { setLoading } from '../../../app/store/fiscomed/slice'
import { creaNotaDiDebitoPR } from '../../../app/store/fiscomed/actions'

// TODO: testare se creaNotaDiDEbitoPR funziona
const PannelloNDDpr = () => {
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsLoadingFiscomed)
  const fiscomedData = useSelector(selectFiscomedData)
  const history = useHistory()

  const salvaEStampa = () => {
    if (!isLoading) {
      dispatch(setLoading(true))
      dispatch(creaNotaDiDebitoPR())
        .unwrap()
        .then((idFattura) => {
          dispatch(setLoading(false))
          if (idFattura) {
            history.push(
              '/fiscomed/nuova-fattura/esito-fattura-elettronica/trasmessa/' +
                idFattura,
            )
          } else {
            history.push(
              '/fiscomed/nuova-fattura/esito-fattura-elettronica/non-trasmessa/id',
            )
          }
        })
    }
  }

  return (
    <Card body className="mt-2 mb-5">
      <h4 className="font-medium">
        <i className="bi bi-person-video2"></i> Cliente
      </h4>
      <h5 className="font-regular">
        {fiscomedData.clienteDiRiferimento?.ragione_sociale}
      </h5>
      <div className="mt-5" />
      <h4 className="d-inline align-bottom font-regular">
        <i className="bi bi-link"></i> Riferimento fattura
      </h4>
      <h5 className="font-regular interlinea-25">
        Numero: {fiscomedData.fatturaDiRiferimento?.numero}
        <br />
        Data di emissione:{' '}
        {moment(fiscomedData.fatturaDiRiferimento?.data_emissione).format(
          'DD/MM/YYYY',
        )}
      </h5>
      <DescrizioneImportoNotaDiVariazionePR />
      <h4 className="d-inline align-bottom font-medium mt-5">
        <i className="bi bi-pencil-square"></i> Nota{' '}
      </h4>
      <Nota />
      <div className="mt-4 mb-1">
        <Button className="bottone-blu float-end" onClick={() => salvaEStampa()}>
          INVIA FATTURA
        </Button>
        <Button
          className="bottone-rosso-outline me-3 float-end"
          tag={Link}
          to="/fiscomed"
        >
          ANNULLA
        </Button>
      </div>
    </Card>
  )
}

export default PannelloNDDpr

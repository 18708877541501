import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user/slice'
import { userMiddleware } from './user/middleware'
import fiscomedReducer from './fiscomed/slice'
import riepilogoFattureReducer from './riepilogoFatture/slice'

export const store = configureStore({
  reducer: {
    user: userReducer,
    fiscomed: fiscomedReducer,
    riepilogoFatture: riepilogoFattureReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(userMiddleware),
})

import request from 'superagent'

export const leadChiamata = (dati, client = 'mdg') =>
  request
    .post(`${process.env.REACT_APP_API_URL}/utente_non_pagante/lead_chiamata`)
    .send({ env: 'production', client, ...dati })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const leadNewsletter = (dati) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/utente_non_pagante/lead_newsletter`)
    .send({ ...dati })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

export const leadGuida = (dati) =>
  request
    .post(`${process.env.REACT_APP_API_URL}/utente_non_pagante/lead_guida`)
    .send({ ...dati })
    .then((response) => response.body)
    .catch((e) => console.error(e.message))

import React from 'react'
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap'

import { useDispatch, useSelector } from 'react-redux'
import { chiudiModalAttestazionePA } from '../../../app/store/riepilogoFatture/slice'
import { downloadAttestazione } from '../../../app/store/riepilogoFatture/actions'
import { selectRiepilogoFattureData } from '../../../app/store/riepilogoFatture/selectors'

const modalAttestazionePAOpen = () => {
  const dispatch = useDispatch()
  const dati = useSelector(selectRiepilogoFattureData)

  return (
    <Modal
      isOpen={dati.modalAttestazionePAOpen}
      toggle={() => dispatch(chiudiModalAttestazionePA())}
    >
      <ModalHeader
        toggle={() => dispatch(chiudiModalAttestazionePA())}
        className="h4 font-regular font-grigio-scuro"
      >
        Attestazione di trasmissione
      </ModalHeader>
      <ModalBody>
        <h6 style={{ lineHeight: '25px' }}>
          Lo SdI ha provato per 10 giorni a consegnare la fattura alla pubblica
          amministrazione, ma non ci è riuscito. 😕 Per questo motivo è stata creata
          un&apos;attestazione di trasmissione, che serve a provare che tu hai
          correttamente inviato la fattura.
        </h6>
        <br />
        <h5 style={{ fontWeight: '500' }}>E ora?</h5>
        <h6 style={{ lineHeight: '25px' }}>
          Clicca sul bottone qui sotto per scaricare l&apos;attestazione e inviarla
          alla pubblica amministrazione
        </h6>
        <br />
        <Button
          className="bottone-blu"
          onClick={() => dispatch(downloadAttestazione())}
        >
          <i className="bi bi-cloud-download"></i> SCARICA ATTESTAZIONE
        </Button>
        <br />
      </ModalBody>
    </Modal>
  )
}

export default modalAttestazionePAOpen

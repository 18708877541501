import React, { useContext } from 'react'
import { Spinner, Container, Card, Row, Col } from 'reactstrap'
import { Helmet } from 'react-helmet'
import { DichiarazioneContext } from '../../app/context/Dichiarazione'
import Header from './component/Header'
import CardArchivio from './component/CardArchivio'
import StepHeader from './component/StepHeader'
import StepSelector from './component/StepSelector'
import Medaglie from './component/Medaglie'
import Chiuso from './Step/Chiuso'
import Delega from './Step/Delega'
import ModalFileUpload from './component/ModalFileUpload'
import ModalViewFile from './component/ModalViewFile'

const DichiarazioneContainer = () => {
  const context = useContext(DichiarazioneContext)

  const caricamento = (
    <center>
      <Spinner size="sm" className="testo-blu" />
    </center>
  )

  return (
    <Container className="mt-4 mb-5" fluid>
      <Helmet>
        <title>Dichiarazione</title>
        <meta property="title" content="Dichiarazione" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ModalFileUpload />
      <ModalViewFile />
      {context.caricamento ? (
        caricamento
      ) : (
        <>
          <Row>
            <Col xs={8}>
              <Header />
            </Col>
            <Col xs={4}>
              <CardArchivio />
            </Col>
            <Col xs={8}>
              {context?.attivo === false ? (
                <Card body className="mt-4 pt-4 h-100">
                  <Chiuso />
                </Card>
              ) : context?.delega === true ? (
                <Card body className="mt-4 pt-4">
                  <StepHeader />
                  <StepSelector />
                </Card>
              ) : (
                <Card body className="mt-4 pt-4">
                  <Delega />
                </Card>
              )}
            </Col>
            <Col xs={4}>
              <Medaglie />
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default DichiarazioneContainer

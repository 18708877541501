import { createSlice } from '@reduxjs/toolkit'
import { emptyUserObject } from './constants'
import { login, loginCommercialista, updateUserInfo } from './actions'

const getInitialState = () => {
  const userContextFromStorage = JSON.parse(localStorage.getItem('userContext'))
  if (userContextFromStorage) {
    return {
      user: { ...userContextFromStorage },
      isLoading: false,
    }
  } else {
    return {
      user: { ...emptyUserObject },
      isLoading: false,
    }
  }
}

const initialState = getInitialState()

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    saveUserInfo: (state, action) => {
      state.user = { ...action.payload }
      state.isLoading = false
    },
    logout: (state) => {
      state.user = { ...emptyUserObject }
      state.isLoading = false
    },
    setLoading: (state, action) => {
      state.isLoading = action.payload
    },
    setError: (state, action) => {
      state.user.error = true
      state.user.message_error = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.user.error = true
        state.user.message_error = action.payload
      })
      .addCase(loginCommercialista.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginCommercialista.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(loginCommercialista.rejected, (state, action) => {
        state.isLoading = false
        state.user.error = true
        state.user.message_error = action.payload
      })
      .addCase(updateUserInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateUserInfo.fulfilled, (state) => {
        state.isLoading = false
      })
      .addCase(updateUserInfo.rejected, (state, action) => {
        state.isLoading = false
        state.user.error = true
        state.user.message_error = action.payload
      })
  },
})

export const { saveUserInfo, logout, setLoading, setError } = userSlice.actions
export default userSlice.reducer

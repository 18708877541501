import React from 'react'
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap'
import { useDispatch } from 'react-redux'
import {
  apriModalEmail,
  apriModalIncasso,
} from '../../../app/store/riepilogoFatture/slice'
import {
  modificaStatoSTS,
  scaricaFatturaCartacea,
  modificaInDaIncassare,
  eliminazioneFattura,
} from '../../../app/store/riepilogoFatture/actions'

const OpzioniCartacea = (props) => {
  const dispatch = useDispatch()

  return (
    <center>
      <UncontrolledDropdown direction="left">
        <DropdownToggle size="sm" outline>
          <i className="bi bi-three-dots-vertical"></i>
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => dispatch(apriModalEmail(props.dati?.fattura?.id))}
          >
            Invia per email
          </DropdownItem>
          <DropdownItem
            onClick={() => dispatch(modificaStatoSTS(props.dati?.fattura?.id))}
          >
            {props.dati?.fattura?.inviata_ts
              ? 'Segna come non inviata a STS'
              : 'Segna come inviata a STS'}
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem
            onClick={() => dispatch(scaricaFatturaCartacea(props.dati?.fattura?.id))}
          >
            Scarica pdf
          </DropdownItem>
          {props.dati?.fattura?.incassata === 'Si' ? (
            <DropdownItem
              onClick={() =>
                dispatch(modificaInDaIncassare(props.dati?.fattura?.id))
              }
            >
              Modifica in &quot;da incassare&quot;
            </DropdownItem>
          ) : (
            <DropdownItem
              onClick={() => dispatch(apriModalIncasso(props.dati?.fattura?.id))}
            >
              Incassa
            </DropdownItem>
          )}
          <DropdownItem
            onClick={() => {
              if (
                window.confirm(
                  'Cancellare definitavemente la fattura di ' +
                    props.dati?.fattura?.totale +
                    ' euro?'
                )
              ) {
                dispatch(eliminazioneFattura(props.dati?.fattura?.id))
              }
            }}
          >
            Elimina fattura
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </center>
  )
}

export default OpzioniCartacea

import React, { useState } from 'react'
import { Container, Card, Nav, NavItem, NavLink, Col, Row } from 'reactstrap'
import Invio from './Invio'
import Storico from './Storico'
import Account from './Account'
import classNames from 'classnames'
import Cancellazione from './Cancellazione'
import Variazione from './Variazione'

const TsContainer = (props) => {
  const { credenziali } = props

  const [page, setPage] = useState('invio')

  const impostaPagina = (string) => {
    setPage(string)
  }

  return (
    <Container>
      <Card body className="mt-4 mb-5">
        <h4 className="font-medium">
          <i className="bi bi-credit-card"></i> Sistema tessera sanitaria
        </h4>
        <Row className="my-3">
          <Col md={3}>
            <img src="/img/fisco/tessera_sanitaria.webp" />
          </Col>

          <Col md={9}>
            <h6 className="mt-3 font-regular interlinea-30">
              Il Sistema Tessera Sanitaria è quel sistema che si occupa di comunicare
              all&apos;Agenzia delle Entrate le spese sanitarie (farmaci, visite…)
              che una persona ha affrontato.
              <br />
              Come le farmacie dichiarano i farmaci che una persona compra, i medici
              devono dichiarare al Sistema Tessera Sanitaria i dati delle{' '}
              <b>fatture delle prestazioni sanitarie</b> fatte nei confronti di{' '}
              <b>persone fisiche, residenti in Italia</b>.
              <br />
              In questa schermata puoi inviare al Sistema Tessera Sanitaria i dati
              delle fatture che hai emesso nei confronti di una persona che ha pagato
              per una tua prestazione sanitaria.
            </h6>
          </Col>
        </Row>
        <br />
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: page === 'invio' })}
              onClick={() => impostaPagina('invio')}
            >
              Inserimento
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: page === 'variazione' })}
              onClick={() => impostaPagina('variazione')}
            >
              Variazione
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: page === 'cancellazione' })}
              onClick={() => impostaPagina('cancellazione')}
            >
              Cancellazione
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: page === 'storico' })}
              onClick={() => impostaPagina('storico')}
            >
              Storico
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: page === 'account' })}
              onClick={() => impostaPagina('account')}
            >
              Account
            </NavLink>
          </NavItem>
        </Nav>
        {page === 'invio' && <Invio />}
        {page === 'variazione' && <Variazione />}
        {page === 'cancellazione' && <Cancellazione />}
        {page === 'storico' && <Storico />}
        {page === 'account' && (
          <Account
            credenziali={credenziali}
            impostaCredenziali={props.impostaCredenziali}
          />
        )}
      </Card>
    </Container>
  )
}

export default TsContainer

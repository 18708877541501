import React from 'react'
import PreviewCartacea from './PreviewCartacea'
import PreviewElettronica from './PreviewElettronica'
import PreviewPA from './PreviewPA'
import PreviewProforma from './PreviewProforma'
import PreviewNotaDiCreditoPa from './PreviewNotaDiCreditoPa'
import PreviewNotaDiCreditoPr from './PreviewNotaDiCreditoPr'
import PreviewNotaDiDebitoPr from './PreviewNotaDiDebitoPr'
import PreviewRettificaPr from './PreviewRettificaPr'
import PreviewAutofattura from './PreviewAutofattura'
import { useSelector } from 'react-redux'
import { selectFiscomedData } from '../../../app/store/fiscomed/selectors'

const PreviewContainer = () => {
  const fiscomedData = useSelector(selectFiscomedData)

  return (
    <div>
      {fiscomedData.tipologiaFattura === 'Fattura cartacea' && <PreviewCartacea />}
      {fiscomedData.tipologiaFattura === 'Fattura elettronica' && (
        <PreviewElettronica />
      )}
      {fiscomedData.tipologiaFattura === 'Proforma' && <PreviewProforma />}
      {fiscomedData.tipologiaFattura === 'Nota di credito pa' && (
        <PreviewNotaDiCreditoPa />
      )}
      {fiscomedData.tipologiaFattura === 'Nota di credito pr' && (
        <PreviewNotaDiCreditoPr />
      )}
      {fiscomedData.tipologiaFattura === 'Nota di debito pr' && (
        <PreviewNotaDiDebitoPr />
      )}
      {fiscomedData.tipologiaFattura === 'Fattura PA' && <PreviewPA />}
      {fiscomedData.tipologiaFattura === 'Rettifica pr' && <PreviewRettificaPr />}
      {fiscomedData.tipologiaFattura === 'Autofattura' && <PreviewAutofattura />}
    </div>
  )
}

export default PreviewContainer

import React from 'react'
import { Button } from 'reactstrap'
import { useDispatch } from 'react-redux'
import { apriModalScartoPA } from '../../../app/store/riepilogoFatture/slice'
const ScartoFatturaPA = (props) => {
  const dispatch = useDispatch()

  return (
    <center>
      <Button
        className="bottone-rosso"
        size="sm"
        onClick={() => dispatch(apriModalScartoPA(props.dati.fattura.id))}
      >
        SCARTATA
      </Button>
    </center>
  )
}

export default ScartoFatturaPA
